<ng-container *tuiLet="activeOrganization$ | async as activeOrganization">
  <tui-loader class="loader" [showLoader]="loading$ | async" [overlay]="true">
    <div class="wrap">
      @if (activeOrganization$ | async) {
        <div class="organization">
          <tui-avatar
            [size]="'m'"
            [src]="activeOrganization.imageUrl ? getImageUrl(activeOrganization.imageUrl) : '@tui.building-2'"
            [round]="true"
          ></tui-avatar>

          @if (isExpanded) {
            <div class="organization__name organization__name--long">
              <tui-line-clamp [content]="activeOrganization.name" [lineHeight]="24" [linesLimit]="1"></tui-line-clamp>
            </div>
          }
        </div>
      } @else {
        <div class="organization">
          <tui-avatar [size]="'m'" [src]="'@tui.building-2'" [round]="true"></tui-avatar>

          @if (isExpanded) {
            <div class="organization__name organization__name--long">
              <tui-line-clamp
                [content]="'Нет активной организации'"
                [lineHeight]="24"
                [linesLimit]="1"
              ></tui-line-clamp>
            </div>
          }
        </div>
      }

      @if (isExpanded) {
        <tui-loader class="loader loader--btn" [showLoader]="loadingOrganization$ | async" [overlay]="true">
          <div
            [tuiDropdown]="organizationsActionsTemplate"
            [tuiDropdownOffset]="-10"
            [(tuiDropdownOpen)]="dropdownOpen"
          >
            <button tuiIconButton type="button" appearance="icon" size="xs" iconStart="@tui.ellipsis-vertical"></button>
          </div>
        </tui-loader>
      }
    </div>
  </tui-loader>

  <ng-template #organizationsActionsTemplate>
    <tui-data-list role="menu" class="menu">
      <div class="menu__header">
        <div>
          <div class="menu__title">Организации</div>
          <div class="menu__description">Всего {{ (data$ | async).length || 0 }} организации</div>
        </div>
      </div>
      @for (organization of data$ | async; track organization) {
        <div class="menu-organization__line"></div>
        <button
          tuiOption
          class="menu-organization"
          [ngClass]="{
            active: organization.id === activeOrganization?.id,
          }"
          (click)="setActiveOrganization(organization.id)"
        >
          <tui-avatar
            class="menu-organization__image"
            [size]="'m'"
            [src]="getImageUrl(organization.imageUrl) || '@tui.building-2'"
            [round]="true"
          ></tui-avatar>
          <div class="menu-organization__text">
            <div class="menu-organization__name">{{ organization.name }}</div>
            <div class="menu-organization__description">{{ organization.description }}</div>
          </div>
          @if (organization.id === activeOrganization?.id) {
            <tui-icon class="menu-organization__selected" [icon]="'@tui.check'"></tui-icon>
          }
        </button>
      }
    </tui-data-list>
  </ng-template>
</ng-container>
