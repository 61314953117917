import {OrganizationDto} from '@accounts-api/util';

const STATE_NAME = '[Organizations Card]';

export class InitView {
  static readonly type = `${STATE_NAME} Init View`;
}

export class GetData {
  static readonly type = `${STATE_NAME} Get Data`;
}

export class GetDataSuccess {
  static readonly type = `${STATE_NAME} Get Data Success`;
  constructor(public apiResponse: readonly OrganizationDto[]) {}
}

export class GetDataFailure {
  static readonly type = `${STATE_NAME} Get Data Failure`;
  constructor(public error: string) {}
}

export class GetActiveOrganization {
  static readonly type = `${STATE_NAME} Get Active Organization`;
}

export class GetActiveOrganizationSuccess {
  static readonly type = `${STATE_NAME} Get Active Organization Success`;
  constructor(public apiResponse: OrganizationDto) {}
}

export class GetActiveOrganizationFailure {
  static readonly type = `${STATE_NAME} Get Active Organization Failure`;
  constructor(public error: string) {}
}

export class SetActiveOrganization {
  static readonly type = `${STATE_NAME} Set Active Organization`;
  constructor(public organizationId: string) {}
}

export class SetActiveOrganizationSuccess {
  static readonly type = `${STATE_NAME} Set Active Organization Success`;
}

export class SetActiveOrganizationFailure {
  static readonly type = `${STATE_NAME} Set Active Organization Failure`;
  constructor(public error: string) {}
}
