import {
  API_URL_PREFIX,
  OrganizationCreateBodyDto,
  OrganizationDto,
  OrganizationsGetAllParams,
  OrganizationUpdateBodyDto,
  UserDto,
} from '@accounts-api/util';
import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class OrganizationsApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getAll(rawParams: OrganizationsGetAllParams = {}): Observable<readonly OrganizationDto[]> {
    return this.http.get<null>(`${this.gatewayUrl}/${this.apiPrefix}/organizations`, {params: rawParams});
  }

  create(body: OrganizationCreateBodyDto): Observable<string> {
    return this.http.post<null>(`${this.gatewayUrl}/${this.apiPrefix}/organizations`, body);
  }

  getById(organizationId: string): Observable<OrganizationDto> {
    return this.http.get<null>(`${this.gatewayUrl}/${this.apiPrefix}/organizations/${organizationId}`);
  }

  update(organizationId: string, body: OrganizationUpdateBodyDto): Observable<OrganizationDto> {
    return this.http.patch<null>(`${this.gatewayUrl}/${this.apiPrefix}/organizations/${organizationId}`, body);
  }

  delete(organizationId: string): Observable<null> {
    return this.http.delete<null>(`${this.gatewayUrl}/${this.apiPrefix}/organizations/${organizationId}`);
  }

  getAllUsers(organizationId: string): Observable<readonly UserDto[]> {
    return this.http.get<readonly UserDto[]>(
      `${this.gatewayUrl}/${this.apiPrefix}/organizations/${organizationId}/users`,
    );
  }

  addUsers(organizationId: string, usersIds: string[]): Observable<null> {
    return this.http.post<null>(`${this.gatewayUrl}/${this.apiPrefix}/organizations/${organizationId}/users`, usersIds);
  }

  removeUsers(organizationId: string, usersIds: string[]): Observable<null> {
    return this.http.request<null>(
      'DELETE',
      `${this.gatewayUrl}/${this.apiPrefix}/organizations/${organizationId}/users`,
      {body: usersIds},
    );
  }

  addUser(organizationId: string, userId: string): Observable<null> {
    return this.http.post<null>(
      `${this.gatewayUrl}/${this.apiPrefix}/organizations/${organizationId}/users/${userId}`,
      null,
    );
  }

  removeUser(organizationId: string, userId: string): Observable<null> {
    return this.http.delete<null>(
      `${this.gatewayUrl}/${this.apiPrefix}/organizations/${organizationId}/users/${userId}`,
    );
  }

  uploadImage(image: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('image', image, image.name);

    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/images/organization`, formData, {
      responseType: 'text',
    });
  }

  getImageFullUrl(imageUrl: string): string {
    return `${this.gatewayUrl}/${this.apiPrefix}/Images/organization/${imageUrl}`;
  }

  getActiveOrganization(): Observable<OrganizationDto> {
    return this.http.get<OrganizationDto>(`${this.gatewayUrl}/${this.apiPrefix}/organizations/active`);
  }

  setActiveOrganization(organizationId: string): Observable<null> {
    return this.http.post<null>(`${this.gatewayUrl}/${this.apiPrefix}/organizations/active/${organizationId}`, null);
  }
}
