import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {convertBlobError} from '@common/operators';
import {API_URL_PREFIX, Plan, StructurePlanType} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable, of, switchMap, timer} from 'rxjs';

@Injectable({providedIn: 'root'})
export class OutputApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getIfc(generationVariantId: string): Observable<Blob> {
    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Output/${generationVariantId}/ifc`, {
      responseType: 'blob',
    });
  }

  /** Генерирует IFC-XML по варианту с передачей конфигурации XSL */
  getIfcXml(generationVariantId: string, xslConfiguration: Blob): Observable<Blob> {
    const formData: FormData = new FormData();

    formData.append('xslConfiguration', xslConfiguration);

    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${generationVariantId}/ifc-xml`, formData, {
      responseType: 'blob',
    });
  }

  getPlan(generationTaskId: string, planType?: Plan): Observable<Blob> {
    const params = planType ? {planTypeDto: planType} : undefined;

    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${generationTaskId}/plan`, `"{}"`, {
      params,
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  getStructurePlan(generationVariantId: string, structurePlanType: StructurePlanType): Observable<Blob> {
    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${generationVariantId}/plan/structure`, `"{}"`, {
      params: {
        structurePlanTypeDto: structurePlanType,
      },
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  getTei(variantId: string): Observable<Blob> {
    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${variantId}/tei`, `"{}"`, {
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  awaitIfcFileGeneration(generationVariantId: string): Observable<Blob> {
    const getRequest$ = (): Observable<Blob> =>
      this.http
        .get(`${this.gatewayUrl}/${this.apiPrefix}/Output/${generationVariantId}/ifc`, {
          observe: 'response',
          responseType: 'blob',
        })
        .pipe(
          switchMap(response => {
            if (response.status === 202 || response.status === 204) {
              return timer(3000).pipe(switchMap(getRequest$));
            }

            return of(response.body);
          }),
          convertBlobError(),
        );

    return getRequest$();
  }
}
