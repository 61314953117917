var t = function (e, r) {
  return t = Object.setPrototypeOf || {
    __proto__: []
  } instanceof Array && function (t, e) {
    t.__proto__ = e;
  } || function (t, e) {
    for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && (t[r] = e[r]);
  }, t(e, r);
};
var e = function () {
  return e = Object.assign || function (t) {
    for (var e, r = 1, n = arguments.length; r < n; r++) for (var o in e = arguments[r]) Object.prototype.hasOwnProperty.call(e, o) && (t[o] = e[o]);
    return t;
  }, e.apply(this, arguments);
};
function r(t, e, r, n) {
  return new (r || (r = Promise))(function (o, i) {
    function s(t) {
      try {
        c(n.next(t));
      } catch (t) {
        i(t);
      }
    }
    function a(t) {
      try {
        c(n.throw(t));
      } catch (t) {
        i(t);
      }
    }
    function c(t) {
      var e;
      t.done ? o(t.value) : (e = t.value, e instanceof r ? e : new r(function (t) {
        t(e);
      })).then(s, a);
    }
    c((n = n.apply(t, e || [])).next());
  });
}
function n(t, e) {
  var r,
    n,
    o,
    i = {
      label: 0,
      sent: function () {
        if (1 & o[0]) throw o[1];
        return o[1];
      },
      trys: [],
      ops: []
    },
    s = Object.create(("function" == typeof Iterator ? Iterator : Object).prototype);
  return s.next = a(0), s.throw = a(1), s.return = a(2), "function" == typeof Symbol && (s[Symbol.iterator] = function () {
    return this;
  }), s;
  function a(a) {
    return function (c) {
      return function (a) {
        if (r) throw new TypeError("Generator is already executing.");
        for (; s && (s = 0, a[0] && (i = 0)), i;) try {
          if (r = 1, n && (o = 2 & a[0] ? n.return : a[0] ? n.throw || ((o = n.return) && o.call(n), 0) : n.next) && !(o = o.call(n, a[1])).done) return o;
          switch (n = 0, o && (a = [2 & a[0], o.value]), a[0]) {
            case 0:
            case 1:
              o = a;
              break;
            case 4:
              return i.label++, {
                value: a[1],
                done: !1
              };
            case 5:
              i.label++, n = a[1], a = [0];
              continue;
            case 7:
              a = i.ops.pop(), i.trys.pop();
              continue;
            default:
              if (!(o = i.trys, (o = o.length > 0 && o[o.length - 1]) || 6 !== a[0] && 2 !== a[0])) {
                i = 0;
                continue;
              }
              if (3 === a[0] && (!o || a[1] > o[0] && a[1] < o[3])) {
                i.label = a[1];
                break;
              }
              if (6 === a[0] && i.label < o[1]) {
                i.label = o[1], o = a;
                break;
              }
              if (o && i.label < o[2]) {
                i.label = o[2], i.ops.push(a);
                break;
              }
              o[2] && i.ops.pop(), i.trys.pop();
              continue;
          }
          a = e.call(t, i);
        } catch (t) {
          a = [6, t], n = 0;
        } finally {
          r = o = 0;
        }
        if (5 & a[0]) throw a[1];
        return {
          value: a[0] ? a[1] : void 0,
          done: !0
        };
      }([a, c]);
    };
  }
}
"function" == typeof SuppressedError && SuppressedError;
var o = {
  exports: {}
};
function i() {}
i.prototype = {
  on: function (t, e, r) {
    var n = this.e || (this.e = {});
    return (n[t] || (n[t] = [])).push({
      fn: e,
      ctx: r
    }), this;
  },
  once: function (t, e, r) {
    var n = this;
    function o() {
      n.off(t, o), e.apply(r, arguments);
    }
    return o._ = e, this.on(t, o, r);
  },
  emit: function (t) {
    for (var e = [].slice.call(arguments, 1), r = ((this.e || (this.e = {}))[t] || []).slice(), n = 0, o = r.length; n < o; n++) r[n].fn.apply(r[n].ctx, e);
    return this;
  },
  off: function (t, e) {
    var r = this.e || (this.e = {}),
      n = r[t],
      o = [];
    if (n && e) for (var i = 0, s = n.length; i < s; i++) n[i].fn !== e && n[i].fn._ !== e && o.push(n[i]);
    return o.length ? r[t] = o : delete r[t], this;
  }
}, o.exports = i;
var s = o.exports.TinyEmitter = i,
  a = function (t) {
    var e = t[1];
    return null != e;
  },
  c = function (t) {
    var e = t.properties,
      r = void 0 === e ? {} : e,
      n = function (t, e) {
        var r = {};
        for (var n in t) Object.prototype.hasOwnProperty.call(t, n) && e.indexOf(n) < 0 && (r[n] = t[n]);
        if (null != t && "function" == typeof Object.getOwnPropertySymbols) {
          var o = 0;
          for (n = Object.getOwnPropertySymbols(t); o < n.length; o++) e.indexOf(n[o]) < 0 && Object.prototype.propertyIsEnumerable.call(t, n[o]) && (r[n[o]] = t[n[o]]);
        }
        return r;
      }(t, ["properties"]),
      o = function (t) {
        return Object.entries(t).sort(function (t, e) {
          var r = t[0],
            n = e[0];
          return r.localeCompare(n, void 0);
        });
      };
    return JSON.stringify([o(n), o(r)]);
  },
  u = function (t) {
    return r(void 0, void 0, void 0, function () {
      var e;
      return n(this, function (o) {
        switch (o.label) {
          case 0:
            e = c(t), o.label = 1;
          case 1:
            return o.trys.push([1, 3,, 4]), [4, (i = e, r(void 0, void 0, void 0, function () {
              var t, e, r, o, s;
              return n(this, function (n) {
                switch (n.label) {
                  case 0:
                    if (t = "undefined" != typeof globalThis && (null === (o = globalThis.crypto) || void 0 === o ? void 0 : o.subtle) ? null === (s = globalThis.crypto) || void 0 === s ? void 0 : s.subtle : void 0, "undefined" == typeof TextEncoder || !(null == t ? void 0 : t.digest) || "undefined" == typeof Uint8Array) throw new Error("Hashing function not available");
                    return e = new TextEncoder().encode(i), [4, t.digest("SHA-256", e)];
                  case 1:
                    return r = n.sent(), [2, Array.from(new Uint8Array(r)).map(function (t) {
                      return t.toString(16).padStart(2, "0");
                    }).join("")];
                }
              });
            }))];
          case 2:
            return [2, o.sent()];
          case 3:
            return o.sent(), [2, e];
          case 4:
            return [2];
        }
        var i;
      });
    });
  },
  l = function () {},
  h = function () {
    function t(t) {
      var e = t.onError,
        r = t.onSent,
        n = t.appName,
        o = t.metricsInterval,
        i = t.disableMetrics,
        s = void 0 !== i && i,
        a = t.url,
        c = t.clientKey,
        u = t.fetch,
        h = t.headerName,
        p = t.customHeaders,
        f = void 0 === p ? {} : p,
        d = t.metricsIntervalInitial;
      this.onError = e, this.onSent = r || l, this.disabled = s, this.metricsInterval = 1e3 * o, this.metricsIntervalInitial = 1e3 * d, this.appName = n, this.url = a instanceof URL ? a : new URL(a), this.clientKey = c, this.bucket = this.createEmptyBucket(), this.fetch = u, this.headerName = h, this.customHeaders = f;
    }
    return t.prototype.start = function () {
      var t = this;
      if (this.disabled) return !1;
      "number" == typeof this.metricsInterval && this.metricsInterval > 0 && (this.metricsIntervalInitial > 0 ? setTimeout(function () {
        t.startTimer(), t.sendMetrics();
      }, this.metricsIntervalInitial) : this.startTimer());
    }, t.prototype.stop = function () {
      this.timer && (clearTimeout(this.timer), delete this.timer);
    }, t.prototype.createEmptyBucket = function () {
      return {
        start: new Date(),
        stop: null,
        toggles: {}
      };
    }, t.prototype.getHeaders = function () {
      var t,
        e = ((t = {})[this.headerName] = this.clientKey, t.Accept = "application/json", t["Content-Type"] = "application/json", t);
      return Object.entries(this.customHeaders).filter(a).forEach(function (t) {
        var r = t[0],
          n = t[1];
        return e[r] = n;
      }), e;
    }, t.prototype.sendMetrics = function () {
      return r(this, void 0, void 0, function () {
        var t, e, r;
        return n(this, function (n) {
          switch (n.label) {
            case 0:
              if (t = "".concat(this.url, "/client/metrics"), e = this.getPayload(), this.bucketIsEmpty(e)) return [2];
              n.label = 1;
            case 1:
              return n.trys.push([1, 3,, 4]), [4, this.fetch(t, {
                cache: "no-cache",
                method: "POST",
                headers: this.getHeaders(),
                body: JSON.stringify(e)
              })];
            case 2:
              return n.sent(), this.onSent(e), [3, 4];
            case 3:
              return r = n.sent(), console.error("Unleash: unable to send feature metrics", r), this.onError(r), [3, 4];
            case 4:
              return [2];
          }
        });
      });
    }, t.prototype.count = function (t, e) {
      return !(this.disabled || !this.bucket) && (this.assertBucket(t), this.bucket.toggles[t][e ? "yes" : "no"]++, !0);
    }, t.prototype.countVariant = function (t, e) {
      return !(this.disabled || !this.bucket) && (this.assertBucket(t), this.bucket.toggles[t].variants[e] ? this.bucket.toggles[t].variants[e] += 1 : this.bucket.toggles[t].variants[e] = 1, !0);
    }, t.prototype.assertBucket = function (t) {
      if (this.disabled || !this.bucket) return !1;
      this.bucket.toggles[t] || (this.bucket.toggles[t] = {
        yes: 0,
        no: 0,
        variants: {}
      });
    }, t.prototype.startTimer = function () {
      var t = this;
      this.timer = setInterval(function () {
        t.sendMetrics();
      }, this.metricsInterval);
    }, t.prototype.bucketIsEmpty = function (t) {
      return 0 === Object.keys(t.bucket.toggles).length;
    }, t.prototype.getPayload = function () {
      var t = e(e({}, this.bucket), {
        stop: new Date()
      });
      return this.bucket = this.createEmptyBucket(), {
        bucket: t,
        appName: this.appName,
        instanceId: "browser"
      };
    }, t;
  }(),
  p = function () {
    function t() {
      this.store = new Map();
    }
    return t.prototype.save = function (t, e) {
      return r(this, void 0, void 0, function () {
        return n(this, function (r) {
          return this.store.set(t, e), [2];
        });
      });
    }, t.prototype.get = function (t) {
      return r(this, void 0, void 0, function () {
        return n(this, function (e) {
          return [2, this.store.get(t)];
        });
      });
    }, t;
  }(),
  f = function () {
    function t(t) {
      void 0 === t && (t = "unleash:repository"), this.prefix = t;
    }
    return t.prototype.save = function (t, e) {
      return r(this, void 0, void 0, function () {
        var r, o;
        return n(this, function (n) {
          r = JSON.stringify(e), o = "".concat(this.prefix, ":").concat(t);
          try {
            window.localStorage.setItem(o, r);
          } catch (t) {
            console.error(t);
          }
          return [2];
        });
      });
    }, t.prototype.get = function (t) {
      try {
        var e = "".concat(this.prefix, ":").concat(t),
          r = window.localStorage.getItem(e);
        return r ? JSON.parse(r) : void 0;
      } catch (t) {
        console.error(t);
      }
    }, t;
  }();
let d;
const v = new Uint8Array(16);
function m() {
  if (!d && (d = "undefined" != typeof crypto && crypto.getRandomValues && crypto.getRandomValues.bind(crypto), !d)) throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return d(v);
}
const g = [];
for (let t = 0; t < 256; ++t) g.push((t + 256).toString(16).slice(1));
var y = {
  randomUUID: "undefined" != typeof crypto && crypto.randomUUID && crypto.randomUUID.bind(crypto)
};
function b(t, e, r) {
  if (y.randomUUID && !e && !t) return y.randomUUID();
  const n = (t = t || {}).random || (t.rng || m)();
  if (n[6] = 15 & n[6] | 64, n[8] = 63 & n[8] | 128, e) {
    r = r || 0;
    for (let t = 0; t < 16; ++t) e[r + t] = n[t];
    return e;
  }
  return function (t, e = 0) {
    return g[t[e + 0]] + g[t[e + 1]] + g[t[e + 2]] + g[t[e + 3]] + "-" + g[t[e + 4]] + g[t[e + 5]] + "-" + g[t[e + 6]] + g[t[e + 7]] + "-" + g[t[e + 8]] + g[t[e + 9]] + "-" + g[t[e + 10]] + g[t[e + 11]] + g[t[e + 12]] + g[t[e + 13]] + g[t[e + 14]] + g[t[e + 15]];
  }(n);
}
var T = function () {
    function t() {}
    return t.prototype.generateEventId = function () {
      return b();
    }, t.prototype.createImpressionEvent = function (t, r, n, o, i, s) {
      var a = this.createBaseEvent(t, r, n, o, i);
      return s ? e(e({}, a), {
        variant: s
      }) : a;
    }, t.prototype.createBaseEvent = function (t, e, r, n, o) {
      return {
        eventType: n,
        eventId: this.generateEventId(),
        context: t,
        enabled: e,
        featureName: r,
        impressionData: o
      };
    }, t;
  }(),
  w = ["userId", "sessionId", "remoteAddress", "currentTime"],
  E = function (t) {
    return w.includes(t);
  },
  S = {
    INIT: "initialized",
    ERROR: "error",
    READY: "ready",
    UPDATE: "update",
    IMPRESSION: "impression",
    SENT: "sent",
    RECOVERED: "recovered"
  },
  I = "isEnabled",
  R = "getVariant",
  x = {
    name: "disabled",
    enabled: !1,
    feature_enabled: !1
  },
  O = "repo",
  k = "repoLastUpdateTimestamp",
  A = function () {
    try {
      if ("undefined" != typeof window && "fetch" in window) return fetch.bind(window);
      if ("fetch" in globalThis) return fetch.bind(globalThis);
    } catch (t) {
      console.error('Unleash failed to resolve "fetch"', t);
    }
  },
  N = function (o) {
    function i(t) {
      var r = t.storageProvider,
        n = t.url,
        i = t.clientKey,
        s = t.disableRefresh,
        a = void 0 !== s && s,
        c = t.refreshInterval,
        u = void 0 === c ? 30 : c,
        l = t.metricsInterval,
        d = void 0 === l ? 30 : l,
        v = t.metricsIntervalInitial,
        m = void 0 === v ? 2 : v,
        g = t.disableMetrics,
        y = void 0 !== g && g,
        b = t.appName,
        w = t.environment,
        E = void 0 === w ? "default" : w,
        I = t.context,
        R = t.fetch,
        x = void 0 === R ? A() : R,
        O = t.createAbortController,
        k = void 0 === O ? function () {
          try {
            if ("undefined" != typeof window && "AbortController" in window) return function () {
              return new window.AbortController();
            };
            if ("fetch" in globalThis) return function () {
              return new globalThis.AbortController();
            };
          } catch (t) {
            console.error('Unleash failed to resolve "AbortController" factory', t);
          }
        }() : O,
        N = t.bootstrap,
        U = t.bootstrapOverride,
        D = void 0 === U || U,
        j = t.headerName,
        C = void 0 === j ? "Authorization" : j,
        P = t.customHeaders,
        L = void 0 === P ? {} : P,
        H = t.impressionDataAll,
        M = void 0 !== H && H,
        _ = t.usePOSTrequests,
        F = void 0 !== _ && _,
        K = t.experimental,
        V = o.call(this) || this;
      if (V.toggles = [], V.etag = "", V.readyEventEmitted = !1, V.fetchedFromServer = !1, V.usePOSTrequests = !1, V.started = !1, !n) throw new Error("url is required");
      if (!i) throw new Error("clientKey is required");
      if (!b) throw new Error("appName is required.");
      return V.eventsHandler = new T(), V.impressionDataAll = M, V.toggles = N && N.length > 0 ? N : [], V.url = n instanceof URL ? n : new URL(n), V.clientKey = i, V.headerName = C, V.customHeaders = L, V.storage = r || ("undefined" != typeof window ? new f() : new p()), V.refreshInterval = a ? 0 : 1e3 * u, V.context = e({
        appName: b,
        environment: E
      }, I), V.usePOSTrequests = F, V.sdkState = "initializing", V.experimental = e({}, K), (null == K ? void 0 : K.togglesStorageTTL) && (null == K ? void 0 : K.togglesStorageTTL) > 0 && (V.experimental.togglesStorageTTL = 1e3 * K.togglesStorageTTL), V.lastRefreshTimestamp = 0, V.ready = new Promise(function (t) {
        V.init().then(t).catch(function (e) {
          console.error(e), V.sdkState = "error", V.emit(S.ERROR, e), V.lastError = e, t();
        });
      }), x || console.error('Unleash: You must either provide your own "fetch" implementation or run in an environment where "fetch" is available.'), k || console.error('Unleash: You must either provide your own "AbortController" implementation or run in an environment where "AbortController" is available.'), V.fetch = x, V.createAbortController = k, V.bootstrap = N && N.length > 0 ? N : void 0, V.bootstrapOverride = D, V.metrics = new h({
        onError: V.emit.bind(V, S.ERROR),
        onSent: V.emit.bind(V, S.SENT),
        appName: b,
        metricsInterval: d,
        disableMetrics: y,
        url: V.url,
        clientKey: i,
        fetch: x,
        headerName: C,
        customHeaders: L,
        metricsIntervalInitial: m
      }), V;
    }
    return function (e, r) {
      if ("function" != typeof r && null !== r) throw new TypeError("Class extends value " + String(r) + " is not a constructor or null");
      function n() {
        this.constructor = e;
      }
      t(e, r), e.prototype = null === r ? Object.create(r) : (n.prototype = r.prototype, new n());
    }(i, o), i.prototype.getAllToggles = function () {
      return function (t, e, r) {
        if (r || 2 === arguments.length) for (var n, o = 0, i = e.length; o < i; o++) !n && o in e || (n || (n = Array.prototype.slice.call(e, 0, o)), n[o] = e[o]);
        return t.concat(n || Array.prototype.slice.call(e));
      }([], this.toggles, !0);
    }, i.prototype.isEnabled = function (t) {
      var e,
        r = this.toggles.find(function (e) {
          return e.name === t;
        }),
        n = !!r && r.enabled;
      if (this.metrics.count(t, n), (null == r ? void 0 : r.impressionData) || this.impressionDataAll) {
        var o = this.eventsHandler.createImpressionEvent(this.context, n, t, I, null !== (e = null == r ? void 0 : r.impressionData) && void 0 !== e ? e : void 0);
        this.emit(S.IMPRESSION, o);
      }
      return n;
    }, i.prototype.getVariant = function (t) {
      var r,
        n = this.toggles.find(function (e) {
          return e.name === t;
        }),
        o = (null == n ? void 0 : n.enabled) || !1,
        i = n ? n.variant : x;
      if (i.name && this.metrics.countVariant(t, i.name), this.metrics.count(t, o), (null == n ? void 0 : n.impressionData) || this.impressionDataAll) {
        var s = this.eventsHandler.createImpressionEvent(this.context, o, t, R, null !== (r = null == n ? void 0 : n.impressionData) && void 0 !== r ? r : void 0, i.name);
        this.emit(S.IMPRESSION, s);
      }
      return e(e({}, i), {
        feature_enabled: o
      });
    }, i.prototype.updateToggles = function () {
      return r(this, void 0, void 0, function () {
        var t = this;
        return n(this, function (e) {
          switch (e.label) {
            case 0:
              return this.timerRef || this.fetchedFromServer ? [4, this.fetchToggles()] : [3, 2];
            case 1:
              return e.sent(), [3, 4];
            case 2:
              return this.started ? [4, new Promise(function (e) {
                var r = function () {
                  t.fetchToggles().then(function () {
                    t.off(S.READY, r), e();
                  });
                };
                t.once(S.READY, r);
              })] : [3, 4];
            case 3:
              e.sent(), e.label = 4;
            case 4:
              return [2];
          }
        });
      });
    }, i.prototype.updateContext = function (t) {
      return r(this, void 0, void 0, function () {
        var r;
        return n(this, function (n) {
          switch (n.label) {
            case 0:
              return (t.appName || t.environment) && console.warn("appName and environment are static. They can't be updated with updateContext."), r = {
                environment: this.context.environment,
                appName: this.context.appName,
                sessionId: this.context.sessionId
              }, this.context = e(e({}, r), t), [4, this.updateToggles()];
            case 1:
              return n.sent(), [2];
          }
        });
      });
    }, i.prototype.getContext = function () {
      return e({}, this.context);
    }, i.prototype.setContextField = function (t, r) {
      var n, o;
      if (E(t)) this.context = e(e({}, this.context), ((n = {})[t] = r, n));else {
        var i = e(e({}, this.context.properties), ((o = {})[t] = r, o));
        this.context = e(e({}, this.context), {
          properties: i
        });
      }
      this.updateToggles();
    }, i.prototype.removeContextField = function (t) {
      var r;
      E(t) ? this.context = e(e({}, this.context), ((r = {})[t] = void 0, r)) : "object" == typeof this.context.properties && delete this.context.properties[t], this.updateToggles();
    }, i.prototype.setReady = function () {
      this.readyEventEmitted = !0, this.emit(S.READY);
    }, i.prototype.init = function () {
      return r(this, void 0, void 0, function () {
        var t, r, o;
        return n(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this.resolveSessionId()];
            case 1:
              return t = n.sent(), this.context = e({
                sessionId: t
              }, this.context), r = this, [4, this.storage.get(O)];
            case 2:
              return r.toggles = n.sent() || [], o = this, [4, this.getLastRefreshTimestamp()];
            case 3:
              return o.lastRefreshTimestamp = n.sent(), !this.bootstrap || !this.bootstrapOverride && 0 !== this.toggles.length ? [3, 6] : [4, this.storage.save(O, this.bootstrap)];
            case 4:
              return n.sent(), this.toggles = this.bootstrap, this.sdkState = "healthy", [4, this.storeLastRefreshTimestamp()];
            case 5:
              n.sent(), this.setReady(), n.label = 6;
            case 6:
              return this.sdkState = "healthy", this.emit(S.INIT), [2];
          }
        });
      });
    }, i.prototype.start = function () {
      return r(this, void 0, void 0, function () {
        var t,
          e = this;
        return n(this, function (r) {
          switch (r.label) {
            case 0:
              return this.started = !0, this.timerRef ? (console.error("Unleash SDK has already started, if you want to restart the SDK you should call client.stop() before starting again."), [2]) : [4, this.ready];
            case 1:
              return r.sent(), this.metrics.start(), t = this.refreshInterval, [4, this.initialFetchToggles()];
            case 2:
              return r.sent(), t > 0 && (this.timerRef = setInterval(function () {
                return e.fetchToggles();
              }, t)), [2];
          }
        });
      });
    }, i.prototype.stop = function () {
      this.timerRef && (clearInterval(this.timerRef), this.timerRef = void 0), this.metrics.stop();
    }, i.prototype.isReady = function () {
      return this.readyEventEmitted;
    }, i.prototype.getError = function () {
      return "error" === this.sdkState ? this.lastError : void 0;
    }, i.prototype.sendMetrics = function () {
      return this.metrics.sendMetrics();
    }, i.prototype.resolveSessionId = function () {
      return r(this, void 0, void 0, function () {
        var t;
        return n(this, function (e) {
          switch (e.label) {
            case 0:
              return this.context.sessionId ? [2, this.context.sessionId] : [4, this.storage.get("sessionId")];
            case 1:
              return (t = e.sent()) ? [3, 3] : (t = Math.floor(1e9 * Math.random()), [4, this.storage.save("sessionId", t.toString(10))]);
            case 2:
              e.sent(), e.label = 3;
            case 3:
              return [2, t.toString(10)];
          }
        });
      });
    }, i.prototype.getHeaders = function () {
      var t,
        e = this.usePOSTrequests,
        r = ((t = {})[this.headerName] = this.clientKey, t.Accept = "application/json", t);
      return e && (r["Content-Type"] = "application/json"), this.etag && (r["If-None-Match"] = this.etag), Object.entries(this.customHeaders).filter(a).forEach(function (t) {
        var e = t[0],
          n = t[1];
        return r[e] = n;
      }), r;
    }, i.prototype.storeToggles = function (t) {
      return r(this, void 0, void 0, function () {
        return n(this, function (e) {
          switch (e.label) {
            case 0:
              return this.toggles = t, this.emit(S.UPDATE), [4, this.storage.save(O, t)];
            case 1:
              return e.sent(), [2];
          }
        });
      });
    }, i.prototype.isTogglesStorageTTLEnabled = function () {
      var t;
      return !!((null === (t = this.experimental) || void 0 === t ? void 0 : t.togglesStorageTTL) && this.experimental.togglesStorageTTL > 0);
    }, i.prototype.isUpToDate = function () {
      var t;
      if (!this.isTogglesStorageTTLEnabled()) return !1;
      var e = Date.now(),
        r = (null === (t = this.experimental) || void 0 === t ? void 0 : t.togglesStorageTTL) || 0;
      return this.lastRefreshTimestamp > 0 && this.lastRefreshTimestamp <= e && e - this.lastRefreshTimestamp <= r;
    }, i.prototype.getLastRefreshTimestamp = function () {
      return r(this, void 0, void 0, function () {
        var t, e;
        return n(this, function (r) {
          switch (r.label) {
            case 0:
              return this.isTogglesStorageTTLEnabled() ? [4, this.storage.get(k)] : [3, 3];
            case 1:
              return t = r.sent(), [4, u(this.context)];
            case 2:
              return e = r.sent(), [2, (null == t ? void 0 : t.key) === e ? t.timestamp : 0];
            case 3:
              return [2, 0];
          }
        });
      });
    }, i.prototype.storeLastRefreshTimestamp = function () {
      return r(this, void 0, void 0, function () {
        var t, e;
        return n(this, function (r) {
          switch (r.label) {
            case 0:
              return this.isTogglesStorageTTLEnabled() ? (this.lastRefreshTimestamp = Date.now(), e = {}, [4, u(this.context)]) : [3, 3];
            case 1:
              return e.key = r.sent(), e.timestamp = this.lastRefreshTimestamp, t = e, [4, this.storage.save(k, t)];
            case 2:
              r.sent(), r.label = 3;
            case 3:
              return [2];
          }
        });
      });
    }, i.prototype.initialFetchToggles = function () {
      if (!this.isUpToDate()) return this.fetchToggles();
      this.fetchedFromServer || (this.fetchedFromServer = !0, this.setReady());
    }, i.prototype.fetchToggles = function () {
      return r(this, void 0, void 0, function () {
        var t, e, r, o, i, s, c, u, l;
        return n(this, function (n) {
          switch (n.label) {
            case 0:
              if (!this.fetch) return [3, 9];
              this.abortController && this.abortController.abort(), this.abortController = null === (l = this.createAbortController) || void 0 === l ? void 0 : l.call(this), t = this.abortController ? this.abortController.signal : void 0, n.label = 1;
            case 1:
              return n.trys.push([1, 7, 8, 9]), e = this.usePOSTrequests, r = e ? this.url : function (t, e) {
                var r = new URL(t.toString());
                return Object.entries(e).filter(a).forEach(function (t) {
                  var e = t[0],
                    n = t[1];
                  "properties" === e && n ? Object.entries(n).filter(a).forEach(function (t) {
                    var e = t[0],
                      n = t[1];
                    return r.searchParams.append("properties[".concat(e, "]"), n);
                  }) : r.searchParams.append(e, n);
                }), r;
              }(this.url, this.context), o = e ? "POST" : "GET", i = e ? JSON.stringify({
                context: this.context
              }) : void 0, [4, this.fetch(r.toString(), {
                method: o,
                cache: "no-cache",
                headers: this.getHeaders(),
                body: i,
                signal: t
              })];
            case 2:
              return s = n.sent(), "error" === this.sdkState && s.status < 400 && (this.sdkState = "healthy", this.emit(S.RECOVERED)), s.ok ? (this.etag = s.headers.get("ETag") || "", [4, s.json()]) : [3, 5];
            case 3:
              return c = n.sent(), [4, this.storeToggles(c.toggles)];
            case 4:
              return n.sent(), "healthy" !== this.sdkState && (this.sdkState = "healthy"), this.fetchedFromServer || (this.fetchedFromServer = !0, this.setReady()), this.storeLastRefreshTimestamp(), [3, 6];
            case 5:
              304 === s.status ? this.storeLastRefreshTimestamp() : (console.error("Unleash: Fetching feature toggles did not have an ok response"), this.sdkState = "error", this.emit(S.ERROR, {
                type: "HttpError",
                code: s.status
              }), this.lastError = {
                type: "HttpError",
                code: s.status
              }), n.label = 6;
            case 6:
              return [3, 9];
            case 7:
              return "object" == typeof (u = n.sent()) && null !== u && "name" in u && "AbortError" === u.name || (console.error("Unleash: unable to fetch feature toggles", u), this.sdkState = "error", this.emit(S.ERROR, u), this.lastError = u), [3, 9];
            case 8:
              return this.abortController = null, [7];
            case 9:
              return [2];
          }
        });
      });
    }, i;
  }(s);
export { S as EVENTS, p as InMemoryStorageProvider, f as LocalStorageProvider, N as UnleashClient, k as lastUpdateKey, A as resolveFetch };
