import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, HostBinding, inject, Input} from '@angular/core';
import {RouterModule} from '@angular/router';
import {environment} from '@env/environment';
import {OrganizationsCardState} from '@layout/data-access/organizations-card';
import {Store} from '@ngxs/store';
import {TuiIcon} from '@taiga-ui/core';

import {AppNavMenuComponent} from '../app-nav-menu';

@Component({
  standalone: true,
  imports: [NgIf, RouterModule, TuiIcon, AppNavMenuComponent, NgClass, AsyncPipe],
  selector: 'app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavComponent {
  private readonly store = inject(Store);

  @HostBinding('class._expanded')
  @Input()
  expanded = false;

  readonly stage = environment.isProduction ? '' : 'stage.';
  readonly activeOrganization$ = this.store.select(OrganizationsCardState.activeOrganization);
}
