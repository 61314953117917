import 'iconify-icon';

import {css, html, LitElement} from 'lit';
import {property} from 'lit/decorators.js';

/**
 * A custom button web component for BIM applications. HTML tag: bim-button
 *
 * @fires click - Fired when the button is clicked.
 */
export class Button extends LitElement {
  static override styles = css`
    .real-button {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      appearance: none;
      padding: 0;
      border: 0;
      background-color: #f6f6f6;
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;
      flex-shrink: 0;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: visible;
      vertical-align: middle;
      max-inline-size: 100%;
      gap: 0.25rem;
      block-size: 2.75rem;
      border-radius: 0.25rem;
      padding: 0.825rem;
      user-select: none;
      color: #333333;
      font: inherit;
      font-weight: 700;
      cursor: pointer;
    }

    :host(:not([checked]):hover) .real-button,
    :host(:not([checked]):active) .real-button,
    :host(:not([checked])[active]) .real-button {
      background-color: #ededed;
    }

    :host([checked]) .real-button,
    :host([checked]) .real-button,
    :host([active][checked]) .real-button {
      background-color: #2a61ed;
      color: #fff;
    }

    :host([disabled]) .real-button {
      background-color: gray;
      cursor: default;
    }

    :host(:not([tooltip-visible])) .tooltip {
      display: none;
    }

    iconify-icon {
      height: 1.25rem;
      width: 1.25rem;
      color: inherit;
      transition: color 0.15s;
    }
  `;

  /**
   * The label to be displayed on the button.
   * @type {string}
   * @default undefined
   * @example <bim-button label="Click me"></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   */
  @property({type: String, reflect: true})
  label?: string;

  /**
   * A boolean attribute which, if present, indicates that the button is disabled.
   * @default false
   * @example <bim-button label="Click me" disabled></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   *          button.disabled = true;
   */
  @property({type: Boolean, reflect: true, attribute: 'disabled'})
  disabled = false;

  /**
   * The icon to be displayed on the button.
   * @type {string}
   * @default undefined
   * @example <bim-button icon="my-icon"></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.icon = 'my-icon';
   */
  @property({type: String, reflect: true})
  icon?: string;

  /**
   * The time (in milliseconds) to wait before showing the tooltip when hovering over the button.
   * @type {number}
   * @default 700
   * @example <bim-button label="Click me" tooltip-time="1000"></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   *          button.tooltipTime = 1000;
   */
  @property({type: Number, attribute: 'tooltip-time', reflect: true})
  tooltipTime?: number;

  /**
   * A boolean attribute which, if present, indicates that the tooltip should be visible.
   * @default false
   * @example <bim-button label="Click me" tooltip-visible></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   *          button.tooltipVisible = true;
   */
  @property({type: Boolean, attribute: 'tooltip-visible', reflect: true})
  tooltipVisible = false;

  /**
   * The title of the tooltip to be displayed when hovering over the button.
   * @type {string}
   * @default undefined
   * @example <bim-button label="Click me" tooltip-title="Button Tooltip"></bim-button>
   * @example const button = document.createElement('bim-button');
   *          button.label = 'Click me';
   *          button.tooltipTitle = 'Button Tooltip';
   */
  @property({type: String, attribute: 'tooltip-title', reflect: true})
  tooltipTitle?: string;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  override click() {
    if (!this.disabled) {
      super.click();
    }
  }

  override firstUpdated() {
    this.addEventListener('onmouseenter', this.onMouseEnter);
  }

  protected override render() {
    return html`
      <button class="real-button" @mouseenter=${this.onMouseEnter} @mouseleave=${this.onMouseLeave}>
        ${this.label || this.icon
          ? html`
              <iconify-icon .icon=${this.icon} height="none"></iconify-icon>
            `
          : null}
        <div
          style="position: absolute;
        width: fit-content;
        top: -40px;
        background: #333;
        padding: 5px 5px;
        border-radius: 4px;
        color: #fff;"
          class="tooltip"
        >
          <p style="text-wrap: nowrap; margin: 0;">
            <strong>${this.tooltipTitle}</strong>
          </p>
        </div>
      </button>
    `;
  }

  private onMouseEnter() {
    this.tooltipVisible = true;
  }

  private onMouseLeave() {
    this.tooltipVisible = false;
  }
}
