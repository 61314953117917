import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ArtefactsType, IterationType} from '@common/types';
import {
  API_URL_PREFIX,
  SettlementCheckStatus,
  SettlementGetConverterResource,
  SettlementGetResourceType,
} from '@generation-api-v2/util';
import {FeaResourceType, SourceType} from '@settlement-api/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SettlementApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  checkStatus(generationVariantId: string, iterationType: IterationType = 'First') {
    const params: SettlementCheckStatus = {
      iterationType,
    };

    return this.http.get<boolean>(`${this.gatewayUrl}/${this.apiPrefix}/Settlement/${generationVariantId}/exists`, {
      params: {...params},
    });
  }

  getResource(
    generationVariantId: string,
    feaResourceType: FeaResourceType,
    iterationType: IterationType = 'First',
    artefactsType: ArtefactsType,
  ): Observable<Blob> {
    const params: SettlementGetResourceType = {
      feaResourceType,
      iterationType,
      artefactsType,
    };

    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Settlement/${generationVariantId}/resource`, {
      params: {...params},
      responseType: 'blob',
    });
  }

  getConverterResource(
    generationVariantId: string,
    iterationType: IterationType = 'First',
    artefactsType: ArtefactsType,
    sourceType?: SourceType,
  ): Observable<Blob> {
    const params: SettlementGetConverterResource = {
      iterationType,
      artefactsType,
      ...(sourceType && {sourceType}),
    };

    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Settlement/${generationVariantId}/converter/resource`, {
      params: {...params},
      responseType: 'blob',
    });
  }
}
