import {OrganizationsApiService} from '@accounts-api/data-access';
import {AsyncPipe, NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, HostBinding, inject, Input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {Store} from '@ngxs/store';
import {TuiLet, tuiPure} from '@taiga-ui/cdk';
import {TuiButton, TuiDataList, TuiDropdown, TuiHint, TuiIcon, TuiLoader} from '@taiga-ui/core';
import {TuiAvatar, TuiLineClamp} from '@taiga-ui/kit';

import {InitView, OrganizationsCardState, SetActiveOrganization} from '../../data-access/organizations-card';

@Component({
  standalone: true,
  imports: [
    // Angular
    AsyncPipe,
    RouterLink,
    NgClass,

    // Taiga
    TuiAvatar,
    TuiIcon,
    TuiButton,
    TuiDataList,
    TuiDropdown,
    TuiHint,
    TuiLineClamp,
    TuiLoader,
    TuiLet,
  ],
  selector: 'organizations-card',
  templateUrl: './organizations-card.component.html',
  styleUrls: ['./organizations-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsCardComponent {
  private readonly store = inject(Store);
  private readonly organizationsApiService = inject(OrganizationsApiService);
  readonly data$ = this.store.select(OrganizationsCardState.data);
  readonly activeOrganization$ = this.store.select(OrganizationsCardState.activeOrganization);
  readonly loading$ = this.store.select(OrganizationsCardState.loading);
  readonly loadingOrganization$ = this.store.select(OrganizationsCardState.loadingOrganization);

  isExpanded = false;
  dropdownOpen = false;

  @HostBinding('class._expanded')
  @Input()
  set expanded(value: boolean) {
    if (!value) {
      this.dropdownOpen = false;
    }

    this.isExpanded = value;
  }

  constructor() {
    this.store.dispatch(new InitView());
  }

  @tuiPure
  getImageUrl(imageUrl: string) {
    return this.organizationsApiService.getImageFullUrl(imageUrl);
  }

  setActiveOrganization(organizationId: string) {
    this.dropdownOpen = false;
    this.store.dispatch(new SetActiveOrganization(organizationId));
  }
}
