import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {convertBlobError} from '@common/operators';
import {API_URL_PREFIX, ScoringModelInfoDto, StructureRankingGetResourceQueryParams} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StructureRankingApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getResource(generationVariantId: string, rawParams: StructureRankingGetResourceQueryParams): Observable<Blob> {
    const params = new HttpParams({
      fromObject: {iterationType: rawParams.iterationType, artefactsType: rawParams.artefactsType},
    });

    return this.http
      .get(`${this.gatewayUrl}/${this.apiPrefix}/structureRanking/${generationVariantId}/resource`, {
        params,
        responseType: 'blob',
      })
      .pipe(convertBlobError());
  }

  getModelInfo(generationVariantId: string): Observable<ScoringModelInfoDto> {
    return this.http.get<ScoringModelInfoDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/structureRanking/${generationVariantId}/modelInfo`,
    );
  }
}
