import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  API_URL_PREFIX,
  SimpleApartmentModelDto,
  StoreyPlanDto,
  TypicalStoreyForSlotsDto,
} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SpaceplanningApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getInsolationDxf(generationVariantId: string): Observable<Blob> {
    return this.http.post(
      `${this.gatewayUrl}/${this.apiPrefix}/SpacePlanning/${generationVariantId}/insolation`,
      {},
      {
        responseType: 'blob',
        headers: {'Content-type': 'application/json'},
      },
    );
  }

  getSlotsId(generationTaskId: string): Observable<TypicalStoreyForSlotsDto[]> {
    const headers = {'Content-type': 'application/json'};

    return this.http.get<TypicalStoreyForSlotsDto[]>(
      `${this.gatewayUrl}/${this.apiPrefix}/SpacePlanning/${generationTaskId}/typical-storey-slots`,
      {
        headers,
      },
    );
  }

  getApartments(generationTaskId: string): Observable<SimpleApartmentModelDto[]> {
    const headers = {'Content-type': 'application/json'};

    return this.http.get<SimpleApartmentModelDto[]>(
      `${this.gatewayUrl}/${this.apiPrefix}/SpacePlanning/${generationTaskId}/simple-apartments`,
      {
        headers,
      },
    );
  }

  filterApartments(generationTaskId: string, slotId: string, lastSelectedSlot: StoreyPlanDto[]): Observable<string[]> {
    const slot = JSON.stringify(JSON.stringify(lastSelectedSlot));
    const headers = {'Content-type': 'application/json'};

    return this.http.post<string[]>(
      `${this.gatewayUrl}/${this.apiPrefix}/SpacePlanning/${generationTaskId}/slots/${slotId}/filter-storeys`,
      slot,
      {
        headers,
      },
    );
  }
}
