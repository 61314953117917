import {Pipe, PipeTransform} from '@angular/core';

import {LayoutSectionApartmentSpecificationDto} from '../../dto/layout-section/layout-section-apartment-specification.dto';
import {LayoutSectionBuildingApartmentsSpecificationDto} from '../../dto/layout-section/layout-section-building-apartments-specification.dto';
import {LayoutSectionShareDto} from '../../dto/layout-section/layout-section-share.dto';
import {layoutSectionGetApartName} from '../../scripts/layout-section/layout-section-get-apart-name';

@Pipe({
  standalone: true,
  name: 'layoutSectionApartNamePipe',
  pure: true,
})
export class LayoutSectionApartNamePipe implements PipeTransform {
  transform(
    share:
      | LayoutSectionShareDto
      | LayoutSectionApartmentSpecificationDto
      | LayoutSectionBuildingApartmentsSpecificationDto,
  ): string {
    return layoutSectionGetApartName(share.roomCount, share.apartmentFormat);
  }
}
