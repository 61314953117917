import {ApartmentFormatEnum} from '../../enums/layout-section';

export function layoutSectionGetPluralApartName(roomCount: number, format: ApartmentFormatEnum): string {
  const formatPlural: {
    [key in ApartmentFormatEnum]: string;
  } = {
    Euro: 'Евро',
    Classic: '',
    Studio: 'Студии',
  };

  return `${roomCount}-комнатные ${formatPlural[format]}`.trim();
}
