import {OrganizationCreateBodyDto} from '@accounts-api/util';

import {EntryFormValue} from '../../types';

export function entryFormValueToCreateBody(formValue: EntryFormValue): OrganizationCreateBodyDto {
  const body: OrganizationCreateBodyDto = {
    name: formValue.name,
  };

  if (formValue.description && formValue.description.trim().length > 0) {
    body.description = formValue.description;
  }

  if (formValue.imageUrl) {
    body.imageUrl = formValue.imageUrl;
  }

  return body;
}
