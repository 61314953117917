import {OrganizationDto} from '@accounts-api/util';

export class InitView {
  static readonly type = '[Organizations List View] Init View';
}

export class RemoveEntry {
  static readonly type = '[Organizations List View] Remove Entry';
  constructor(public entry: OrganizationDto) {}
}

export class RemoveEntrySuccess {
  static readonly type = '[Organizations List View] Remove Entry Success';
  constructor(public entry: OrganizationDto) {}
}

export class RemoveEntryFailure {
  static readonly type = '[Organizations List View] Remove Entry Failure';
  constructor(
    public error: string,
    public entry: OrganizationDto,
  ) {}
}
