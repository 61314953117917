import {Pipe, PipeTransform} from '@angular/core';

import {LayoutSectionShareDto} from '../../dto/layout-section/layout-section-share.dto';
import {layoutSectionGetApartColor} from '../../scripts/layout-section/layout-section-get-apart-color';

@Pipe({
  standalone: true,
  name: 'layoutSectionApartColorPipe',
  pure: true,
})
export class LayoutSectionApartColorPipe implements PipeTransform {
  transform(share: LayoutSectionShareDto): string {
    return layoutSectionGetApartColor(share.roomCount, share.apartmentFormat);
  }
}
