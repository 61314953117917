/**
 * Уровень инсоляции
 *
 * @alias 'Platforma.Contracts.Input.MinInsolationLevel'
 */
export enum InsolationLevel {
  Green = 'Green',
  Yellow = 'Yellow',
  Red = 'Red',
  NotInsolated = 'NotInsolated',
}
