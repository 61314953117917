import {ApartmentFormatEnum} from '../../enums/layout-section';

export function layoutSectionGetApartColor(roomCount: number, format: ApartmentFormatEnum): string {
  const apartColors: {
    [key: number]: {
      [key in ApartmentFormatEnum]?: string;
    };
  } = {
    1: {
      Euro: 'rgb(252,100,100)',
      Classic: 'rgb(238,221,149)',
      Studio: 'rgb(229,196,189)',
    },
    2: {
      Euro: 'rgb(221,209,193)',
      Classic: 'rgb(221,205,161)',
    },
    3: {
      Euro: 'rgb(199,210,192)',
      Classic: 'rgb(175, 197, 216)',
    },
    4: {
      Euro: 'rgb(208, 216,220)',
      Classic: 'rgb(205,223,227)',
    },
  };

  return apartColors[roomCount][format] ?? '#ccc';
}
