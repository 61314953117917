import {OrganizationDto} from '@accounts-api/util';

import {EntryFormValue} from '../../types';

export function entryDtoToFormValue(entryDto: OrganizationDto): EntryFormValue {
  return {
    name: entryDto.name,
    description: entryDto.description,
    imageUrl: entryDto.imageUrl,
  };
}
