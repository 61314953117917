/**
 * Тип квартиры
 *
 * @alias '	Platforma.Architecture.Layout.Domain.Section.SectionType'
 */
export enum ApartmentFormatEnum {
  Studio = 'Studio',
  Classic = 'Classic',
  Euro = 'Euro',
}
