import * as A from "@thatopen/components";
import * as J from "web-ifc";
import * as j from "@thatopen/components-front";
import * as R from "three";
import { Object3D as Lr, Vector2 as Dr, Vector3 as gn, Matrix4 as Fo } from "three";
var jr = Object.defineProperty,
  zr = (i, t, e) => t in i ? jr(i, t, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: e
  }) : i[t] = e,
  Mt = (i, t, e) => (zr(i, typeof t != "symbol" ? t + "" : t, e), e);
const ie = Math.min,
  gt = Math.max,
  di = Math.round,
  Et = i => ({
    x: i,
    y: i
  }),
  Fr = {
    left: "right",
    right: "left",
    bottom: "top",
    top: "bottom"
  },
  Br = {
    start: "end",
    end: "start"
  };
function Hn(i, t, e) {
  return gt(i, ie(t, e));
}
function Ve(i, t) {
  return typeof i == "function" ? i(t) : i;
}
function yt(i) {
  return i.split("-")[0];
}
function xi(i) {
  return i.split("-")[1];
}
function Bo(i) {
  return i === "x" ? "y" : "x";
}
function Ho(i) {
  return i === "y" ? "height" : "width";
}
function Ut(i) {
  return ["top", "bottom"].includes(yt(i)) ? "y" : "x";
}
function Uo(i) {
  return Bo(Ut(i));
}
function Hr(i, t, e) {
  e === void 0 && (e = !1);
  const o = xi(i),
    n = Uo(i),
    s = Ho(n);
  let r = n === "x" ? o === (e ? "end" : "start") ? "right" : "left" : o === "start" ? "bottom" : "top";
  return t.reference[s] > t.floating[s] && (r = ui(r)), [r, ui(r)];
}
function Ur(i) {
  const t = ui(i);
  return [Zi(i), t, Zi(t)];
}
function Zi(i) {
  return i.replace(/start|end/g, t => Br[t]);
}
function Vr(i, t, e) {
  const o = ["left", "right"],
    n = ["right", "left"],
    s = ["top", "bottom"],
    r = ["bottom", "top"];
  switch (i) {
    case "top":
    case "bottom":
      return e ? t ? n : o : t ? o : n;
    case "left":
    case "right":
      return t ? s : r;
    default:
      return [];
  }
}
function Wr(i, t, e, o) {
  const n = xi(i);
  let s = Vr(yt(i), e === "start", o);
  return n && (s = s.map(r => r + "-" + n), t && (s = s.concat(s.map(Zi)))), s;
}
function ui(i) {
  return i.replace(/left|right|bottom|top/g, t => Fr[t]);
}
function Gr(i) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...i
  };
}
function Vo(i) {
  return typeof i != "number" ? Gr(i) : {
    top: i,
    right: i,
    bottom: i,
    left: i
  };
}
function ne(i) {
  const {
    x: t,
    y: e,
    width: o,
    height: n
  } = i;
  return {
    width: o,
    height: n,
    top: e,
    left: t,
    right: t + o,
    bottom: e + n,
    x: t,
    y: e
  };
}
function Un(i, t, e) {
  let {
    reference: o,
    floating: n
  } = i;
  const s = Ut(t),
    r = Uo(t),
    a = Ho(r),
    l = yt(t),
    c = s === "y",
    h = o.x + o.width / 2 - n.width / 2,
    u = o.y + o.height / 2 - n.height / 2,
    p = o[a] / 2 - n[a] / 2;
  let d;
  switch (l) {
    case "top":
      d = {
        x: h,
        y: o.y - n.height
      };
      break;
    case "bottom":
      d = {
        x: h,
        y: o.y + o.height
      };
      break;
    case "right":
      d = {
        x: o.x + o.width,
        y: u
      };
      break;
    case "left":
      d = {
        x: o.x - n.width,
        y: u
      };
      break;
    default:
      d = {
        x: o.x,
        y: o.y
      };
  }
  switch (xi(t)) {
    case "start":
      d[r] -= p * (e && c ? -1 : 1);
      break;
    case "end":
      d[r] += p * (e && c ? -1 : 1);
      break;
  }
  return d;
}
const qr = async (i, t, e) => {
  const {
      placement: o = "bottom",
      strategy: n = "absolute",
      middleware: s = [],
      platform: r
    } = e,
    a = s.filter(Boolean),
    l = await (r.isRTL == null ? void 0 : r.isRTL(t));
  let c = await r.getElementRects({
      reference: i,
      floating: t,
      strategy: n
    }),
    {
      x: h,
      y: u
    } = Un(c, o, l),
    p = o,
    d = {},
    m = 0;
  for (let y = 0; y < a.length; y++) {
    const {
        name: _,
        fn: $
      } = a[y],
      {
        x: w,
        y: b,
        data: v,
        reset: C
      } = await $({
        x: h,
        y: u,
        initialPlacement: o,
        placement: p,
        strategy: n,
        middlewareData: d,
        rects: c,
        platform: r,
        elements: {
          reference: i,
          floating: t
        }
      });
    h = w ?? h, u = b ?? u, d = {
      ...d,
      [_]: {
        ...d[_],
        ...v
      }
    }, C && m <= 50 && (m++, typeof C == "object" && (C.placement && (p = C.placement), C.rects && (c = C.rects === !0 ? await r.getElementRects({
      reference: i,
      floating: t,
      strategy: n
    }) : C.rects), ({
      x: h,
      y: u
    } = Un(c, p, l))), y = -1);
  }
  return {
    x: h,
    y: u,
    placement: p,
    strategy: n,
    middlewareData: d
  };
};
async function Wo(i, t) {
  var e;
  t === void 0 && (t = {});
  const {
      x: o,
      y: n,
      platform: s,
      rects: r,
      elements: a,
      strategy: l
    } = i,
    {
      boundary: c = "clippingAncestors",
      rootBoundary: h = "viewport",
      elementContext: u = "floating",
      altBoundary: p = !1,
      padding: d = 0
    } = Ve(t, i),
    m = Vo(d),
    y = a[p ? u === "floating" ? "reference" : "floating" : u],
    _ = ne(await s.getClippingRect({
      element: (e = await (s.isElement == null ? void 0 : s.isElement(y))) == null || e ? y : y.contextElement || (await (s.getDocumentElement == null ? void 0 : s.getDocumentElement(a.floating))),
      boundary: c,
      rootBoundary: h,
      strategy: l
    })),
    $ = u === "floating" ? {
      x: o,
      y: n,
      width: r.floating.width,
      height: r.floating.height
    } : r.reference,
    w = await (s.getOffsetParent == null ? void 0 : s.getOffsetParent(a.floating)),
    b = (await (s.isElement == null ? void 0 : s.isElement(w))) ? (await (s.getScale == null ? void 0 : s.getScale(w))) || {
      x: 1,
      y: 1
    } : {
      x: 1,
      y: 1
    },
    v = ne(s.convertOffsetParentRelativeRectToViewportRelativeRect ? await s.convertOffsetParentRelativeRectToViewportRelativeRect({
      elements: a,
      rect: $,
      offsetParent: w,
      strategy: l
    }) : $);
  return {
    top: (_.top - v.top + m.top) / b.y,
    bottom: (v.bottom - _.bottom + m.bottom) / b.y,
    left: (_.left - v.left + m.left) / b.x,
    right: (v.right - _.right + m.right) / b.x
  };
}
const Yr = function (i) {
  return i === void 0 && (i = {}), {
    name: "flip",
    options: i,
    async fn(t) {
      var e, o;
      const {
          placement: n,
          middlewareData: s,
          rects: r,
          initialPlacement: a,
          platform: l,
          elements: c
        } = t,
        {
          mainAxis: h = !0,
          crossAxis: u = !0,
          fallbackPlacements: p,
          fallbackStrategy: d = "bestFit",
          fallbackAxisSideDirection: m = "none",
          flipAlignment: y = !0,
          ..._
        } = Ve(i, t);
      if ((e = s.arrow) != null && e.alignmentOffset) return {};
      const $ = yt(n),
        w = Ut(a),
        b = yt(a) === a,
        v = await (l.isRTL == null ? void 0 : l.isRTL(c.floating)),
        C = p || (b || !y ? [ui(a)] : Ur(a)),
        x = m !== "none";
      !p && x && C.push(...Wr(a, y, m, v));
      const E = [a, ...C],
        M = await Wo(t, _),
        L = [];
      let k = ((o = s.flip) == null ? void 0 : o.overflows) || [];
      if (h && L.push(M[$]), u) {
        const S = Hr(n, r, v);
        L.push(M[S[0]], M[S[1]]);
      }
      if (k = [...k, {
        placement: n,
        overflows: L
      }], !L.every(S => S <= 0)) {
        var P, Y;
        const S = (((P = s.flip) == null ? void 0 : P.index) || 0) + 1,
          z = E[S];
        if (z) return {
          data: {
            index: S,
            overflows: k
          },
          reset: {
            placement: z
          }
        };
        let N = (Y = k.filter(V => V.overflows[0] <= 0).sort((V, nt) => V.overflows[1] - nt.overflows[1])[0]) == null ? void 0 : Y.placement;
        if (!N) switch (d) {
          case "bestFit":
            {
              var H;
              const V = (H = k.filter(nt => {
                if (x) {
                  const pt = Ut(nt.placement);
                  return pt === w ||
                  // Create a bias to the `y` side axis due to horizontal
                  // reading directions favoring greater width.
                  pt === "y";
                }
                return !0;
              }).map(nt => [nt.placement, nt.overflows.filter(pt => pt > 0).reduce((pt, Di) => pt + Di, 0)]).sort((nt, pt) => nt[1] - pt[1])[0]) == null ? void 0 : H[0];
              V && (N = V);
              break;
            }
          case "initialPlacement":
            N = a;
            break;
        }
        if (n !== N) return {
          reset: {
            placement: N
          }
        };
      }
      return {};
    }
  };
};
function Go(i) {
  const t = ie(...i.map(s => s.left)),
    e = ie(...i.map(s => s.top)),
    o = gt(...i.map(s => s.right)),
    n = gt(...i.map(s => s.bottom));
  return {
    x: t,
    y: e,
    width: o - t,
    height: n - e
  };
}
function Xr(i) {
  const t = i.slice().sort((n, s) => n.y - s.y),
    e = [];
  let o = null;
  for (let n = 0; n < t.length; n++) {
    const s = t[n];
    !o || s.y - o.y > o.height / 2 ? e.push([s]) : e[e.length - 1].push(s), o = s;
  }
  return e.map(n => ne(Go(n)));
}
const Jr = function (i) {
  return i === void 0 && (i = {}), {
    name: "inline",
    options: i,
    async fn(t) {
      const {
          placement: e,
          elements: o,
          rects: n,
          platform: s,
          strategy: r
        } = t,
        {
          padding: a = 2,
          x: l,
          y: c
        } = Ve(i, t),
        h = Array.from((await (s.getClientRects == null ? void 0 : s.getClientRects(o.reference))) || []),
        u = Xr(h),
        p = ne(Go(h)),
        d = Vo(a);
      function m() {
        if (u.length === 2 && u[0].left > u[1].right && l != null && c != null) return u.find(_ => l > _.left - d.left && l < _.right + d.right && c > _.top - d.top && c < _.bottom + d.bottom) || p;
        if (u.length >= 2) {
          if (Ut(e) === "y") {
            const k = u[0],
              P = u[u.length - 1],
              Y = yt(e) === "top",
              H = k.top,
              S = P.bottom,
              z = Y ? k.left : P.left,
              N = Y ? k.right : P.right,
              V = N - z,
              nt = S - H;
            return {
              top: H,
              bottom: S,
              left: z,
              right: N,
              width: V,
              height: nt,
              x: z,
              y: H
            };
          }
          const _ = yt(e) === "left",
            $ = gt(...u.map(k => k.right)),
            w = ie(...u.map(k => k.left)),
            b = u.filter(k => _ ? k.left === w : k.right === $),
            v = b[0].top,
            C = b[b.length - 1].bottom,
            x = w,
            E = $,
            M = E - x,
            L = C - v;
          return {
            top: v,
            bottom: C,
            left: x,
            right: E,
            width: M,
            height: L,
            x,
            y: v
          };
        }
        return p;
      }
      const y = await s.getElementRects({
        reference: {
          getBoundingClientRect: m
        },
        floating: o.floating,
        strategy: r
      });
      return n.reference.x !== y.reference.x || n.reference.y !== y.reference.y || n.reference.width !== y.reference.width || n.reference.height !== y.reference.height ? {
        reset: {
          rects: y
        }
      } : {};
    }
  };
};
async function Qr(i, t) {
  const {
      placement: e,
      platform: o,
      elements: n
    } = i,
    s = await (o.isRTL == null ? void 0 : o.isRTL(n.floating)),
    r = yt(e),
    a = xi(e),
    l = Ut(e) === "y",
    c = ["left", "top"].includes(r) ? -1 : 1,
    h = s && l ? -1 : 1,
    u = Ve(t, i);
  let {
    mainAxis: p,
    crossAxis: d,
    alignmentAxis: m
  } = typeof u == "number" ? {
    mainAxis: u,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: u.mainAxis || 0,
    crossAxis: u.crossAxis || 0,
    alignmentAxis: u.alignmentAxis
  };
  return a && typeof m == "number" && (d = a === "end" ? m * -1 : m), l ? {
    x: d * h,
    y: p * c
  } : {
    x: p * c,
    y: d * h
  };
}
const qo = function (i) {
    return {
      name: "offset",
      options: i,
      async fn(t) {
        var e, o;
        const {
            x: n,
            y: s,
            placement: r,
            middlewareData: a
          } = t,
          l = await Qr(t, i);
        return r === ((e = a.offset) == null ? void 0 : e.placement) && (o = a.arrow) != null && o.alignmentOffset ? {} : {
          x: n + l.x,
          y: s + l.y,
          data: {
            ...l,
            placement: r
          }
        };
      }
    };
  },
  Kr = function (i) {
    return i === void 0 && (i = {}), {
      name: "shift",
      options: i,
      async fn(t) {
        const {
            x: e,
            y: o,
            placement: n
          } = t,
          {
            mainAxis: s = !0,
            crossAxis: r = !1,
            limiter: a = {
              fn: _ => {
                let {
                  x: $,
                  y: w
                } = _;
                return {
                  x: $,
                  y: w
                };
              }
            },
            ...l
          } = Ve(i, t),
          c = {
            x: e,
            y: o
          },
          h = await Wo(t, l),
          u = Ut(yt(n)),
          p = Bo(u);
        let d = c[p],
          m = c[u];
        if (s) {
          const _ = p === "y" ? "top" : "left",
            $ = p === "y" ? "bottom" : "right",
            w = d + h[_],
            b = d - h[$];
          d = Hn(w, d, b);
        }
        if (r) {
          const _ = u === "y" ? "top" : "left",
            $ = u === "y" ? "bottom" : "right",
            w = m + h[_],
            b = m - h[$];
          m = Hn(w, m, b);
        }
        const y = a.fn({
          ...t,
          [p]: d,
          [u]: m
        });
        return {
          ...y,
          data: {
            x: y.x - e,
            y: y.y - o,
            enabled: {
              [p]: s,
              [u]: r
            }
          }
        };
      }
    };
  };
function Ci() {
  return typeof window < "u";
}
function St(i) {
  return Yo(i) ? (i.nodeName || "").toLowerCase() : "#document";
}
function et(i) {
  var t;
  return (i == null || (t = i.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function It(i) {
  var t;
  return (t = (Yo(i) ? i.ownerDocument : i.document) || window.document) == null ? void 0 : t.documentElement;
}
function Yo(i) {
  return Ci() ? i instanceof Node || i instanceof et(i).Node : !1;
}
function mt(i) {
  return Ci() ? i instanceof Element || i instanceof et(i).Element : !1;
}
function ft(i) {
  return Ci() ? i instanceof HTMLElement || i instanceof et(i).HTMLElement : !1;
}
function Vn(i) {
  return !Ci() || typeof ShadowRoot > "u" ? !1 : i instanceof ShadowRoot || i instanceof et(i).ShadowRoot;
}
function We(i) {
  const {
    overflow: t,
    overflowX: e,
    overflowY: o,
    display: n
  } = st(i);
  return /auto|scroll|overlay|hidden|clip/.test(t + o + e) && !["inline", "contents"].includes(n);
}
function Zr(i) {
  return ["table", "td", "th"].includes(St(i));
}
function ta(i) {
  return [":popover-open", ":modal"].some(t => {
    try {
      return i.matches(t);
    } catch {
      return !1;
    }
  });
}
function yn(i) {
  const t = vn(),
    e = mt(i) ? st(i) : i;
  return e.transform !== "none" || e.perspective !== "none" || (e.containerType ? e.containerType !== "normal" : !1) || !t && (e.backdropFilter ? e.backdropFilter !== "none" : !1) || !t && (e.filter ? e.filter !== "none" : !1) || ["transform", "perspective", "filter"].some(o => (e.willChange || "").includes(o)) || ["paint", "layout", "strict", "content"].some(o => (e.contain || "").includes(o));
}
function ea(i) {
  let t = oe(i);
  for (; ft(t) && !Ai(t);) {
    if (yn(t)) return t;
    if (ta(t)) return null;
    t = oe(t);
  }
  return null;
}
function vn() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function Ai(i) {
  return ["html", "body", "#document"].includes(St(i));
}
function st(i) {
  return et(i).getComputedStyle(i);
}
function Ei(i) {
  return mt(i) ? {
    scrollLeft: i.scrollLeft,
    scrollTop: i.scrollTop
  } : {
    scrollLeft: i.scrollX,
    scrollTop: i.scrollY
  };
}
function oe(i) {
  if (St(i) === "html") return i;
  const t =
  // Step into the shadow DOM of the parent of a slotted node.
  i.assignedSlot ||
  // DOM Element detected.
  i.parentNode ||
  // ShadowRoot detected.
  Vn(i) && i.host ||
  // Fallback.
  It(i);
  return Vn(t) ? t.host : t;
}
function Xo(i) {
  const t = oe(i);
  return Ai(t) ? i.ownerDocument ? i.ownerDocument.body : i.body : ft(t) && We(t) ? t : Xo(t);
}
function tn(i, t, e) {
  var o;
  t === void 0 && (t = []), e === void 0 && (e = !0);
  const n = Xo(i),
    s = n === ((o = i.ownerDocument) == null ? void 0 : o.body),
    r = et(n);
  if (s) {
    const a = ia(r);
    return t.concat(r, r.visualViewport || [], We(n) ? n : [], a && e ? tn(a) : []);
  }
  return t.concat(n, tn(n, [], e));
}
function ia(i) {
  return i.parent && Object.getPrototypeOf(i.parent) ? i.frameElement : null;
}
function Jo(i) {
  const t = st(i);
  let e = parseFloat(t.width) || 0,
    o = parseFloat(t.height) || 0;
  const n = ft(i),
    s = n ? i.offsetWidth : e,
    r = n ? i.offsetHeight : o,
    a = di(e) !== s || di(o) !== r;
  return a && (e = s, o = r), {
    width: e,
    height: o,
    $: a
  };
}
function Qo(i) {
  return mt(i) ? i : i.contextElement;
}
function Zt(i) {
  const t = Qo(i);
  if (!ft(t)) return Et(1);
  const e = t.getBoundingClientRect(),
    {
      width: o,
      height: n,
      $: s
    } = Jo(t);
  let r = (s ? di(e.width) : e.width) / o,
    a = (s ? di(e.height) : e.height) / n;
  return (!r || !Number.isFinite(r)) && (r = 1), (!a || !Number.isFinite(a)) && (a = 1), {
    x: r,
    y: a
  };
}
const na = /* @__PURE__ */Et(0);
function Ko(i) {
  const t = et(i);
  return !vn() || !t.visualViewport ? na : {
    x: t.visualViewport.offsetLeft,
    y: t.visualViewport.offsetTop
  };
}
function oa(i, t, e) {
  return t === void 0 && (t = !1), !e || t && e !== et(i) ? !1 : t;
}
function Pe(i, t, e, o) {
  t === void 0 && (t = !1), e === void 0 && (e = !1);
  const n = i.getBoundingClientRect(),
    s = Qo(i);
  let r = Et(1);
  t && (o ? mt(o) && (r = Zt(o)) : r = Zt(i));
  const a = oa(s, e, o) ? Ko(s) : Et(0);
  let l = (n.left + a.x) / r.x,
    c = (n.top + a.y) / r.y,
    h = n.width / r.x,
    u = n.height / r.y;
  if (s) {
    const p = et(s),
      d = o && mt(o) ? et(o) : o;
    let m = p,
      y = m.frameElement;
    for (; y && o && d !== m;) {
      const _ = Zt(y),
        $ = y.getBoundingClientRect(),
        w = st(y),
        b = $.left + (y.clientLeft + parseFloat(w.paddingLeft)) * _.x,
        v = $.top + (y.clientTop + parseFloat(w.paddingTop)) * _.y;
      l *= _.x, c *= _.y, h *= _.x, u *= _.y, l += b, c += v, m = et(y), y = m.frameElement;
    }
  }
  return ne({
    width: h,
    height: u,
    x: l,
    y: c
  });
}
const sa = [":popover-open", ":modal"];
function Zo(i) {
  return sa.some(t => {
    try {
      return i.matches(t);
    } catch {
      return !1;
    }
  });
}
function ra(i) {
  let {
    elements: t,
    rect: e,
    offsetParent: o,
    strategy: n
  } = i;
  const s = n === "fixed",
    r = It(o),
    a = t ? Zo(t.floating) : !1;
  if (o === r || a && s) return e;
  let l = {
      scrollLeft: 0,
      scrollTop: 0
    },
    c = Et(1);
  const h = Et(0),
    u = ft(o);
  if ((u || !u && !s) && ((St(o) !== "body" || We(r)) && (l = Ei(o)), ft(o))) {
    const p = Pe(o);
    c = Zt(o), h.x = p.x + o.clientLeft, h.y = p.y + o.clientTop;
  }
  return {
    width: e.width * c.x,
    height: e.height * c.y,
    x: e.x * c.x - l.scrollLeft * c.x + h.x,
    y: e.y * c.y - l.scrollTop * c.y + h.y
  };
}
function aa(i) {
  return Array.from(i.getClientRects());
}
function ts(i) {
  return Pe(It(i)).left + Ei(i).scrollLeft;
}
function la(i) {
  const t = It(i),
    e = Ei(i),
    o = i.ownerDocument.body,
    n = gt(t.scrollWidth, t.clientWidth, o.scrollWidth, o.clientWidth),
    s = gt(t.scrollHeight, t.clientHeight, o.scrollHeight, o.clientHeight);
  let r = -e.scrollLeft + ts(i);
  const a = -e.scrollTop;
  return st(o).direction === "rtl" && (r += gt(t.clientWidth, o.clientWidth) - n), {
    width: n,
    height: s,
    x: r,
    y: a
  };
}
function ca(i, t) {
  const e = et(i),
    o = It(i),
    n = e.visualViewport;
  let s = o.clientWidth,
    r = o.clientHeight,
    a = 0,
    l = 0;
  if (n) {
    s = n.width, r = n.height;
    const c = vn();
    (!c || c && t === "fixed") && (a = n.offsetLeft, l = n.offsetTop);
  }
  return {
    width: s,
    height: r,
    x: a,
    y: l
  };
}
function da(i, t) {
  const e = Pe(i, !0, t === "fixed"),
    o = e.top + i.clientTop,
    n = e.left + i.clientLeft,
    s = ft(i) ? Zt(i) : Et(1),
    r = i.clientWidth * s.x,
    a = i.clientHeight * s.y,
    l = n * s.x,
    c = o * s.y;
  return {
    width: r,
    height: a,
    x: l,
    y: c
  };
}
function Wn(i, t, e) {
  let o;
  if (t === "viewport") o = ca(i, e);else if (t === "document") o = la(It(i));else if (mt(t)) o = da(t, e);else {
    const n = Ko(i);
    o = {
      ...t,
      x: t.x - n.x,
      y: t.y - n.y
    };
  }
  return ne(o);
}
function es(i, t) {
  const e = oe(i);
  return e === t || !mt(e) || Ai(e) ? !1 : st(e).position === "fixed" || es(e, t);
}
function ua(i, t) {
  const e = t.get(i);
  if (e) return e;
  let o = tn(i, [], !1).filter(a => mt(a) && St(a) !== "body"),
    n = null;
  const s = st(i).position === "fixed";
  let r = s ? oe(i) : i;
  for (; mt(r) && !Ai(r);) {
    const a = st(r),
      l = yn(r);
    !l && a.position === "fixed" && (n = null), (s ? !l && !n : !l && a.position === "static" && n && ["absolute", "fixed"].includes(n.position) || We(r) && !l && es(i, r)) ? o = o.filter(c => c !== r) : n = a, r = oe(r);
  }
  return t.set(i, o), o;
}
function ha(i) {
  let {
    element: t,
    boundary: e,
    rootBoundary: o,
    strategy: n
  } = i;
  const s = [...(e === "clippingAncestors" ? ua(t, this._c) : [].concat(e)), o],
    r = s[0],
    a = s.reduce((l, c) => {
      const h = Wn(t, c, n);
      return l.top = gt(h.top, l.top), l.right = ie(h.right, l.right), l.bottom = ie(h.bottom, l.bottom), l.left = gt(h.left, l.left), l;
    }, Wn(t, r, n));
  return {
    width: a.right - a.left,
    height: a.bottom - a.top,
    x: a.left,
    y: a.top
  };
}
function pa(i) {
  const {
    width: t,
    height: e
  } = Jo(i);
  return {
    width: t,
    height: e
  };
}
function ma(i, t, e) {
  const o = ft(t),
    n = It(t),
    s = e === "fixed",
    r = Pe(i, !0, s, t);
  let a = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const l = Et(0);
  if (o || !o && !s) if ((St(t) !== "body" || We(n)) && (a = Ei(t)), o) {
    const u = Pe(t, !0, s, t);
    l.x = u.x + t.clientLeft, l.y = u.y + t.clientTop;
  } else n && (l.x = ts(n));
  const c = r.left + a.scrollLeft - l.x,
    h = r.top + a.scrollTop - l.y;
  return {
    x: c,
    y: h,
    width: r.width,
    height: r.height
  };
}
function Gn(i, t) {
  return !ft(i) || st(i).position === "fixed" ? null : t ? t(i) : i.offsetParent;
}
function is(i, t) {
  const e = et(i);
  if (!ft(i) || Zo(i)) return e;
  let o = Gn(i, t);
  for (; o && Zr(o) && st(o).position === "static";) o = Gn(o, t);
  return o && (St(o) === "html" || St(o) === "body" && st(o).position === "static" && !yn(o)) ? e : o || ea(i) || e;
}
const fa = async function (i) {
  const t = this.getOffsetParent || is,
    e = this.getDimensions;
  return {
    reference: ma(i.reference, await t(i.floating), i.strategy),
    floating: {
      x: 0,
      y: 0,
      ...(await e(i.floating))
    }
  };
};
function ba(i) {
  return st(i).direction === "rtl";
}
const ga = {
    convertOffsetParentRelativeRectToViewportRelativeRect: ra,
    getDocumentElement: It,
    getClippingRect: ha,
    getOffsetParent: is,
    getElementRects: fa,
    getClientRects: aa,
    getDimensions: pa,
    getScale: Zt,
    isElement: mt,
    isRTL: ba
  },
  ns = Kr,
  os = Yr,
  ss = Jr,
  rs = (i, t, e) => {
    const o = /* @__PURE__ */new Map(),
      n = {
        platform: ga,
        ...e
      },
      s = {
        ...n.platform,
        _c: o
      };
    return qr(i, t, {
      ...n,
      platform: s
    });
  };
/**
 * @license
 * Copyright 2019 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const ri = globalThis,
  _n = ri.ShadowRoot && (ri.ShadyCSS === void 0 || ri.ShadyCSS.nativeShadow) && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype,
  wn = Symbol(),
  qn = /* @__PURE__ */new WeakMap();
let as = class {
  constructor(i, t, e) {
    if (this._$cssResult$ = !0, e !== wn) throw Error("CSSResult is not constructable. Use `unsafeCSS` or `css` instead.");
    this.cssText = i, this.t = t;
  }
  get styleSheet() {
    let i = this.o;
    const t = this.t;
    if (_n && i === void 0) {
      const e = t !== void 0 && t.length === 1;
      e && (i = qn.get(t)), i === void 0 && ((this.o = i = new CSSStyleSheet()).replaceSync(this.cssText), e && qn.set(t, i));
    }
    return i;
  }
  toString() {
    return this.cssText;
  }
};
const ya = i => new as(typeof i == "string" ? i : i + "", void 0, wn),
  O = (i, ...t) => {
    const e = i.length === 1 ? i[0] : t.reduce((o, n, s) => o + (r => {
      if (r._$cssResult$ === !0) return r.cssText;
      if (typeof r == "number") return r;
      throw Error("Value passed to 'css' function must be a 'css' function result: " + r + ". Use 'unsafeCSS' to pass non-literal values, but take care to ensure page security.");
    })(n) + i[s + 1], i[0]);
    return new as(e, i, wn);
  },
  va = (i, t) => {
    if (_n) i.adoptedStyleSheets = t.map(e => e instanceof CSSStyleSheet ? e : e.styleSheet);else for (const e of t) {
      const o = document.createElement("style"),
        n = ri.litNonce;
      n !== void 0 && o.setAttribute("nonce", n), o.textContent = e.cssText, i.appendChild(o);
    }
  },
  Yn = _n ? i => i : i => i instanceof CSSStyleSheet ? (t => {
    let e = "";
    for (const o of t.cssRules) e += o.cssText;
    return ya(e);
  })(i) : i;
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const {
    is: _a,
    defineProperty: wa,
    getOwnPropertyDescriptor: $a,
    getOwnPropertyNames: xa,
    getOwnPropertySymbols: Ca,
    getPrototypeOf: Aa
  } = Object,
  se = globalThis,
  Xn = se.trustedTypes,
  Ea = Xn ? Xn.emptyScript : "",
  Jn = se.reactiveElementPolyfillSupport,
  Ee = (i, t) => i,
  hi = {
    toAttribute(i, t) {
      switch (t) {
        case Boolean:
          i = i ? Ea : null;
          break;
        case Object:
        case Array:
          i = i == null ? i : JSON.stringify(i);
      }
      return i;
    },
    fromAttribute(i, t) {
      let e = i;
      switch (t) {
        case Boolean:
          e = i !== null;
          break;
        case Number:
          e = i === null ? null : Number(i);
          break;
        case Object:
        case Array:
          try {
            e = JSON.parse(i);
          } catch {
            e = null;
          }
      }
      return e;
    }
  },
  $n = (i, t) => !_a(i, t),
  Qn = {
    attribute: !0,
    type: String,
    converter: hi,
    reflect: !1,
    hasChanged: $n
  };
Symbol.metadata ?? (Symbol.metadata = Symbol("metadata")), se.litPropertyMetadata ?? (se.litPropertyMetadata = /* @__PURE__ */new WeakMap());
class Qt extends HTMLElement {
  static addInitializer(t) {
    this._$Ei(), (this.l ?? (this.l = [])).push(t);
  }
  static get observedAttributes() {
    return this.finalize(), this._$Eh && [...this._$Eh.keys()];
  }
  static createProperty(t, e = Qn) {
    if (e.state && (e.attribute = !1), this._$Ei(), this.elementProperties.set(t, e), !e.noAccessor) {
      const o = Symbol(),
        n = this.getPropertyDescriptor(t, o, e);
      n !== void 0 && wa(this.prototype, t, n);
    }
  }
  static getPropertyDescriptor(t, e, o) {
    const {
      get: n,
      set: s
    } = $a(this.prototype, t) ?? {
      get() {
        return this[e];
      },
      set(r) {
        this[e] = r;
      }
    };
    return {
      get() {
        return n == null ? void 0 : n.call(this);
      },
      set(r) {
        const a = n == null ? void 0 : n.call(this);
        s.call(this, r), this.requestUpdate(t, a, o);
      },
      configurable: !0,
      enumerable: !0
    };
  }
  static getPropertyOptions(t) {
    return this.elementProperties.get(t) ?? Qn;
  }
  static _$Ei() {
    if (this.hasOwnProperty(Ee("elementProperties"))) return;
    const t = Aa(this);
    t.finalize(), t.l !== void 0 && (this.l = [...t.l]), this.elementProperties = new Map(t.elementProperties);
  }
  static finalize() {
    if (this.hasOwnProperty(Ee("finalized"))) return;
    if (this.finalized = !0, this._$Ei(), this.hasOwnProperty(Ee("properties"))) {
      const e = this.properties,
        o = [...xa(e), ...Ca(e)];
      for (const n of o) this.createProperty(n, e[n]);
    }
    const t = this[Symbol.metadata];
    if (t !== null) {
      const e = litPropertyMetadata.get(t);
      if (e !== void 0) for (const [o, n] of e) this.elementProperties.set(o, n);
    }
    this._$Eh = /* @__PURE__ */new Map();
    for (const [e, o] of this.elementProperties) {
      const n = this._$Eu(e, o);
      n !== void 0 && this._$Eh.set(n, e);
    }
    this.elementStyles = this.finalizeStyles(this.styles);
  }
  static finalizeStyles(t) {
    const e = [];
    if (Array.isArray(t)) {
      const o = new Set(t.flat(1 / 0).reverse());
      for (const n of o) e.unshift(Yn(n));
    } else t !== void 0 && e.push(Yn(t));
    return e;
  }
  static _$Eu(t, e) {
    const o = e.attribute;
    return o === !1 ? void 0 : typeof o == "string" ? o : typeof t == "string" ? t.toLowerCase() : void 0;
  }
  constructor() {
    super(), this._$Ep = void 0, this.isUpdatePending = !1, this.hasUpdated = !1, this._$Em = null, this._$Ev();
  }
  _$Ev() {
    var t;
    this._$ES = new Promise(e => this.enableUpdating = e), this._$AL = /* @__PURE__ */new Map(), this._$E_(), this.requestUpdate(), (t = this.constructor.l) == null || t.forEach(e => e(this));
  }
  addController(t) {
    var e;
    (this._$EO ?? (this._$EO = /* @__PURE__ */new Set())).add(t), this.renderRoot !== void 0 && this.isConnected && ((e = t.hostConnected) == null || e.call(t));
  }
  removeController(t) {
    var e;
    (e = this._$EO) == null || e.delete(t);
  }
  _$E_() {
    const t = /* @__PURE__ */new Map(),
      e = this.constructor.elementProperties;
    for (const o of e.keys()) this.hasOwnProperty(o) && (t.set(o, this[o]), delete this[o]);
    t.size > 0 && (this._$Ep = t);
  }
  createRenderRoot() {
    const t = this.shadowRoot ?? this.attachShadow(this.constructor.shadowRootOptions);
    return va(t, this.constructor.elementStyles), t;
  }
  connectedCallback() {
    var t;
    this.renderRoot ?? (this.renderRoot = this.createRenderRoot()), this.enableUpdating(!0), (t = this._$EO) == null || t.forEach(e => {
      var o;
      return (o = e.hostConnected) == null ? void 0 : o.call(e);
    });
  }
  enableUpdating(t) {}
  disconnectedCallback() {
    var t;
    (t = this._$EO) == null || t.forEach(e => {
      var o;
      return (o = e.hostDisconnected) == null ? void 0 : o.call(e);
    });
  }
  attributeChangedCallback(t, e, o) {
    this._$AK(t, o);
  }
  _$EC(t, e) {
    var o;
    const n = this.constructor.elementProperties.get(t),
      s = this.constructor._$Eu(t, n);
    if (s !== void 0 && n.reflect === !0) {
      const r = (((o = n.converter) == null ? void 0 : o.toAttribute) !== void 0 ? n.converter : hi).toAttribute(e, n.type);
      this._$Em = t, r == null ? this.removeAttribute(s) : this.setAttribute(s, r), this._$Em = null;
    }
  }
  _$AK(t, e) {
    var o;
    const n = this.constructor,
      s = n._$Eh.get(t);
    if (s !== void 0 && this._$Em !== s) {
      const r = n.getPropertyOptions(s),
        a = typeof r.converter == "function" ? {
          fromAttribute: r.converter
        } : ((o = r.converter) == null ? void 0 : o.fromAttribute) !== void 0 ? r.converter : hi;
      this._$Em = s, this[s] = a.fromAttribute(e, r.type), this._$Em = null;
    }
  }
  requestUpdate(t, e, o) {
    if (t !== void 0) {
      if (o ?? (o = this.constructor.getPropertyOptions(t)), !(o.hasChanged ?? $n)(this[t], e)) return;
      this.P(t, e, o);
    }
    this.isUpdatePending === !1 && (this._$ES = this._$ET());
  }
  P(t, e, o) {
    this._$AL.has(t) || this._$AL.set(t, e), o.reflect === !0 && this._$Em !== t && (this._$Ej ?? (this._$Ej = /* @__PURE__ */new Set())).add(t);
  }
  async _$ET() {
    this.isUpdatePending = !0;
    try {
      await this._$ES;
    } catch (e) {
      Promise.reject(e);
    }
    const t = this.scheduleUpdate();
    return t != null && (await t), !this.isUpdatePending;
  }
  scheduleUpdate() {
    return this.performUpdate();
  }
  performUpdate() {
    var t;
    if (!this.isUpdatePending) return;
    if (!this.hasUpdated) {
      if (this.renderRoot ?? (this.renderRoot = this.createRenderRoot()), this._$Ep) {
        for (const [s, r] of this._$Ep) this[s] = r;
        this._$Ep = void 0;
      }
      const n = this.constructor.elementProperties;
      if (n.size > 0) for (const [s, r] of n) r.wrapped !== !0 || this._$AL.has(s) || this[s] === void 0 || this.P(s, this[s], r);
    }
    let e = !1;
    const o = this._$AL;
    try {
      e = this.shouldUpdate(o), e ? (this.willUpdate(o), (t = this._$EO) == null || t.forEach(n => {
        var s;
        return (s = n.hostUpdate) == null ? void 0 : s.call(n);
      }), this.update(o)) : this._$EU();
    } catch (n) {
      throw e = !1, this._$EU(), n;
    }
    e && this._$AE(o);
  }
  willUpdate(t) {}
  _$AE(t) {
    var e;
    (e = this._$EO) == null || e.forEach(o => {
      var n;
      return (n = o.hostUpdated) == null ? void 0 : n.call(o);
    }), this.hasUpdated || (this.hasUpdated = !0, this.firstUpdated(t)), this.updated(t);
  }
  _$EU() {
    this._$AL = /* @__PURE__ */new Map(), this.isUpdatePending = !1;
  }
  get updateComplete() {
    return this.getUpdateComplete();
  }
  getUpdateComplete() {
    return this._$ES;
  }
  shouldUpdate(t) {
    return !0;
  }
  update(t) {
    this._$Ej && (this._$Ej = this._$Ej.forEach(e => this._$EC(e, this[e]))), this._$EU();
  }
  updated(t) {}
  firstUpdated(t) {}
}
Qt.elementStyles = [], Qt.shadowRootOptions = {
  mode: "open"
}, Qt[Ee("elementProperties")] = /* @__PURE__ */new Map(), Qt[Ee("finalized")] = /* @__PURE__ */new Map(), Jn == null || Jn({
  ReactiveElement: Qt
}), (se.reactiveElementVersions ?? (se.reactiveElementVersions = [])).push("2.0.4");
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const pi = globalThis,
  mi = pi.trustedTypes,
  Kn = mi ? mi.createPolicy("lit-html", {
    createHTML: i => i
  }) : void 0,
  ls = "$lit$",
  xt = `lit$${Math.random().toFixed(9).slice(2)}$`,
  cs = "?" + xt,
  Sa = `<${cs}>`,
  Vt = document,
  Ne = () => Vt.createComment(""),
  Me = i => i === null || typeof i != "object" && typeof i != "function",
  xn = Array.isArray,
  ka = i => xn(i) || typeof (i == null ? void 0 : i[Symbol.iterator]) == "function",
  Hi = `[ 	
\f\r]`,
  $e = /<(?:(!--|\/[^a-zA-Z])|(\/?[a-zA-Z][^>\s]*)|(\/?$))/g,
  Zn = /-->/g,
  to = />/g,
  Lt = RegExp(`>|${Hi}(?:([^\\s"'>=/]+)(${Hi}*=${Hi}*(?:[^ 	
\f\r"'\`<>=]|("|')|))|$)`, "g"),
  eo = /'/g,
  io = /"/g,
  ds = /^(?:script|style|textarea|title)$/i,
  Ta = i => (t, ...e) => ({
    _$litType$: i,
    strings: t,
    values: e
  }),
  f = Ta(1),
  Wt = Symbol.for("lit-noChange"),
  F = Symbol.for("lit-nothing"),
  no = /* @__PURE__ */new WeakMap(),
  jt = Vt.createTreeWalker(Vt, 129);
function us(i, t) {
  if (!xn(i) || !i.hasOwnProperty("raw")) throw Error("invalid template strings array");
  return Kn !== void 0 ? Kn.createHTML(t) : t;
}
const Ia = (i, t) => {
  const e = i.length - 1,
    o = [];
  let n,
    s = t === 2 ? "<svg>" : t === 3 ? "<math>" : "",
    r = $e;
  for (let a = 0; a < e; a++) {
    const l = i[a];
    let c,
      h,
      u = -1,
      p = 0;
    for (; p < l.length && (r.lastIndex = p, h = r.exec(l), h !== null);) p = r.lastIndex, r === $e ? h[1] === "!--" ? r = Zn : h[1] !== void 0 ? r = to : h[2] !== void 0 ? (ds.test(h[2]) && (n = RegExp("</" + h[2], "g")), r = Lt) : h[3] !== void 0 && (r = Lt) : r === Lt ? h[0] === ">" ? (r = n ?? $e, u = -1) : h[1] === void 0 ? u = -2 : (u = r.lastIndex - h[2].length, c = h[1], r = h[3] === void 0 ? Lt : h[3] === '"' ? io : eo) : r === io || r === eo ? r = Lt : r === Zn || r === to ? r = $e : (r = Lt, n = void 0);
    const d = r === Lt && i[a + 1].startsWith("/>") ? " " : "";
    s += r === $e ? l + Sa : u >= 0 ? (o.push(c), l.slice(0, u) + ls + l.slice(u) + xt + d) : l + xt + (u === -2 ? a : d);
  }
  return [us(i, s + (i[e] || "<?>") + (t === 2 ? "</svg>" : t === 3 ? "</math>" : "")), o];
};
class Le {
  constructor({
    strings: t,
    _$litType$: e
  }, o) {
    let n;
    this.parts = [];
    let s = 0,
      r = 0;
    const a = t.length - 1,
      l = this.parts,
      [c, h] = Ia(t, e);
    if (this.el = Le.createElement(c, o), jt.currentNode = this.el.content, e === 2 || e === 3) {
      const u = this.el.content.firstChild;
      u.replaceWith(...u.childNodes);
    }
    for (; (n = jt.nextNode()) !== null && l.length < a;) {
      if (n.nodeType === 1) {
        if (n.hasAttributes()) for (const u of n.getAttributeNames()) if (u.endsWith(ls)) {
          const p = h[r++],
            d = n.getAttribute(u).split(xt),
            m = /([.?@])?(.*)/.exec(p);
          l.push({
            type: 1,
            index: s,
            name: m[2],
            strings: d,
            ctor: m[1] === "." ? Ra : m[1] === "?" ? Pa : m[1] === "@" ? Na : Si
          }), n.removeAttribute(u);
        } else u.startsWith(xt) && (l.push({
          type: 6,
          index: s
        }), n.removeAttribute(u));
        if (ds.test(n.tagName)) {
          const u = n.textContent.split(xt),
            p = u.length - 1;
          if (p > 0) {
            n.textContent = mi ? mi.emptyScript : "";
            for (let d = 0; d < p; d++) n.append(u[d], Ne()), jt.nextNode(), l.push({
              type: 2,
              index: ++s
            });
            n.append(u[p], Ne());
          }
        }
      } else if (n.nodeType === 8) if (n.data === cs) l.push({
        type: 2,
        index: s
      });else {
        let u = -1;
        for (; (u = n.data.indexOf(xt, u + 1)) !== -1;) l.push({
          type: 7,
          index: s
        }), u += xt.length - 1;
      }
      s++;
    }
  }
  static createElement(t, e) {
    const o = Vt.createElement("template");
    return o.innerHTML = t, o;
  }
}
function re(i, t, e = i, o) {
  var n, s;
  if (t === Wt) return t;
  let r = o !== void 0 ? (n = e._$Co) == null ? void 0 : n[o] : e._$Cl;
  const a = Me(t) ? void 0 : t._$litDirective$;
  return (r == null ? void 0 : r.constructor) !== a && ((s = r == null ? void 0 : r._$AO) == null || s.call(r, !1), a === void 0 ? r = void 0 : (r = new a(i), r._$AT(i, e, o)), o !== void 0 ? (e._$Co ?? (e._$Co = []))[o] = r : e._$Cl = r), r !== void 0 && (t = re(i, r._$AS(i, t.values), r, o)), t;
}
class Oa {
  constructor(t, e) {
    this._$AV = [], this._$AN = void 0, this._$AD = t, this._$AM = e;
  }
  get parentNode() {
    return this._$AM.parentNode;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  u(t) {
    const {
        el: {
          content: e
        },
        parts: o
      } = this._$AD,
      n = ((t == null ? void 0 : t.creationScope) ?? Vt).importNode(e, !0);
    jt.currentNode = n;
    let s = jt.nextNode(),
      r = 0,
      a = 0,
      l = o[0];
    for (; l !== void 0;) {
      if (r === l.index) {
        let c;
        l.type === 2 ? c = new Ge(s, s.nextSibling, this, t) : l.type === 1 ? c = new l.ctor(s, l.name, l.strings, this, t) : l.type === 6 && (c = new Ma(s, this, t)), this._$AV.push(c), l = o[++a];
      }
      r !== (l == null ? void 0 : l.index) && (s = jt.nextNode(), r++);
    }
    return jt.currentNode = Vt, n;
  }
  p(t) {
    let e = 0;
    for (const o of this._$AV) o !== void 0 && (o.strings !== void 0 ? (o._$AI(t, o, e), e += o.strings.length - 2) : o._$AI(t[e])), e++;
  }
}
class Ge {
  get _$AU() {
    var t;
    return ((t = this._$AM) == null ? void 0 : t._$AU) ?? this._$Cv;
  }
  constructor(t, e, o, n) {
    this.type = 2, this._$AH = F, this._$AN = void 0, this._$AA = t, this._$AB = e, this._$AM = o, this.options = n, this._$Cv = (n == null ? void 0 : n.isConnected) ?? !0;
  }
  get parentNode() {
    let t = this._$AA.parentNode;
    const e = this._$AM;
    return e !== void 0 && (t == null ? void 0 : t.nodeType) === 11 && (t = e.parentNode), t;
  }
  get startNode() {
    return this._$AA;
  }
  get endNode() {
    return this._$AB;
  }
  _$AI(t, e = this) {
    t = re(this, t, e), Me(t) ? t === F || t == null || t === "" ? (this._$AH !== F && this._$AR(), this._$AH = F) : t !== this._$AH && t !== Wt && this._(t) : t._$litType$ !== void 0 ? this.$(t) : t.nodeType !== void 0 ? this.T(t) : ka(t) ? this.k(t) : this._(t);
  }
  O(t) {
    return this._$AA.parentNode.insertBefore(t, this._$AB);
  }
  T(t) {
    this._$AH !== t && (this._$AR(), this._$AH = this.O(t));
  }
  _(t) {
    this._$AH !== F && Me(this._$AH) ? this._$AA.nextSibling.data = t : this.T(Vt.createTextNode(t)), this._$AH = t;
  }
  $(t) {
    var e;
    const {
        values: o,
        _$litType$: n
      } = t,
      s = typeof n == "number" ? this._$AC(t) : (n.el === void 0 && (n.el = Le.createElement(us(n.h, n.h[0]), this.options)), n);
    if (((e = this._$AH) == null ? void 0 : e._$AD) === s) this._$AH.p(o);else {
      const r = new Oa(s, this),
        a = r.u(this.options);
      r.p(o), this.T(a), this._$AH = r;
    }
  }
  _$AC(t) {
    let e = no.get(t.strings);
    return e === void 0 && no.set(t.strings, e = new Le(t)), e;
  }
  k(t) {
    xn(this._$AH) || (this._$AH = [], this._$AR());
    const e = this._$AH;
    let o,
      n = 0;
    for (const s of t) n === e.length ? e.push(o = new Ge(this.O(Ne()), this.O(Ne()), this, this.options)) : o = e[n], o._$AI(s), n++;
    n < e.length && (this._$AR(o && o._$AB.nextSibling, n), e.length = n);
  }
  _$AR(t = this._$AA.nextSibling, e) {
    var o;
    for ((o = this._$AP) == null ? void 0 : o.call(this, !1, !0, e); t && t !== this._$AB;) {
      const n = t.nextSibling;
      t.remove(), t = n;
    }
  }
  setConnected(t) {
    var e;
    this._$AM === void 0 && (this._$Cv = t, (e = this._$AP) == null || e.call(this, t));
  }
}
class Si {
  get tagName() {
    return this.element.tagName;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  constructor(t, e, o, n, s) {
    this.type = 1, this._$AH = F, this._$AN = void 0, this.element = t, this.name = e, this._$AM = n, this.options = s, o.length > 2 || o[0] !== "" || o[1] !== "" ? (this._$AH = Array(o.length - 1).fill(new String()), this.strings = o) : this._$AH = F;
  }
  _$AI(t, e = this, o, n) {
    const s = this.strings;
    let r = !1;
    if (s === void 0) t = re(this, t, e, 0), r = !Me(t) || t !== this._$AH && t !== Wt, r && (this._$AH = t);else {
      const a = t;
      let l, c;
      for (t = s[0], l = 0; l < s.length - 1; l++) c = re(this, a[o + l], e, l), c === Wt && (c = this._$AH[l]), r || (r = !Me(c) || c !== this._$AH[l]), c === F ? t = F : t !== F && (t += (c ?? "") + s[l + 1]), this._$AH[l] = c;
    }
    r && !n && this.j(t);
  }
  j(t) {
    t === F ? this.element.removeAttribute(this.name) : this.element.setAttribute(this.name, t ?? "");
  }
}
class Ra extends Si {
  constructor() {
    super(...arguments), this.type = 3;
  }
  j(t) {
    this.element[this.name] = t === F ? void 0 : t;
  }
}
class Pa extends Si {
  constructor() {
    super(...arguments), this.type = 4;
  }
  j(t) {
    this.element.toggleAttribute(this.name, !!t && t !== F);
  }
}
class Na extends Si {
  constructor(t, e, o, n, s) {
    super(t, e, o, n, s), this.type = 5;
  }
  _$AI(t, e = this) {
    if ((t = re(this, t, e, 0) ?? F) === Wt) return;
    const o = this._$AH,
      n = t === F && o !== F || t.capture !== o.capture || t.once !== o.once || t.passive !== o.passive,
      s = t !== F && (o === F || n);
    n && this.element.removeEventListener(this.name, this, o), s && this.element.addEventListener(this.name, this, t), this._$AH = t;
  }
  handleEvent(t) {
    var e;
    typeof this._$AH == "function" ? this._$AH.call(((e = this.options) == null ? void 0 : e.host) ?? this.element, t) : this._$AH.handleEvent(t);
  }
}
class Ma {
  constructor(t, e, o) {
    this.element = t, this.type = 6, this._$AN = void 0, this._$AM = e, this.options = o;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AI(t) {
    re(this, t);
  }
}
const oo = pi.litHtmlPolyfillSupport;
oo == null || oo(Le, Ge), (pi.litHtmlVersions ?? (pi.litHtmlVersions = [])).push("3.2.1");
const ae = (i, t, e) => {
  const o = (e == null ? void 0 : e.renderBefore) ?? t;
  let n = o._$litPart$;
  if (n === void 0) {
    const s = (e == null ? void 0 : e.renderBefore) ?? null;
    o._$litPart$ = n = new Ge(t.insertBefore(Ne(), s), s, void 0, e ?? {});
  }
  return n._$AI(i), n;
};
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
let I = class extends Qt {
  constructor() {
    super(...arguments), this.renderOptions = {
      host: this
    }, this._$Do = void 0;
  }
  createRenderRoot() {
    var i;
    const t = super.createRenderRoot();
    return (i = this.renderOptions).renderBefore ?? (i.renderBefore = t.firstChild), t;
  }
  update(i) {
    const t = this.render();
    this.hasUpdated || (this.renderOptions.isConnected = this.isConnected), super.update(i), this._$Do = ae(t, this.renderRoot, this.renderOptions);
  }
  connectedCallback() {
    var i;
    super.connectedCallback(), (i = this._$Do) == null || i.setConnected(!0);
  }
  disconnectedCallback() {
    var i;
    super.disconnectedCallback(), (i = this._$Do) == null || i.setConnected(!1);
  }
  render() {
    return Wt;
  }
};
var so;
I._$litElement$ = !0, I.finalized = !0, (so = globalThis.litElementHydrateSupport) == null || so.call(globalThis, {
  LitElement: I
});
const ro = globalThis.litElementPolyfillSupport;
ro == null || ro({
  LitElement: I
});
(globalThis.litElementVersions ?? (globalThis.litElementVersions = [])).push("4.1.1");
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const La = {
    attribute: !0,
    type: String,
    converter: hi,
    reflect: !1,
    hasChanged: $n
  },
  Da = (i = La, t, e) => {
    const {
      kind: o,
      metadata: n
    } = e;
    let s = globalThis.litPropertyMetadata.get(n);
    if (s === void 0 && globalThis.litPropertyMetadata.set(n, s = /* @__PURE__ */new Map()), s.set(e.name, i), o === "accessor") {
      const {
        name: r
      } = e;
      return {
        set(a) {
          const l = t.get.call(this);
          t.set.call(this, a), this.requestUpdate(r, l, i);
        },
        init(a) {
          return a !== void 0 && this.P(r, void 0, i), a;
        }
      };
    }
    if (o === "setter") {
      const {
        name: r
      } = e;
      return function (a) {
        const l = this[r];
        t.call(this, a), this.requestUpdate(r, l, i);
      };
    }
    throw Error("Unsupported decorator location: " + o);
  };
function g(i) {
  return (t, e) => typeof e == "object" ? Da(i, t, e) : ((o, n, s) => {
    const r = n.hasOwnProperty(s);
    return n.constructor.createProperty(s, r ? {
      ...o,
      wrapped: !0
    } : o), r ? Object.getOwnPropertyDescriptor(n, s) : void 0;
  })(i, t, e);
}
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function he(i) {
  return g({
    ...i,
    state: !0,
    attribute: !1
  });
}
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const ja = i => i.strings === void 0;
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const hs = {
    ATTRIBUTE: 1,
    CHILD: 2,
    PROPERTY: 3,
    BOOLEAN_ATTRIBUTE: 4,
    EVENT: 5,
    ELEMENT: 6
  },
  ps = i => (...t) => ({
    _$litDirective$: i,
    values: t
  });
let ms = class {
  constructor(i) {}
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AT(i, t, e) {
    this._$Ct = i, this._$AM = t, this._$Ci = e;
  }
  _$AS(i, t) {
    return this.update(i, t);
  }
  update(i, t) {
    return this.render(...t);
  }
};
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const Se = (i, t) => {
    var e;
    const o = i._$AN;
    if (o === void 0) return !1;
    for (const n of o) (e = n._$AO) == null || e.call(n, t, !1), Se(n, t);
    return !0;
  },
  fi = i => {
    let t, e;
    do {
      if ((t = i._$AM) === void 0) break;
      e = t._$AN, e.delete(i), i = t;
    } while ((e == null ? void 0 : e.size) === 0);
  },
  fs = i => {
    for (let t; t = i._$AM; i = t) {
      let e = t._$AN;
      if (e === void 0) t._$AN = e = /* @__PURE__ */new Set();else if (e.has(i)) break;
      e.add(i), Ba(t);
    }
  };
function za(i) {
  this._$AN !== void 0 ? (fi(this), this._$AM = i, fs(this)) : this._$AM = i;
}
function Fa(i, t = !1, e = 0) {
  const o = this._$AH,
    n = this._$AN;
  if (n !== void 0 && n.size !== 0) if (t) {
    if (Array.isArray(o)) for (let s = e; s < o.length; s++) Se(o[s], !1), fi(o[s]);else o != null && (Se(o, !1), fi(o));
  } else Se(this, i);
}
const Ba = i => {
  i.type == hs.CHILD && (i._$AP ?? (i._$AP = Fa), i._$AQ ?? (i._$AQ = za));
};
class Ha extends ms {
  constructor() {
    super(...arguments), this._$AN = void 0;
  }
  _$AT(t, e, o) {
    super._$AT(t, e, o), fs(this), this.isConnected = t._$AU;
  }
  _$AO(t, e = !0) {
    var o, n;
    t !== this.isConnected && (this.isConnected = t, t ? (o = this.reconnected) == null || o.call(this) : (n = this.disconnected) == null || n.call(this)), e && (Se(this, t), fi(this));
  }
  setValue(t) {
    if (ja(this._$Ct)) this._$Ct._$AI(t, this);else {
      const e = [...this._$Ct._$AH];
      e[this._$Ci] = t, this._$Ct._$AI(e, this, 0);
    }
  }
  disconnected() {}
  reconnected() {}
}
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const le = () => new Ua();
class Ua {}
const Ui = /* @__PURE__ */new WeakMap(),
  K = ps(class extends Ha {
    render(i) {
      return F;
    }
    update(i, [t]) {
      var e;
      const o = t !== this.Y;
      return o && this.Y !== void 0 && this.rt(void 0), (o || this.lt !== this.ct) && (this.Y = t, this.ht = (e = i.options) == null ? void 0 : e.host, this.rt(this.ct = i.element)), F;
    }
    rt(i) {
      if (this.isConnected || (i = void 0), typeof this.Y == "function") {
        const t = this.ht ?? globalThis;
        let e = Ui.get(t);
        e === void 0 && (e = /* @__PURE__ */new WeakMap(), Ui.set(t, e)), e.get(this.Y) !== void 0 && this.Y.call(this.ht, void 0), e.set(this.Y, i), i !== void 0 && this.Y.call(this.ht, i);
      } else this.Y.value = i;
    }
    get lt() {
      var i, t;
      return typeof this.Y == "function" ? (i = Ui.get(this.ht ?? globalThis)) == null ? void 0 : i.get(this.Y) : (t = this.Y) == null ? void 0 : t.value;
    }
    disconnected() {
      this.lt === this.ct && this.rt(void 0);
    }
    reconnected() {
      this.rt(this.ct);
    }
  });
/**
* (c) Iconify
*
* For the full copyright and license information, please view the license.txt
* files at https://github.com/iconify/iconify
*
* Licensed under MIT.
*
* @license MIT
* @version 2.0.0
*/
const bs = Object.freeze({
    left: 0,
    top: 0,
    width: 16,
    height: 16
  }),
  bi = Object.freeze({
    rotate: 0,
    vFlip: !1,
    hFlip: !1
  }),
  qe = Object.freeze({
    ...bs,
    ...bi
  }),
  en = Object.freeze({
    ...qe,
    body: "",
    hidden: !1
  }),
  Va = Object.freeze({
    width: null,
    height: null
  }),
  gs = Object.freeze({
    // Dimensions
    ...Va,
    // Transformations
    ...bi
  });
function Wa(i, t = 0) {
  const e = i.replace(/^-?[0-9.]*/, "");
  function o(n) {
    for (; n < 0;) n += 4;
    return n % 4;
  }
  if (e === "") {
    const n = parseInt(i);
    return isNaN(n) ? 0 : o(n);
  } else if (e !== i) {
    let n = 0;
    switch (e) {
      case "%":
        n = 25;
        break;
      case "deg":
        n = 90;
    }
    if (n) {
      let s = parseFloat(i.slice(0, i.length - e.length));
      return isNaN(s) ? 0 : (s = s / n, s % 1 === 0 ? o(s) : 0);
    }
  }
  return t;
}
const Ga = /[\s,]+/;
function qa(i, t) {
  t.split(Ga).forEach(e => {
    switch (e.trim()) {
      case "horizontal":
        i.hFlip = !0;
        break;
      case "vertical":
        i.vFlip = !0;
        break;
    }
  });
}
const ys = {
  ...gs,
  preserveAspectRatio: ""
};
function ao(i) {
  const t = {
      ...ys
    },
    e = (o, n) => i.getAttribute(o) || n;
  return t.width = e("width", null), t.height = e("height", null), t.rotate = Wa(e("rotate", "")), qa(t, e("flip", "")), t.preserveAspectRatio = e("preserveAspectRatio", e("preserveaspectratio", "")), t;
}
function Ya(i, t) {
  for (const e in ys) if (i[e] !== t[e]) return !0;
  return !1;
}
const ke = /^[a-z0-9]+(-[a-z0-9]+)*$/,
  Ye = (i, t, e, o = "") => {
    const n = i.split(":");
    if (i.slice(0, 1) === "@") {
      if (n.length < 2 || n.length > 3) return null;
      o = n.shift().slice(1);
    }
    if (n.length > 3 || !n.length) return null;
    if (n.length > 1) {
      const a = n.pop(),
        l = n.pop(),
        c = {
          // Allow provider without '@': "provider:prefix:name"
          provider: n.length > 0 ? n[0] : o,
          prefix: l,
          name: a
        };
      return t && !ai(c) ? null : c;
    }
    const s = n[0],
      r = s.split("-");
    if (r.length > 1) {
      const a = {
        provider: o,
        prefix: r.shift(),
        name: r.join("-")
      };
      return t && !ai(a) ? null : a;
    }
    if (e && o === "") {
      const a = {
        provider: o,
        prefix: "",
        name: s
      };
      return t && !ai(a, e) ? null : a;
    }
    return null;
  },
  ai = (i, t) => i ? !!((i.provider === "" || i.provider.match(ke)) && (t && i.prefix === "" || i.prefix.match(ke)) && i.name.match(ke)) : !1;
function Xa(i, t) {
  const e = {};
  !i.hFlip != !t.hFlip && (e.hFlip = !0), !i.vFlip != !t.vFlip && (e.vFlip = !0);
  const o = ((i.rotate || 0) + (t.rotate || 0)) % 4;
  return o && (e.rotate = o), e;
}
function lo(i, t) {
  const e = Xa(i, t);
  for (const o in en) o in bi ? o in i && !(o in e) && (e[o] = bi[o]) : o in t ? e[o] = t[o] : o in i && (e[o] = i[o]);
  return e;
}
function Ja(i, t) {
  const e = i.icons,
    o = i.aliases || /* @__PURE__ */Object.create(null),
    n = /* @__PURE__ */Object.create(null);
  function s(r) {
    if (e[r]) return n[r] = [];
    if (!(r in n)) {
      n[r] = null;
      const a = o[r] && o[r].parent,
        l = a && s(a);
      l && (n[r] = [a].concat(l));
    }
    return n[r];
  }
  return Object.keys(e).concat(Object.keys(o)).forEach(s), n;
}
function Qa(i, t, e) {
  const o = i.icons,
    n = i.aliases || /* @__PURE__ */Object.create(null);
  let s = {};
  function r(a) {
    s = lo(o[a] || n[a], s);
  }
  return r(t), e.forEach(r), lo(i, s);
}
function vs(i, t) {
  const e = [];
  if (typeof i != "object" || typeof i.icons != "object") return e;
  i.not_found instanceof Array && i.not_found.forEach(n => {
    t(n, null), e.push(n);
  });
  const o = Ja(i);
  for (const n in o) {
    const s = o[n];
    s && (t(n, Qa(i, n, s)), e.push(n));
  }
  return e;
}
const Ka = {
  provider: "",
  aliases: {},
  not_found: {},
  ...bs
};
function Vi(i, t) {
  for (const e in t) if (e in i && typeof i[e] != typeof t[e]) return !1;
  return !0;
}
function _s(i) {
  if (typeof i != "object" || i === null) return null;
  const t = i;
  if (typeof t.prefix != "string" || !i.icons || typeof i.icons != "object" || !Vi(i, Ka)) return null;
  const e = t.icons;
  for (const n in e) {
    const s = e[n];
    if (!n.match(ke) || typeof s.body != "string" || !Vi(s, en)) return null;
  }
  const o = t.aliases || /* @__PURE__ */Object.create(null);
  for (const n in o) {
    const s = o[n],
      r = s.parent;
    if (!n.match(ke) || typeof r != "string" || !e[r] && !o[r] || !Vi(s, en)) return null;
  }
  return t;
}
const gi = /* @__PURE__ */Object.create(null);
function Za(i, t) {
  return {
    provider: i,
    prefix: t,
    icons: /* @__PURE__ */Object.create(null),
    missing: /* @__PURE__ */new Set()
  };
}
function kt(i, t) {
  const e = gi[i] || (gi[i] = /* @__PURE__ */Object.create(null));
  return e[t] || (e[t] = Za(i, t));
}
function Cn(i, t) {
  return _s(t) ? vs(t, (e, o) => {
    o ? i.icons[e] = o : i.missing.add(e);
  }) : [];
}
function tl(i, t, e) {
  try {
    if (typeof e.body == "string") return i.icons[t] = {
      ...e
    }, !0;
  } catch {}
  return !1;
}
function el(i, t) {
  let e = [];
  return (typeof i == "string" ? [i] : Object.keys(gi)).forEach(o => {
    (typeof o == "string" && typeof t == "string" ? [t] : Object.keys(gi[o] || {})).forEach(n => {
      const s = kt(o, n);
      e = e.concat(Object.keys(s.icons).map(r => (o !== "" ? "@" + o + ":" : "") + n + ":" + r));
    });
  }), e;
}
let De = !1;
function ws(i) {
  return typeof i == "boolean" && (De = i), De;
}
function je(i) {
  const t = typeof i == "string" ? Ye(i, !0, De) : i;
  if (t) {
    const e = kt(t.provider, t.prefix),
      o = t.name;
    return e.icons[o] || (e.missing.has(o) ? null : void 0);
  }
}
function $s(i, t) {
  const e = Ye(i, !0, De);
  if (!e) return !1;
  const o = kt(e.provider, e.prefix);
  return tl(o, e.name, t);
}
function co(i, t) {
  if (typeof i != "object") return !1;
  if (typeof t != "string" && (t = i.provider || ""), De && !t && !i.prefix) {
    let n = !1;
    return _s(i) && (i.prefix = "", vs(i, (s, r) => {
      r && $s(s, r) && (n = !0);
    })), n;
  }
  const e = i.prefix;
  if (!ai({
    provider: t,
    prefix: e,
    name: "a"
  })) return !1;
  const o = kt(t, e);
  return !!Cn(o, i);
}
function uo(i) {
  return !!je(i);
}
function il(i) {
  const t = je(i);
  return t ? {
    ...qe,
    ...t
  } : null;
}
function nl(i) {
  const t = {
      loaded: [],
      missing: [],
      pending: []
    },
    e = /* @__PURE__ */Object.create(null);
  i.sort((n, s) => n.provider !== s.provider ? n.provider.localeCompare(s.provider) : n.prefix !== s.prefix ? n.prefix.localeCompare(s.prefix) : n.name.localeCompare(s.name));
  let o = {
    provider: "",
    prefix: "",
    name: ""
  };
  return i.forEach(n => {
    if (o.name === n.name && o.prefix === n.prefix && o.provider === n.provider) return;
    o = n;
    const s = n.provider,
      r = n.prefix,
      a = n.name,
      l = e[s] || (e[s] = /* @__PURE__ */Object.create(null)),
      c = l[r] || (l[r] = kt(s, r));
    let h;
    a in c.icons ? h = t.loaded : r === "" || c.missing.has(a) ? h = t.missing : h = t.pending;
    const u = {
      provider: s,
      prefix: r,
      name: a
    };
    h.push(u);
  }), t;
}
function xs(i, t) {
  i.forEach(e => {
    const o = e.loaderCallbacks;
    o && (e.loaderCallbacks = o.filter(n => n.id !== t));
  });
}
function ol(i) {
  i.pendingCallbacksFlag || (i.pendingCallbacksFlag = !0, setTimeout(() => {
    i.pendingCallbacksFlag = !1;
    const t = i.loaderCallbacks ? i.loaderCallbacks.slice(0) : [];
    if (!t.length) return;
    let e = !1;
    const o = i.provider,
      n = i.prefix;
    t.forEach(s => {
      const r = s.icons,
        a = r.pending.length;
      r.pending = r.pending.filter(l => {
        if (l.prefix !== n) return !0;
        const c = l.name;
        if (i.icons[c]) r.loaded.push({
          provider: o,
          prefix: n,
          name: c
        });else if (i.missing.has(c)) r.missing.push({
          provider: o,
          prefix: n,
          name: c
        });else return e = !0, !0;
        return !1;
      }), r.pending.length !== a && (e || xs([i], s.id), s.callback(r.loaded.slice(0), r.missing.slice(0), r.pending.slice(0), s.abort));
    });
  }));
}
let sl = 0;
function rl(i, t, e) {
  const o = sl++,
    n = xs.bind(null, e, o);
  if (!t.pending.length) return n;
  const s = {
    id: o,
    icons: t,
    callback: i,
    abort: n
  };
  return e.forEach(r => {
    (r.loaderCallbacks || (r.loaderCallbacks = [])).push(s);
  }), n;
}
const nn = /* @__PURE__ */Object.create(null);
function ho(i, t) {
  nn[i] = t;
}
function on(i) {
  return nn[i] || nn[""];
}
function al(i, t = !0, e = !1) {
  const o = [];
  return i.forEach(n => {
    const s = typeof n == "string" ? Ye(n, t, e) : n;
    s && o.push(s);
  }), o;
}
var ll = {
  resources: [],
  index: 0,
  timeout: 2e3,
  rotate: 750,
  random: !1,
  dataAfterTimeout: !1
};
function cl(i, t, e, o) {
  const n = i.resources.length,
    s = i.random ? Math.floor(Math.random() * n) : i.index;
  let r;
  if (i.random) {
    let x = i.resources.slice(0);
    for (r = []; x.length > 1;) {
      const E = Math.floor(Math.random() * x.length);
      r.push(x[E]), x = x.slice(0, E).concat(x.slice(E + 1));
    }
    r = r.concat(x);
  } else r = i.resources.slice(s).concat(i.resources.slice(0, s));
  const a = Date.now();
  let l = "pending",
    c = 0,
    h,
    u = null,
    p = [],
    d = [];
  typeof o == "function" && d.push(o);
  function m() {
    u && (clearTimeout(u), u = null);
  }
  function y() {
    l === "pending" && (l = "aborted"), m(), p.forEach(x => {
      x.status === "pending" && (x.status = "aborted");
    }), p = [];
  }
  function _(x, E) {
    E && (d = []), typeof x == "function" && d.push(x);
  }
  function $() {
    return {
      startTime: a,
      payload: t,
      status: l,
      queriesSent: c,
      queriesPending: p.length,
      subscribe: _,
      abort: y
    };
  }
  function w() {
    l = "failed", d.forEach(x => {
      x(void 0, h);
    });
  }
  function b() {
    p.forEach(x => {
      x.status === "pending" && (x.status = "aborted");
    }), p = [];
  }
  function v(x, E, M) {
    const L = E !== "success";
    switch (p = p.filter(k => k !== x), l) {
      case "pending":
        break;
      case "failed":
        if (L || !i.dataAfterTimeout) return;
        break;
      default:
        return;
    }
    if (E === "abort") {
      h = M, w();
      return;
    }
    if (L) {
      h = M, p.length || (r.length ? C() : w());
      return;
    }
    if (m(), b(), !i.random) {
      const k = i.resources.indexOf(x.resource);
      k !== -1 && k !== i.index && (i.index = k);
    }
    l = "completed", d.forEach(k => {
      k(M);
    });
  }
  function C() {
    if (l !== "pending") return;
    m();
    const x = r.shift();
    if (x === void 0) {
      if (p.length) {
        u = setTimeout(() => {
          m(), l === "pending" && (b(), w());
        }, i.timeout);
        return;
      }
      w();
      return;
    }
    const E = {
      status: "pending",
      resource: x,
      callback: (M, L) => {
        v(E, M, L);
      }
    };
    p.push(E), c++, u = setTimeout(C, i.rotate), e(x, t, E.callback);
  }
  return setTimeout(C), $;
}
function Cs(i) {
  const t = {
    ...ll,
    ...i
  };
  let e = [];
  function o() {
    e = e.filter(r => r().status === "pending");
  }
  function n(r, a, l) {
    const c = cl(t, r, a, (h, u) => {
      o(), l && l(h, u);
    });
    return e.push(c), c;
  }
  function s(r) {
    return e.find(a => r(a)) || null;
  }
  return {
    query: n,
    find: s,
    setIndex: r => {
      t.index = r;
    },
    getIndex: () => t.index,
    cleanup: o
  };
}
function An(i) {
  let t;
  if (typeof i.resources == "string") t = [i.resources];else if (t = i.resources, !(t instanceof Array) || !t.length) return null;
  return {
    // API hosts
    resources: t,
    // Root path
    path: i.path || "/",
    // URL length limit
    maxURL: i.maxURL || 500,
    // Timeout before next host is used.
    rotate: i.rotate || 750,
    // Timeout before failing query.
    timeout: i.timeout || 5e3,
    // Randomise default API end point.
    random: i.random === !0,
    // Start index
    index: i.index || 0,
    // Receive data after time out (used if time out kicks in first, then API module sends data anyway).
    dataAfterTimeout: i.dataAfterTimeout !== !1
  };
}
const ki = /* @__PURE__ */Object.create(null),
  ni = ["https://api.simplesvg.com", "https://api.unisvg.com"],
  sn = [];
for (; ni.length > 0;) ni.length === 1 || Math.random() > 0.5 ? sn.push(ni.shift()) : sn.push(ni.pop());
ki[""] = An({
  resources: ["https://api.iconify.design"].concat(sn)
});
function po(i, t) {
  const e = An(t);
  return e === null ? !1 : (ki[i] = e, !0);
}
function Ti(i) {
  return ki[i];
}
function dl() {
  return Object.keys(ki);
}
function mo() {}
const Wi = /* @__PURE__ */Object.create(null);
function ul(i) {
  if (!Wi[i]) {
    const t = Ti(i);
    if (!t) return;
    const e = Cs(t),
      o = {
        config: t,
        redundancy: e
      };
    Wi[i] = o;
  }
  return Wi[i];
}
function As(i, t, e) {
  let o, n;
  if (typeof i == "string") {
    const s = on(i);
    if (!s) return e(void 0, 424), mo;
    n = s.send;
    const r = ul(i);
    r && (o = r.redundancy);
  } else {
    const s = An(i);
    if (s) {
      o = Cs(s);
      const r = i.resources ? i.resources[0] : "",
        a = on(r);
      a && (n = a.send);
    }
  }
  return !o || !n ? (e(void 0, 424), mo) : o.query(t, n, e)().abort;
}
const fo = "iconify2",
  ze = "iconify",
  Es = ze + "-count",
  bo = ze + "-version",
  Ss = 36e5,
  hl = 168,
  pl = 50;
function rn(i, t) {
  try {
    return i.getItem(t);
  } catch {}
}
function En(i, t, e) {
  try {
    return i.setItem(t, e), !0;
  } catch {}
}
function go(i, t) {
  try {
    i.removeItem(t);
  } catch {}
}
function an(i, t) {
  return En(i, Es, t.toString());
}
function ln(i) {
  return parseInt(rn(i, Es)) || 0;
}
const Ft = {
    local: !0,
    session: !0
  },
  ks = {
    local: /* @__PURE__ */new Set(),
    session: /* @__PURE__ */new Set()
  };
let Sn = !1;
function ml(i) {
  Sn = i;
}
let oi = typeof window > "u" ? {} : window;
function Ts(i) {
  const t = i + "Storage";
  try {
    if (oi && oi[t] && typeof oi[t].length == "number") return oi[t];
  } catch {}
  Ft[i] = !1;
}
function Is(i, t) {
  const e = Ts(i);
  if (!e) return;
  const o = rn(e, bo);
  if (o !== fo) {
    if (o) {
      const a = ln(e);
      for (let l = 0; l < a; l++) go(e, ze + l.toString());
    }
    En(e, bo, fo), an(e, 0);
    return;
  }
  const n = Math.floor(Date.now() / Ss) - hl,
    s = a => {
      const l = ze + a.toString(),
        c = rn(e, l);
      if (typeof c == "string") {
        try {
          const h = JSON.parse(c);
          if (typeof h == "object" && typeof h.cached == "number" && h.cached > n && typeof h.provider == "string" && typeof h.data == "object" && typeof h.data.prefix == "string" &&
          // Valid item: run callback
          t(h, a)) return !0;
        } catch {}
        go(e, l);
      }
    };
  let r = ln(e);
  for (let a = r - 1; a >= 0; a--) s(a) || (a === r - 1 ? (r--, an(e, r)) : ks[i].add(a));
}
function Os() {
  if (!Sn) {
    ml(!0);
    for (const i in Ft) Is(i, t => {
      const e = t.data,
        o = t.provider,
        n = e.prefix,
        s = kt(o, n);
      if (!Cn(s, e).length) return !1;
      const r = e.lastModified || -1;
      return s.lastModifiedCached = s.lastModifiedCached ? Math.min(s.lastModifiedCached, r) : r, !0;
    });
  }
}
function fl(i, t) {
  const e = i.lastModifiedCached;
  if (
  // Matches or newer
  e && e >= t) return e === t;
  if (i.lastModifiedCached = t, e) for (const o in Ft) Is(o, n => {
    const s = n.data;
    return n.provider !== i.provider || s.prefix !== i.prefix || s.lastModified === t;
  });
  return !0;
}
function bl(i, t) {
  Sn || Os();
  function e(o) {
    let n;
    if (!Ft[o] || !(n = Ts(o))) return;
    const s = ks[o];
    let r;
    if (s.size) s.delete(r = Array.from(s).shift());else if (r = ln(n), r >= pl || !an(n, r + 1)) return;
    const a = {
      cached: Math.floor(Date.now() / Ss),
      provider: i.provider,
      data: t
    };
    return En(n, ze + r.toString(), JSON.stringify(a));
  }
  t.lastModified && !fl(i, t.lastModified) || Object.keys(t.icons).length && (t.not_found && (t = Object.assign({}, t), delete t.not_found), e("local") || e("session"));
}
function yo() {}
function gl(i) {
  i.iconsLoaderFlag || (i.iconsLoaderFlag = !0, setTimeout(() => {
    i.iconsLoaderFlag = !1, ol(i);
  }));
}
function yl(i, t) {
  i.iconsToLoad ? i.iconsToLoad = i.iconsToLoad.concat(t).sort() : i.iconsToLoad = t, i.iconsQueueFlag || (i.iconsQueueFlag = !0, setTimeout(() => {
    i.iconsQueueFlag = !1;
    const {
        provider: e,
        prefix: o
      } = i,
      n = i.iconsToLoad;
    delete i.iconsToLoad;
    let s;
    !n || !(s = on(e)) || s.prepare(e, o, n).forEach(r => {
      As(e, r, a => {
        if (typeof a != "object") r.icons.forEach(l => {
          i.missing.add(l);
        });else try {
          const l = Cn(i, a);
          if (!l.length) return;
          const c = i.pendingIcons;
          c && l.forEach(h => {
            c.delete(h);
          }), bl(i, a);
        } catch (l) {
          console.error(l);
        }
        gl(i);
      });
    });
  }));
}
const kn = (i, t) => {
    const e = al(i, !0, ws()),
      o = nl(e);
    if (!o.pending.length) {
      let l = !0;
      return t && setTimeout(() => {
        l && t(o.loaded, o.missing, o.pending, yo);
      }), () => {
        l = !1;
      };
    }
    const n = /* @__PURE__ */Object.create(null),
      s = [];
    let r, a;
    return o.pending.forEach(l => {
      const {
        provider: c,
        prefix: h
      } = l;
      if (h === a && c === r) return;
      r = c, a = h, s.push(kt(c, h));
      const u = n[c] || (n[c] = /* @__PURE__ */Object.create(null));
      u[h] || (u[h] = []);
    }), o.pending.forEach(l => {
      const {
          provider: c,
          prefix: h,
          name: u
        } = l,
        p = kt(c, h),
        d = p.pendingIcons || (p.pendingIcons = /* @__PURE__ */new Set());
      d.has(u) || (d.add(u), n[c][h].push(u));
    }), s.forEach(l => {
      const {
        provider: c,
        prefix: h
      } = l;
      n[c][h].length && yl(l, n[c][h]);
    }), t ? rl(t, o, s) : yo;
  },
  vl = i => new Promise((t, e) => {
    const o = typeof i == "string" ? Ye(i, !0) : i;
    if (!o) {
      e(i);
      return;
    }
    kn([o || i], n => {
      if (n.length && o) {
        const s = je(o);
        if (s) {
          t({
            ...qe,
            ...s
          });
          return;
        }
      }
      e(i);
    });
  });
function _l(i) {
  try {
    const t = typeof i == "string" ? JSON.parse(i) : i;
    if (typeof t.body == "string") return {
      ...t
    };
  } catch {}
}
function wl(i, t) {
  const e = typeof i == "string" ? Ye(i, !0, !0) : null;
  if (!e) {
    const s = _l(i);
    return {
      value: i,
      data: s
    };
  }
  const o = je(e);
  if (o !== void 0 || !e.prefix) return {
    value: i,
    name: e,
    data: o
    // could be 'null' -> icon is missing
  };
  const n = kn([e], () => t(i, e, je(e)));
  return {
    value: i,
    name: e,
    loading: n
  };
}
function Gi(i) {
  return i.hasAttribute("inline");
}
let Rs = !1;
try {
  Rs = navigator.vendor.indexOf("Apple") === 0;
} catch {}
function $l(i, t) {
  switch (t) {
    case "svg":
    case "bg":
    case "mask":
      return t;
  }
  return t !== "style" && (Rs || i.indexOf("<a") === -1) ? "svg" : i.indexOf("currentColor") === -1 ? "bg" : "mask";
}
const xl = /(-?[0-9.]*[0-9]+[0-9.]*)/g,
  Cl = /^-?[0-9.]*[0-9]+[0-9.]*$/g;
function cn(i, t, e) {
  if (t === 1) return i;
  if (e = e || 100, typeof i == "number") return Math.ceil(i * t * e) / e;
  if (typeof i != "string") return i;
  const o = i.split(xl);
  if (o === null || !o.length) return i;
  const n = [];
  let s = o.shift(),
    r = Cl.test(s);
  for (;;) {
    if (r) {
      const a = parseFloat(s);
      isNaN(a) ? n.push(s) : n.push(Math.ceil(a * t * e) / e);
    } else n.push(s);
    if (s = o.shift(), s === void 0) return n.join("");
    r = !r;
  }
}
function Al(i, t = "defs") {
  let e = "";
  const o = i.indexOf("<" + t);
  for (; o >= 0;) {
    const n = i.indexOf(">", o),
      s = i.indexOf("</" + t);
    if (n === -1 || s === -1) break;
    const r = i.indexOf(">", s);
    if (r === -1) break;
    e += i.slice(n + 1, s).trim(), i = i.slice(0, o).trim() + i.slice(r + 1);
  }
  return {
    defs: e,
    content: i
  };
}
function El(i, t) {
  return i ? "<defs>" + i + "</defs>" + t : t;
}
function Sl(i, t, e) {
  const o = Al(i);
  return El(o.defs, t + o.content + e);
}
const kl = i => i === "unset" || i === "undefined" || i === "none";
function Ps(i, t) {
  const e = {
      ...qe,
      ...i
    },
    o = {
      ...gs,
      ...t
    },
    n = {
      left: e.left,
      top: e.top,
      width: e.width,
      height: e.height
    };
  let s = e.body;
  [e, o].forEach(y => {
    const _ = [],
      $ = y.hFlip,
      w = y.vFlip;
    let b = y.rotate;
    $ ? w ? b += 2 : (_.push("translate(" + (n.width + n.left).toString() + " " + (0 - n.top).toString() + ")"), _.push("scale(-1 1)"), n.top = n.left = 0) : w && (_.push("translate(" + (0 - n.left).toString() + " " + (n.height + n.top).toString() + ")"), _.push("scale(1 -1)"), n.top = n.left = 0);
    let v;
    switch (b < 0 && (b -= Math.floor(b / 4) * 4), b = b % 4, b) {
      case 1:
        v = n.height / 2 + n.top, _.unshift("rotate(90 " + v.toString() + " " + v.toString() + ")");
        break;
      case 2:
        _.unshift("rotate(180 " + (n.width / 2 + n.left).toString() + " " + (n.height / 2 + n.top).toString() + ")");
        break;
      case 3:
        v = n.width / 2 + n.left, _.unshift("rotate(-90 " + v.toString() + " " + v.toString() + ")");
        break;
    }
    b % 2 === 1 && (n.left !== n.top && (v = n.left, n.left = n.top, n.top = v), n.width !== n.height && (v = n.width, n.width = n.height, n.height = v)), _.length && (s = Sl(s, '<g transform="' + _.join(" ") + '">', "</g>"));
  });
  const r = o.width,
    a = o.height,
    l = n.width,
    c = n.height;
  let h, u;
  r === null ? (u = a === null ? "1em" : a === "auto" ? c : a, h = cn(u, l / c)) : (h = r === "auto" ? l : r, u = a === null ? cn(h, c / l) : a === "auto" ? c : a);
  const p = {},
    d = (y, _) => {
      kl(_) || (p[y] = _.toString());
    };
  d("width", h), d("height", u);
  const m = [n.left, n.top, l, c];
  return p.viewBox = m.join(" "), {
    attributes: p,
    viewBox: m,
    body: s
  };
}
function Tn(i, t) {
  let e = i.indexOf("xlink:") === -1 ? "" : ' xmlns:xlink="http://www.w3.org/1999/xlink"';
  for (const o in t) e += " " + o + '="' + t[o] + '"';
  return '<svg xmlns="http://www.w3.org/2000/svg"' + e + ">" + i + "</svg>";
}
function Tl(i) {
  return i.replace(/"/g, "'").replace(/%/g, "%25").replace(/#/g, "%23").replace(/</g, "%3C").replace(/>/g, "%3E").replace(/\s+/g, " ");
}
function Il(i) {
  return "data:image/svg+xml," + Tl(i);
}
function Ns(i) {
  return 'url("' + Il(i) + '")';
}
const Ol = () => {
  let i;
  try {
    if (i = fetch, typeof i == "function") return i;
  } catch {}
};
let yi = Ol();
function Rl(i) {
  yi = i;
}
function Pl() {
  return yi;
}
function Nl(i, t) {
  const e = Ti(i);
  if (!e) return 0;
  let o;
  if (!e.maxURL) o = 0;else {
    let n = 0;
    e.resources.forEach(r => {
      n = Math.max(n, r.length);
    });
    const s = t + ".json?icons=";
    o = e.maxURL - n - e.path.length - s.length;
  }
  return o;
}
function Ml(i) {
  return i === 404;
}
const Ll = (i, t, e) => {
  const o = [],
    n = Nl(i, t),
    s = "icons";
  let r = {
      type: s,
      provider: i,
      prefix: t,
      icons: []
    },
    a = 0;
  return e.forEach((l, c) => {
    a += l.length + 1, a >= n && c > 0 && (o.push(r), r = {
      type: s,
      provider: i,
      prefix: t,
      icons: []
    }, a = l.length), r.icons.push(l);
  }), o.push(r), o;
};
function Dl(i) {
  if (typeof i == "string") {
    const t = Ti(i);
    if (t) return t.path;
  }
  return "/";
}
const jl = (i, t, e) => {
    if (!yi) {
      e("abort", 424);
      return;
    }
    let o = Dl(t.provider);
    switch (t.type) {
      case "icons":
        {
          const s = t.prefix,
            r = t.icons.join(","),
            a = new URLSearchParams({
              icons: r
            });
          o += s + ".json?" + a.toString();
          break;
        }
      case "custom":
        {
          const s = t.uri;
          o += s.slice(0, 1) === "/" ? s.slice(1) : s;
          break;
        }
      default:
        e("abort", 400);
        return;
    }
    let n = 503;
    yi(i + o).then(s => {
      const r = s.status;
      if (r !== 200) {
        setTimeout(() => {
          e(Ml(r) ? "abort" : "next", r);
        });
        return;
      }
      return n = 501, s.json();
    }).then(s => {
      if (typeof s != "object" || s === null) {
        setTimeout(() => {
          s === 404 ? e("abort", s) : e("next", n);
        });
        return;
      }
      setTimeout(() => {
        e("success", s);
      });
    }).catch(() => {
      e("next", n);
    });
  },
  zl = {
    prepare: Ll,
    send: jl
  };
function vo(i, t) {
  switch (i) {
    case "local":
    case "session":
      Ft[i] = t;
      break;
    case "all":
      for (const e in Ft) Ft[e] = t;
      break;
  }
}
const qi = "data-style";
let Ms = "";
function Fl(i) {
  Ms = i;
}
function _o(i, t) {
  let e = Array.from(i.childNodes).find(o => o.hasAttribute && o.hasAttribute(qi));
  e || (e = document.createElement("style"), e.setAttribute(qi, qi), i.appendChild(e)), e.textContent = ":host{display:inline-block;vertical-align:" + (t ? "-0.125em" : "0") + "}span,svg{display:block}" + Ms;
}
function Ls() {
  ho("", zl), ws(!0);
  let i;
  try {
    i = window;
  } catch {}
  if (i) {
    if (Os(), i.IconifyPreload !== void 0) {
      const t = i.IconifyPreload,
        e = "Invalid IconifyPreload syntax.";
      typeof t == "object" && t !== null && (t instanceof Array ? t : [t]).forEach(o => {
        try {
          (typeof o != "object" || o === null || o instanceof Array ||
          // Check for 'icons' and 'prefix'
          typeof o.icons != "object" || typeof o.prefix != "string" ||
          // Add icon set
          !co(o)) && console.error(e);
        } catch {
          console.error(e);
        }
      });
    }
    if (i.IconifyProviders !== void 0) {
      const t = i.IconifyProviders;
      if (typeof t == "object" && t !== null) for (const e in t) {
        const o = "IconifyProviders[" + e + "] is invalid.";
        try {
          const n = t[e];
          if (typeof n != "object" || !n || n.resources === void 0) continue;
          po(e, n) || console.error(o);
        } catch {
          console.error(o);
        }
      }
    }
  }
  return {
    enableCache: t => vo(t, !0),
    disableCache: t => vo(t, !1),
    iconLoaded: uo,
    iconExists: uo,
    getIcon: il,
    listIcons: el,
    addIcon: $s,
    addCollection: co,
    calculateSize: cn,
    buildIcon: Ps,
    iconToHTML: Tn,
    svgToURL: Ns,
    loadIcons: kn,
    loadIcon: vl,
    addAPIProvider: po,
    appendCustomStyle: Fl,
    _api: {
      getAPIConfig: Ti,
      setAPIModule: ho,
      sendAPIQuery: As,
      setFetch: Rl,
      getFetch: Pl,
      listAPIProviders: dl
    }
  };
}
const dn = {
    "background-color": "currentColor"
  },
  Ds = {
    "background-color": "transparent"
  },
  wo = {
    image: "var(--svg)",
    repeat: "no-repeat",
    size: "100% 100%"
  },
  $o = {
    "-webkit-mask": dn,
    mask: dn,
    background: Ds
  };
for (const i in $o) {
  const t = $o[i];
  for (const e in wo) t[i + "-" + e] = wo[e];
}
function xo(i) {
  return i ? i + (i.match(/^[-0-9.]+$/) ? "px" : "") : "inherit";
}
function Bl(i, t, e) {
  const o = document.createElement("span");
  let n = i.body;
  n.indexOf("<a") !== -1 && (n += "<!-- " + Date.now() + " -->");
  const s = i.attributes,
    r = Tn(n, {
      ...s,
      width: t.width + "",
      height: t.height + ""
    }),
    a = Ns(r),
    l = o.style,
    c = {
      "--svg": a,
      width: xo(s.width),
      height: xo(s.height),
      ...(e ? dn : Ds)
    };
  for (const h in c) l.setProperty(h, c[h]);
  return o;
}
let Te;
function Hl() {
  try {
    Te = window.trustedTypes.createPolicy("iconify", {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      createHTML: i => i
    });
  } catch {
    Te = null;
  }
}
function Ul(i) {
  return Te === void 0 && Hl(), Te ? Te.createHTML(i) : i;
}
function Vl(i) {
  const t = document.createElement("span"),
    e = i.attributes;
  let o = "";
  e.width || (o = "width: inherit;"), e.height || (o += "height: inherit;"), o && (e.style = o);
  const n = Tn(i.body, e);
  return t.innerHTML = Ul(n), t.firstChild;
}
function un(i) {
  return Array.from(i.childNodes).find(t => {
    const e = t.tagName && t.tagName.toUpperCase();
    return e === "SPAN" || e === "SVG";
  });
}
function Co(i, t) {
  const e = t.icon.data,
    o = t.customisations,
    n = Ps(e, o);
  o.preserveAspectRatio && (n.attributes.preserveAspectRatio = o.preserveAspectRatio);
  const s = t.renderedMode;
  let r;
  switch (s) {
    case "svg":
      r = Vl(n);
      break;
    default:
      r = Bl(n, {
        ...qe,
        ...e
      }, s === "mask");
  }
  const a = un(i);
  a ? r.tagName === "SPAN" && a.tagName === r.tagName ? a.setAttribute("style", r.getAttribute("style")) : i.replaceChild(r, a) : i.appendChild(r);
}
function Ao(i, t, e) {
  const o = e && (e.rendered ? e : e.lastRender);
  return {
    rendered: !1,
    inline: t,
    icon: i,
    lastRender: o
  };
}
function Wl(i = "iconify-icon") {
  let t, e;
  try {
    t = window.customElements, e = window.HTMLElement;
  } catch {
    return;
  }
  if (!t || !e) return;
  const o = t.get(i);
  if (o) return o;
  const n = [
    // Icon
    "icon",
    // Mode
    "mode", "inline", "observe",
    // Customisations
    "width", "height", "rotate", "flip"],
    s = class extends e {
      /**
       * Constructor
       */
      constructor() {
        super(), Mt(this, "_shadowRoot"), Mt(this, "_initialised", !1), Mt(this, "_state"), Mt(this, "_checkQueued", !1), Mt(this, "_connected", !1), Mt(this, "_observer", null), Mt(this, "_visible", !0);
        const a = this._shadowRoot = this.attachShadow({
            mode: "open"
          }),
          l = Gi(this);
        _o(a, l), this._state = Ao({
          value: ""
        }, l), this._queueCheck();
      }
      /**
       * Connected to DOM
       */
      connectedCallback() {
        this._connected = !0, this.startObserver();
      }
      /**
       * Disconnected from DOM
       */
      disconnectedCallback() {
        this._connected = !1, this.stopObserver();
      }
      /**
       * Observed attributes
       */
      static get observedAttributes() {
        return n.slice(0);
      }
      /**
       * Observed properties that are different from attributes
       *
       * Experimental! Need to test with various frameworks that support it
       */
      /*
      static get properties() {
          return {
              inline: {
                  type: Boolean,
                  reflect: true,
              },
              // Not listing other attributes because they are strings or combination
              // of string and another type. Cannot have multiple types
          };
      }
      */
      /**
       * Attribute has changed
       */
      attributeChangedCallback(a) {
        switch (a) {
          case "inline":
            {
              const l = Gi(this),
                c = this._state;
              l !== c.inline && (c.inline = l, _o(this._shadowRoot, l));
              break;
            }
          case "observer":
            {
              this.observer ? this.startObserver() : this.stopObserver();
              break;
            }
          default:
            this._queueCheck();
        }
      }
      /**
       * Get/set icon
       */
      get icon() {
        const a = this.getAttribute("icon");
        if (a && a.slice(0, 1) === "{") try {
          return JSON.parse(a);
        } catch {}
        return a;
      }
      set icon(a) {
        typeof a == "object" && (a = JSON.stringify(a)), this.setAttribute("icon", a);
      }
      /**
       * Get/set inline
       */
      get inline() {
        return Gi(this);
      }
      set inline(a) {
        a ? this.setAttribute("inline", "true") : this.removeAttribute("inline");
      }
      /**
       * Get/set observer
       */
      get observer() {
        return this.hasAttribute("observer");
      }
      set observer(a) {
        a ? this.setAttribute("observer", "true") : this.removeAttribute("observer");
      }
      /**
       * Restart animation
       */
      restartAnimation() {
        const a = this._state;
        if (a.rendered) {
          const l = this._shadowRoot;
          if (a.renderedMode === "svg") try {
            l.lastChild.setCurrentTime(0);
            return;
          } catch {}
          Co(l, a);
        }
      }
      /**
       * Get status
       */
      get status() {
        const a = this._state;
        return a.rendered ? "rendered" : a.icon.data === null ? "failed" : "loading";
      }
      /**
       * Queue attributes re-check
       */
      _queueCheck() {
        this._checkQueued || (this._checkQueued = !0, setTimeout(() => {
          this._check();
        }));
      }
      /**
       * Check for changes
       */
      _check() {
        if (!this._checkQueued) return;
        this._checkQueued = !1;
        const a = this._state,
          l = this.getAttribute("icon");
        if (l !== a.icon.value) {
          this._iconChanged(l);
          return;
        }
        if (!a.rendered || !this._visible) return;
        const c = this.getAttribute("mode"),
          h = ao(this);
        (a.attrMode !== c || Ya(a.customisations, h) || !un(this._shadowRoot)) && this._renderIcon(a.icon, h, c);
      }
      /**
       * Icon value has changed
       */
      _iconChanged(a) {
        const l = wl(a, (c, h, u) => {
          const p = this._state;
          if (p.rendered || this.getAttribute("icon") !== c) return;
          const d = {
            value: c,
            name: h,
            data: u
          };
          d.data ? this._gotIconData(d) : p.icon = d;
        });
        l.data ? this._gotIconData(l) : this._state = Ao(l, this._state.inline, this._state);
      }
      /**
       * Force render icon on state change
       */
      _forceRender() {
        if (!this._visible) {
          const a = un(this._shadowRoot);
          a && this._shadowRoot.removeChild(a);
          return;
        }
        this._queueCheck();
      }
      /**
       * Got new icon data, icon is ready to (re)render
       */
      _gotIconData(a) {
        this._checkQueued = !1, this._renderIcon(a, ao(this), this.getAttribute("mode"));
      }
      /**
       * Re-render based on icon data
       */
      _renderIcon(a, l, c) {
        const h = $l(a.data.body, c),
          u = this._state.inline;
        Co(this._shadowRoot, this._state = {
          rendered: !0,
          icon: a,
          inline: u,
          customisations: l,
          attrMode: c,
          renderedMode: h
        });
      }
      /**
       * Start observer
       */
      startObserver() {
        if (!this._observer) try {
          this._observer = new IntersectionObserver(a => {
            const l = a.some(c => c.isIntersecting);
            l !== this._visible && (this._visible = l, this._forceRender());
          }), this._observer.observe(this);
        } catch {
          if (this._observer) {
            try {
              this._observer.disconnect();
            } catch {}
            this._observer = null;
          }
        }
      }
      /**
       * Stop observer
       */
      stopObserver() {
        this._observer && (this._observer.disconnect(), this._observer = null, this._visible = !0, this._connected && this._forceRender());
      }
    };
  n.forEach(a => {
    a in s.prototype || Object.defineProperty(s.prototype, a, {
      get: function () {
        return this.getAttribute(a);
      },
      set: function (l) {
        l !== null ? this.setAttribute(a, l) : this.removeAttribute(a);
      }
    });
  });
  const r = Ls();
  for (const a in r) s[a] = s.prototype[a] = r[a];
  return t.define(i, s), s;
}
Wl() || Ls();
const Gl = O`
  ::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
    overflow: hidden;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background-color: var(
      --bim-scrollbar--c,
      color-mix(in lab, var(--bim-ui_main-base), white 15%)
    );
  }

  ::-webkit-scrollbar-track {
    background-color: var(--bim-scrollbar--bgc, var(--bim-ui_bg-base));
  }
`,
  ql = O`
  :root {
    /* Grayscale Colors */
    --bim-ui_gray-0: hsl(210 10% 5%);
    --bim-ui_gray-1: hsl(210 10% 10%);
    --bim-ui_gray-2: hsl(210 10% 20%);
    --bim-ui_gray-3: hsl(210 10% 30%);
    --bim-ui_gray-4: hsl(210 10% 40%);
    --bim-ui_gray-6: hsl(210 10% 60%);
    --bim-ui_gray-7: hsl(210 10% 70%);
    --bim-ui_gray-8: hsl(210 10% 80%);
    --bim-ui_gray-9: hsl(210 10% 90%);
    --bim-ui_gray-10: hsl(210 10% 95%);

    /* Brand Colors */
    --bim-ui_main-base: #6528d7;
    --bim-ui_accent-base: #bcf124;

    /* Brand Colors Contrasts */
    --bim-ui_main-contrast: var(--bim-ui_gray-10);
    --bim-ui_accent-contrast: var(--bim-ui_gray-0);

    /* Sizes */
    --bim-ui_size-4xs: 0.375rem;
    --bim-ui_size-3xs: 0.5rem;
    --bim-ui_size-2xs: 0.625rem;
    --bim-ui_size-xs: 0.75rem;
    --bim-ui_size-sm: 0.875rem;
    --bim-ui_size-base: 1rem;
    --bim-ui_size-lg: 1.125rem;
    --bim-ui_size-xl: 1.25rem;
    --bim-ui_size-2xl: 1.375rem;
    --bim-ui_size-3xl: 1.5rem;
    --bim-ui_size-4xl: 1.625rem;
    --bim-ui_size-5xl: 1.75rem;
    --bim-ui_size-6xl: 1.875rem;
    --bim-ui_size-7xl: 2rem;
    --bim-ui_size-8xl: 2.125rem;
    --bim-ui_size-9xl: 2.25rem;
  }

  /* Background Colors */
  @media (prefers-color-scheme: dark) {
    :root {
      --bim-ui_bg-base: var(--bim-ui_gray-0);
      --bim-ui_bg-contrast-10: var(--bim-ui_gray-1);
      --bim-ui_bg-contrast-20: var(--bim-ui_gray-2);
      --bim-ui_bg-contrast-30: var(--bim-ui_gray-3);
      --bim-ui_bg-contrast-40: var(--bim-ui_gray-4);
      --bim-ui_bg-contrast-60: var(--bim-ui_gray-6);
      --bim-ui_bg-contrast-80: var(--bim-ui_gray-8);
      --bim-ui_bg-contrast-100: var(--bim-ui_gray-10);
    }
  }

  @media (prefers-color-scheme: light) {
    :root {
      --bim-ui_bg-base: var(--bim-ui_gray-10);
      --bim-ui_bg-contrast-10: var(--bim-ui_gray-9);
      --bim-ui_bg-contrast-20: var(--bim-ui_gray-8);
      --bim-ui_bg-contrast-30: var(--bim-ui_gray-7);
      --bim-ui_bg-contrast-40: var(--bim-ui_gray-6);
      --bim-ui_bg-contrast-60: var(--bim-ui_gray-4);
      --bim-ui_bg-contrast-80: var(--bim-ui_gray-2);
      --bim-ui_bg-contrast-100: var(--bim-ui_gray-0);
      --bim-ui_accent-base: #6528d7;
    }
  }

  html.bim-ui-dark {
    --bim-ui_bg-base: var(--bim-ui_gray-0);
    --bim-ui_bg-contrast-10: var(--bim-ui_gray-1);
    --bim-ui_bg-contrast-20: var(--bim-ui_gray-2);
    --bim-ui_bg-contrast-30: var(--bim-ui_gray-3);
    --bim-ui_bg-contrast-40: var(--bim-ui_gray-4);
    --bim-ui_bg-contrast-60: var(--bim-ui_gray-6);
    --bim-ui_bg-contrast-80: var(--bim-ui_gray-8);
    --bim-ui_bg-contrast-100: var(--bim-ui_gray-10);
  }

  html.bim-ui-light {
    --bim-ui_bg-base: var(--bim-ui_gray-10);
    --bim-ui_bg-contrast-10: var(--bim-ui_gray-9);
    --bim-ui_bg-contrast-20: var(--bim-ui_gray-8);
    --bim-ui_bg-contrast-30: var(--bim-ui_gray-7);
    --bim-ui_bg-contrast-40: var(--bim-ui_gray-6);
    --bim-ui_bg-contrast-60: var(--bim-ui_gray-4);
    --bim-ui_bg-contrast-80: var(--bim-ui_gray-2);
    --bim-ui_bg-contrast-100: var(--bim-ui_gray-0);
    --bim-ui_accent-base: #6528d7;
  }

  [data-context-dialog]::backdrop {
    background-color: transparent;
  }
`,
  Ot = {
    scrollbar: Gl,
    globalStyles: ql
  },
  js = class T {
    static set config(t) {
      this._config = {
        ...T._config,
        ...t
      };
    }
    static get config() {
      return T._config;
    }
    static addGlobalStyles() {
      let t = document.querySelector("style[id='bim-ui']");
      if (t) return;
      t = document.createElement("style"), t.id = "bim-ui", t.textContent = Ot.globalStyles.cssText;
      const e = document.head.firstChild;
      e ? document.head.insertBefore(t, e) : document.head.append(t);
    }
    static defineCustomElement(t, e) {
      customElements.get(t) || customElements.define(t, e);
    }
    /**
     * @deprecated Use `Manager.init()` instead.
     */
    static registerComponents() {
      T.init();
    }
    /**
     * Initializes the BIM UI library by defining custom elements.
     * It ensures that all necessary styles and custom elements are registered for use in BIM UI components.
     *
     * @example
     * ```typescript
     * import { Manager } from "@thatopen/ui";
     * Manager.init();
     * ```
     */
    static init() {
      T.addGlobalStyles(), T.defineCustomElement("bim-button", Zl), T.defineCustomElement("bim-checkbox", pe), T.defineCustomElement("bim-color-input", qt), T.defineCustomElement("bim-context-menu", pn), T.defineCustomElement("bim-dropdown", vt), T.defineCustomElement("bim-grid", On), T.defineCustomElement("bim-icon", ac), T.defineCustomElement("bim-input", Je), T.defineCustomElement("bim-label", fe), T.defineCustomElement("bim-number-input", it), T.defineCustomElement("bim-option", q), T.defineCustomElement("bim-panel", Yt), T.defineCustomElement("bim-panel-section", be), T.defineCustomElement("bim-selector", ge), T.defineCustomElement("bim-table", lt), T.defineCustomElement("bim-tabs", Jt), T.defineCustomElement("bim-tab", ot), T.defineCustomElement("bim-table-cell", Zs), T.defineCustomElement("bim-table-children", er), T.defineCustomElement("bim-table-group", nr), T.defineCustomElement("bim-table-row", Xt), T.defineCustomElement("bim-text-input", ct), T.defineCustomElement("bim-toolbar", Mi), T.defineCustomElement("bim-toolbar-group", Pi), T.defineCustomElement("bim-toolbar-section", _e), T.defineCustomElement("bim-viewport", mr);
    }
    static newRandomId() {
      const t = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let e = "";
      for (let o = 0; o < 10; o++) {
        const n = Math.floor(Math.random() * t.length);
        e += t.charAt(n);
      }
      return e;
    }
  };
js._config = {
  sectionLabelOnVerticalToolbar: !1
  // draggableToolbars: true,
  // draggablePanels: true,
};
let Q = js;
class U extends I {
  constructor() {
    super(...arguments), this._lazyLoadObserver = null, this._visibleElements = [], this.ELEMENTS_BEFORE_OBSERVER = 20, this.useObserver = !1, this.elements = /* @__PURE__ */new Set(), this.observe = t => {
      if (!this.useObserver) return;
      for (const o of t) this.elements.add(o);
      const e = t.slice(this.ELEMENTS_BEFORE_OBSERVER);
      for (const o of e) o.remove();
      this.observeLastElement();
    };
  }
  set visibleElements(t) {
    this._visibleElements = this.useObserver ? t : [], this.requestUpdate();
  }
  get visibleElements() {
    return this._visibleElements;
  }
  getLazyObserver() {
    if (!this.useObserver) return null;
    if (this._lazyLoadObserver) return this._lazyLoadObserver;
    const t = new IntersectionObserver(e => {
      const o = e[0];
      if (!o.isIntersecting) return;
      const n = o.target;
      t.unobserve(n);
      const s = this.ELEMENTS_BEFORE_OBSERVER + this.visibleElements.length,
        r = [...this.elements][s];
      r && (this.visibleElements = [...this.visibleElements, r], t.observe(r));
    }, {
      threshold: 0.5
    });
    return t;
  }
  observeLastElement() {
    const t = this.getLazyObserver();
    if (!t) return;
    const e = this.ELEMENTS_BEFORE_OBSERVER + this.visibleElements.length - 1,
      o = [...this.elements][e];
    o && t.observe(o);
  }
  resetVisibleElements() {
    const t = this.getLazyObserver();
    if (t) {
      for (const e of this.elements) t.unobserve(e);
      this.visibleElements = [], this.observeLastElement();
    }
  }
  /**
   * Creates a new UI component instance based on the provided template and initial state.
   *
   * @template T - The type of the UI component element.
   * @template S - The type of the component state.
   *
   * @param template - The component template function (stateless or stateful).
   * @param initialState - The initial state of the component (optional for stateless components).
   * @returns The created UI component element or an array containing the element and a function to update its state.
   */
  static create(t, e) {
    const o = document.createDocumentFragment();
    if (t.length === 0) return ae(t(), o), o.firstElementChild;
    if (!e) throw new Error("UIComponent: Initial state is required for statefull components.");
    let n = e;
    const s = t,
      r = l => (n = {
        ...n,
        ...l
      }, ae(s(n, r), o), n);
    r(e);
    const a = () => n;
    return [o.firstElementChild, r, a];
  }
}
const Fe = (i, t = {}, e = !0) => {
    let o = {};
    for (const n of i.children) {
      const s = n,
        r = s.getAttribute("name") || s.getAttribute("label"),
        a = t[r];
      if (r) {
        if ("value" in s && typeof s.value < "u" && s.value !== null) {
          const l = s.value;
          if (typeof l == "object" && !Array.isArray(l) && Object.keys(l).length === 0) continue;
          o[r] = a ? a(s.value) : s.value;
        } else if (e) {
          const l = Fe(s, t);
          if (Object.keys(l).length === 0) continue;
          o[r] = a ? a(l) : l;
        }
      } else e && (o = {
        ...o,
        ...Fe(s, t)
      });
    }
    return o;
  },
  Ii = i => i === "true" || i === "false" ? i === "true" : i && !isNaN(Number(i)) && i.trim() !== "" ? Number(i) : i,
  Yl = [">=", "<=", "=", ">", "<", "?", "/", "#"];
function Eo(i) {
  const t = Yl.find(r => i.split(r).length === 2),
    e = i.split(t).map(r => r.trim()),
    [o, n] = e,
    s = n.startsWith("'") && n.endsWith("'") ? n.replace(/'/g, "") : Ii(n);
  return {
    key: o,
    condition: t,
    value: s
  };
}
const hn = i => {
    try {
      const t = [],
        e = i.split(/&(?![^()]*\))/).map(o => o.trim());
      for (const o of e) {
        const n = !o.startsWith("(") && !o.endsWith(")"),
          s = o.startsWith("(") && o.endsWith(")");
        if (n) {
          const r = Eo(o);
          t.push(r);
        }
        if (s) {
          const r = {
            operator: "&",
            queries: o.replace(/^(\()|(\))$/g, "").split("&").map(a => a.trim()).map((a, l) => {
              const c = Eo(a);
              return l > 0 && (c.operator = "&"), c;
            })
          };
          t.push(r);
        }
      }
      return t;
    } catch {
      return null;
    }
  },
  So = (i, t, e) => {
    let o = !1;
    switch (t) {
      case "=":
        o = i === e;
        break;
      case "?":
        o = String(i).includes(String(e));
        break;
      case "<":
        (typeof i == "number" || typeof e == "number") && (o = i < e);
        break;
      case "<=":
        (typeof i == "number" || typeof e == "number") && (o = i <= e);
        break;
      case ">":
        (typeof i == "number" || typeof e == "number") && (o = i > e);
        break;
      case ">=":
        (typeof i == "number" || typeof e == "number") && (o = i >= e);
        break;
      case "/":
        o = String(i).startsWith(String(e));
        break;
    }
    return o;
  };
var Xl = Object.defineProperty,
  Jl = Object.getOwnPropertyDescriptor,
  zs = (i, t, e, o) => {
    for (var n = Jl(t, e), s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && Xl(t, e, n), n;
  },
  G;
const In = (G = class extends I {
  constructor() {
    super(...arguments), this._previousContainer = null, this._visible = !1;
  }
  get placement() {
    return this._placement;
  }
  set placement(i) {
    this._placement = i, this.updatePosition();
  }
  static removeMenus() {
    for (const i of G.menus) i instanceof G && (i.visible = !1);
    G.dialog.close(), G.dialog.remove();
  }
  get visible() {
    return this._visible;
  }
  set visible(i) {
    var t;
    this._visible = i, i ? (G.dialog.parentElement || document.body.append(G.dialog), this._previousContainer = this.parentElement, G.dialog.style.top = `${window.scrollY || document.documentElement.scrollTop}px`, G.dialog.append(this), G.dialog.showModal(), this.updatePosition(), this.dispatchEvent(new Event("visible"))) : ((t = this._previousContainer) == null || t.append(this), this._previousContainer = null, this.dispatchEvent(new Event("hidden")));
  }
  /**
   * Asynchronously updates the position of the context menu relative to a target element.
   * If no target element is provided, it attempts to use the parent node as the target.
   * The position is calculated using the `computePosition` function from `@floating-ui/dom`,
   * which considers various adjustments like offset, inline positioning, flipping, and shifting
   * to ensure the context menu is properly placed relative to the target element.
   *
   * @param [target] - The target element relative to which the context menu should be positioned.
   *                                 If not provided, the parent node is used as the target.
   * @returns A promise that resolves once the position has been updated. This method
   *                          does not explicitly return a value but updates the context menu's style
   *                          properties to position it on the screen.
   */
  async updatePosition() {
    if (!(this.visible && this._previousContainer)) return;
    const i = this.placement ?? "right",
      t = await rs(this._previousContainer, this, {
        placement: i,
        middleware: [qo(10), ss(), os(), ns({
          padding: 5
        })]
      }),
      {
        x: e,
        y: o
      } = t;
    this.style.left = `${e}px`, this.style.top = `${o}px`;
  }
  connectedCallback() {
    super.connectedCallback(), G.menus.push(this);
  }
  render() {
    return f` <slot></slot> `;
  }
}, G.styles = [Ot.scrollbar, O`
      :host {
        pointer-events: auto;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        overflow: auto;
        max-height: 20rem;
        min-width: 3rem;
        flex-direction: column;
        box-shadow: 1px 2px 8px 2px rgba(0, 0, 0, 0.15);
        padding: 0.5rem;
        border-radius: var(--bim-ui_size-4xs);
        display: flex;
        background-color: var(
          --bim-context-menu--bgc,
          var(--bim-ui_bg-contrast-20)
        );
      }

      :host(:not([visible])) {
        display: none;
      }
    `], G.dialog = U.create(() => f` <dialog
      @click=${i => {
  i.target === G.dialog && G.removeMenus();
}}
      @cancel=${() => G.removeMenus()}
      data-context-dialog
      style="
      width: 0;
      height: 0;
      position: relative;
      padding: 0;
      border: none;
      outline: none;
      margin: none;
      overflow: visible;
      background-color: transparent;
    "
    ></dialog>`), G.menus = [], G);
zs([g({
  type: String,
  reflect: !0
})], In.prototype, "placement");
zs([g({
  type: Boolean,
  reflect: !0
})], In.prototype, "visible");
let pn = In;
var Ql = Object.defineProperty,
  Kl = Object.getOwnPropertyDescriptor,
  ut = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? Kl(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && Ql(t, e, n), n;
  },
  xe;
const rt = (xe = class extends I {
  constructor() {
    super(), this.labelHidden = !1, this.active = !1, this.disabled = !1, this.vertical = !1, this.tooltipVisible = !1, this._stateBeforeLoading = {
      disabled: !1,
      icon: ""
    }, this._loading = !1, this._parent = le(), this._tooltip = le(), this._mouseLeave = !1, this.onClick = i => {
      i.stopPropagation(), this.disabled || this.dispatchEvent(new Event("click"));
    }, this.showContextMenu = () => {
      const i = this._contextMenu;
      if (i) {
        const t = this.getAttribute("data-context-group");
        t && i.setAttribute("data-context-group", t), this.closeNestedContexts();
        const e = Q.newRandomId();
        for (const o of i.children) o instanceof xe && o.setAttribute("data-context-group", e);
        i.visible = !0;
      }
    }, this.mouseLeave = !0;
  }
  set loading(i) {
    if (this._loading = i, i) this._stateBeforeLoading = {
      disabled: this.disabled,
      icon: this.icon
    }, this.disabled = i, this.icon = "eos-icons:loading";else {
      const {
        disabled: t,
        icon: e
      } = this._stateBeforeLoading;
      this.disabled = t, this.icon = e;
    }
  }
  get loading() {
    return this._loading;
  }
  set mouseLeave(i) {
    this._mouseLeave = i, i && (this.tooltipVisible = !1, clearTimeout(this.timeoutID));
  }
  get mouseLeave() {
    return this._mouseLeave;
  }
  computeTooltipPosition() {
    const {
        value: i
      } = this._parent,
      {
        value: t
      } = this._tooltip;
    i && t && rs(i, t, {
      placement: "bottom",
      middleware: [qo(10), ss(), os(), ns({
        padding: 5
      })]
    }).then(e => {
      const {
        x: o,
        y: n
      } = e;
      Object.assign(t.style, {
        left: `${o}px`,
        top: `${n}px`
      });
    });
  }
  onMouseEnter() {
    if (!(this.tooltipTitle || this.tooltipText)) return;
    this.mouseLeave = !1;
    const i = this.tooltipTime ?? 700;
    this.timeoutID = setTimeout(() => {
      this.mouseLeave || (this.computeTooltipPosition(), this.tooltipVisible = !0);
    }, i);
  }
  closeNestedContexts() {
    const i = this.getAttribute("data-context-group");
    if (i) for (const t of pn.dialog.children) {
      const e = t.getAttribute("data-context-group");
      if (t instanceof pn && e === i) {
        t.visible = !1, t.removeAttribute("data-context-group");
        for (const o of t.children) o instanceof xe && (o.closeNestedContexts(), o.removeAttribute("data-context-group"));
      }
    }
  }
  click() {
    this.disabled || super.click();
  }
  get _contextMenu() {
    return this.querySelector("bim-context-menu");
  }
  connectedCallback() {
    super.connectedCallback(), this.addEventListener("click", this.showContextMenu);
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.removeEventListener("click", this.showContextMenu);
  }
  render() {
    const i = f`
      <div ${K(this._tooltip)} class="tooltip">
        ${this.tooltipTitle ? f`<p style="text-wrap: nowrap;">
              <strong>${this.tooltipTitle}</strong>
            </p>` : null}
        ${this.tooltipText ? f`<p style="width: 9rem;">${this.tooltipText}</p>` : null}
      </div>
    `,
      t = f`<svg
      xmlns="http://www.w3.org/2000/svg"
      height="1.125rem"
      viewBox="0 0 24 24"
      width="1.125rem"
      style="fill: var(--bim-label--c)"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
    </svg>`;
    return f`
      <div ${K(this._parent)} class="parent" @click=${this.onClick}>
        ${this.label || this.icon ? f`
              <div
                class="button"
                @mouseenter=${this.onMouseEnter}
                @mouseleave=${() => this.mouseLeave = !0}
              >
                <bim-label
                  .icon=${this.icon}
                  .vertical=${this.vertical}
                  .labelHidden=${this.labelHidden}
                  >${this.label}${this.label && this._contextMenu ? t : null}</bim-label
                >
              </div>
            ` : null}
        ${this.tooltipTitle || this.tooltipText ? i : null}
      </div>
      <slot></slot>
    `;
  }
}, xe.styles = O`
    :host {
      --bim-label--c: var(--bim-ui_bg-contrast-100, white);
      display: block;
      flex: 1;
      pointer-events: none;
      background-color: var(--bim-button--bgc, var(--bim-ui_bg-contrast-20));
      border-radius: var(--bim-ui_size-4xs);
      transition: all 0.15s;
    }

    :host(:not([disabled]):hover) {
      cursor: pointer;
    }

    bim-label {
      pointer-events: none;
    }

    .parent {
      --bim-icon--c: var(--bim-label--c);
      position: relative;
      display: flex;
      height: 100%;
      user-select: none;
      row-gap: 0.125rem;
      min-height: var(--bim-ui_size-5xl);
      min-width: var(--bim-ui_size-5xl);
    }

    .button,
    .children {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: auto;
    }

    .children {
      padding: 0 0.375rem;
      position: absolute;
      height: 100%;
      right: 0;
    }

    :host(:not([label-hidden])[icon][vertical]) .parent {
      min-height: 2.5rem;
    }

    .button {
      flex-grow: 1;
    }

    :host(:not([label-hidden])[label]) .button {
      justify-content: var(--bim-button--jc, center);
    }

    :host(:hover),
    :host([active]) {
      --bim-label--c: var(--bim-ui_main-contrast);
      background-color: var(--bim-ui_main-base);
    }

    :host(:not([label]):not([icon])) .children {
      flex: 1;
    }

    :host([vertical]) .parent {
      justify-content: center;
    }

    :host(:not([label-hidden])[label]) .button {
      padding: 0 0.5rem;
    }

    :host([disabled]) {
      --bim-label--c: var(--bim-ui_bg-contrast-80) !important;
      background-color: gray !important;
    }

    ::slotted(bim-button) {
      --bim-icon--fz: var(--bim-ui_size-base);
      --bim-button--bdrs: var(--bim-ui_size-4xs);
      --bim-button--olw: 0;
      --bim-button--olc: transparent;
    }

    .tooltip {
      position: absolute;
      padding: 0.75rem;
      z-index: 99;
      display: flex;
      flex-flow: column;
      row-gap: 0.375rem;
      box-shadow: 0 0 10px 3px rgba(0 0 0 / 20%);
      outline: 1px solid var(--bim-ui_bg-contrast-40);
      font-size: var(--bim-ui_size-xs);
      border-radius: var(--bim-ui_size-4xs);
      background-color: var(--bim-ui_bg-contrast-20);
      color: var(--bim-ui_bg-contrast-100);
    }

    .tooltip p {
      margin: 0;
      padding: 0;
    }

    :host(:not([tooltip-visible])) .tooltip {
      display: none;
    }
  `, xe);
ut([g({
  type: String,
  reflect: !0
})], rt.prototype, "label", 2);
ut([g({
  type: Boolean,
  attribute: "label-hidden",
  reflect: !0
})], rt.prototype, "labelHidden", 2);
ut([g({
  type: Boolean,
  reflect: !0
})], rt.prototype, "active", 2);
ut([g({
  type: Boolean,
  reflect: !0,
  attribute: "disabled"
})], rt.prototype, "disabled", 2);
ut([g({
  type: String,
  reflect: !0
})], rt.prototype, "icon", 2);
ut([g({
  type: Boolean,
  reflect: !0
})], rt.prototype, "vertical", 2);
ut([g({
  type: Number,
  attribute: "tooltip-time",
  reflect: !0
})], rt.prototype, "tooltipTime", 2);
ut([g({
  type: Boolean,
  attribute: "tooltip-visible",
  reflect: !0
})], rt.prototype, "tooltipVisible", 2);
ut([g({
  type: String,
  attribute: "tooltip-title",
  reflect: !0
})], rt.prototype, "tooltipTitle", 2);
ut([g({
  type: String,
  attribute: "tooltip-text",
  reflect: !0
})], rt.prototype, "tooltipText", 2);
ut([g({
  type: Boolean,
  reflect: !0
})], rt.prototype, "loading", 1);
let Zl = rt;
var tc = Object.defineProperty,
  Xe = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && tc(t, e, n), n;
  };
const Fs = class extends I {
  constructor() {
    super(...arguments), this.checked = !1, this.inverted = !1, this.onValueChange = new Event("change");
  }
  /**
   * A getter that returns the current checked state of the checkbox. This is useful for retrieving the checkbox's value in form submissions or JavaScript interactions as it provides a consistent `value` property as many other components.
   * @type {boolean}
   * @default false
   * @example <script>console.log(document.querySelector('bim-checkbox').value);<\/script>
   * @example
   * const checkbox = document.createElement('bim-checkbox');
   * document.body.appendChild(checkbox);
   * console.log(checkbox.value); // false initially
   */
  get value() {
    return this.checked;
  }
  onChange(t) {
    t.stopPropagation(), this.checked = t.target.checked, this.dispatchEvent(this.onValueChange);
  }
  render() {
    return f`
      <div class="parent">
        ${this.label ? f`<bim-label .icon="${this.icon}">${this.label}</bim-label> ` : null}
        <input
          type="checkbox"
          aria-label=${this.label || this.name || "Checkbox Input"}
          @change="${this.onChange}"
          .checked="${this.checked}"
        />
      </div>
    `;
  }
};
Fs.styles = O`
    :host {
      display: block;
    }

    .parent {
      display: flex;
      justify-content: space-between;
      height: 1.75rem;
      column-gap: 0.25rem;
      width: 100%;
      align-items: center;
      transition: all 0.15s;
    }

    :host([inverted]) .parent {
      flex-direction: row-reverse;
      justify-content: start;
    }

    input {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
      border: none;
      outline: none;
      accent-color: var(--bim-checkbox--c, var(--bim-ui_main-base));
      transition: all 0.15s;
    }

    input:focus {
      outline: var(--bim-checkbox--olw, 2px) solid
        var(--bim-checkbox--olc, var(--bim-ui_accent-base));
    }
  `;
let pe = Fs;
Xe([g({
  type: String,
  reflect: !0
})], pe.prototype, "icon");
Xe([g({
  type: String,
  reflect: !0
})], pe.prototype, "name");
Xe([g({
  type: String,
  reflect: !0
})], pe.prototype, "label");
Xe([g({
  type: Boolean,
  reflect: !0
})], pe.prototype, "checked");
Xe([g({
  type: Boolean,
  reflect: !0
})], pe.prototype, "inverted");
var ec = Object.defineProperty,
  me = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && ec(t, e, n), n;
  };
const Bs = class extends I {
  constructor() {
    super(...arguments), this.vertical = !1, this.color = "#bcf124", this._colorInput = le(), this._textInput = le(), this.onValueChange = new Event("input"), this.onOpacityInput = t => {
      const e = t.target;
      this.opacity = e.value, this.dispatchEvent(this.onValueChange);
    };
  }
  /**
   * Represents both the color and opacity values combined into a single object. This is an instance property, not an HTMLElement attribute.
   * @type {Object}
   * @example
   * const colorInput = document.createElement('bim-color-input');
   * colorInput.value = { color: '#ff0000', opacity: 0.5 };
   */
  set value(t) {
    const {
      color: e,
      opacity: o
    } = t;
    this.color = e, o && (this.opacity = o);
  }
  get value() {
    const t = {
      color: this.color
    };
    return this.opacity && (t.opacity = this.opacity), t;
  }
  onColorInput(t) {
    t.stopPropagation();
    const {
      value: e
    } = this._colorInput;
    e && (this.color = e.value, this.dispatchEvent(this.onValueChange));
  }
  onTextInput(t) {
    t.stopPropagation();
    const {
      value: e
    } = this._textInput;
    if (!e) return;
    const {
      value: o
    } = e;
    let n = o.replace(/[^a-fA-F0-9]/g, "");
    n.startsWith("#") || (n = `#${n}`), e.value = n.slice(0, 7), e.value.length === 7 && (this.color = e.value, this.dispatchEvent(this.onValueChange));
  }
  /**
   * Focuses on the color input by programmatically triggering a click event on the underlying color input element.
   * If the color input element is not available, the function does nothing.
   */
  focus() {
    const {
      value: t
    } = this._colorInput;
    t && t.click();
  }
  render() {
    return f`
      <div class="parent">
        <bim-input
          .label=${this.label}
          .icon=${this.icon}
          .vertical="${this.vertical}"
        >
          <div class="color-container">
            <div
              style="display: flex; align-items: center; gap: .375rem; height: 100%; flex: 1; padding: 0 0.5rem;"
            >
              <input
                ${K(this._colorInput)}
                @input="${this.onColorInput}"
                type="color"
                aria-label=${this.label || this.name || "Color Input"}
                value="${this.color}"
              />
              <div
                @click=${this.focus}
                class="sample"
                style="background-color: ${this.color}"
              ></div>
              <input
                ${K(this._textInput)}
                @input="${this.onTextInput}"
                value="${this.color}"
                type="text"
                aria-label=${this.label || this.name || "Text Color Input"}
              />
            </div>
            ${this.opacity !== void 0 ? f`<bim-number-input
                  @change=${this.onOpacityInput}
                  slider
                  suffix="%"
                  min="0"
                  value=${this.opacity}
                  max="100"
                ></bim-number-input>` : null}
          </div>
        </bim-input>
      </div>
    `;
  }
};
Bs.styles = O`
    :host {
      --bim-input--bgc: var(--bim-ui_bg-contrast-20);
      flex: 1;
      display: block;
    }

    :host(:focus) {
      --bim-input--olw: var(--bim-number-input--olw, 2px);
      --bim-input--olc: var(--bim-ui_accent-base);
    }

    .parent {
      display: flex;
      gap: 0.375rem;
    }

    .color-container {
      position: relative;
      outline: none;
      display: flex;
      height: 100%;
      gap: 0.5rem;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      border-radius: var(--bim-color-input--bdrs, var(--bim-ui_size-4xs));
    }

    .color-container input[type="color"] {
      position: absolute;
      bottom: -0.25rem;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    .color-container .sample {
      width: 1rem;
      height: 1rem;
      border-radius: 0.125rem;
      background-color: #fff;
    }

    .color-container input[type="text"] {
      height: 100%;
      flex: 1;
      width: 3.25rem;
      text-transform: uppercase;
      font-size: 0.75rem;
      background-color: transparent;
      padding: 0%;
      outline: none;
      border: none;
      color: var(--bim-color-input--c, var(--bim-ui_bg-contrast-100));
    }

    bim-number-input {
      flex-grow: 0;
    }
  `;
let qt = Bs;
me([g({
  type: String,
  reflect: !0
})], qt.prototype, "name");
me([g({
  type: String,
  reflect: !0
})], qt.prototype, "label");
me([g({
  type: String,
  reflect: !0
})], qt.prototype, "icon");
me([g({
  type: Boolean,
  reflect: !0
})], qt.prototype, "vertical");
me([g({
  type: Number,
  reflect: !0
})], qt.prototype, "opacity");
me([g({
  type: String,
  reflect: !0
})], qt.prototype, "color");
var ic = Object.defineProperty,
  nc = Object.getOwnPropertyDescriptor,
  Rt = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? nc(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && ic(t, e, n), n;
  };
const Hs = class extends I {
  constructor() {
    super(...arguments), this.checked = !1, this.checkbox = !1, this.noMark = !1, this.vertical = !1;
  }
  get value() {
    return this._value !== void 0 ? this._value : this.label ? Ii(this.label) : this.label;
  }
  set value(t) {
    this._value = t;
  }
  render() {
    return f`
      <div class="parent" .title=${this.label ?? ""}>
        ${this.img || this.icon || this.label ? f` <div style="display: flex; column-gap: 0.375rem">
              ${this.checkbox && !this.noMark ? f`<bim-checkbox
                    style="pointer-events: none"
                    .checked=${this.checked}
                  ></bim-checkbox>` : null}
              <bim-label
                .vertical=${this.vertical}
                .icon=${this.icon}
                .img=${this.img}
                >${this.label}</bim-label
              >
            </div>` : null}
        ${!this.checkbox && !this.noMark && this.checked ? f`<svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.125rem"
              viewBox="0 0 24 24"
              width="1.125rem"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
            </svg>` : null}
        <slot></slot>
      </div>
    `;
  }
};
Hs.styles = O`
    :host {
      --bim-label--c: var(--bim-ui_bg-contrast-100);
      display: block;
      box-sizing: border-box;
      flex: 1;
      padding: 0rem 0.5rem;
      border-radius: var(--bim-ui_size-4xs);
      transition: all 0.15s;
    }

    :host(:hover) {
      cursor: pointer;
      background-color: color-mix(
        in lab,
        var(--bim-selector--bgc, var(--bim-ui_bg-contrast-20)),
        var(--bim-ui_main-base) 10%
      );
    }

    :host([checked]) {
      --bim-label--c: color-mix(in lab, var(--bim-ui_main-base), white 30%);
    }

    :host([checked]) svg {
      fill: color-mix(in lab, var(--bim-ui_main-base), white 30%);
    }

    .parent {
      box-sizing: border-box;
      display: flex;
      justify-content: var(--bim-option--jc, space-between);
      column-gap: 0.5rem;
      align-items: center;
      min-height: 1.75rem;
      height: 100%;
    }

    input {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
      border: none;
      outline: none;
      accent-color: var(--bim-checkbox--c, var(--bim-ui_main-base));
    }

    input:focus {
      outline: var(--bim-checkbox--olw, 2px) solid
        var(--bim-checkbox--olc, var(--bim-ui_accent-base));
    }

    bim-label {
      pointer-events: none;
    }
  `;
let q = Hs;
Rt([g({
  type: String,
  reflect: !0
})], q.prototype, "img", 2);
Rt([g({
  type: String,
  reflect: !0
})], q.prototype, "label", 2);
Rt([g({
  type: String,
  reflect: !0
})], q.prototype, "icon", 2);
Rt([g({
  type: Boolean,
  reflect: !0
})], q.prototype, "checked", 2);
Rt([g({
  type: Boolean,
  reflect: !0
})], q.prototype, "checkbox", 2);
Rt([g({
  type: Boolean,
  attribute: "no-mark",
  reflect: !0
})], q.prototype, "noMark", 2);
Rt([g({
  converter: {
    fromAttribute(i) {
      return i && Ii(i);
    }
  }
})], q.prototype, "value", 1);
Rt([g({
  type: Boolean,
  reflect: !0
})], q.prototype, "vertical", 2);
var oc = Object.defineProperty,
  sc = Object.getOwnPropertyDescriptor,
  Pt = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? sc(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && oc(t, e, n), n;
  };
const Us = class extends U {
  constructor() {
    super(), this.multiple = !1, this.required = !1, this.vertical = !1, this._visible = !1, this._value = /* @__PURE__ */new Set(), this.onValueChange = new Event("change"), this._contextMenu = le(), this.onOptionClick = t => {
      const e = t.target,
        o = this._value.has(e);
      if (!this.multiple && !this.required && !o) this._value = /* @__PURE__ */new Set([e]);else if (!this.multiple && !this.required && o) this._value = /* @__PURE__ */new Set([]);else if (!this.multiple && this.required && !o) this._value = /* @__PURE__ */new Set([e]);else if (this.multiple && !this.required && !o) this._value = /* @__PURE__ */new Set([...this._value, e]);else if (this.multiple && !this.required && o) {
        const n = [...this._value].filter(s => s !== e);
        this._value = new Set(n);
      } else if (this.multiple && this.required && !o) this._value = /* @__PURE__ */new Set([...this._value, e]);else if (this.multiple && this.required && o) {
        const n = [...this._value].filter(r => r !== e),
          s = new Set(n);
        s.size !== 0 && (this._value = s);
      }
      this.updateOptionsState(), this.dispatchEvent(this.onValueChange);
    }, this.useObserver = !0;
  }
  set visible(t) {
    if (t) {
      const {
        value: e
      } = this._contextMenu;
      if (!e) return;
      for (const o of this.elements) e.append(o);
      this._visible = !0;
    } else {
      for (const e of this.elements) this.append(e);
      this._visible = !1, this.resetVisibleElements();
    }
  }
  get visible() {
    return this._visible;
  }
  /**
   * The selected values in the dropdown.
   * @type {any[]}
   * @example
   * const dropdown = document.createElement('bim-dropdown');
   * dropdown.value = ['option1', 'option2'];
   */
  set value(t) {
    if (this.required && Object.keys(t).length === 0) return;
    const e = /* @__PURE__ */new Set();
    for (const o of t) {
      const n = this.findOption(o);
      if (n && (e.add(n), !this.multiple && Object.keys(t).length === 1)) break;
    }
    this._value = e, this.updateOptionsState(), this.dispatchEvent(this.onValueChange);
  }
  get value() {
    return [...this._value].filter(t => t instanceof q && t.checked).map(t => t.value);
  }
  get _options() {
    const t = /* @__PURE__ */new Set([...this.elements]);
    for (const e of this.children) e instanceof q && t.add(e);
    return [...t];
  }
  onSlotChange(t) {
    const e = t.target.assignedElements();
    this.observe(e);
    const o = /* @__PURE__ */new Set();
    for (const n of this.elements) {
      if (!(n instanceof q)) {
        n.remove();
        continue;
      }
      n.checked && o.add(n), n.removeEventListener("click", this.onOptionClick), n.addEventListener("click", this.onOptionClick);
    }
    this._value = o;
  }
  updateOptionsState() {
    for (const t of this._options) t instanceof q && (t.checked = this._value.has(t));
  }
  findOption(t) {
    return this._options.find(e => e instanceof q ? e.label === t || e.value === t : !1);
  }
  render() {
    let t, e, o;
    if (this._value.size === 0) t = "Select an option...";else if (this._value.size === 1) {
      const n = [...this._value][0];
      t = (n == null ? void 0 : n.label) || (n == null ? void 0 : n.value), e = n == null ? void 0 : n.img, o = n == null ? void 0 : n.icon;
    } else t = `Multiple (${this._value.size})`;
    return f`
      <bim-input
        title=${this.label ?? ""}
        .label=${this.label}
        .icon=${this.icon}
        .vertical=${this.vertical}
      >
        <div class="input" @click=${() => this.visible = !this.visible}>
          <bim-label
            .img=${e}
            .icon=${o}
            style="overflow: hidden;"
            >${t}</bim-label
          >
          <svg
            style="flex-shrink: 0; fill: var(--bim-dropdown--c, var(--bim-ui_bg-contrast-100))"
            xmlns="http://www.w3.org/2000/svg"
            height="1.125rem"
            viewBox="0 0 24 24"
            width="1.125rem"
            fill="#9ca3af"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
          </svg>
          <bim-context-menu
            ${K(this._contextMenu)}
            .visible=${this.visible}
            @hidden=${() => {
      this.visible && (this.visible = !1);
    }}
          >
            <slot @slotchange=${this.onSlotChange}></slot>
          </bim-context-menu>
        </div>
      </bim-input>
    `;
  }
};
Us.styles = [Ot.scrollbar, O`
      :host {
        --bim-input--bgc: var(
          --bim-dropdown--bgc,
          var(--bim-ui_bg-contrast-20)
        );
        --bim-input--olw: 2px;
        --bim-input--olc: transparent;
        --bim-input--bdrs: var(--bim-ui_size-4xs);
        flex: 1;
        display: block;
      }

      :host([visible]) {
        --bim-input--olc: var(--bim-ui_accent-base);
      }

      .input {
        --bim-label--fz: var(--bim-drodown--fz, var(--bim-ui_size-xs));
        --bim-label--c: var(--bim-dropdown--c, var(--bim-ui_bg-contrast-100));
        height: 100%;
        display: flex;
        flex: 1;
        overflow: hidden;
        column-gap: 0.25rem;
        outline: none;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.5rem;
      }

      bim-label {
        pointer-events: none;
      }
    `];
let vt = Us;
Pt([g({
  type: String,
  reflect: !0
})], vt.prototype, "name", 2);
Pt([g({
  type: String,
  reflect: !0
})], vt.prototype, "icon", 2);
Pt([g({
  type: String,
  reflect: !0
})], vt.prototype, "label", 2);
Pt([g({
  type: Boolean,
  reflect: !0
})], vt.prototype, "multiple", 2);
Pt([g({
  type: Boolean,
  reflect: !0
})], vt.prototype, "required", 2);
Pt([g({
  type: Boolean,
  reflect: !0
})], vt.prototype, "vertical", 2);
Pt([g({
  type: Boolean,
  reflect: !0
})], vt.prototype, "visible", 1);
Pt([he()], vt.prototype, "_value", 2);
var rc = Object.defineProperty,
  Vs = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && rc(t, e, n), n;
  };
const Ws = class extends I {
  constructor() {
    super(...arguments), this.floating = !1, this._layouts = {}, this._updateFunctions = {};
  }
  /**
   * Represents a collection of predefined grid layouts for the Grid component.
   * Each layout is defined by a unique name, a grid template string, and a map of area names to HTMLElement instances or
   * Statefull/Stateless component definitions.
   * The grid template string defines the structure of the grid, and the area names correspond to the grid-area property of the HTMLElement instances.
   * The HTMLElement instances are used to populate the grid with content.
   * @remarks Once defined, the layout is meant to be immutable.
   */
  set layouts(t) {
    this._layouts = t;
    const e = {};
    for (const [o, n] of Object.entries(t)) for (const s in n.elements) e[o] || (e[o] = {}), e[o][s] = r => {
      const a = this._updateFunctions[o];
      if (!a) return;
      const l = a[s];
      l && l(r);
    };
    this.updateComponent = e;
  }
  get layouts() {
    return this._layouts;
  }
  // private isVerticalArea(area: string) {
  //   const { rows } = this;
  //   const row = rows.find((row) => row.includes(area));
  //   if (!row)
  //     throw new Error(
  //       `${area} wasn't defined in the grid-template of this bim-grid`,
  //     );
  //   const index = rows.indexOf(row);
  //   const abovePanel = index > 0 && rows[index - 1].includes(area);
  //   const belowPanel =
  //     index < rows.length - 1 && rows[index + 1].includes(area);
  //   return abovePanel || belowPanel;
  // }
  getLayoutAreas(t) {
    const {
        template: e
      } = t,
      o = e.split(`
`).map(n => n.trim()).map(n => n.split('"')[1]).filter(n => n !== void 0).flatMap(n => n.split(/\s+/));
    return [...new Set(o)].filter(n => n !== "");
  }
  firstUpdated() {
    this._onLayoutChange = new Event("layoutchange");
  }
  render() {
    if (this.layout) {
      if (this._updateFunctions = {}, this.layouts[this.layout]) {
        this.innerHTML = "", this._updateFunctions[this.layout] = {};
        const t = this._updateFunctions[this.layout],
          e = this.layouts[this.layout],
          o = this.getLayoutAreas(e).map(n => {
            const s = e.elements[n];
            if (!s) return null;
            if (s instanceof HTMLElement) return s.style.gridArea = n, s;
            if ("template" in s) {
              const {
                  template: r,
                  initialState: a
                } = s,
                [l, c] = U.create(r, a);
              return l.style.gridArea = n, t[n] = c, l;
            }
            return U.create(s);
          }).filter(n => !!n);
        this.style.gridTemplate = e.template, this.append(...o), this._onLayoutChange && this.dispatchEvent(this._onLayoutChange);
      }
    } else this._updateFunctions = {}, this.innerHTML = "", this.style.gridTemplate = "", this._onLayoutChange && this.dispatchEvent(this._onLayoutChange);
    return f`<slot></slot>`;
  }
};
Ws.styles = O`
    :host {
      display: grid;
      height: 100%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }

    /* :host(:not([layout])) {
      display: none;
    } */

    :host([floating]) {
      --bim-panel--bdrs: var(--bim-ui_size-4xs);
      background-color: transparent;
      padding: 1rem;
      gap: 1rem;
      position: absolute;
      pointer-events: none;
      top: 0px;
      left: 0px;
    }

    :host(:not([floating])) {
      --bim-panel--bdrs: 0;
      background-color: var(--bim-ui_bg-contrast-20);
      gap: 1px;
    }
  `;
let On = Ws;
Vs([g({
  type: Boolean,
  reflect: !0
})], On.prototype, "floating");
Vs([g({
  type: String,
  reflect: !0
})], On.prototype, "layout");
const mn = class extends I {
  render() {
    return f`
      <iconify-icon .icon=${this.icon} height="none"></iconify-icon>
    `;
  }
};
mn.styles = O`
    :host {
      height: var(--bim-icon--fz, var(--bim-ui_size-sm));
      width: var(--bim-icon--fz, var(--bim-ui_size-sm));
    }

    iconify-icon {
      height: var(--bim-icon--fz, var(--bim-ui_size-sm));
      width: var(--bim-icon--fz, var(--bim-ui_size-sm));
      color: var(--bim-icon--c);
      transition: all 0.15s;
    }
  `, mn.properties = {
  icon: {
    type: String
  }
};
let ac = mn;
var lc = Object.defineProperty,
  Oi = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && lc(t, e, n), n;
  };
const Gs = class extends I {
  constructor() {
    super(...arguments), this.vertical = !1, this.onValueChange = new Event("change");
  }
  get value() {
    const t = {};
    for (const e of this.children) {
      const o = e;
      "value" in o ? t[o.name || o.label] = o.value : "checked" in o && (t[o.name || o.label] = o.checked);
    }
    return t;
  }
  set value(t) {
    const e = [...this.children];
    for (const o in t) {
      const n = e.find(a => {
        const l = a;
        return l.name === o || l.label === o;
      });
      if (!n) continue;
      const s = n,
        r = t[o];
      typeof r == "boolean" ? s.checked = r : s.value = r;
    }
  }
  render() {
    return f`
      <div class="parent">
        ${this.label || this.icon ? f`<bim-label .icon=${this.icon}>${this.label}</bim-label>` : null}
        <div class="input">
          <slot></slot>
        </div>
      </div>
    `;
  }
};
Gs.styles = O`
    :host {
      flex: 1;
      display: block;
    }

    .parent {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
      row-gap: 0.375rem;
      user-select: none;
      flex: 1;
    }

    :host(:not([vertical])) .parent {
      justify-content: space-between;
    }

    :host([vertical]) .parent {
      flex-direction: column;
    }

    .input {
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-height: 1.75rem;
      min-width: 3rem;
      gap: var(--bim-input--g, var(--bim-ui_size-4xs));
      padding: var(--bim-input--p, 0);
      background-color: var(--bim-input--bgc, transparent);
      outline: var(--bim-input--olw, 2px) solid
        var(--bim-input--olc, transparent);
      border-radius: var(--bim-input--bdrs, var(--bim-ui_size-4xs));
      transition: all 0.15s;
    }

    :host(:not([vertical])) .input {
      flex: 1;
      justify-content: flex-end;
    }

    :host(:not([vertical])[label]) .input {
      max-width: fit-content;
    }
  `;
let Je = Gs;
Oi([g({
  type: String,
  reflect: !0
})], Je.prototype, "name");
Oi([g({
  type: String,
  reflect: !0
})], Je.prototype, "label");
Oi([g({
  type: String,
  reflect: !0
})], Je.prototype, "icon");
Oi([g({
  type: Boolean,
  reflect: !0
})], Je.prototype, "vertical");
var cc = Object.defineProperty,
  Qe = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && cc(t, e, n), n;
  };
const qs = class extends I {
  constructor() {
    super(...arguments), this.labelHidden = !1, this.iconHidden = !1, this.vertical = !1;
  }
  get value() {
    return this.textContent ? Ii(this.textContent) : this.textContent;
  }
  render() {
    return f`
      <div class="parent" .title=${this.textContent ?? ""}>
        ${this.img ? f`<img .src=${this.img} .alt=${this.textContent || ""} />` : null}
        ${!this.iconHidden && this.icon ? f`<bim-icon .icon=${this.icon}></bim-icon>` : null}
        <p><slot></slot></p>
      </div>
    `;
  }
};
qs.styles = O`
    :host {
      --bim-icon--c: var(--bim-label--c);
      color: var(--bim-label--c, var(--bim-ui_bg-contrast-60));
      font-size: var(--bim-label--fz, var(--bim-ui_size-xs));
      overflow: hidden;
      display: block;
      white-space: nowrap;
      line-height: 1.1rem;
      transition: all 0.15s;
    }

    .parent {
      display: flex;
      align-items: center;
      column-gap: 0.25rem;
      row-gap: 0.125rem;
      user-select: none;
      height: 100%;
    }

    :host([vertical]) .parent {
      flex-direction: column;
    }

    .parent p {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
      gap: 0.125rem;
    }

    :host([label-hidden]) .parent p,
    :host(:empty) .parent p {
      display: none;
    }

    img {
      height: 100%;
      aspect-ratio: 1;
      border-radius: 100%;
      margin-right: 0.125rem;
    }

    :host(:not([vertical])) img {
      max-height: var(
        --bim-label_icon--sz,
        calc(var(--bim-label--fz, var(--bim-ui_size-xs)) * 1.8)
      );
    }

    :host([vertical]) img {
      max-height: var(
        --bim-label_icon--sz,
        calc(var(--bim-label--fz, var(--bim-ui_size-xs)) * 4)
      );
    }
  `;
let fe = qs;
Qe([g({
  type: String,
  reflect: !0
})], fe.prototype, "img");
Qe([g({
  type: Boolean,
  attribute: "label-hidden",
  reflect: !0
})], fe.prototype, "labelHidden");
Qe([g({
  type: String,
  reflect: !0
})], fe.prototype, "icon");
Qe([g({
  type: Boolean,
  attribute: "icon-hidden",
  reflect: !0
})], fe.prototype, "iconHidden");
Qe([g({
  type: Boolean,
  reflect: !0
})], fe.prototype, "vertical");
var dc = Object.defineProperty,
  uc = Object.getOwnPropertyDescriptor,
  at = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? uc(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && dc(t, e, n), n;
  };
const Ys = class extends I {
  constructor() {
    super(...arguments), this._value = 0, this.vertical = !1, this.slider = !1, this._input = le(), this.onValueChange = new Event("change");
  }
  set value(t) {
    this.setValue(t.toString());
  }
  get value() {
    return this._value;
  }
  onChange(t) {
    t.stopPropagation();
    const {
      value: e
    } = this._input;
    e && this.setValue(e.value);
  }
  setValue(t) {
    const {
      value: e
    } = this._input;
    let o = t;
    if (o = o.replace(/[^0-9.-]/g, ""), o = o.replace(/(\..*)\./g, "$1"), o.endsWith(".") || (o.lastIndexOf("-") > 0 && (o = o[0] + o.substring(1).replace(/-/g, "")), o === "-" || o === "-0")) return;
    let n = Number(o);
    Number.isNaN(n) || (n = this.min !== void 0 ? Math.max(n, this.min) : n, n = this.max !== void 0 ? Math.min(n, this.max) : n, this.value !== n && (this._value = n, e && (e.value = this.value.toString()), this.requestUpdate(), this.dispatchEvent(this.onValueChange)));
  }
  onBlur() {
    const {
      value: t
    } = this._input;
    t && Number.isNaN(Number(t.value)) && (t.value = this.value.toString());
  }
  onSliderMouseDown(t) {
    document.body.style.cursor = "w-resize";
    const {
        clientX: e
      } = t,
      o = this.value;
    let n = !1;
    const s = l => {
        var c;
        n = !0;
        const {
            clientX: h
          } = l,
          u = this.step ?? 1,
          p = ((c = u.toString().split(".")[1]) == null ? void 0 : c.length) || 0,
          d = 1 / (this.sensitivity ?? 1),
          m = (h - e) / d;
        if (Math.floor(Math.abs(m)) !== Math.abs(m)) return;
        const y = o + m * u;
        this.setValue(y.toFixed(p));
      },
      r = () => {
        this.slider = !0, this.removeEventListener("blur", r);
      },
      a = () => {
        document.removeEventListener("mousemove", s), document.body.style.cursor = "default", n ? n = !1 : (this.addEventListener("blur", r), this.slider = !1, requestAnimationFrame(() => this.focus())), document.removeEventListener("mouseup", a);
      };
    document.addEventListener("mousemove", s), document.addEventListener("mouseup", a);
  }
  onFocus(t) {
    t.stopPropagation();
    const e = o => {
      o.key === "Escape" && (this.blur(), window.removeEventListener("keydown", e));
    };
    window.addEventListener("keydown", e);
  }
  connectedCallback() {
    super.connectedCallback(), this.min && this.min > this.value && (this._value = this.min), this.max && this.max < this.value && (this._value = this.max);
  }
  /**
   * Sets focus to the input element of the number input component.
   * This method is useful for programmatically focusing the input element, for example,
   * in response to a user action or to emphasize the input in the UI.
   *
   * If the input element reference is not available (not yet rendered or disconnected),
   * this method will do nothing.
   */
  focus() {
    const {
      value: t
    } = this._input;
    t && t.focus();
  }
  render() {
    const t = f`
      ${this.pref || this.icon ? f`<bim-label
            style="pointer-events: auto"
            @mousedown=${this.onSliderMouseDown}
            .icon=${this.icon}
            >${this.pref}</bim-label
          >` : null}
      <input
        ${K(this._input)}
        type="text"
        aria-label=${this.label || this.name || "Number Input"}
        size="1"
        @input=${a => a.stopPropagation()}
        @change=${this.onChange}
        @blur=${this.onBlur}
        @focus=${this.onFocus}
        .value=${this.value.toString()}
      />
      ${this.suffix ? f`<bim-label
            style="pointer-events: auto"
            @mousedown=${this.onSliderMouseDown}
            >${this.suffix}</bim-label
          >` : null}
    `,
      e = this.min ?? -1 / 0,
      o = this.max ?? 1 / 0,
      n = 100 * (this.value - e) / (o - e),
      s = f`
      <style>
        .slider-indicator {
          width: ${`${n}%`};
        }
      </style>
      <div class="slider" @mousedown=${this.onSliderMouseDown}>
        <div class="slider-indicator"></div>
        ${this.pref || this.icon ? f`<bim-label
              style="z-index: 1; margin-right: 0.125rem"
              .icon=${this.icon}
              >${`${this.pref}: `}</bim-label
            >` : null}
        <bim-label style="z-index: 1;">${this.value}</bim-label>
        ${this.suffix ? f`<bim-label style="z-index: 1;">${this.suffix}</bim-label>` : null}
      </div>
    `,
      r = `${this.label || this.name || this.pref ? `${this.label || this.name || this.pref}: ` : ""}${this.value}${this.suffix ?? ""}`;
    return f`
      <bim-input
        title=${r}
        .label=${this.label}
        .icon=${this.icon}
        .vertical=${this.vertical}
      >
        ${this.slider ? s : t}
      </bim-input>
    `;
  }
};
Ys.styles = O`
    :host {
      --bim-input--bgc: var(
        --bim-number-input--bgc,
        var(--bim-ui_bg-contrast-20)
      );
      --bim-input--olw: var(--bim-number-input--olw, 2px);
      --bim-input--olc: var(--bim-number-input--olc, transparent);
      --bim-input--bdrs: var(--bim-number-input--bdrs, var(--bim-ui_size-4xs));
      --bim-input--p: 0 0.375rem;
      flex: 1;
      display: block;
    }

    :host(:focus) {
      --bim-input--olw: var(--bim-number-input--olw, 2px);
      --bim-input--olc: var(
        --bim-number-input¡focus--c,
        var(--bim-ui_accent-base)
      );
    }

    :host(:not([slider])) bim-label {
      --bim-label--c: var(
        --bim-number-input_affixes--c,
        var(--bim-ui_bg-contrast-60)
      );
      --bim-label--fz: var(
        --bim-number-input_affixes--fz,
        var(--bim-ui_size-xs)
      );
    }

    p {
      margin: 0;
      padding: 0;
    }

    input {
      background-color: transparent;
      outline: none;
      border: none;
      padding: 0;
      flex-grow: 1;
      text-align: right;
      font-family: inherit;
      font-feature-settings: inherit;
      font-variation-settings: inherit;
      font-size: var(--bim-number-input--fz, var(--bim-ui_size-xs));
      color: var(--bim-number-input--c, var(--bim-ui_bg-contrast-100));
    }

    :host([suffix]:not([pref])) input {
      text-align: left;
    }

    :host([slider]) {
      --bim-input--p: 0;
    }

    :host([slider]) .slider {
      --bim-label--c: var(--bim-ui_bg-contrast-100);
    }

    .slider {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 0.5rem;
    }

    .slider-indicator {
      height: 100%;
      background-color: var(--bim-ui_main-base);
      position: absolute;
      top: 0;
      left: 0;
      border-radius: var(--bim-input--bdrs, var(--bim-ui_size-4xs));
    }

    bim-input {
      display: flex;
    }

    bim-label {
      pointer-events: none;
    }
  `;
let it = Ys;
at([g({
  type: String,
  reflect: !0
})], it.prototype, "name", 2);
at([g({
  type: String,
  reflect: !0
})], it.prototype, "icon", 2);
at([g({
  type: String,
  reflect: !0
})], it.prototype, "label", 2);
at([g({
  type: String,
  reflect: !0
})], it.prototype, "pref", 2);
at([g({
  type: Number,
  reflect: !0
})], it.prototype, "min", 2);
at([g({
  type: Number,
  reflect: !0
})], it.prototype, "value", 1);
at([g({
  type: Number,
  reflect: !0
})], it.prototype, "step", 2);
at([g({
  type: Number,
  reflect: !0
})], it.prototype, "sensitivity", 2);
at([g({
  type: Number,
  reflect: !0
})], it.prototype, "max", 2);
at([g({
  type: String,
  reflect: !0
})], it.prototype, "suffix", 2);
at([g({
  type: Boolean,
  reflect: !0
})], it.prototype, "vertical", 2);
at([g({
  type: Boolean,
  reflect: !0
})], it.prototype, "slider", 2);
var hc = Object.defineProperty,
  pc = Object.getOwnPropertyDescriptor,
  Ke = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? pc(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && hc(t, e, n), n;
  };
const Xs = class extends I {
  constructor() {
    super(...arguments), this.onValueChange = new Event("change"), this._hidden = !1, this.headerHidden = !1, this.valueTransform = {}, this.activationButton = document.createElement("bim-button");
  }
  set hidden(t) {
    this._hidden = t, this.activationButton.active = !t, this.dispatchEvent(new Event("hiddenchange"));
  }
  get hidden() {
    return this._hidden;
  }
  /**
   * The `value` getter computes and returns the current state of the panel's form elements as an object. This property is dynamic and reflects the current input values within the panel. When accessed, it traverses the panel's child elements, collecting values from those that have a `name` or `label` attribute, and constructs an object where each key corresponds to the `name` or `label` of the element, and the value is the element's value. This property is particularly useful for forms or interactive panels where the user's input needs to be retrieved programmatically. The value returned is a snapshot of the panel's state at the time of access, and it does not maintain a live link to the input elements.
   *
   * @default {}
   * @example <bim-panel></bim-panel> <!-- Access via JavaScript to get value -->
   * @example
   * const panel = document.createElement('bim-panel');
   * document.body.appendChild(panel);
   * console.log(panel.value); // Logs the current value object of the panel
   */
  get value() {
    return Fe(this, this.valueTransform);
  }
  /**
   * The `value` setter allows programmatically updating the values of the panel's form elements. When a data object is passed to this property, it attempts to match the object's keys with the `name` or `label` attributes of the panel's child elements. If a match is found, the corresponding element's value is updated to the value associated with the key in the data object. This property is useful for initializing the panel with specific data or updating its state based on external inputs. Note that this operation does not affect elements without a matching `name` or `label`, and it only updates the values of elements that are direct children of the panel.
   *
   * @type {Record<string, any>}
   * @example <bim-panel></bim-panel> <!-- Set value via JavaScript -->
   * @example
   * const panel = document.createElement('bim-panel');
   * document.body.appendChild(panel);
   * panel.value = { 'input-name': 'John Doe', 'checkbox-name': true };
   */
  set value(t) {
    const e = [...this.children];
    for (const o in t) {
      const n = e.find(r => {
        const a = r;
        return a.name === o || a.label === o;
      });
      if (!n) continue;
      const s = n;
      s.value = t[o];
    }
  }
  connectedCallback() {
    super.connectedCallback(), this.activationButton.active = !this.hidden, this.activationButton.onclick = () => this.hidden = !this.hidden;
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.activationButton.remove();
  }
  /**
   * Collapses all `bim-panel-section` elements within the panel.
   * This method iterates over each `bim-panel-section` found within the panel's DOM and sets their `collapsed` property to `true`,
   * effectively hiding their content from view. This can be used to programmatically minimize the space taken up by sections
   * within the panel, making the panel more compact or to hide details that are not immediately necessary.
   */
  collapseSections() {
    const t = this.querySelectorAll("bim-panel-section");
    for (const e of t) e.collapsed = !0;
  }
  /**
   * Expands all `bim-panel-section` elements within the panel.
   * This method iterates over each `bim-panel-section` found within the panel's DOM and sets their `collapsed` property to `false`,
   * effectively showing their content. This can be used to programmatically reveal the content of sections within the panel,
   * making the panel more informative or to display details that are necessary for the user.
   */
  expandSections() {
    const t = this.querySelectorAll("bim-panel-section");
    for (const e of t) e.collapsed = !1;
  }
  render() {
    return this.activationButton.icon = this.icon, this.activationButton.label = this.label || this.name, this.activationButton.tooltipTitle = this.label || this.name, f`
      <div class="parent">
        ${this.label || this.name || this.icon ? f`<bim-label .icon=${this.icon}>${this.label}</bim-label>` : null}
        <div class="sections">
          <slot></slot>
        </div>
      </div>
    `;
  }
};
Xs.styles = [Ot.scrollbar, O`
      :host {
        display: flex;
        border-radius: var(--bim-ui_size-base);
        background-color: var(--bim-ui_bg-base);
        overflow: auto;
      }

      :host([hidden]) {
        display: none;
      }

      .parent {
        display: flex;
        flex: 1;
        flex-direction: column;
        pointer-events: auto;
        overflow: auto;
      }

      .parent bim-label {
        --bim-label--c: var(--bim-panel--c, var(--bim-ui_bg-contrast-80));
        --bim-label--fz: var(--bim-panel--fz, var(--bim-ui_size-sm));
        font-weight: 600;
        padding: 1rem;
        flex-shrink: 0;
        border-bottom: 1px solid var(--bim-ui_bg-contrast-20);
      }

      :host([header-hidden]) .parent bim-label {
        display: none;
      }

      .sections {
        display: flex;
        flex-direction: column;
        overflow: auto;
      }

      ::slotted(bim-panel-section:not(:last-child)) {
        border-bottom: 1px solid var(--bim-ui_bg-contrast-20);
      }
    `];
let Yt = Xs;
Ke([g({
  type: String,
  reflect: !0
})], Yt.prototype, "icon", 2);
Ke([g({
  type: String,
  reflect: !0
})], Yt.prototype, "name", 2);
Ke([g({
  type: String,
  reflect: !0
})], Yt.prototype, "label", 2);
Ke([g({
  type: Boolean,
  reflect: !0
})], Yt.prototype, "hidden", 1);
Ke([g({
  type: Boolean,
  attribute: "header-hidden",
  reflect: !0
})], Yt.prototype, "headerHidden", 2);
var mc = Object.defineProperty,
  Ze = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && mc(t, e, n), n;
  };
const Js = class extends I {
  constructor() {
    super(...arguments), this.onValueChange = new Event("change"), this.valueTransform = {};
  }
  /**
   * The `value` getter computes and returns the current state of the panel section's form elements as an object. This object's keys are the `name` or `label` attributes of the child elements, and the values are the corresponding values of these elements. This property is particularly useful for retrieving a consolidated view of the user's input or selections within the panel section. When the value of any child element changes, the returned object from this getter will reflect those changes, providing a dynamic snapshot of the panel section's state. Note that this property does not have a default value as it dynamically reflects the current state of the panel section's form elements.
   * @example <bim-panel-section></bim-panel-section> <!-- Usage in HTML not directly applicable as this is a getter -->
   * @example
   * const section = document.createElement('bim-panel-section');
   * console.log(section.value); // Logs the current value object
   */
  get value() {
    const t = this.parentElement;
    let e;
    return t instanceof Yt && (e = t.valueTransform), Object.values(this.valueTransform).length !== 0 && (e = this.valueTransform), Fe(this, e);
  }
  /**
   * The `value` setter allows programmatically updating the values of the panel section's child elements. It accepts an object where keys correspond to the `name` or `label` attributes of the child elements, and the values are the new values to be set for these elements. This property is useful for initializing the panel section with specific values or updating its state based on external data. When the property changes, the corresponding child elements' values are updated to reflect the new state. This does not have a default value as it is a method for updating child elements' values.
   * @type {Record<string, any>}
   * @default undefined
   * @example <bim-panel-section></bim-panel-section> <!-- Usage in HTML not directly applicable as this is a setter -->
   * @example
   * const section = document.createElement('bim-panel-section');
   * section.value = { 'user-settings': 'John Doe' }; // Programmatically sets the value of a child element named 'user-settings'
   */
  set value(t) {
    const e = [...this.children];
    for (const o in t) {
      const n = e.find(r => {
        const a = r;
        return a.name === o || a.label === o;
      });
      if (!n) continue;
      const s = n;
      s.value = t[o];
    }
  }
  onHeaderClick() {
    this.fixed || (this.collapsed = !this.collapsed);
  }
  render() {
    const t = this.label || this.icon || this.name || this.fixed,
      e = f`<svg
      xmlns="http://www.w3.org/2000/svg"
      height="1.125rem"
      viewBox="0 0 24 24"
      width="1.125rem"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
    </svg>`,
      o = f`<svg
      xmlns="http://www.w3.org/2000/svg"
      height="1.125rem"
      viewBox="0 0 24 24"
      width="1.125rem"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    </svg>`,
      n = this.collapsed ? e : o,
      s = f`
      <div
        class="header"
        title=${this.label ?? ""}
        @click=${this.onHeaderClick}
      >
        ${this.label || this.icon || this.name ? f`<bim-label .icon=${this.icon}>${this.label}</bim-label>` : null}
        ${this.fixed ? null : n}
      </div>
    `;
    return f`
      <div class="parent">
        ${t ? s : null}
        <div class="components">
          <slot></slot>
        </div>
      </div>
    `;
  }
};
Js.styles = [Ot.scrollbar, O`
      :host {
        display: block;
        pointer-events: auto;
      }

      :host(:not([fixed])) .header:hover {
        --bim-label--c: var(--bim-ui_accent-base);
        color: var(--bim-ui_accent-base);
        cursor: pointer;
      }

      :host(:not([fixed])) .header:hover svg {
        fill: var(--bim-ui_accent-base);
      }

      .header {
        --bim-label--fz: var(--bim-ui_size-sm);
        --bim-label--c: var(--bim-ui_bg-contrast-80);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        height: 1.5rem;
        padding: 0.75rem 1rem;
      }

      .header svg {
        fill: var(--bim-ui_bg-contrast-80);
      }

      .title {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
      }

      .title p {
        font-size: var(--bim-ui_size-sm);
      }

      .components {
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;
        padding: 0.125rem 1rem 1rem;
      }

      :host(:not([fixed])[collapsed]) .components {
        display: none;
        height: 0px;
      }

      bim-label {
        pointer-events: none;
      }
    `];
let be = Js;
Ze([g({
  type: String,
  reflect: !0
})], be.prototype, "icon");
Ze([g({
  type: String,
  reflect: !0
})], be.prototype, "label");
Ze([g({
  type: String,
  reflect: !0
})], be.prototype, "name");
Ze([g({
  type: Boolean,
  reflect: !0
})], be.prototype, "fixed");
Ze([g({
  type: Boolean,
  reflect: !0
})], be.prototype, "collapsed");
var fc = Object.defineProperty,
  ti = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && fc(t, e, n), n;
  };
const Qs = class extends I {
  constructor() {
    super(...arguments), this.vertical = !1, this.onValueChange = new Event("change"), this._canEmitEvents = !1, this._value = document.createElement("bim-option"), this.onOptionClick = t => {
      this._value = t.target, this.dispatchEvent(this.onValueChange);
      for (const e of this.children) e instanceof q && (e.checked = e === t.target);
    };
  }
  get _options() {
    return [...this.querySelectorAll("bim-option")];
  }
  /**
   * Sets the value of the selector.
   * It finds the matching option based on the provided value and sets it as the selected option.
   * If no matching option is found, it does nothing.
   *
   * @param value - The value to set for the selector.
   */
  set value(t) {
    const e = this.findOption(t);
    if (e) {
      for (const o of this._options) o.checked = o === e;
      this._value = e, this._canEmitEvents && this.dispatchEvent(this.onValueChange);
    }
  }
  get value() {
    return this._value.value;
  }
  onSlotChange(t) {
    const e = t.target.assignedElements();
    for (const o of e) o instanceof q && (o.noMark = !0, o.removeEventListener("click", this.onOptionClick), o.addEventListener("click", this.onOptionClick));
  }
  findOption(t) {
    return this._options.find(e => e instanceof q ? e.label === t || e.value === t : !1);
  }
  firstUpdated() {
    const t = [...this.children].find(e => e instanceof q && e.checked);
    t && (this._value = t);
  }
  render() {
    return f`
      <bim-input
        .vertical=${this.vertical}
        .label=${this.label}
        .icon=${this.icon}
      >
        <slot @slotchange=${this.onSlotChange}></slot>
      </bim-input>
    `;
  }
};
Qs.styles = O`
    :host {
      --bim-input--bgc: var(--bim-ui_bg-contrast-20);
      --bim-input--g: 0;
      --bim-option--jc: center;
      flex: 1;
      display: block;
    }

    ::slotted(bim-option) {
      border-radius: 0;
    }

    ::slotted(bim-option[checked]) {
      --bim-label--c: var(--bim-ui_main-contrast);
      background-color: var(--bim-ui_main-base);
    }
  `;
let ge = Qs;
ti([g({
  type: String,
  reflect: !0
})], ge.prototype, "name");
ti([g({
  type: String,
  reflect: !0
})], ge.prototype, "icon");
ti([g({
  type: String,
  reflect: !0
})], ge.prototype, "label");
ti([g({
  type: Boolean,
  reflect: !0
})], ge.prototype, "vertical");
ti([he()], ge.prototype, "_value");
const bc = () => f`
    <style>
      div {
        display: flex;
        gap: 0.375rem;
        border-radius: 0.25rem;
        min-height: 1.25rem;
      }

      [data-type="row"] {
        background-color: var(--bim-ui_bg-contrast-10);
        animation: row-loading 1s linear infinite alternate;
        padding: 0.5rem;
      }

      [data-type="cell"] {
        background-color: var(--bim-ui_bg-contrast-20);
        flex: 0.25;
      }

      @keyframes row-loading {
        0% {
          background-color: var(--bim-ui_bg-contrast-10);
        }
        100% {
          background-color: var(--bim-ui_bg-contrast-20);
        }
      }
    </style>
    <div style="display: flex; flex-direction: column;">
      <div data-type="row" style="gap: 2rem">
        <div data-type="cell" style="flex: 1"></div>
        <div data-type="cell" style="flex: 2"></div>
        <div data-type="cell" style="flex: 1"></div>
        <div data-type="cell" style="flex: 0.5"></div>
      </div>
      <div style="display: flex;">
        <div data-type="row" style="flex: 1">
          <div data-type="cell" style="flex: 0.5"></div>
        </div>
        <div data-type="row" style="flex: 2">
          <div data-type="cell" style="flex: 0.75"></div>
        </div>
        <div data-type="row" style="flex: 1">
          <div data-type="cell"></div>
        </div>
        <div data-type="row" style="flex: 0.5">
          <div data-type="cell" style="flex: 0.75"></div>
        </div>
      </div>
      <div style="display: flex;">
        <div data-type="row" style="flex: 1">
          <div data-type="cell" style="flex: 0.75"></div>
        </div>
        <div data-type="row" style="flex: 2">
          <div data-type="cell"></div>
        </div>
        <div data-type="row" style="flex: 1">
          <div data-type="cell" style="flex: 0.5"></div>
        </div>
        <div data-type="row" style="flex: 0.5">
          <div data-type="cell" style="flex: 0.5"></div>
        </div>
      </div>
      <div style="display: flex;">
        <div data-type="row" style="flex: 1">
          <div data-type="cell"></div>
        </div>
        <div data-type="row" style="flex: 2">
          <div data-type="cell" style="flex: 0.5"></div>
        </div>
        <div data-type="row" style="flex: 1">
          <div data-type="cell" style="flex: 0.75"></div>
        </div>
        <div data-type="row" style="flex: 0.5">
          <div data-type="cell" style="flex: 0.7s5"></div>
        </div>
      </div>
    </div>
  `,
  gc = () => f`
    <style>
      .loader {
        grid-area: Processing;
        position: relative;
        padding: 0.125rem;
      }
      .loader:before {
        content: "";
        position: absolute;
      }
      .loader .loaderBar {
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        left: 0;
        background: var(--bim-ui_main-base);
        /* width: 25%; */
        width: 0;
        animation: borealisBar 2s linear infinite;
      }

      @keyframes borealisBar {
        0% {
          left: 0%;
          right: 100%;
          width: 0%;
        }
        10% {
          left: 0%;
          right: 75%;
          width: 25%;
        }
        90% {
          right: 0%;
          left: 75%;
          width: 25%;
        }
        100% {
          left: 100%;
          right: 0%;
          width: 0%;
        }
      }
    </style>
    <div class="loader">
      <div class="loaderBar"></div>
    </div>
  `;
var yc = Object.defineProperty,
  vc = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && yc(t, e, n), n;
  };
const Ks = class extends I {
  constructor() {
    super(...arguments), this.column = "", this.columnIndex = 0, this.rowData = {};
  }
  get data() {
    return this.column ? this.rowData[this.column] : null;
  }
  render() {
    return f`
      <style>
        :host {
          grid-area: ${this.column ?? "unset"};
        }
      </style>
      <slot></slot>
    `;
  }
};
Ks.styles = O`
    :host {
      padding: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :host([data-column-index="0"]) {
      justify-content: normal;
    }

    :host([data-column-index="0"]:not([data-cell-header]))
      ::slotted(bim-label) {
      text-align: left;
    }

    ::slotted(*) {
      --bim-input--bgc: transparent;
      --bim-input--olc: var(--bim-ui_bg-contrast-20);
      --bim-input--olw: 1px;
    }

    ::slotted(bim-input) {
      --bim-input--olw: 0;
    }

    ::slotted(bim-label) {
      white-space: normal;
      text-align: center;
    }
  `;
let Zs = Ks;
vc([g({
  type: String,
  reflect: !0
})], Zs.prototype, "column");
var _c = Object.defineProperty,
  wc = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && _c(t, e, n), n;
  };
const tr = class extends I {
  constructor() {
    super(...arguments), this._groups = [], this.data = [], this.table = this.closest("bim-table");
  }
  toggleGroups(t, e = !1) {
    for (const o of this._groups) o.childrenHidden = typeof t > "u" ? !o.childrenHidden : !t, e && o.toggleChildren(t, e);
  }
  render() {
    return this._groups = [], f`
      <slot></slot>
      ${this.data.map(t => {
      const e = document.createElement("bim-table-group");
      return this._groups.push(e), e.table = this.table, e.data = t, e;
    })}
    `;
  }
};
tr.styles = O`
    :host {
      --bim-button--bgc: transparent;
      position: relative;
      grid-area: Children;
    }

    :host([hidden]) {
      display: none;
    }

    ::slotted(.branch.branch-vertical) {
      top: 0;
      bottom: 1.125rem;
    }
  `;
let er = tr;
wc([g({
  type: Array,
  attribute: !1
})], er.prototype, "data");
var $c = Object.defineProperty,
  xc = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && $c(t, e, n), n;
  };
const ir = class extends I {
  constructor() {
    super(...arguments), this.data = {
      data: {}
    }, this.childrenHidden = !0, this.table = this.closest("bim-table");
  }
  connectedCallback() {
    super.connectedCallback(), this.table && this.table.expanded ? this.childrenHidden = !1 : this.childrenHidden = !0;
  }
  toggleChildren(t, e = !1) {
    this._children && (this.childrenHidden = typeof t > "u" ? !this.childrenHidden : !t, e && this._children.toggleGroups(t, e));
  }
  render() {
    if (!this.table) throw new Error("TableGroup: parent table wasn't found!");
    const t = this.table.getGroupIndentation(this.data) ?? 0,
      e = f`
      ${this.table.noIndentation ? null : f`
            <style>
              .branch-vertical {
                left: ${t + (this.table.selectableRows ? 1.9375 : 0.5625)}rem;
              }
            </style>
            <div class="branch branch-vertical"></div>
          `}
    `,
      o = document.createDocumentFragment();
    ae(e, o);
    let n = null;
    this.table.noIndentation || (n = document.createElement("div"), n.classList.add("branch", "branch-horizontal"), n.style.left = `${t - 1 + (this.table.selectableRows ? 2.05 : 0.5625)}rem`);
    let s = null;
    if (!this.table.noIndentation) {
      const l = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      l.setAttribute("height", "9.5"), l.setAttribute("width", "7.5"), l.setAttribute("viewBox", "0 0 4.6666672 7.3333333");
      const c = document.createElementNS("http://www.w3.org/2000/svg", "path");
      c.setAttribute("d", "m 1.7470835,6.9583848 2.5899999,-2.59 c 0.39,-0.39 0.39,-1.02 0,-1.41 L 1.7470835,0.36838483 c -0.63,-0.62000003 -1.71000005,-0.18 -1.71000005,0.70999997 v 5.17 c 0,0.9 1.08000005,1.34 1.71000005,0.71 z"), l.append(c);
      const h = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      h.setAttribute("height", "6.5"), h.setAttribute("width", "9.5"), h.setAttribute("viewBox", "0 0 5.9111118 5.0175439");
      const u = document.createElementNS("http://www.w3.org/2000/svg", "path");
      u.setAttribute("d", "M -0.33616196,1.922522 2.253838,4.5125219 c 0.39,0.39 1.02,0.39 1.41,0 L 6.2538379,1.922522 c 0.6200001,-0.63 0.18,-1.71000007 -0.7099999,-1.71000007 H 0.37383804 c -0.89999997,0 -1.33999997,1.08000007 -0.71,1.71000007 z"), h.append(u), s = document.createElement("div"), s.addEventListener("click", p => {
        p.stopPropagation(), this.toggleChildren();
      }), s.classList.add("caret"), s.style.left = `${(this.table.selectableRows ? 1.5 : 0.125) + t}rem`, this.childrenHidden ? s.append(l) : s.append(h);
    }
    const r = document.createElement("bim-table-row");
    this.data.children && !this.childrenHidden && r.append(o), r.table = this.table, r.data = this.data.data, this.table.dispatchEvent(new CustomEvent("rowcreated", {
      detail: {
        row: r
      }
    })), s && this.data.children && r.append(s), t !== 0 && (!this.data.children || this.childrenHidden) && n && r.append(n);
    let a;
    if (this.data.children) {
      a = document.createElement("bim-table-children"), this._children = a, a.table = this.table, a.data = this.data.children;
      const l = document.createDocumentFragment();
      ae(e, l), a.append(l);
    }
    return f`
      <div class="parent">${r} ${this.childrenHidden ? null : a}</div>
    `;
  }
};
ir.styles = O`
    :host {
      position: relative;
    }

    .parent {
      display: grid;
      grid-template-areas: "Data" "Children";
    }

    .branch {
      position: absolute;
      z-index: 1;
    }

    .branch-vertical {
      border-left: 1px dotted var(--bim-ui_bg-contrast-40);
    }

    .branch-horizontal {
      top: 50%;
      width: 1rem;
      border-bottom: 1px dotted var(--bim-ui_bg-contrast-40);
    }

    .caret {
      position: absolute;
      z-index: 2;
      transform: translateY(-50%) rotate(0deg);
      top: 50%;
      display: flex;
      width: 0.95rem;
      height: 0.95rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .caret svg {
      fill: var(--bim-ui_bg-contrast-60);
    }
  `;
let nr = ir;
xc([g({
  type: Boolean,
  attribute: "children-hidden",
  reflect: !0
})], nr.prototype, "childrenHidden");
var Cc = Object.defineProperty,
  ye = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && Cc(t, e, n), n;
  };
const or = class extends I {
  constructor() {
    super(...arguments), this.selected = !1, this.columns = [], this.hiddenColumns = [], this.data = {}, this.isHeader = !1, this.table = this.closest("bim-table"), this.onTableColumnsChange = () => {
      this.table && (this.columns = this.table.columns);
    }, this.onTableColumnsHidden = () => {
      this.table && (this.hiddenColumns = this.table.hiddenColumns);
    }, this._observer = new IntersectionObserver(t => {
      this._intersecting = t[0].isIntersecting;
    }, {
      rootMargin: "36px"
    });
  }
  get _columnNames() {
    return this.columns.filter(t => !this.hiddenColumns.includes(t.name)).map(t => t.name);
  }
  get _columnWidths() {
    return this.columns.filter(t => !this.hiddenColumns.includes(t.name)).map(t => t.width);
  }
  get _isSelected() {
    var t;
    return (t = this.table) == null ? void 0 : t.selection.has(this.data);
  }
  onSelectionChange(t) {
    if (!this.table) return;
    const e = t.target;
    this.selected = e.value, e.value ? (this.table.selection.add(this.data), this.table.dispatchEvent(new CustomEvent("rowselected", {
      detail: {
        data: this.data
      }
    }))) : (this.table.selection.delete(this.data), this.table.dispatchEvent(new CustomEvent("rowdeselected", {
      detail: {
        data: this.data
      }
    })));
  }
  connectedCallback() {
    super.connectedCallback(), this._observer.observe(this), this.table && (this.columns = this.table.columns, this.hiddenColumns = this.table.hiddenColumns, this.table.addEventListener("columnschange", this.onTableColumnsChange), this.table.addEventListener("columnshidden", this.onTableColumnsHidden), this.toggleAttribute("selected", this._isSelected));
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this._observer.unobserve(this), this.table && (this.columns = [], this.hiddenColumns = [], this.table.removeEventListener("columnschange", this.onTableColumnsChange), this.table.removeEventListener("columnshidden", this.onTableColumnsHidden), this.toggleAttribute("selected", !1));
  }
  compute() {
    if (!this.table) throw new Error("TableRow: parent table wasn't found!");
    const t = this.table.getRowIndentation(this.data) ?? 0,
      e = this.isHeader ? this.data : this.table.applyDataTransform(this.data) ?? this.data,
      o = [];
    for (const n in e) {
      if (this.hiddenColumns.includes(n)) continue;
      const s = e[n];
      let r;
      if (typeof s == "string" || typeof s == "boolean" || typeof s == "number" ? (r = document.createElement("bim-label"), r.textContent = String(s)) : s instanceof HTMLElement ? r = s : (r = document.createDocumentFragment(), ae(s, r)), !r) continue;
      const a = document.createElement("bim-table-cell");
      a.append(r), a.column = n, this._columnNames.indexOf(n) === 0 && (a.style.marginLeft = `${this.table.noIndentation ? 0 : t + 0.75}rem`);
      const l = this._columnNames.indexOf(n);
      a.setAttribute("data-column-index", String(l)), a.toggleAttribute("data-no-indentation", l === 0 && this.table.noIndentation), a.toggleAttribute("data-cell-header", this.isHeader), a.rowData = this.data, this.table.dispatchEvent(new CustomEvent("cellcreated", {
        detail: {
          cell: a
        }
      })), o.push(a);
    }
    return this.style.gridTemplateAreas = `"${this.table.selectableRows ? "Selection" : ""} ${this._columnNames.join(" ")}"`, this.style.gridTemplateColumns = `${this.table.selectableRows ? "1.6rem" : ""} ${this._columnWidths.join(" ")}`, f`
      ${!this.isHeader && this.table.selectableRows ? f`<bim-checkbox
            @change=${this.onSelectionChange}
            .checked=${this._isSelected}
            style="align-self: center; justify-self: center"
          ></bim-checkbox>` : null}
      ${o}
      <slot></slot>
    `;
  }
  render() {
    return f`${this._intersecting ? this.compute() : f``}`;
  }
};
or.styles = O`
    :host {
      position: relative;
      grid-area: Data;
      display: grid;
      min-height: 2.25rem;
      transition: all 0.15s;
    }

    ::slotted(.branch.branch-vertical) {
      top: 50%;
      bottom: 0;
    }

    :host([selected]) {
      background-color: color-mix(
        in lab,
        var(--bim-ui_bg-contrast-20) 30%,
        var(--bim-ui_main-base) 10%
      );
    }
  `;
let Xt = or;
ye([g({
  type: Boolean,
  reflect: !0
})], Xt.prototype, "selected");
ye([g({
  attribute: !1
})], Xt.prototype, "columns");
ye([g({
  attribute: !1
})], Xt.prototype, "hiddenColumns");
ye([g({
  attribute: !1
})], Xt.prototype, "data");
ye([g({
  type: Boolean,
  attribute: "is-header",
  reflect: !0
})], Xt.prototype, "isHeader");
ye([he()], Xt.prototype, "_intersecting");
var Ac = Object.defineProperty,
  Ec = Object.getOwnPropertyDescriptor,
  ht = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? Ec(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && Ac(t, e, n), n;
  };
const sr = class extends I {
  constructor() {
    super(...arguments), this._filteredData = [], this.headersHidden = !1, this.minColWidth = "4rem", this._columns = [], this._textDelimiters = {
      comma: ",",
      tab: "	"
    }, this._queryString = null, this._data = [], this.expanded = !1, this.preserveStructureOnFilter = !1, this.indentationInText = !1, this.dataTransform = {}, this.selectableRows = !1, this.selection = /* @__PURE__ */new Set(), this.noIndentation = !1, this.loading = !1, this._errorLoading = !1, this._onColumnsHidden = new Event("columnshidden"), this._hiddenColumns = [], this._stringFilterFunction = (t, e) => Object.values(e.data).some(o => String(o).toLowerCase().includes(t.toLowerCase())), this._queryFilterFunction = (t, e) => {
      let o = !1;
      const n = hn(t) ?? [];
      for (const s of n) {
        if ("queries" in s) {
          o = !1;
          break;
        }
        const {
          condition: r,
          value: a
        } = s;
        let {
          key: l
        } = s;
        if (l.startsWith("[") && l.endsWith("]")) {
          const c = l.replace("[", "").replace("]", "");
          l = c, o = Object.keys(e.data).filter(h => h.includes(c)).map(h => So(e.data[h], r, a)).some(h => h);
        } else o = So(e.data[l], r, a);
        if (!o) break;
      }
      return o;
    };
  }
  set columns(t) {
    const e = [];
    for (const o of t) {
      const n = typeof o == "string" ? {
        name: o,
        width: `minmax(${this.minColWidth}, 1fr)`
      } : o;
      e.push(n);
    }
    this._columns = e, this.computeMissingColumns(this.data), this.dispatchEvent(new Event("columnschange"));
  }
  get columns() {
    return this._columns;
  }
  get _headerRowData() {
    const t = {};
    for (const e of this.columns) {
      const {
        name: o
      } = e;
      t[o] = String(o);
    }
    return t;
  }
  /**
   * Getter for the `value` property.
   * Returns the filtered data if a search string is provided, otherwise returns the original data.
   *
   * @example
   * ```typescript
   * const tableValue = table.value;
   * console.log(tableValue); // Output: The filtered or original data.
   * ```
   */
  get value() {
    return this._filteredData;
  }
  /**
   * Sets the search string for filtering the table data.
   * This property allows you to filter the table data based on a search string.
   * If a search string is provided, the table will only display rows that match the search criteria.
   * The search criteria can be a simple string or a complex query.
   * If a simple string is provided, the table will filter rows based on the string's presence in any column.
   * If a complex query is provided, the table will filter rows based on the query's conditions and values.
   *
   * @example Simple Query
   * ```typescript
   * table.queryString = "example";
   * ```
   *
   * @example Complex Query
   * ```typescript
   * table.queryString = "column1="Jhon Doe" & column2=20";
   * ```
   */
  set queryString(t) {
    this.toggleAttribute("data-processing", !0), this._queryString = t && t.trim() !== "" ? t.trim() : null, this.updateFilteredData(), this.toggleAttribute("data-processing", !1);
  }
  get queryString() {
    return this._queryString;
  }
  set data(t) {
    this._data = t, this.updateFilteredData(), this.computeMissingColumns(t) && (this.columns = this._columns);
  }
  get data() {
    return this._data;
  }
  get dataAsync() {
    return new Promise(t => {
      setTimeout(() => {
        t(this.data);
      });
    });
  }
  set hiddenColumns(t) {
    this._hiddenColumns = t, setTimeout(() => {
      this.dispatchEvent(this._onColumnsHidden);
    });
  }
  get hiddenColumns() {
    return this._hiddenColumns;
  }
  updateFilteredData() {
    this.queryString ? (hn(this.queryString) ? (this.filterFunction = this._queryFilterFunction, this._filteredData = this.filter(this.queryString)) : (this.filterFunction = this._stringFilterFunction, this._filteredData = this.filter(this.queryString)), this.preserveStructureOnFilter && (this._expandedBeforeFilter === void 0 && (this._expandedBeforeFilter = this.expanded), this.expanded = !0)) : (this.preserveStructureOnFilter && this._expandedBeforeFilter !== void 0 && (this.expanded = this._expandedBeforeFilter, this._expandedBeforeFilter = void 0), this._filteredData = this.data);
  }
  computeMissingColumns(t) {
    let e = !1;
    for (const o of t) {
      const {
        children: n,
        data: s
      } = o;
      for (const r in s) this._columns.map(a => typeof a == "string" ? a : a.name).includes(r) || (this._columns.push({
        name: r,
        width: `minmax(${this.minColWidth}, 1fr)`
      }), e = !0);
      if (n) {
        const r = this.computeMissingColumns(n);
        r && !e && (e = r);
      }
    }
    return e;
  }
  generateText(t = "comma", e = this.value, o = "", n = !0) {
    const s = this._textDelimiters[t];
    let r = "";
    const a = this.columns.map(l => l.name);
    if (n) {
      this.indentationInText && (r += `Indentation${s}`);
      const l = `${a.join(s)}
`;
      r += l;
    }
    for (const [l, c] of e.entries()) {
      const {
          data: h,
          children: u
        } = c,
        p = this.indentationInText ? `${o}${l + 1}${s}` : "",
        d = a.map(y => h[y] ?? ""),
        m = `${p}${d.join(s)}
`;
      r += m, u && (r += this.generateText(t, c.children, `${o}${l + 1}.`, !1));
    }
    return r;
  }
  /**
   * A getter function that generates a CSV (Comma Separated Values) representation of the table data.
   *
   * @returns A string containing the CSV representation of the table data.
   *
   * @example
   * ```typescript
   * const csvData = table.csv;
   * console.log(csvData); // Output: "Column 1,Column 2\nValue 1,Value 2\nValue 3,Value 4"
   * ```
   */
  get csv() {
    return this.generateText("comma");
  }
  /**
   * A getter function that generates a Tab Separated Values (TSV) representation of the table data.
   *
   * @returns A string containing the TSV representation of the table data.
   *
   * @example
   * ```typescript
   * const tsvData = table.tsv;
   * console.log(tsvData); // Output: "Column 1\tColumn 2\nValue 1\tValue 2\nValue 3\tValue 4"
   * ```
   */
  get tsv() {
    return this.generateText("tab");
  }
  applyDataTransform(t) {
    const e = {};
    for (const n of Object.keys(this.dataTransform)) {
      const s = this.columns.find(r => r.name === n);
      s && s.forceDataTransform && (n in t || (t[n] = ""));
    }
    const o = t;
    for (const n in o) {
      const s = this.dataTransform[n];
      s ? e[n] = s(o[n], t) : e[n] = t[n];
    }
    return e;
  }
  /**
   * The `downloadData` method is used to download the table data in different formats.
   *
   * @param fileName - The name of the downloaded file. Default is "BIM Table Data".
   * @param format - The format of the downloaded file. Can be "json", "tsv", or "csv". Default is "json".
   *
   * @returns - This method does not return any value.
   *
   * @example
   * ```typescript
   * table.downloadData("MyTableData", "tsv");
   * ```
   */
  downloadData(t = "BIM Table Data", e = "json") {
    let o = null;
    if (e === "json" && (o = new File([JSON.stringify(this.value, void 0, 2)], `${t}.json`)), e === "csv" && (o = new File([this.csv], `${t}.csv`)), e === "tsv" && (o = new File([this.tsv], `${t}.tsv`)), !o) return;
    const n = document.createElement("a");
    n.href = URL.createObjectURL(o), n.download = o.name, n.click(), URL.revokeObjectURL(n.href);
  }
  getRowIndentation(t, e = this.value, o = 0) {
    for (const n of e) {
      if (n.data === t) return o;
      if (n.children) {
        const s = this.getRowIndentation(t, n.children, o + 1);
        if (s !== null) return s;
      }
    }
    return null;
  }
  getGroupIndentation(t, e = this.value, o = 0) {
    for (const n of e) {
      if (n === t) return o;
      if (n.children) {
        const s = this.getGroupIndentation(t, n.children, o + 1);
        if (s !== null) return s;
      }
    }
    return null;
  }
  connectedCallback() {
    super.connectedCallback(), this.dispatchEvent(new Event("connected"));
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.dispatchEvent(new Event("disconnected"));
  }
  /**
   * Asynchronously loads data into the table based on Table.loadFunction.
   * If the data is already available, just set it in Table.data.
   *
   * @param force - A boolean indicating whether to force loading even if the table already has data.
   *
   * @returns - A promise that resolves to a boolean indicating whether the data loading was successful.
   * If the promise resolves to `true`, the data loading was successful.
   * If the promise resolves to `false`, the data loading was not successful.
   *
   * @remarks - If the table already has data and `force` is `false`, the function resolves to `false` without making any changes.
   * If the table already has data and `force` is `true`, the existing data is discarded before loading the new data.
   * If an error occurs during data loading, the function sets the `errorLoadingMessage` property with the error message and resolves to `false`.
   */
  async loadData(t = !1) {
    if (this._filteredData.length !== 0 && !t || !this.loadFunction) return !1;
    this.loading = !0;
    try {
      const e = await this.loadFunction();
      return this.data = e, this.loading = !1, this._errorLoading = !1, !0;
    } catch (e) {
      if (this.loading = !1, this._filteredData.length !== 0) return !1;
      const o = this.querySelector("[slot='error-loading']"),
        n = o == null ? void 0 : o.querySelector("[data-table-element='error-message']");
      return e instanceof Error && n && e.message.trim() !== "" && (n.textContent = e.message), this._errorLoading = !0, !1;
    }
  }
  filter(t, e = this.filterFunction ?? this._stringFilterFunction, o = this.data) {
    const n = [];
    for (const s of o) if (e(t, s)) {
      if (this.preserveStructureOnFilter) {
        const r = {
          data: s.data
        };
        if (s.children) {
          const a = this.filter(t, e, s.children);
          a.length && (r.children = a);
        }
        n.push(r);
      } else if (n.push({
        data: s.data
      }), s.children) {
        const r = this.filter(t, e, s.children);
        n.push(...r);
      }
    } else if (s.children) {
      const r = this.filter(t, e, s.children);
      this.preserveStructureOnFilter && r.length ? n.push({
        data: s.data,
        children: r
      }) : n.push(...r);
    }
    return n;
  }
  get _missingDataElement() {
    return this.querySelector("[slot='missing-data']");
  }
  render() {
    if (this.loading) return bc();
    if (this._errorLoading) return f`<slot name="error-loading"></slot>`;
    if (this._filteredData.length === 0 && this._missingDataElement) return f`<slot name="missing-data"></slot>`;
    const t = document.createElement("bim-table-row");
    t.table = this, t.isHeader = !0, t.data = this._headerRowData, t.style.gridArea = "Header", t.style.position = "sticky", t.style.top = "0", t.style.zIndex = "5";
    const e = document.createElement("bim-table-children");
    return e.table = this, e.data = this.value, e.style.gridArea = "Body", e.style.backgroundColor = "transparent", f`
      <div class="parent">
        ${this.headersHidden ? null : t} ${gc()}
        <div style="overflow-x: hidden; grid-area: Body">${e}</div>
      </div>
    `;
  }
};
sr.styles = [Ot.scrollbar, O`
      :host {
        position: relative;
        overflow: auto;
        display: block;
        pointer-events: auto;
      }

      :host(:not([data-processing])) .loader {
        display: none;
      }

      .parent {
        display: grid;
        grid-template:
          "Header" auto
          "Processing" auto
          "Body" 1fr
          "Footer" auto;
        overflow: auto;
        height: 100%;
      }

      .parent > bim-table-row[is-header] {
        color: var(--bim-table_header--c, var(--bim-ui_bg-contrast-100));
        background-color: var(
          --bim-table_header--bgc,
          var(--bim-ui_bg-contrast-20)
        );
      }

      .controls {
        display: flex;
        gap: 0.375rem;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;
      }
    `];
let lt = sr;
ht([he()], lt.prototype, "_filteredData", 2);
ht([g({
  type: Boolean,
  attribute: "headers-hidden",
  reflect: !0
})], lt.prototype, "headersHidden", 2);
ht([g({
  type: String,
  attribute: "min-col-width",
  reflect: !0
})], lt.prototype, "minColWidth", 2);
ht([g({
  type: Array,
  attribute: !1
})], lt.prototype, "columns", 1);
ht([g({
  type: Array,
  attribute: !1
})], lt.prototype, "data", 1);
ht([g({
  type: Boolean,
  reflect: !0
})], lt.prototype, "expanded", 2);
ht([g({
  type: Boolean,
  reflect: !0,
  attribute: "selectable-rows"
})], lt.prototype, "selectableRows", 2);
ht([g({
  attribute: !1
})], lt.prototype, "selection", 2);
ht([g({
  type: Boolean,
  attribute: "no-indentation",
  reflect: !0
})], lt.prototype, "noIndentation", 2);
ht([g({
  type: Boolean,
  reflect: !0
})], lt.prototype, "loading", 2);
ht([he()], lt.prototype, "_errorLoading", 2);
var Sc = Object.defineProperty,
  kc = Object.getOwnPropertyDescriptor,
  Ri = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? kc(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && Sc(t, e, n), n;
  };
const rr = class extends I {
  constructor() {
    super(...arguments), this._defaultName = "__unnamed__", this.name = this._defaultName, this._hidden = !1;
  }
  set hidden(t) {
    this._hidden = t, this.dispatchEvent(new Event("hiddenchange"));
  }
  get hidden() {
    return this._hidden;
  }
  connectedCallback() {
    super.connectedCallback();
    const {
      parentElement: t
    } = this;
    if (t && this.name === this._defaultName) {
      const e = [...t.children].indexOf(this);
      this.name = `${this._defaultName}${e}`;
    }
  }
  render() {
    return f` <slot></slot> `;
  }
};
rr.styles = O`
    :host {
      display: block;
      height: 100%;
    }

    :host([hidden]) {
      display: none;
    }
  `;
let ot = rr;
Ri([g({
  type: String,
  reflect: !0
})], ot.prototype, "name", 2);
Ri([g({
  type: String,
  reflect: !0
})], ot.prototype, "label", 2);
Ri([g({
  type: String,
  reflect: !0
})], ot.prototype, "icon", 2);
Ri([g({
  type: Boolean,
  reflect: !0
})], ot.prototype, "hidden", 1);
var Tc = Object.defineProperty,
  Ic = Object.getOwnPropertyDescriptor,
  ve = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? Ic(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && Tc(t, e, n), n;
  };
const ar = class extends I {
  constructor() {
    super(...arguments), this._switchers = [], this.bottom = !1, this.switchersHidden = !1, this.floating = !1, this.switchersFull = !1, this.onTabHiddenChange = t => {
      const e = t.target;
      e instanceof ot && !e.hidden && (e.removeEventListener("hiddenchange", this.onTabHiddenChange), this.tab = e.name, e.addEventListener("hiddenchange", this.onTabHiddenChange));
    };
  }
  set tab(t) {
    this._tab = t;
    const e = [...this.children],
      o = e.find(n => n instanceof ot && n.name === t);
    for (const n of e) {
      if (!(n instanceof ot)) continue;
      n.hidden = o !== n;
      const s = this.getTabSwitcher(n.name);
      s && s.toggleAttribute("data-active", !n.hidden);
    }
  }
  get tab() {
    return this._tab;
  }
  getTabSwitcher(t) {
    return this._switchers.find(e => e.getAttribute("data-name") === t);
  }
  createSwitchers() {
    this._switchers = [];
    for (const t of this.children) {
      if (!(t instanceof ot)) continue;
      const e = document.createElement("div");
      e.addEventListener("click", () => {
        this.tab === t.name ? this.toggleAttribute("tab", !1) : this.tab = t.name;
      }), e.setAttribute("data-name", t.name), e.className = "switcher";
      const o = document.createElement("bim-label");
      o.textContent = t.label ?? "", o.icon = t.icon, e.append(o), this._switchers.push(e);
    }
  }
  onSlotChange(t) {
    this.createSwitchers();
    const e = t.target.assignedElements(),
      o = e.find(n => n instanceof ot ? this.tab ? n.name === this.tab : !n.hidden : !1);
    o && o instanceof ot && (this.tab = o.name);
    for (const n of e) {
      if (!(n instanceof ot)) {
        n.remove();
        continue;
      }
      n.removeEventListener("hiddenchange", this.onTabHiddenChange), o !== n && (n.hidden = !0), n.addEventListener("hiddenchange", this.onTabHiddenChange);
    }
  }
  render() {
    return f`
      <div class="parent">
        <div class="switchers">${this._switchers}</div>
        <div class="content">
          <slot @slotchange=${this.onSlotChange}></slot>
        </div>
      </div>
    `;
  }
};
ar.styles = [Ot.scrollbar, O`
      * {
        box-sizing: border-box;
      }

      :host {
        background-color: var(--bim-ui_bg-base);
        display: block;
        overflow: auto;
      }

      .parent {
        display: grid;
        grid-template: "switchers" auto "content" 1fr;
        height: 100%;
      }

      :host([bottom]) .parent {
        grid-template: "content" 1fr "switchers" auto;
      }

      .switchers {
        display: flex;
        height: 2.25rem;
        font-weight: 600;
        grid-area: switchers;
      }

      .switcher {
        --bim-label--c: var(--bim-ui_bg-contrast-80);
        background-color: var(--bim-ui_bg-base);
        cursor: pointer;
        pointer-events: auto;
        padding: 0rem 0.75rem;
        display: flex;
        justify-content: center;
        transition: all 0.15s;
      }

      :host([switchers-full]) .switcher {
        flex: 1;
      }

      .switcher:hover,
      .switcher[data-active] {
        --bim-label--c: var(--bim-ui_main-contrast);
        background-color: var(--bim-ui_main-base);
      }

      .switchers bim-label {
        pointer-events: none;
      }

      :host([switchers-hidden]) .switchers {
        display: none;
      }

      .content {
        grid-area: content;
        overflow: auto;
      }

      :host(:not([bottom])) .content {
        border-top: 1px solid var(--bim-ui_bg-contrast-20);
      }

      :host([bottom]) .content {
        border-bottom: 1px solid var(--bim-ui_bg-contrast-20);
      }

      :host(:not([tab])) .content {
        display: none;
      }

      :host([floating]) {
        background-color: transparent;
      }

      :host([floating]) .switchers {
        justify-self: center;
        overflow: auto;
      }

      :host([floating]:not([bottom])) .switchers {
        border-radius: var(--bim-ui_size-2xs) var(--bim-ui_size-2xs) 0 0;
        border-top: 1px solid var(--bim-ui_bg-contrast-20);
        border-left: 1px solid var(--bim-ui_bg-contrast-20);
        border-right: 1px solid var(--bim-ui_bg-contrast-20);
      }

      :host([floating][bottom]) .switchers {
        border-radius: 0 0 var(--bim-ui_size-2xs) var(--bim-ui_size-2xs);
        border-bottom: 1px solid var(--bim-ui_bg-contrast-20);
        border-left: 1px solid var(--bim-ui_bg-contrast-20);
        border-right: 1px solid var(--bim-ui_bg-contrast-20);
      }

      :host([floating]:not([tab])) .switchers {
        border-radius: var(--bim-ui_size-2xs);
        border-bottom: 1px solid var(--bim-ui_bg-contrast-20);
      }

      :host([floating][bottom]:not([tab])) .switchers {
        border-top: 1px solid var(--bim-ui_bg-contrast-20);
      }

      :host([floating]) .content {
        border: 1px solid var(--bim-ui_bg-contrast-20);
        border-radius: var(--bim-ui_size-2xs);
        background-color: var(--bim-ui_bg-base);
      }
    `];
let Jt = ar;
ve([he()], Jt.prototype, "_switchers", 2);
ve([g({
  type: Boolean,
  reflect: !0
})], Jt.prototype, "bottom", 2);
ve([g({
  type: Boolean,
  attribute: "switchers-hidden",
  reflect: !0
})], Jt.prototype, "switchersHidden", 2);
ve([g({
  type: Boolean,
  reflect: !0
})], Jt.prototype, "floating", 2);
ve([g({
  type: String,
  reflect: !0
})], Jt.prototype, "tab", 1);
ve([g({
  type: Boolean,
  attribute: "switchers-full",
  reflect: !0
})], Jt.prototype, "switchersFull", 2);
/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const ko = i => i ?? F;
var Oc = Object.defineProperty,
  Rc = Object.getOwnPropertyDescriptor,
  _t = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? Rc(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && Oc(t, e, n), n;
  };
const lr = class extends I {
  constructor() {
    super(...arguments), this._inputTypes = ["date", "datetime-local", "email", "month", "password", "search", "tel", "text", "time", "url", "week", "area"], this.value = "", this.vertical = !1, this._type = "text", this.onValueChange = new Event("input");
  }
  set type(t) {
    this._inputTypes.includes(t) && (this._type = t);
  }
  get type() {
    return this._type;
  }
  /**
   * Gets the query value derived from the current input value.
   * The `getQuery` function is assumed to be a utility function that takes a string as input
   * and returns a processed query value based on the input.
   *
   * @returns The processed query value derived from the current input value.
   *
   * @example
   * ```typescript
   * const textInput = new TextInput();
   * textInput.value = "Key?Value";
   * console.log(textInput.query);
   * ```
   */
  get query() {
    return hn(this.value);
  }
  onInputChange(t) {
    t.stopPropagation();
    const e = t.target;
    clearTimeout(this._debounceTimeoutID), this._debounceTimeoutID = setTimeout(() => {
      this.value = e.value, this.dispatchEvent(this.onValueChange);
    }, this.debounce);
  }
  focus() {
    setTimeout(() => {
      var t;
      const e = (t = this.shadowRoot) == null ? void 0 : t.querySelector("input");
      e == null || e.focus();
    });
  }
  render() {
    return f`
      <bim-input
        .name=${this.name}
        .icon=${this.icon}
        .label=${this.label}
        .vertical=${this.vertical}
      >
        ${this.type === "area" ? f` <textarea
              aria-label=${this.label || this.name || "Text Input"}
              .value=${this.value}
              .rows=${this.rows ?? 5}
              placeholder=${ko(this.placeholder)}
              @input=${this.onInputChange}
            ></textarea>` : f` <input
              aria-label=${this.label || this.name || "Text Input"}
              .type=${this.type}
              .value=${this.value}
              placeholder=${ko(this.placeholder)}
              @input=${this.onInputChange}
            />`}
      </bim-input>
    `;
  }
};
lr.styles = [Ot.scrollbar, O`
      :host {
        --bim-input--bgc: var(--bim-ui_bg-contrast-20);
        flex: 1;
        display: block;
      }

      input,
      textarea {
        font-family: inherit;
        background-color: transparent;
        border: none;
        width: 100%;
        padding: var(--bim-ui_size-3xs);
        color: var(--bim-text-input--c, var(--bim-ui_bg-contrast-100));
      }

      input {
        outline: none;
        height: 100%;
        padding: 0 var(--bim-ui_size-3xs); /* Override padding */
        border-radius: var(--bim-text-input--bdrs, var(--bim-ui_size-4xs));
      }

      textarea {
        line-height: 1.1rem;
        resize: vertical;
      }

      :host(:focus) {
        --bim-input--olc: var(--bim-ui_accent-base);
      }

      /* :host([disabled]) {
      --bim-input--bgc: var(--bim-ui_bg-contrast-20);
    } */
    `];
let ct = lr;
_t([g({
  type: String,
  reflect: !0
})], ct.prototype, "icon", 2);
_t([g({
  type: String,
  reflect: !0
})], ct.prototype, "label", 2);
_t([g({
  type: String,
  reflect: !0
})], ct.prototype, "name", 2);
_t([g({
  type: String,
  reflect: !0
})], ct.prototype, "placeholder", 2);
_t([g({
  type: String,
  reflect: !0
})], ct.prototype, "value", 2);
_t([g({
  type: Boolean,
  reflect: !0
})], ct.prototype, "vertical", 2);
_t([g({
  type: Number,
  reflect: !0
})], ct.prototype, "debounce", 2);
_t([g({
  type: Number,
  reflect: !0
})], ct.prototype, "rows", 2);
_t([g({
  type: String,
  reflect: !0
})], ct.prototype, "type", 1);
var Pc = Object.defineProperty,
  Nc = Object.getOwnPropertyDescriptor,
  cr = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? Nc(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && Pc(t, e, n), n;
  };
const dr = class extends I {
  constructor() {
    super(...arguments), this.rows = 2, this._vertical = !1;
  }
  set vertical(t) {
    this._vertical = t, this.updateChildren();
  }
  get vertical() {
    return this._vertical;
  }
  updateChildren() {
    const t = this.children;
    for (const e of t) this.vertical ? e.setAttribute("label-hidden", "") : e.removeAttribute("label-hidden");
  }
  render() {
    return f`
      <style>
        .parent {
          grid-auto-flow: ${this.vertical ? "row" : "column"};
          grid-template-rows: repeat(${this.rows}, 1fr);
        }
      </style>
      <div class="parent">
        <slot @slotchange=${this.updateChildren}></slot>
      </div>
    `;
  }
};
dr.styles = O`
    .parent {
      display: grid;
      gap: 0.25rem;
    }

    ::slotted(bim-button[label]:not([vertical])) {
      --bim-button--jc: flex-start;
    }

    ::slotted(bim-button) {
      --bim-label--c: var(--bim-ui_bg-contrast-80);
    }
  `;
let Pi = dr;
cr([g({
  type: Number,
  reflect: !0
})], Pi.prototype, "rows", 2);
cr([g({
  type: Boolean,
  reflect: !0
})], Pi.prototype, "vertical", 1);
var Mc = Object.defineProperty,
  Lc = Object.getOwnPropertyDescriptor,
  Ni = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? Lc(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && Mc(t, e, n), n;
  };
const ur = class extends I {
  constructor() {
    super(...arguments), this._vertical = !1, this._labelHidden = !1;
  }
  set vertical(t) {
    this._vertical = t, this.updateChildren();
  }
  get vertical() {
    return this._vertical;
  }
  set labelHidden(t) {
    this._labelHidden = t, this.updateChildren();
  }
  get labelHidden() {
    return this._labelHidden;
  }
  updateChildren() {
    const t = this.children;
    for (const e of t) e instanceof Pi && (e.vertical = this.vertical), e.toggleAttribute("label-hidden", this.vertical);
  }
  render() {
    return f`
      <div class="parent">
        <div class="children">
          <slot @slotchange=${this.updateChildren}></slot>
        </div>
        ${!this.labelHidden && (this.label || this.icon) ? f`<bim-label .icon=${this.icon}>${this.label}</bim-label>` : null}
      </div>
    `;
  }
};
ur.styles = O`
    :host {
      --bim-label--fz: var(--bim-ui_size-xs);
      --bim-label--c: var(--bim-ui_bg-contrast-60);
      display: block;
      flex: 1;
    }

    :host(:not([vertical])) ::slotted(bim-button[vertical]) {
      --bim-icon--fz: var(--bim-ui_size-5xl);
      min-height: 3.75rem;
    }

    ::slotted(bim-button) {
      --bim-label--c: var(--bim-ui_bg-contrast-80);
    }

    .parent {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      padding: 0.5rem;
      height: 100%;
      box-sizing: border-box;
      justify-content: space-between;
    }

    :host([vertical]) .parent {
      flex-direction: row-reverse;
    }

    :host([vertical]) .parent > bim-label {
      writing-mode: tb;
    }

    .children {
      display: flex;
      gap: 0.25rem;
    }

    :host([vertical]) .children {
      flex-direction: column;
    }
  `;
let _e = ur;
Ni([g({
  type: String,
  reflect: !0
})], _e.prototype, "label", 2);
Ni([g({
  type: String,
  reflect: !0
})], _e.prototype, "icon", 2);
Ni([g({
  type: Boolean,
  reflect: !0
})], _e.prototype, "vertical", 1);
Ni([g({
  type: Boolean,
  attribute: "label-hidden",
  reflect: !0
})], _e.prototype, "labelHidden", 1);
var Dc = Object.defineProperty,
  jc = Object.getOwnPropertyDescriptor,
  Rn = (i, t, e, o) => {
    for (var n = o > 1 ? void 0 : o ? jc(t, e) : t, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = (o ? r(t, e, n) : r(n)) || n);
    return o && n && Dc(t, e, n), n;
  };
const hr = class extends I {
  constructor() {
    super(...arguments), this.labelsHidden = !1, this._vertical = !1, this._hidden = !1;
  }
  set vertical(t) {
    this._vertical = t, this.updateSections();
  }
  get vertical() {
    return this._vertical;
  }
  set hidden(t) {
    this._hidden = t, this.dispatchEvent(new Event("hiddenchange"));
  }
  get hidden() {
    return this._hidden;
  }
  // private setActivationButton() {
  //   this.activationButton.draggable = Manager.config.draggableToolbars;
  //   this.activationButton.addEventListener(
  //     "click",
  //     () => (this.hidden = !this.hidden),
  //   );
  //   this.activationButton.setAttribute("data-ui-manager-id", this._managerID);
  //   this.activationButton.addEventListener("dragstart", (e) => {
  //     const id = this.getAttribute("data-ui-manager-id");
  //     if (e.dataTransfer && id) {
  //       e.dataTransfer.setData("id", id);
  //       e.dataTransfer.effectAllowed = "move";
  //     }
  //     const containers = document.querySelectorAll("bim-toolbars-container");
  //     for (const container of containers) {
  //       if (container === this.parentElement) continue;
  //       container.dropping = true;
  //     }
  //   });
  //   this.activationButton.addEventListener("dragend", (e) => {
  //     if (e.dataTransfer) e.dataTransfer.clearData();
  //     const containers = document.querySelectorAll("bim-toolbars-container");
  //     for (const container of containers) {
  //       container.dropping = false;
  //     }
  //   });
  // }
  updateSections() {
    const t = this.children;
    for (const e of t) e instanceof _e && (e.labelHidden = this.vertical && !Q.config.sectionLabelOnVerticalToolbar, e.vertical = this.vertical);
  }
  // firstUpdated() {
  //   this.setAttribute("data-ui-manager-id", this._managerID);
  // }
  render() {
    return f`
      <div class="parent">
        <slot @slotchange=${this.updateSections}></slot>
      </div>
    `;
  }
};
hr.styles = O`
    :host {
      --bim-button--bgc: transparent;
      background-color: var(--bim-ui_bg-base);
      border-radius: var(--bim-ui_size-2xs);
      display: block;
    }

    :host([hidden]) {
      display: none;
    }

    .parent {
      display: flex;
      width: min-content;
      pointer-events: auto;
    }

    :host([vertical]) .parent {
      flex-direction: column;
    }

    :host([vertical]) {
      width: min-content;
      border-radius: var(--bim-ui_size-2xs);
      border: 1px solid var(--bim-ui_bg-contrast-20);
    }

    ::slotted(bim-toolbar-section:not(:last-child)) {
      border-right: 1px solid var(--bim-ui_bg-contrast-20);
      border-bottom: none;
    }

    :host([vertical]) ::slotted(bim-toolbar-section:not(:last-child)) {
      border-bottom: 1px solid var(--bim-ui_bg-contrast-20);
      border-right: none;
    }
  `;
let Mi = hr;
Rn([g({
  type: String,
  reflect: !0
})], Mi.prototype, "icon", 2);
Rn([g({
  type: Boolean,
  attribute: "labels-hidden",
  reflect: !0
})], Mi.prototype, "labelsHidden", 2);
Rn([g({
  type: Boolean,
  reflect: !0
})], Mi.prototype, "vertical", 1);
var zc = Object.defineProperty,
  Fc = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && zc(t, e, n), n;
  };
const pr = class extends I {
  constructor() {
    super(), this._onResize = new Event("resize"), new ResizeObserver(() => {
      setTimeout(() => {
        this.dispatchEvent(this._onResize);
      });
    }).observe(this);
  }
  render() {
    return f`
      <div class="parent">
        <slot></slot>
      </div>
    `;
  }
};
pr.styles = O`
    :host {
      display: grid;
      min-width: 0;
      min-height: 0;
      height: 100%;
    }

    .parent {
      overflow: hidden;
      position: relative;
    }
  `;
let mr = pr;
Fc([g({
  type: String,
  reflect: !0
})], mr.prototype, "name");
/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const fr = "important",
  Bc = " !" + fr,
  Bt = ps(class extends ms {
    constructor(i) {
      var t;
      if (super(i), i.type !== hs.ATTRIBUTE || i.name !== "style" || ((t = i.strings) == null ? void 0 : t.length) > 2) throw Error("The `styleMap` directive must be used in the `style` attribute and must be the only part in the attribute.");
    }
    render(i) {
      return Object.keys(i).reduce((t, e) => {
        const o = i[e];
        return o == null ? t : t + `${e = e.includes("-") ? e : e.replace(/(?:^(webkit|moz|ms|o)|)(?=[A-Z])/g, "-$&").toLowerCase()}:${o};`;
      }, "");
    }
    update(i, [t]) {
      const {
        style: e
      } = i.element;
      if (this.ft === void 0) return this.ft = new Set(Object.keys(t)), this.render(t);
      for (const o of this.ft) t[o] == null && (this.ft.delete(o), o.includes("-") ? e.removeProperty(o) : e[o] = null);
      for (const o in t) {
        const n = t[o];
        if (n != null) {
          this.ft.add(o);
          const s = typeof n == "string" && n.endsWith(Bc);
          o.includes("-") || s ? e.setProperty(o, s ? n.slice(0, -11) : n, s ? fr : "") : e[o] = n;
        }
      }
      return Wt;
    }
  }),
  Hc = i => {
    const {
        components: t
      } = i,
      e = t.get(A.IfcLoader);
    return f`
    <bim-button
      data-ui-id="import-ifc"
      label="Load IFC"
      icon="mage:box-3d-fill"
      @click=${() => {
      const n = document.createElement("input");
      n.type = "file", n.accept = ".ifc", n.onchange = async () => {
        if (n.files === null || n.files.length === 0) return;
        const s = n.files[0],
          r = s.name.replace(".ifc", "");
        n.remove();
        const a = await s.arrayBuffer(),
          l = new Uint8Array(a);
        await e.load(l, !0, r);
      }, n.click();
    }}
    ></bim-button>
  `;
  },
  Uc = i => U.create(Hc, i),
  Vc = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    loadIfc: Uc
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  hh = {
    ...Vc
    // fragmentExploder,
  },
  Wc = i => {
    const {
        components: t,
        actions: e,
        tags: o
      } = i,
      n = (e == null ? void 0 : e.dispose) ?? !0,
      s = (e == null ? void 0 : e.download) ?? !0,
      r = (e == null ? void 0 : e.visibility) ?? !0,
      a = (o == null ? void 0 : o.schema) ?? !0,
      l = (o == null ? void 0 : o.viewDefinition) ?? !0,
      c = t.get(A.FragmentsManager),
      h = ({
        detail: p
      }) => {
        const {
          cell: d
        } = p;
        d.style.padding = "0.25rem 0";
      };
    return f`
    <bim-table ${K(p => {
      if (!p) return;
      const d = p;
      d.hiddenColumns = ["modelID"];
      const m = [];
      for (const [, y] of c.groups) {
        if (!y) continue;
        const _ = {
          data: {
            Name: y.name || y.uuid,
            modelID: y.uuid
          }
        };
        m.push(_);
      }
      d.dataTransform = {
        Name: (y, _) => {
          const {
            modelID: $
          } = _;
          if (typeof $ != "string") return y;
          const w = c.groups.get($);
          if (!w) return $;
          const b = {};
          for (const k of w.items) b[k.id] = k.ids;
          let v;
          const {
            schema: C
          } = w.ifcMetadata;
          a && C && (v = f`
            <bim-label style="background-color: var(--bim-ui_main-base); padding: 0 0.25rem; color: var(--bim-ui_main-contrast); border-radius: 0.25rem;">${C}</bim-label>
            `);
          let x;
          if (l && "viewDefinition" in w.ifcMetadata) {
            const k = w.ifcMetadata.viewDefinition;
            x = f`
            ${k.split(",").map(P => f`<bim-label style="background-color: var(--bim-ui_main-base); padding: 0 0.25rem; color: var(--bim-ui_main-contrast); border-radius: 0.25rem;">${P}</bim-label>`)}
          `;
          }
          let E;
          n && (E = f`<bim-button @click=${() => c.disposeGroup(w)} icon="mdi:delete"></bim-button>`);
          let M;
          r && (M = f`<bim-button @click=${P => {
            const Y = t.get(A.Hider),
              H = P.target;
            Y.set(H.hasAttribute("data-model-hidden"), b), H.toggleAttribute("data-model-hidden"), H.icon = H.hasAttribute("data-model-hidden") ? "mdi:eye-off" : "mdi:eye";
          }} icon="mdi:eye"></bim-button>`);
          let L;
          return s && (L = f`<bim-button @click=${() => {
            const P = document.createElement("input");
            P.type = "file", P.accept = ".ifc", P.multiple = !1, P.addEventListener("change", async () => {
              if (!(P.files && P.files.length === 1)) return;
              const Y = P.files[0],
                H = await Y.arrayBuffer(),
                z = await t.get(A.IfcPropertiesManager).saveToIfc(w, new Uint8Array(H)),
                N = new File([z], Y.name),
                V = document.createElement("a");
              V.href = URL.createObjectURL(N), V.download = N.name, V.click(), URL.revokeObjectURL(V.href);
            }), P.click();
          }} icon="flowbite:download-solid"></bim-button>`), f`
         <div style="display: flex; flex: 1; gap: var(--bim-ui_size-4xs); justify-content: space-between; overflow: auto;">
          <div style="display: flex; flex-wrap: wrap; align-items: center; gap: 0 var(--bim-ui_size-4xs); flex-grow: 1; overflow: auto;">
            <div style="min-height: 1.75rem; overflow: auto; display: flex;">
              <bim-label style="white-space: normal;">${y}</bim-label>
            </div>
            <div style="display: flex; flex-wrap: wrap; gap: var(--bim-ui_size-4xs); overflow: auto;">
              ${v}
              ${x}
            </div>
          </div>
          <div style="display: flex; align-self: flex-start; flex-shrink: 0;">
            ${L}
            ${M}
            ${E}
          </div>
         </div>
        `;
        }
      }, d.data = m;
    })} @cellcreated=${h} headers-hidden no-indentation>
      <bim-label slot="missing-data" style="--bim-icon--c: gold" icon="ic:round-warning">
        No models has been loaded yet
      </bim-label>
    </bim-table>
  `;
  },
  Gc = (i, t = !0) => {
    const e = U.create(Wc, i);
    if (t) {
      const {
          components: o
        } = i,
        n = o.get(A.FragmentsManager),
        [, s] = e;
      n.onFragmentsLoaded.add(() => setTimeout(() => s())), n.onFragmentsDisposed.add(() => s());
    }
    return e;
  },
  qc = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    modelsList: Gc
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  br = ["Name", "ContainedInStructure", "ForLayerSet", "LayerThickness", "HasProperties", "HasAssociations", "HasAssignments", "HasPropertySets", "PredefinedType", "Quantities", "ReferencedSource", "Identification", i => i.includes("Value"), i => i.startsWith("Material"), i => i.startsWith("Relating"), i => {
    const t = ["IsGroupedBy", "IsDecomposedBy"];
    return i.startsWith("Is") && !t.includes(i);
  }];
async function li(i, t, e, o = br, n = !1) {
  const s = i.get(A.IfcRelationsIndexer),
    r = await t.getProperties(e);
  if (!r) return {
    data: {
      Entity: `${e} properties not found...`
    }
    // onRowCreated(row) {
    //   row.addEventListener("cellcreated", (event) => {
    //     if (!(event instanceof CustomEvent)) return;
    //     const { cell } = event.detail;
    //     cell.style.gridColumn = "1 / -1";
    //   });
    // },
  };
  const a = s.relationMaps[t.uuid],
    l = {
      data: {}
    };
  for (const c in r) {
    const h = o.map(p => typeof p == "string" ? c === p : p(c)).includes(!0);
    if (!(c === "type" || h)) continue;
    const u = r[c];
    if (u) if (u.type === 5) {
      l.children || (l.children = []);
      const p = await li(i, t, u.value, o, n);
      l.children.push(p);
    } else if (typeof u == "object" && !Array.isArray(u)) {
      const {
        value: p,
        type: d
      } = u;
      if (n) d === 1 || d === 2 || d === 3 || (l.data[c] = p);else {
        const m = typeof p == "number" ? Number(p.toFixed(3)) : p;
        l.data[c] = m;
      }
    } else if (Array.isArray(u)) for (const p of u) {
      if (p.type !== 5) continue;
      l.children || (l.children = []);
      const d = await li(i, t, p.value, o, n);
      l.children.push(d);
    } else if (c === "type") {
      const p = A.IfcCategoryMap[u];
      l.data.Entity = p;
    } else l.data[c] = u;
  }
  if (a && a.get(r.expressID)) {
    const c = a.get(r.expressID);
    for (const h of o) {
      const u = [];
      if (typeof h == "string") {
        const p = s._inverseAttributes.indexOf(h);
        p !== -1 && u.push(p);
      } else {
        const p = s._inverseAttributes.filter(d => h(d));
        for (const d of p) {
          const m = s._inverseAttributes.indexOf(d);
          u.push(m);
        }
      }
      for (const p of u) {
        const d = c.get(p);
        if (d) for (const m of d) {
          const y = await li(i, t, m, o, n);
          l.children || (l.children = []), l.children.push(y);
        }
      }
    }
  }
  return l;
}
const Yc = i => {
    const {
        components: t,
        fragmentIdMap: e,
        attributesToInclude: o,
        editable: n,
        tableDefinition: s
      } = i,
      r = t.get(A.FragmentsManager);
    let a;
    return typeof o == "function" ? a = o(br) : a = o, f`<bim-table ${K(async c => {
      if (!c) return;
      const h = c,
        u = [],
        p = [];
      for (const d in e) {
        const m = r.list.get(d);
        if (!(m && m.group)) continue;
        const y = m.group,
          _ = p.find($ => $.model === y);
        if (_) for (const $ of e[d]) _.expressIDs.add($);else {
          const $ = {
            model: y,
            expressIDs: new Set(e[d])
          };
          p.push($);
        }
      }
      for (const d of p) {
        const {
          model: m,
          expressIDs: y
        } = d;
        for (const _ of y) {
          const $ = await li(t, m, _, a, n);
          u.push($);
        }
      }
      h.dataTransform = s, h.data = u, h.columns = [{
        name: "Entity",
        width: "minmax(15rem, 1fr)"
      }];
    })}></bim-table>`;
  },
  Xc = i => U.create(Yc, i),
  Jc = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    entityAttributes: Xc
  }, Symbol.toStringTag, {
    value: "Module"
  }));
let wt;
const Qc = i => {
    const {
        components: t,
        classifications: e
      } = i,
      o = t.get(A.Classifier),
      n = t.get(A.Hider);
    wt || (wt = document.createElement("bim-table"), wt.headersHidden = !0, wt.hiddenColumns = ["system"], wt.columns = ["Name", {
      name: "Actions",
      width: "auto"
    }], wt.dataTransform = {
      Actions: (r, a) => {
        const {
          system: l,
          Name: c
        } = a;
        if (!(typeof l == "string" && typeof c == "string")) return r;
        const h = o.list[l];
        if (!(h && h[c])) return r;
        const u = h[c],
          {
            map: p
          } = u;
        return f`
          <div>
            <bim-checkbox checked @change=${m => {
          const y = m.target;
          n.set(y.value, p);
        }}></bim-checkbox>
          </div>
        `;
      }
    });
    const s = [];
    for (const r of e) {
      const a = typeof r == "string" ? r : r.system,
        l = typeof r == "string" ? r : r.label,
        c = o.list[a];
      c && s.push({
        data: {
          Name: l,
          system: a
        },
        children: Object.keys(c).map(h => ({
          data: {
            Name: h,
            system: a,
            Actions: ""
          }
        }))
      });
    }
    return wt.data = s, f`${wt}`;
  },
  Kc = (i, t = !0) => {
    const e = U.create(Qc, i);
    if (t) {
      const {
          components: o
        } = i,
        n = o.get(A.FragmentsManager),
        [, s] = e;
      n.onFragmentsDisposed.add(() => s());
    }
    return e;
  },
  Zc = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    classificationTree: Kc
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  gr = async (i, t, e) => {
    var a, l, c, h;
    const o = i.get(A.IfcRelationsIndexer),
      n = {
        data: {
          Name: (a = e.Name) == null ? void 0 : a.value
        },
        children: [{
          data: {
            Name: "Identification",
            Value: (l = e.Identification) == null ? void 0 : l.value
          }
        }, {
          data: {
            Name: "Name",
            Value: (c = e.Name) == null ? void 0 : c.value
          }
        }, {
          data: {
            Name: "Description",
            Value: (h = e.Description) == null ? void 0 : h.value
          }
        }]
      },
      s = o.getEntityRelations(t, e.expressID, "IsNestedBy");
    if (!s) return n;
    n.children || (n.children = []);
    const r = [];
    n.children.push({
      data: {
        Name: "Tasks"
      },
      children: r
    });
    for (const u of s) {
      const p = await t.getProperties(u);
      if (!p) continue;
      const d = await gr(i, t, p);
      r.push(d);
    }
    return n;
  },
  td = async (i, t, e) => {
    const o = [];
    for (const s of e) {
      const r = await gr(i, t, s);
      o.push(r);
    }
    return {
      data: {
        Name: "Tasks"
      },
      children: o
    };
  },
  ed = async (i, t) => {
    var o, n, s, r;
    const e = {
      data: {
        Name: "Classifications"
      }
    };
    for (const a of t) {
      const {
          value: l
        } = a.ReferencedSource,
        c = await i.getProperties(l);
      if (!c) continue;
      const h = {
        data: {
          Name: (o = c.Name) == null ? void 0 : o.value
        },
        children: [{
          data: {
            Name: "Identification",
            Value: ((n = a.Identification) == null ? void 0 : n.value) || ((s = a.ItemReference) == null ? void 0 : s.value)
          }
        }, {
          data: {
            Name: "Name",
            Value: (r = a.Name) == null ? void 0 : r.value
          }
        }]
      };
      e.children || (e.children = []), e.children.push(h);
    }
    return e;
  },
  id = async (i, t) => {
    var o, n, s, r, a, l;
    const e = {
      data: {
        Name: "Materials"
      }
    };
    for (const c of t) {
      if (c.type === J.IFCMATERIALLAYERSETUSAGE) {
        const h = (o = c.ForLayerSet) == null ? void 0 : o.value,
          u = await i.getProperties(h);
        if (!u) continue;
        for (const p of u.MaterialLayers) {
          const {
              value: d
            } = p,
            m = await i.getProperties(d);
          if (!m) continue;
          const y = await i.getProperties((n = m.Material) == null ? void 0 : n.value);
          if (!y) continue;
          const _ = {
            data: {
              Name: "Layer"
            },
            children: [{
              data: {
                Name: "Thickness",
                Value: (s = m.LayerThickness) == null ? void 0 : s.value
              }
            }, {
              data: {
                Name: "Material",
                Value: (r = y.Name) == null ? void 0 : r.value
              }
            }]
          };
          e.children || (e.children = []), e.children.push(_);
        }
      }
      if (c.type === J.IFCMATERIALLIST) for (const h of c.Materials) {
        const {
            value: u
          } = h,
          p = await i.getProperties(u);
        if (!p) continue;
        const d = {
          data: {
            Name: "Name",
            Value: (a = p.Name) == null ? void 0 : a.value
          }
        };
        e.children || (e.children = []), e.children.push(d);
      }
      if (c.type === J.IFCMATERIAL) {
        const h = {
          data: {
            Name: "Name",
            Value: (l = c.Name) == null ? void 0 : l.value
          }
        };
        e.children || (e.children = []), e.children.push(h);
      }
    }
    return e;
  },
  nd = {
    IFCLENGTHMEASURE: "LENGTHUNIT",
    IFCAREAMEASURE: "AREAUNIT",
    IFCVOLUMEMEASURE: "VOLUMEUNIT",
    IFCPLANEANGLEMEASURE: "PLANEANGLEUNIT"
  },
  od = {
    MILLIMETRE: {
      symbol: "mm",
      digits: 0
    },
    METRE: {
      symbol: "m",
      digits: 2
    },
    KILOMETRE: {
      symbol: "km",
      digits: 2
    },
    SQUARE_METRE: {
      symbol: "m²",
      digits: 2
    },
    CUBIC_METRE: {
      symbol: "m³",
      digits: 2
    },
    DEGREE: {
      symbol: "°",
      digits: 2
    },
    RADIAN: {
      symbol: "rad",
      digits: 2
    },
    GRAM: {
      symbol: "g",
      digits: 0
    },
    KILOGRAM: {
      symbol: "kg",
      digits: 2
    },
    MILLISECOND: {
      symbol: "ms",
      digits: 0
    },
    SECOND: {
      symbol: "s",
      digits: 0
    }
  },
  yr = async (i, t) => {
    var n, s, r;
    const e = Object.values(await i.getAllPropertiesOfType(J.IFCUNITASSIGNMENT))[0];
    let o;
    for (const a of e.Units) {
      const l = await i.getProperties(a.value);
      if (l && ((n = l.UnitType) == null ? void 0 : n.value) === nd[t]) {
        o = `${((s = l.Prefix) == null ? void 0 : s.value) ?? ""}${((r = l.Name) == null ? void 0 : r.value) ?? ""}`;
        break;
      }
    }
    return o ? od[o] : null;
  },
  sd = async (i, t, e) => {
    var s, r;
    const {
        displayUnits: o
      } = e,
      n = {
        data: {
          Name: "PropertySets"
        }
      };
    for (const a of t) {
      const l = {
        data: {
          Name: (s = a.Name) == null ? void 0 : s.value
        }
      };
      if (a.type === J.IFCPROPERTYSET) {
        for (const c of a.HasProperties) {
          const {
              value: h
            } = c,
            u = await i.getProperties(h);
          if (!u) continue;
          const p = Object.keys(u).find(_ => _.includes("Value"));
          if (!(p && u[p])) continue;
          let d = u[p].value,
            m = "";
          if (o) {
            const {
                name: _
              } = u[p],
              $ = (await yr(i, _)) ?? {};
            m = $.symbol, d = u[p].value, typeof d == "number" && $.digits && (d = d.toFixed($.digits));
          }
          const y = {
            data: {
              Name: (r = u.Name) == null ? void 0 : r.value,
              Value: `${d} ${m ?? ""}`
            }
          };
          l.children || (l.children = []), l.children.push(y);
        }
        l.children && (n.children || (n.children = []), n.children.push(l));
      }
    }
    return n;
  },
  rd = async (i, t, e) => {
    var s, r;
    const {
        displayUnits: o
      } = e,
      n = {
        data: {
          Name: "QuantitySets"
        }
      };
    for (const a of t) {
      const l = {
        data: {
          Name: (s = a.Name) == null ? void 0 : s.value
        }
      };
      if (a.type === J.IFCELEMENTQUANTITY) {
        for (const c of a.Quantities) {
          const {
              value: h
            } = c,
            u = await i.getProperties(h);
          if (!u) continue;
          const p = Object.keys(u).find(_ => _.includes("Value"));
          if (!(p && u[p])) continue;
          let d = u[p].value,
            m = "";
          if (o) {
            const {
                name: _
              } = u[p],
              $ = (await yr(i, _)) ?? {};
            m = $.symbol, d = u[p].value, typeof d == "number" && $.digits && (d = d.toFixed($.digits));
          }
          const y = {
            data: {
              Name: (r = u.Name) == null ? void 0 : r.value,
              Value: `${d} ${m ?? ""}`
            }
          };
          l.children || (l.children = []), l.children.push(y);
        }
        l.children && (n.children || (n.children = []), n.children.push(l));
      }
    }
    return n;
  },
  ad = ["OwnerHistory", "ObjectPlacement", "CompositionType"],
  vr = async (i, t) => {
    const o = {
        ...{
          groupName: "Attributes",
          includeClass: !1
        },
        ...t
      },
      {
        groupName: n,
        includeClass: s
      } = o,
      r = {
        data: {
          Name: n
        }
      };
    s && (r.children || (r.children = []), r.children.push({
      data: {
        Name: "Class",
        Value: A.IfcCategoryMap[i.type]
      }
    }));
    for (const a in i) {
      if (ad.includes(a)) continue;
      const l = i[a];
      if (l && typeof l == "object" && !Array.isArray(l)) {
        if (l.type === J.REF) continue;
        const c = {
          data: {
            Name: a,
            Value: l.value
          }
        };
        r.children || (r.children = []), r.children.push(c);
      }
    }
    return r;
  },
  ce = (i, ...t) => {
    i.children || (i.children = []), i.children.push(...t);
  },
  ld = async (i, t, e, o, n) => {
    const r = i.get(A.IfcRelationsIndexer).getEntityRelations(t, e, "IsDefinedBy");
    if (r) {
      const a = [],
        l = [];
      for (const u of r) {
        const p = await t.getProperties(u);
        p && (p.type === J.IFCPROPERTYSET && a.push(p), p.type === J.IFCELEMENTQUANTITY && l.push(p));
      }
      const c = await sd(t, a, n);
      c.children && ce(o, c);
      const h = await rd(t, l, n);
      h.children && ce(o, h);
    }
  },
  cd = async (i, t, e, o) => {
    const s = i.get(A.IfcRelationsIndexer).getEntityRelations(t, e, "HasAssociations");
    if (s) {
      const r = [],
        a = [];
      for (const h of s) {
        const u = await t.getProperties(h);
        u && (u.type === J.IFCCLASSIFICATIONREFERENCE && r.push(u), (u.type === J.IFCMATERIALLAYERSETUSAGE || u.type === J.IFCMATERIALLAYERSET || u.type === J.IFCMATERIALLAYER || u.type === J.IFCMATERIAL || u.type === J.IFCMATERIALLIST) && a.push(u));
      }
      const l = await ed(t, r);
      l.children && ce(o, l);
      const c = await id(t, a);
      c.children && ce(o, c);
    }
  },
  dd = async (i, t, e, o) => {
    const s = i.get(A.IfcRelationsIndexer).getEntityRelations(t, e, "HasAssignments");
    if (s) {
      const r = [];
      for (const l of s) {
        const c = await t.getProperties(l);
        c && c.type === J.IFCTASK && r.push(c);
      }
      const a = await td(i, t, r);
      a.children && ce(o, a);
    }
  },
  ud = async (i, t, e, o) => {
    const s = i.get(A.IfcRelationsIndexer).getEntityRelations(t, e, "ContainedInStructure");
    if (s && s[0]) {
      const r = s[0],
        a = await t.getProperties(r);
      if (a) {
        const l = await vr(a, {
          groupName: "SpatialContainer"
        });
        ce(o, l);
      }
    }
  };
let si = {};
const hd = async (i, t, e) => {
    var a;
    const o = i.get(A.IfcRelationsIndexer),
      n = i.get(A.FragmentsManager),
      s = n.getModelIdMap(t);
    Object.keys(t).length === 0 && (si = {});
    const r = [];
    for (const l in s) {
      const c = n.groups.get(l);
      if (!c) continue;
      const h = o.relationMaps[c.uuid];
      if (!h) continue;
      l in si || (si[l] = /* @__PURE__ */new Map());
      const u = si[l],
        p = s[l];
      for (const d of p) {
        let m = u.get(d);
        if (m) {
          r.push(m);
          continue;
        }
        const y = await c.getProperties(d);
        if (!y) continue;
        m = {
          data: {
            Name: (a = y.Name) == null ? void 0 : a.value
          }
        }, r.push(m), u.set(d, m);
        const _ = await vr(y, {
          includeClass: !0
        });
        m.children || (m.children = []), m.children.push(_), h.get(d) && (await ld(i, c, d, m, e), await cd(i, c, d, m), await dd(i, c, d, m), await ud(i, c, d, m));
      }
    }
    return r;
  },
  pd = i => {
    const t = {
        emptySelectionWarning: !0,
        ...i
      },
      {
        components: e,
        fragmentIdMap: o,
        emptySelectionWarning: n
      } = t;
    return f`
    <bim-table @cellcreated=${({
      detail: a
    }) => {
      const {
        cell: l
      } = a;
      l.column === "Name" && !("Value" in l.rowData) && (l.style.gridColumn = "1 / -1");
    }} ${K(async a => {
      if (!a) return;
      const l = a;
      l.columns = [{
        name: "Name",
        width: "12rem"
      }], l.headersHidden = !0, l.loadFunction = () => hd(e, o, i), (await l.loadData(!0)) && l.dispatchEvent(new Event("datacomputed"));
    })}>
      ${n ? f`
            <bim-label slot="missing-data" style="--bim-icon--c: gold" icon="ic:round-warning">
              Select some elements to display its properties
            </bim-label>
            ` : null}
      <bim-label slot="error-loading" style="--bim-icon--c: #e72e2e" icon="bxs:error-alt">
        Something went wrong with the properties
      </bim-label>
    </bim-table>
  `;
  },
  md = i => U.create(pd, i),
  fd = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    elementProperties: md
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  fn = async (i, t, e, o) => {
    var c;
    const n = [],
      s = i.get(A.IfcRelationsIndexer),
      r = await t.getProperties(e);
    if (!r) return n;
    const {
        type: a
      } = r,
      l = {
        data: {
          Entity: A.IfcCategoryMap[a],
          Name: (c = r.Name) == null ? void 0 : c.value,
          modelID: t.uuid,
          expressID: e
        }
      };
    for (const h of o) {
      const u = s.getEntityRelations(t, e, h);
      if (!u) continue;
      l.children || (l.children = []), l.data.relations = JSON.stringify(u);
      const p = {};
      for (const d of u) {
        const m = await fn(i, t, d, o);
        for (const y of m) if (y.data.relations) l.children.push(y);else {
          const _ = t.data.get(d);
          if (!_) {
            l.children.push(y);
            continue;
          }
          const $ = _[1][1],
            w = A.IfcCategoryMap[$];
          w in p || (p[w] = []), y.data.Entity = y.data.Name, delete y.data.Name, p[w].push(y);
        }
      }
      for (const d in p) {
        const m = p[d],
          y = m.map($ => $.data.expressID),
          _ = {
            data: {
              Entity: d,
              modelID: t.uuid,
              relations: JSON.stringify(y)
            },
            children: m
          };
        l.children.push(_);
      }
    }
    return n.push(l), n;
  },
  bd = async (i, t, e, o) => {
    const n = i.get(A.IfcRelationsIndexer),
      s = [];
    for (const r of t) {
      let a;
      if (o) a = {
        data: {
          Entity: r.name !== "" ? r.name : r.uuid
        },
        children: await fn(i, r, o, e)
      };else {
        const l = n.relationMaps[r.uuid],
          c = await r.getAllPropertiesOfType(J.IFCPROJECT);
        if (!(l && c)) continue;
        const {
          expressID: h
        } = Object.values(c)[0];
        a = {
          data: {
            Entity: r.name !== "" ? r.name : r.uuid
          },
          children: await fn(i, r, h, e)
        };
      }
      s.push(a);
    }
    return s;
  };
let bt;
const gd = (i, t) => {
    const e = i.get(A.FragmentsManager),
      {
        modelID: o,
        expressID: n,
        relations: s
      } = t;
    if (!o) return null;
    const r = e.groups.get(o);
    return r ? r.getFragmentMap([n, ...JSON.parse(s ?? "[]")]) : null;
  },
  yd = i => {
    const {
        components: t,
        models: e,
        expressID: o
      } = i,
      n = i.selectHighlighterName ?? "select",
      s = i.hoverHighlighterName ?? "hover";
    bt || (bt = document.createElement("bim-table"), bt.hiddenColumns = ["modelID", "expressID", "relations"], bt.columns = ["Entity", "Name"], bt.headersHidden = !0, bt.addEventListener("cellcreated", ({
      detail: a
    }) => {
      const {
        cell: l
      } = a;
      l.column === "Entity" && !("Name" in l.rowData) && (l.style.gridColumn = "1 / -1");
    })), bt.addEventListener("rowcreated", a => {
      a.stopImmediatePropagation();
      const {
          row: l
        } = a.detail,
        c = t.get(j.Highlighter),
        h = gd(t, l.data);
      h && Object.keys(h).length !== 0 && (l.onmouseover = () => {
        s && (l.style.backgroundColor = "var(--bim-ui_bg-contrast-20)", c.highlightByID(s, h, !0, !1, c.selection[n] ?? {}));
      }, l.onmouseout = () => {
        l.style.backgroundColor = "", c.clear(s);
      }, l.onclick = () => {
        n && c.highlightByID(n, h, !0, !0);
      });
    });
    const r = i.inverseAttributes ?? ["IsDecomposedBy", "ContainsElements"];
    return bd(t, e, r, o).then(a => bt.data = a), f`${bt}`;
  },
  vd = (i, t = !0) => {
    const e = U.create(yd, i);
    if (t) {
      const [, o] = e,
        {
          components: n
        } = i,
        s = n.get(A.FragmentsManager),
        r = n.get(A.IfcRelationsIndexer),
        a = () => o({
          models: s.groups.values()
        });
      r.onRelationsIndexed.add(a), s.onFragmentsDisposed.add(a);
    }
    return e;
  },
  _d = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    relationsTree: vd
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  Ce = (i, t) => [...i.get(A.Grids).list.values()].find(n => n.world === t),
  wd = (i, t) => f`
    <bim-color-input @input=${o => {
    const n = o.target;
    i.color = new R.Color(n.color);
  }} color=${t}></bim-color-input>
  `,
  $d = (i, t) => {
    const {
        postproduction: e
      } = i,
      o = e.n8ao.configuration;
    return f`
    <bim-color-input @input=${s => {
      const r = s.target;
      o.color = new R.Color(r.color);
    }} color=${t}></bim-color-input>
  `;
  },
  xd = (i, t) => {
    const {
        color: e,
        opacity: o
      } = JSON.parse(t),
      {
        postproduction: n
      } = i,
      {
        customEffects: s
      } = n;
    return f`
    <bim-color-input @input=${a => {
      const {
        color: l,
        opacity: c
      } = a.target;
      s.lineColor = new R.Color(l).getHex(), c && (s.opacity = c / 100);
    }} color=${e} opacity=${o * 100}></bim-color-input>
  `;
  },
  Cd = (i, t) => f`
    <bim-color-input @input=${o => {
    const n = o.target,
      s = new R.Color(n.color);
    i.material.uniforms.uColor.value = s;
  }} color=${t}></bim-color-input>
  `,
  Ad = (i, t) => {
    const {
      postproduction: e
    } = i;
    return f`
    <bim-checkbox @change=${n => {
      const s = n.target;
      e.setPasses({
        ao: s.checked
      });
    }} .checked=${t}></bim-checkbox>
  `;
  },
  Ed = (i, t) => {
    const {
      postproduction: e
    } = i;
    return f`
    <bim-checkbox @change=${n => {
      const s = n.target;
      e.setPasses({
        gamma: s.checked
      });
    }} .checked=${t}></bim-checkbox>
  `;
  },
  Sd = (i, t) => {
    const {
      postproduction: e
    } = i;
    return f`
    <bim-checkbox @change=${n => {
      const s = n.target;
      e.setPasses({
        custom: s.checked
      });
    }} .checked=${t}></bim-checkbox>
  `;
  },
  $t = (i, t, e, o = () => {}) => f`
    <bim-checkbox .checked="${e}" @change="${s => {
    const a = s.target.checked;
    i[t] = a, o(a);
  }}"></bim-checkbox> 
  `,
  D = (i, t, e, o) => {
    const n = {
        slider: !1,
        min: 0,
        max: 100,
        step: 1,
        prefix: null,
        suffix: null,
        onInputSet: () => {},
        ...o
      },
      {
        slider: s,
        min: r,
        max: a,
        step: l,
        suffix: c,
        prefix: h,
        onInputSet: u
      } = n;
    return f`
    <bim-number-input
      .pref=${h}
      .suffix=${c}
      .slider=${s} 
      min=${r} 
      value="${e}" 
      max=${a} 
      step=${l} 
      @change="${d => {
      const y = d.target.value;
      i[t] = y, u(y);
    }}"
    ></bim-number-input> 
  `;
  },
  kd = i => {
    const {
        components: t
      } = i,
      e = t.get(A.Worlds);
    return f`
    <bim-table @cellcreated=${({
      detail: s
    }) => {
      const r = s.cell.parentNode;
      if (!r) return;
      const a = r.querySelector("bim-table-cell[column='Name']"),
        l = r.querySelector("bim-table-cell[column='Value']");
      a && !l && (a.style.gridColumn = "1 / -1");
    }} ${K(async s => {
      var l, c, h, u, p;
      if (!s) return;
      const r = s;
      r.preserveStructureOnFilter = !0, r.dataTransform = {
        Value: (d, m) => {
          const y = m.World,
            _ = e.list.get(y);
          if (!_) return d;
          const {
              scene: $,
              camera: w,
              renderer: b
            } = _,
            v = m.Name;
          if (v === "Grid" && m.IsGridConfig && typeof d == "boolean") {
            const C = Ce(t, _);
            return C ? $t(C, "visible", d) : d;
          }
          if (v === "Color" && m.IsGridConfig && typeof d == "string") {
            const C = Ce(t, _);
            return C ? Cd(C, d) : d;
          }
          if (v === "Distance" && m.IsGridConfig && typeof d == "number") {
            const C = Ce(t, _);
            return C ? D(C.material.uniforms.uDistance, "value", d, {
              slider: !0,
              min: 300,
              max: 1e3
            }) : d;
          }
          if (v === "Size" && m.IsGridConfig && typeof d == "string") {
            const C = Ce(t, _);
            if (!C) return d;
            const {
                x,
                y: E
              } = JSON.parse(d),
              M = D(C.material.uniforms.uSize1, "value", x, {
                slider: !0,
                suffix: "m",
                prefix: "A",
                min: 1,
                max: 20
              }),
              L = D(C.material.uniforms.uSize2, "value", E, {
                slider: !0,
                suffix: "m",
                prefix: "B",
                min: 1,
                max: 20
              });
            return f`
            <div style="display: flex; gap: 0.25rem; width: 100%; flex-wrap: wrap">${M}${L}</div>
          `;
          }
          if (v === "Near Frustum" && w.three instanceof R.PerspectiveCamera && typeof d == "number") {
            const C = w.three;
            return D(w.three, "near", d, {
              slider: !0,
              min: 0.1,
              max: 10,
              step: 0.1,
              onInputSet: () => C.updateProjectionMatrix()
            });
          }
          if (v === "Far Frustum" && w.three instanceof R.PerspectiveCamera && typeof d == "number") {
            const C = w.three;
            return D(w.three, "far", d, {
              slider: !0,
              min: 300,
              max: 2e3,
              step: 10,
              onInputSet: () => C.updateProjectionMatrix()
            });
          }
          if (v === "Field of View" && w.three instanceof R.PerspectiveCamera && typeof d == "number") {
            const C = w.three;
            return D(w.three, "fov", d, {
              slider: !0,
              min: 10,
              max: 120,
              onInputSet: () => C.updateProjectionMatrix()
            });
          }
          if (v === "Invert Drag" && w.hasCameraControls() && typeof d == "boolean") return $t(w.controls, "dollyDragInverted", d);
          if (v === "Dolly Speed" && w.hasCameraControls() && typeof d == "number") return D(w.controls, "dollySpeed", d, {
            slider: !0,
            min: 0.5,
            max: 3,
            step: 0.1
          });
          if (v === "Truck Speed" && w.hasCameraControls() && typeof d == "number") return D(w.controls, "truckSpeed", d, {
            slider: !0,
            min: 0.5,
            max: 6,
            step: 0.1
          });
          if (v === "Smooth Time" && w.hasCameraControls() && typeof d == "number") return D(w.controls, "smoothTime", d, {
            slider: !0,
            min: 0.01,
            max: 2,
            step: 0.01
          });
          if (v === "Intensity" && typeof d == "number") {
            if (m.Light && typeof m.Light == "string") {
              const C = $.three.children.find(x => x.uuid === m.Light);
              return C && C instanceof R.Light ? D(C, "intensity", d, {
                slider: !0,
                min: 0,
                max: 10,
                step: 0.1
              }) : d;
            }
            if (m.IsAOConfig && b instanceof j.PostproductionRenderer) return D(b.postproduction.n8ao.configuration, "intensity", d, {
              slider: !0,
              max: 16,
              step: 0.1
            });
          }
          if (v === "Color" && typeof d == "string") {
            const C = m.Light,
              x = $.three.children.find(E => E.uuid === C);
            if (x && x instanceof R.Light) return wd(x, d);
            if (m.IsAOConfig && b instanceof j.PostproductionRenderer) return $d(b, d);
          }
          if (v === "Ambient Oclussion" && typeof d == "boolean" && m.IsAOConfig && b instanceof j.PostproductionRenderer) return Ad(b, d);
          if (v === "Half Resolution" && m.IsAOConfig && b instanceof j.PostproductionRenderer && typeof d == "boolean") return $t(b.postproduction.n8ao.configuration, "halfRes", d);
          if (v === "Screen Space Radius" && m.IsAOConfig && b instanceof j.PostproductionRenderer && typeof d == "boolean") return $t(b.postproduction.n8ao.configuration, "screenSpaceRadius", d);
          if (v === "Radius" && m.IsAOConfig && b instanceof j.PostproductionRenderer && typeof d == "number") return D(b.postproduction.n8ao.configuration, "aoRadius", d, {
            slider: !0,
            max: 2,
            step: 0.1
          });
          if (v === "Denoise Samples" && m.IsAOConfig && b instanceof j.PostproductionRenderer && typeof d == "number") return D(b.postproduction.n8ao.configuration, "denoiseSamples", d, {
            slider: !0,
            min: 1,
            max: 16
          });
          if (v === "Samples" && m.IsAOConfig && b instanceof j.PostproductionRenderer && typeof d == "number") return D(b.postproduction.n8ao.configuration, "aoSamples", d, {
            slider: !0,
            min: 1,
            max: 16
          });
          if (v === "Denoise Radius" && m.IsAOConfig && b instanceof j.PostproductionRenderer && typeof d == "number") return D(b.postproduction.n8ao.configuration, "denoiseRadius", d, {
            slider: !0,
            min: 0,
            max: 16,
            step: 0.1
          });
          if (v === "Distance Falloff" && m.IsAOConfig && b instanceof j.PostproductionRenderer && typeof d == "number") return D(b.postproduction.n8ao.configuration, "distanceFalloff", d, {
            slider: !0,
            min: 0,
            max: 4,
            step: 0.1
          });
          if (v === "Directional Light" && m.Light && typeof m.Light == "string" && typeof d == "boolean") {
            const C = $.three.children.find(x => x.uuid === m.Light);
            return C && C instanceof R.Light ? $t(C, "visible", d) : d;
          }
          if (v === "Ambient Light" && m.Light && typeof m.Light == "string" && typeof d == "boolean") {
            const C = $.three.children.find(x => x.uuid === m.Light);
            return C && C instanceof R.Light ? $t(C, "visible", d) : d;
          }
          if (v === "Position" && m.Light && typeof m.Light == "string" && typeof d == "string") {
            const C = $.three.children.find(Y => Y.uuid === m.Light);
            if (!(C && C instanceof R.Light)) return d;
            const {
                x,
                y: E,
                z: M
              } = JSON.parse(d),
              L = D(C.position, "x", x, {
                slider: !0,
                prefix: "X",
                suffix: "m",
                min: -50,
                max: 50
              }),
              k = D(C.position, "y", E, {
                slider: !0,
                prefix: "Y",
                suffix: "m",
                min: -50,
                max: 50
              }),
              P = D(C.position, "z", M, {
                slider: !0,
                prefix: "Z",
                suffix: "m",
                min: -50,
                max: 50
              });
            return f`
            <div style="display: flex; gap: 0.25rem; width: 100%; flex-wrap: wrap">${L}${k}${P}</div>
          `;
          }
          return v === "Custom Effects" && m.IsCEConfig && b instanceof j.PostproductionRenderer && typeof d == "boolean" ? Sd(b, d) : v === "Color" && m.IsOutlineConfig && b instanceof j.PostproductionRenderer && typeof d == "string" ? xd(b, d) : v === "Tolerance" && m.IsOutlineConfig && b instanceof j.PostproductionRenderer && typeof d == "number" ? D(b.postproduction.customEffects, "tolerance", d, {
            slider: !0,
            min: 0,
            max: 6,
            step: 0.01
          }) : v === "Outline" && m.IsOutlineConfig && b instanceof j.PostproductionRenderer && typeof d == "boolean" ? $t(b.postproduction.customEffects, "outlineEnabled", d) : v === "Gloss" && m.IsGlossConfig && b instanceof j.PostproductionRenderer && typeof d == "boolean" ? $t(b.postproduction.customEffects, "glossEnabled", d) : v === "Min" && m.IsGlossConfig && b instanceof j.PostproductionRenderer && typeof d == "number" ? D(b.postproduction.customEffects, "minGloss", d, {
            slider: !0,
            min: -0.5,
            max: 0.5,
            step: 0.01
          }) : v === "Max" && m.IsGlossConfig && b instanceof j.PostproductionRenderer && typeof d == "number" ? D(b.postproduction.customEffects, "maxGloss", d, {
            slider: !0,
            min: -0.5,
            max: 0.5,
            step: 0.01
          }) : v === "Exponent" && m.IsGlossConfig && b instanceof j.PostproductionRenderer && typeof d == "number" ? D(b.postproduction.customEffects, "glossExponent", d, {
            slider: !0,
            min: 0,
            max: 5,
            step: 0.01
          }) : v === "Gamma Correction" && m.IsGammaConfig && b instanceof j.PostproductionRenderer && typeof d == "boolean" ? Ed(b, d) : d;
        }
      };
      const a = [];
      for (const [, d] of e.list) {
        const {
            scene: m,
            camera: y,
            renderer: _
          } = d,
          $ = Ce(t, d),
          w = {
            data: {
              Name: d instanceof A.SimpleWorld && d.name || d.uuid
            },
            children: []
          };
        if (m) {
          const b = {
            data: {
              Name: "Scene"
            }
          };
          if ($) {
            const E = `#${$.material.uniforms.uColor.value.getHexString()}`,
              M = JSON.stringify({
                x: $.material.uniforms.uSize1.value,
                y: $.material.uniforms.uSize2.value
              }),
              L = {
                data: {
                  Name: "Grid",
                  Value: $.three.visible,
                  World: d.uuid,
                  IsGridConfig: !0
                },
                children: [{
                  data: {
                    Name: "Color",
                    Value: E,
                    World: d.uuid,
                    IsGridConfig: !0
                  }
                }, {
                  data: {
                    Name: "Size",
                    Value: M,
                    World: d.uuid,
                    IsGridConfig: !0
                  }
                }, {
                  data: {
                    Name: "Distance",
                    Value: $.material.uniforms.uDistance.value,
                    World: d.uuid,
                    IsGridConfig: !0
                  }
                }]
              };
            b.children || (b.children = []), b.children.push(L);
          }
          const v = m.three.children.filter(x => x instanceof R.DirectionalLight);
          for (const x of v) {
            const E = {
              data: {
                Name: "Directional Light",
                Value: x.visible,
                World: d.uuid,
                Light: x.uuid
              },
              children: [{
                data: {
                  Name: "Position",
                  Value: JSON.stringify(x.position),
                  World: d.uuid,
                  Light: x.uuid
                }
              }, {
                data: {
                  Name: "Intensity",
                  Value: x.intensity,
                  World: d.uuid,
                  Light: x.uuid
                }
              }, {
                data: {
                  Name: "Color",
                  Value: `#${x.color.getHexString()}`,
                  World: d.uuid,
                  Light: x.uuid
                }
              }]
            };
            b.children || (b.children = []), b.children.push(E);
          }
          const C = m.three.children.filter(x => x instanceof R.AmbientLight);
          for (const x of C) {
            const E = {
              data: {
                Name: "Ambient Light",
                Value: x.visible,
                World: d.uuid,
                Light: x.uuid
              },
              children: [{
                data: {
                  Name: "Intensity",
                  Value: x.intensity,
                  World: d.uuid,
                  Light: x.uuid
                }
              }, {
                data: {
                  Name: "Color",
                  Value: `#${x.color.getHexString()}`,
                  World: d.uuid,
                  Light: x.uuid
                }
              }]
            };
            b.children || (b.children = []), b.children.push(E);
          }
          b.children && ((l = b.children) == null ? void 0 : l.length) > 0 && ((c = w.children) == null || c.push(b));
        }
        if (y.three instanceof R.PerspectiveCamera) {
          const b = {
            data: {
              Name: "Perspective Camera"
            },
            children: [{
              data: {
                Name: "Near Frustum",
                Value: y.three.near,
                World: d.uuid
              }
            }, {
              data: {
                Name: "Far Frustum",
                Value: y.three.far,
                World: d.uuid
              }
            }, {
              data: {
                Name: "Field of View",
                Value: y.three.fov,
                World: d.uuid
              }
            }]
          };
          if (y.hasCameraControls()) {
            const {
                controls: v
              } = y,
              C = {
                dollyDragInverted: "Invert Drag",
                dollySpeed: "Dolly Speed",
                truckSpeed: "Truck Speed",
                smoothTime: "Smooth Time"
              };
            for (const x in C) {
              const E = v[x];
              E != null && ((h = b.children) == null || h.push({
                data: {
                  Name: C[x],
                  Value: E,
                  World: d.uuid
                }
              }));
            }
          }
          (u = w.children) == null || u.push(b);
        }
        if (_ instanceof j.PostproductionRenderer) {
          const {
              postproduction: b
            } = _,
            v = b.n8ao.configuration,
            C = {
              data: {
                Name: "Renderer"
              },
              children: [{
                data: {
                  Name: "Gamma Correction",
                  Value: b.settings.gamma ?? !1,
                  World: d.uuid,
                  IsGammaConfig: !0
                }
              }, {
                data: {
                  Name: "Ambient Oclussion",
                  Value: b.settings.ao ?? !1,
                  World: d.uuid,
                  IsAOConfig: !0
                },
                children: [{
                  data: {
                    Name: "Samples",
                    Value: v.aoSamples,
                    World: d.uuid,
                    IsAOConfig: !0
                  }
                }, {
                  data: {
                    Name: "Color",
                    Value: `#${v.color.getHexString()}`,
                    World: d.uuid,
                    IsAOConfig: !0
                  }
                }, {
                  data: {
                    Name: "Half Resolution",
                    Value: v.halfRes,
                    World: d.uuid,
                    IsAOConfig: !0
                  }
                }, {
                  data: {
                    Name: "Screen Space Radius",
                    Value: v.screenSpaceRadius,
                    World: d.uuid,
                    IsAOConfig: !0
                  }
                }, {
                  data: {
                    Name: "Radius",
                    Value: v.aoRadius,
                    World: d.uuid,
                    IsAOConfig: !0
                  }
                }, {
                  data: {
                    Name: "Intensity",
                    Value: v.intensity,
                    World: d.uuid,
                    IsAOConfig: !0
                  }
                }, {
                  data: {
                    Name: "Distance Falloff",
                    Value: v.distanceFalloff,
                    World: d.uuid,
                    IsAOConfig: !0
                  }
                }, {
                  data: {
                    Name: "Denoise Samples",
                    Value: v.denoiseSamples,
                    World: d.uuid,
                    IsAOConfig: !0
                  }
                }, {
                  data: {
                    Name: "Denoise Radius",
                    Value: v.denoiseRadius,
                    World: d.uuid,
                    IsAOConfig: !0
                  }
                }]
              }, {
                data: {
                  Name: "Custom Effects",
                  Value: b.settings.custom ?? !1,
                  World: d.uuid,
                  IsCEConfig: !0
                },
                children: [{
                  data: {
                    Name: "Gloss",
                    Value: b.customEffects.glossEnabled,
                    World: d.uuid,
                    IsGlossConfig: !0
                  },
                  children: [{
                    data: {
                      Name: "Min",
                      Value: b.customEffects.minGloss,
                      World: d.uuid,
                      IsGlossConfig: !0
                    }
                  }, {
                    data: {
                      Name: "Max",
                      Value: b.customEffects.maxGloss,
                      World: d.uuid,
                      IsGlossConfig: !0
                    }
                  }, {
                    data: {
                      Name: "Exponent",
                      Value: b.customEffects.glossExponent,
                      World: d.uuid,
                      IsGlossConfig: !0
                    }
                  }]
                }, {
                  data: {
                    Name: "Outline",
                    Value: b.customEffects.outlineEnabled,
                    World: d.uuid,
                    IsOutlineConfig: !0
                  },
                  children: [{
                    data: {
                      Name: "Color",
                      get Value() {
                        const x = new R.Color(b.customEffects.lineColor),
                          E = b.customEffects.opacity;
                        return JSON.stringify({
                          color: `#${x.getHexString()}`,
                          opacity: E
                        });
                      },
                      World: d.uuid,
                      IsOutlineConfig: !0
                    }
                  }, {
                    data: {
                      Name: "Tolerance",
                      Value: b.customEffects.tolerance,
                      World: d.uuid,
                      IsOutlineConfig: !0
                    }
                  }]
                }]
              }]
            };
          (p = w.children) == null || p.push(C);
        }
        a.push(w);
      }
      r.columns = [{
        name: "Name",
        width: "11rem"
      }], r.hiddenColumns = ["World", "Light", "IsAOConfig", "IsCEConfig", "IsGlossConfig", "IsOutlineConfig", "IsGammaConfig", "IsGridConfig"], r.data = a;
    })} headers-hidden expanded>
     <bim-label slot="missing-data" style="--bim-icon--c: gold" icon="ic:round-warning">
        No worlds to configure
      </bim-label>
    </bim-table>
  `;
  },
  Td = (i, t = !0) => {
    const e = U.create(kd, i);
    if (t) {
      const [, o] = e,
        {
          components: n
        } = i;
      n.get(A.Worlds).list.onItemDeleted.add(() => o());
    }
    return e;
  },
  Id = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    worldsConfiguration: Td
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  te = (i, t) => {
    const e = t[i],
      o = (e == null ? void 0 : e.name) ?? i,
      n = o.trim().split(/\s+/);
    let s, r;
    return n[0] && n[0][0] && (s = n[0][0].toUpperCase(), n[0][1] && (r = n[0][1].toUpperCase())), n[1] && n[1][0] && (r = n[1][0].toUpperCase()), f`
    <div style="display: flex; gap: 0.25rem; overflow: hidden;">
      ${!(e != null && e.picture) && (s || r) ? f`
        <bim-label
          style=${Bt({
      borderRadius: "999px",
      padding: "0.375rem",
      backgroundColor: "var(--bim-ui_bg-contrast-20)",
      aspectRatio: "1",
      fontSize: "0.7rem"
    })}>${s}${r}</bim-label>
        ` : null}
      <bim-label .img=${e == null ? void 0 : e.picture}>${o}</bim-label>
    </div>
  `;
  },
  tt = {
    users: {
      "jhon.doe@example.com": {
        name: "Jhon Doe"
      }
    },
    priorities: {
      "On hold": {
        icon: "flowbite:circle-pause-outline",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#767676"
        }
      },
      Minor: {
        icon: "mingcute:arrows-down-fill",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#4CAF50"
        }
      },
      Normal: {
        icon: "fa6-solid:grip-lines",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#FB8C00"
        }
      },
      Major: {
        icon: "mingcute:arrows-up-fill",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#FF5252"
        }
      },
      Critical: {
        icon: "ph:warning",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#FB8C00"
        }
      }
    },
    statuses: {
      Active: {
        icon: "prime:circle-fill",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)"
        }
      },
      "In Progress": {
        icon: "prime:circle-fill",
        style: {
          backgroundColor: "#fa89004d",
          "--bim-label--c": "#FB8C00",
          "--bim-icon--c": "#FB8C00"
        }
      },
      "In Review": {
        icon: "prime:circle-fill",
        style: {
          backgroundColor: "#9c6bff4d",
          "--bim-label--c": "#9D6BFF",
          "--bim-icon--c": "#9D6BFF"
        }
      },
      Done: {
        icon: "prime:circle-fill",
        style: {
          backgroundColor: "#4CAF504D",
          "--bim-label--c": "#4CAF50",
          "--bim-icon--c": "#4CAF50"
        }
      },
      Closed: {
        icon: "prime:circle-fill",
        style: {
          backgroundColor: "#414141",
          "--bim-label--c": "#727272",
          "--bim-icon--c": "#727272"
        }
      }
    },
    types: {
      Clash: {
        icon: "gg:close-r",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#FB8C00"
        }
      },
      Issue: {
        icon: "mdi:bug-outline",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#FF5252"
        }
      },
      Failure: {
        icon: "mdi:bug-outline",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#FF5252"
        }
      },
      Inquiry: {
        icon: "majesticons:comment-line",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#FF5252"
        }
      },
      Fault: {
        icon: "ph:warning",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#FF5252"
        }
      },
      Remark: {
        icon: "ph:note-blank-bold",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#FB8C00"
        }
      },
      Request: {
        icon: "mynaui:edit-one",
        style: {
          backgroundColor: "var(--bim-ui_bg-contrast-20)",
          "--bim-icon--c": "#9D6BFF"
        }
      }
    }
  },
  ee = {
    padding: "0.25rem 0.5rem",
    borderRadius: "999px",
    "--bim-label--c": "var(--bim-ui_bg-contrast-100)"
  };
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const Ie = globalThis,
  vi = Ie.trustedTypes,
  To = vi ? vi.createPolicy("lit-html", {
    createHTML: i => i
  }) : void 0,
  _r = "$lit$",
  Ct = `lit$${Math.random().toFixed(9).slice(2)}$`,
  wr = "?" + Ct,
  Od = `<${wr}>`,
  Gt = document,
  Be = () => Gt.createComment(""),
  He = i => i === null || typeof i != "object" && typeof i != "function",
  Pn = Array.isArray,
  Rd = i => Pn(i) || typeof (i == null ? void 0 : i[Symbol.iterator]) == "function",
  Yi = `[ 	
\f\r]`,
  Ae = /<(?:(!--|\/[^a-zA-Z])|(\/?[a-zA-Z][^>\s]*)|(\/?$))/g,
  Io = /-->/g,
  Oo = />/g,
  Dt = RegExp(`>|${Yi}(?:([^\\s"'>=/]+)(${Yi}*=${Yi}*(?:[^ 	
\f\r"'\`<>=]|("|')|))|$)`, "g"),
  Ro = /'/g,
  Po = /"/g,
  $r = /^(?:script|style|textarea|title)$/i,
  Pd = i => (t, ...e) => ({
    _$litType$: i,
    strings: t,
    values: e
  }),
  Nn = Pd(1),
  de = Symbol.for("lit-noChange"),
  B = Symbol.for("lit-nothing"),
  No = /* @__PURE__ */new WeakMap(),
  zt = Gt.createTreeWalker(Gt, 129);
function xr(i, t) {
  if (!Pn(i) || !i.hasOwnProperty("raw")) throw Error("invalid template strings array");
  return To !== void 0 ? To.createHTML(t) : t;
}
const Nd = (i, t) => {
  const e = i.length - 1,
    o = [];
  let n,
    s = t === 2 ? "<svg>" : t === 3 ? "<math>" : "",
    r = Ae;
  for (let a = 0; a < e; a++) {
    const l = i[a];
    let c,
      h,
      u = -1,
      p = 0;
    for (; p < l.length && (r.lastIndex = p, h = r.exec(l), h !== null);) p = r.lastIndex, r === Ae ? h[1] === "!--" ? r = Io : h[1] !== void 0 ? r = Oo : h[2] !== void 0 ? ($r.test(h[2]) && (n = RegExp("</" + h[2], "g")), r = Dt) : h[3] !== void 0 && (r = Dt) : r === Dt ? h[0] === ">" ? (r = n ?? Ae, u = -1) : h[1] === void 0 ? u = -2 : (u = r.lastIndex - h[2].length, c = h[1], r = h[3] === void 0 ? Dt : h[3] === '"' ? Po : Ro) : r === Po || r === Ro ? r = Dt : r === Io || r === Oo ? r = Ae : (r = Dt, n = void 0);
    const d = r === Dt && i[a + 1].startsWith("/>") ? " " : "";
    s += r === Ae ? l + Od : u >= 0 ? (o.push(c), l.slice(0, u) + _r + l.slice(u) + Ct + d) : l + Ct + (u === -2 ? a : d);
  }
  return [xr(i, s + (i[e] || "<?>") + (t === 2 ? "</svg>" : t === 3 ? "</math>" : "")), o];
};
class Ue {
  constructor({
    strings: t,
    _$litType$: e
  }, o) {
    let n;
    this.parts = [];
    let s = 0,
      r = 0;
    const a = t.length - 1,
      l = this.parts,
      [c, h] = Nd(t, e);
    if (this.el = Ue.createElement(c, o), zt.currentNode = this.el.content, e === 2 || e === 3) {
      const u = this.el.content.firstChild;
      u.replaceWith(...u.childNodes);
    }
    for (; (n = zt.nextNode()) !== null && l.length < a;) {
      if (n.nodeType === 1) {
        if (n.hasAttributes()) for (const u of n.getAttributeNames()) if (u.endsWith(_r)) {
          const p = h[r++],
            d = n.getAttribute(u).split(Ct),
            m = /([.?@])?(.*)/.exec(p);
          l.push({
            type: 1,
            index: s,
            name: m[2],
            strings: d,
            ctor: m[1] === "." ? Ld : m[1] === "?" ? Dd : m[1] === "@" ? jd : Li
          }), n.removeAttribute(u);
        } else u.startsWith(Ct) && (l.push({
          type: 6,
          index: s
        }), n.removeAttribute(u));
        if ($r.test(n.tagName)) {
          const u = n.textContent.split(Ct),
            p = u.length - 1;
          if (p > 0) {
            n.textContent = vi ? vi.emptyScript : "";
            for (let d = 0; d < p; d++) n.append(u[d], Be()), zt.nextNode(), l.push({
              type: 2,
              index: ++s
            });
            n.append(u[p], Be());
          }
        }
      } else if (n.nodeType === 8) if (n.data === wr) l.push({
        type: 2,
        index: s
      });else {
        let u = -1;
        for (; (u = n.data.indexOf(Ct, u + 1)) !== -1;) l.push({
          type: 7,
          index: s
        }), u += Ct.length - 1;
      }
      s++;
    }
  }
  static createElement(t, e) {
    const o = Gt.createElement("template");
    return o.innerHTML = t, o;
  }
}
function ue(i, t, e = i, o) {
  var r, a;
  if (t === de) return t;
  let n = o !== void 0 ? (r = e._$Co) == null ? void 0 : r[o] : e._$Cl;
  const s = He(t) ? void 0 : t._$litDirective$;
  return (n == null ? void 0 : n.constructor) !== s && ((a = n == null ? void 0 : n._$AO) == null || a.call(n, !1), s === void 0 ? n = void 0 : (n = new s(i), n._$AT(i, e, o)), o !== void 0 ? (e._$Co ?? (e._$Co = []))[o] = n : e._$Cl = n), n !== void 0 && (t = ue(i, n._$AS(i, t.values), n, o)), t;
}
class Md {
  constructor(t, e) {
    this._$AV = [], this._$AN = void 0, this._$AD = t, this._$AM = e;
  }
  get parentNode() {
    return this._$AM.parentNode;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  u(t) {
    const {
        el: {
          content: e
        },
        parts: o
      } = this._$AD,
      n = ((t == null ? void 0 : t.creationScope) ?? Gt).importNode(e, !0);
    zt.currentNode = n;
    let s = zt.nextNode(),
      r = 0,
      a = 0,
      l = o[0];
    for (; l !== void 0;) {
      if (r === l.index) {
        let c;
        l.type === 2 ? c = new ei(s, s.nextSibling, this, t) : l.type === 1 ? c = new l.ctor(s, l.name, l.strings, this, t) : l.type === 6 && (c = new zd(s, this, t)), this._$AV.push(c), l = o[++a];
      }
      r !== (l == null ? void 0 : l.index) && (s = zt.nextNode(), r++);
    }
    return zt.currentNode = Gt, n;
  }
  p(t) {
    let e = 0;
    for (const o of this._$AV) o !== void 0 && (o.strings !== void 0 ? (o._$AI(t, o, e), e += o.strings.length - 2) : o._$AI(t[e])), e++;
  }
}
class ei {
  get _$AU() {
    var t;
    return ((t = this._$AM) == null ? void 0 : t._$AU) ?? this._$Cv;
  }
  constructor(t, e, o, n) {
    this.type = 2, this._$AH = B, this._$AN = void 0, this._$AA = t, this._$AB = e, this._$AM = o, this.options = n, this._$Cv = (n == null ? void 0 : n.isConnected) ?? !0;
  }
  get parentNode() {
    let t = this._$AA.parentNode;
    const e = this._$AM;
    return e !== void 0 && (t == null ? void 0 : t.nodeType) === 11 && (t = e.parentNode), t;
  }
  get startNode() {
    return this._$AA;
  }
  get endNode() {
    return this._$AB;
  }
  _$AI(t, e = this) {
    t = ue(this, t, e), He(t) ? t === B || t == null || t === "" ? (this._$AH !== B && this._$AR(), this._$AH = B) : t !== this._$AH && t !== de && this._(t) : t._$litType$ !== void 0 ? this.$(t) : t.nodeType !== void 0 ? this.T(t) : Rd(t) ? this.k(t) : this._(t);
  }
  O(t) {
    return this._$AA.parentNode.insertBefore(t, this._$AB);
  }
  T(t) {
    this._$AH !== t && (this._$AR(), this._$AH = this.O(t));
  }
  _(t) {
    this._$AH !== B && He(this._$AH) ? this._$AA.nextSibling.data = t : this.T(Gt.createTextNode(t)), this._$AH = t;
  }
  $(t) {
    var s;
    const {
        values: e,
        _$litType$: o
      } = t,
      n = typeof o == "number" ? this._$AC(t) : (o.el === void 0 && (o.el = Ue.createElement(xr(o.h, o.h[0]), this.options)), o);
    if (((s = this._$AH) == null ? void 0 : s._$AD) === n) this._$AH.p(e);else {
      const r = new Md(n, this),
        a = r.u(this.options);
      r.p(e), this.T(a), this._$AH = r;
    }
  }
  _$AC(t) {
    let e = No.get(t.strings);
    return e === void 0 && No.set(t.strings, e = new Ue(t)), e;
  }
  k(t) {
    Pn(this._$AH) || (this._$AH = [], this._$AR());
    const e = this._$AH;
    let o,
      n = 0;
    for (const s of t) n === e.length ? e.push(o = new ei(this.O(Be()), this.O(Be()), this, this.options)) : o = e[n], o._$AI(s), n++;
    n < e.length && (this._$AR(o && o._$AB.nextSibling, n), e.length = n);
  }
  _$AR(t = this._$AA.nextSibling, e) {
    var o;
    for ((o = this._$AP) == null ? void 0 : o.call(this, !1, !0, e); t && t !== this._$AB;) {
      const n = t.nextSibling;
      t.remove(), t = n;
    }
  }
  setConnected(t) {
    var e;
    this._$AM === void 0 && (this._$Cv = t, (e = this._$AP) == null || e.call(this, t));
  }
}
class Li {
  get tagName() {
    return this.element.tagName;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  constructor(t, e, o, n, s) {
    this.type = 1, this._$AH = B, this._$AN = void 0, this.element = t, this.name = e, this._$AM = n, this.options = s, o.length > 2 || o[0] !== "" || o[1] !== "" ? (this._$AH = Array(o.length - 1).fill(new String()), this.strings = o) : this._$AH = B;
  }
  _$AI(t, e = this, o, n) {
    const s = this.strings;
    let r = !1;
    if (s === void 0) t = ue(this, t, e, 0), r = !He(t) || t !== this._$AH && t !== de, r && (this._$AH = t);else {
      const a = t;
      let l, c;
      for (t = s[0], l = 0; l < s.length - 1; l++) c = ue(this, a[o + l], e, l), c === de && (c = this._$AH[l]), r || (r = !He(c) || c !== this._$AH[l]), c === B ? t = B : t !== B && (t += (c ?? "") + s[l + 1]), this._$AH[l] = c;
    }
    r && !n && this.j(t);
  }
  j(t) {
    t === B ? this.element.removeAttribute(this.name) : this.element.setAttribute(this.name, t ?? "");
  }
}
class Ld extends Li {
  constructor() {
    super(...arguments), this.type = 3;
  }
  j(t) {
    this.element[this.name] = t === B ? void 0 : t;
  }
}
class Dd extends Li {
  constructor() {
    super(...arguments), this.type = 4;
  }
  j(t) {
    this.element.toggleAttribute(this.name, !!t && t !== B);
  }
}
class jd extends Li {
  constructor(t, e, o, n, s) {
    super(t, e, o, n, s), this.type = 5;
  }
  _$AI(t, e = this) {
    if ((t = ue(this, t, e, 0) ?? B) === de) return;
    const o = this._$AH,
      n = t === B && o !== B || t.capture !== o.capture || t.once !== o.once || t.passive !== o.passive,
      s = t !== B && (o === B || n);
    n && this.element.removeEventListener(this.name, this, o), s && this.element.addEventListener(this.name, this, t), this._$AH = t;
  }
  handleEvent(t) {
    var e;
    typeof this._$AH == "function" ? this._$AH.call(((e = this.options) == null ? void 0 : e.host) ?? this.element, t) : this._$AH.handleEvent(t);
  }
}
class zd {
  constructor(t, e, o) {
    this.element = t, this.type = 6, this._$AN = void 0, this._$AM = e, this.options = o;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AI(t) {
    ue(this, t);
  }
}
const Xi = Ie.litHtmlPolyfillSupport;
Xi == null || Xi(Ue, ei), (Ie.litHtmlVersions ?? (Ie.litHtmlVersions = [])).push("3.2.1");
const Fd = (i, t, e) => {
  const o = (e == null ? void 0 : e.renderBefore) ?? t;
  let n = o._$litPart$;
  if (n === void 0) {
    const s = (e == null ? void 0 : e.renderBefore) ?? null;
    o._$litPart$ = n = new ei(t.insertBefore(Be(), s), s, void 0, e ?? {});
  }
  return n._$AI(i), n;
};
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const Bd = i => i.strings === void 0;
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const Hd = {
    ATTRIBUTE: 1,
    CHILD: 2,
    PROPERTY: 3,
    BOOLEAN_ATTRIBUTE: 4,
    EVENT: 5,
    ELEMENT: 6
  },
  Ud = i => (...t) => ({
    _$litDirective$: i,
    values: t
  });
let Vd = class {
  constructor(t) {}
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AT(t, e, o) {
    this._$Ct = t, this._$AM = e, this._$Ci = o;
  }
  _$AS(t, e) {
    return this.update(t, e);
  }
  update(t, e) {
    return this.render(...e);
  }
};
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const Oe = (i, t) => {
    var o;
    const e = i._$AN;
    if (e === void 0) return !1;
    for (const n of e) (o = n._$AO) == null || o.call(n, t, !1), Oe(n, t);
    return !0;
  },
  _i = i => {
    let t, e;
    do {
      if ((t = i._$AM) === void 0) break;
      e = t._$AN, e.delete(i), i = t;
    } while ((e == null ? void 0 : e.size) === 0);
  },
  Cr = i => {
    for (let t; t = i._$AM; i = t) {
      let e = t._$AN;
      if (e === void 0) t._$AN = e = /* @__PURE__ */new Set();else if (e.has(i)) break;
      e.add(i), qd(t);
    }
  };
function Wd(i) {
  this._$AN !== void 0 ? (_i(this), this._$AM = i, Cr(this)) : this._$AM = i;
}
function Gd(i, t = !1, e = 0) {
  const o = this._$AH,
    n = this._$AN;
  if (n !== void 0 && n.size !== 0) if (t) {
    if (Array.isArray(o)) for (let s = e; s < o.length; s++) Oe(o[s], !1), _i(o[s]);else o != null && (Oe(o, !1), _i(o));
  } else Oe(this, i);
}
const qd = i => {
  i.type == Hd.CHILD && (i._$AP ?? (i._$AP = Gd), i._$AQ ?? (i._$AQ = Wd));
};
let Yd = class extends Vd {
  constructor() {
    super(...arguments), this._$AN = void 0;
  }
  _$AT(t, e, o) {
    super._$AT(t, e, o), Cr(this), this.isConnected = t._$AU;
  }
  _$AO(t, e = !0) {
    var o, n;
    t !== this.isConnected && (this.isConnected = t, t ? (o = this.reconnected) == null || o.call(this) : (n = this.disconnected) == null || n.call(this)), e && (Oe(this, t), _i(this));
  }
  setValue(t) {
    if (Bd(this._$Ct)) this._$Ct._$AI(t, this);else {
      const e = [...this._$Ct._$AH];
      e[this._$Ci] = t, this._$Ct._$AI(e, this, 0);
    }
  }
  disconnected() {}
  reconnected() {}
};
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const bn = () => new Xd();
let Xd = class {};
const Ji = /* @__PURE__ */new WeakMap(),
  Jd = Ud(class extends Yd {
    render(i) {
      return B;
    }
    update(i, [t]) {
      var o;
      const e = t !== this.Y;
      return e && this.Y !== void 0 && this.rt(void 0), (e || this.lt !== this.ct) && (this.Y = t, this.ht = (o = i.options) == null ? void 0 : o.host, this.rt(this.ct = i.element)), B;
    }
    rt(i) {
      if (this.isConnected || (i = void 0), typeof this.Y == "function") {
        const t = this.ht ?? globalThis;
        let e = Ji.get(t);
        e === void 0 && (e = /* @__PURE__ */new WeakMap(), Ji.set(t, e)), e.get(this.Y) !== void 0 && this.Y.call(this.ht, void 0), e.set(this.Y, i), i !== void 0 && this.Y.call(this.ht, i);
      } else this.Y.value = i;
    }
    get lt() {
      var i, t;
      return typeof this.Y == "function" ? (i = Ji.get(this.ht ?? globalThis)) == null ? void 0 : i.get(this.Y) : (t = this.Y) == null ? void 0 : t.value;
    }
    disconnected() {
      this.lt === this.ct && this.rt(void 0);
    }
    reconnected() {
      this.rt(this.ct);
    }
  }),
  Qd = {
    dueDate: i => {
      if (typeof i == "string" && i.trim() !== "") return new Date(i);
    },
    status: i => {
      if (Array.isArray(i) && i.length !== 0) return i[0];
    },
    type: i => {
      if (Array.isArray(i) && i.length !== 0) return i[0];
    },
    priority: i => {
      if (Array.isArray(i) && i.length !== 0) return i[0];
    },
    stage: i => {
      if (Array.isArray(i) && i.length !== 0) return i[0];
    },
    assignedTo: i => {
      if (Array.isArray(i) && i.length !== 0) return i[0];
    },
    labels: i => {
      if (Array.isArray(i)) return new Set(i);
    }
  },
  Ar = i => {
    const {
        components: t,
        topic: e,
        value: o,
        onCancel: n,
        onSubmit: s,
        styles: r
      } = i,
      a = s ?? (() => {}),
      l = t.get(A.BCFTopics),
      c = (o == null ? void 0 : o.title) ?? (e == null ? void 0 : e.title) ?? A.Topic.default.title,
      h = (o == null ? void 0 : o.status) ?? (e == null ? void 0 : e.status) ?? A.Topic.default.status,
      u = (o == null ? void 0 : o.type) ?? (e == null ? void 0 : e.type) ?? A.Topic.default.type,
      p = (o == null ? void 0 : o.priority) ?? (e == null ? void 0 : e.priority) ?? A.Topic.default.priority,
      d = (o == null ? void 0 : o.assignedTo) ?? (e == null ? void 0 : e.assignedTo) ?? A.Topic.default.assignedTo,
      m = (o == null ? void 0 : o.labels) ?? (e == null ? void 0 : e.labels) ?? A.Topic.default.labels,
      y = (o == null ? void 0 : o.stage) ?? (e == null ? void 0 : e.stage) ?? A.Topic.default.stage,
      _ = (o == null ? void 0 : o.description) ?? (e == null ? void 0 : e.description) ?? A.Topic.default.description,
      $ = e != null && e.dueDate ? e.dueDate.toISOString().split("T")[0] : null,
      w = /* @__PURE__ */new Set([...l.config.statuses]);
    h && w.add(h);
    const b = /* @__PURE__ */new Set([...l.config.types]);
    u && b.add(u);
    const v = /* @__PURE__ */new Set([...l.config.priorities]);
    p && v.add(p);
    const C = /* @__PURE__ */new Set([...l.config.users]);
    d && C.add(d);
    const x = /* @__PURE__ */new Set([...l.config.labels]);
    if (m) for (const S of m) x.add(S);
    const E = /* @__PURE__ */new Set([...l.config.stages]);
    y && E.add(y);
    const M = bn(),
      L = async () => {
        const {
          value: S
        } = M;
        if (!S) return;
        const z = Fe(S, Qd);
        if (e) e.set(z), await a(e);else {
          const N = l.create(z);
          await a(N);
        }
      },
      k = bn(),
      P = S => {
        const {
          value: z
        } = k;
        if (!z) return;
        const N = S.target;
        z.disabled = N.value.trim() === "";
      },
      Y = `btn-${Q.newRandomId()}`,
      H = `btn-${Q.newRandomId()}`;
    return f`
    <div ${K(M)} style="display: flex; flex-direction: column; gap: 0.75rem;">
      <div style="display: flex; gap: 0.375rem">
        <bim-text-input @input=${P} vertical label="Title" name="title" .value=${c}></bim-text-input>
        ${e ? f`
            <bim-dropdown vertical label="Status" name="status" required>
              ${[...w].map(S => f`<bim-option label=${S} .checked=${h === S}></bim-option>`)}
            </bim-dropdown>` : f``}
      </div>
      <div style="display: flex; gap: 0.375rem">
        <bim-dropdown vertical label="Type" name="type" required>
          ${[...b].map(S => f`<bim-option label=${S} .checked=${u === S}></bim-option>`)}
        </bim-dropdown>
        <bim-dropdown vertical label="Priority" name="priority">
          ${[...v].map(S => f`<bim-option label=${S} .checked=${p === S}></bim-option>`)}
        </bim-dropdown>
      </div>
      <div style="display: flex; gap: 0.375rem">
        <bim-dropdown vertical label="Labels" name="labels" multiple>
          ${[...x].map(S => f`<bim-option label=${S} .checked=${m ? [...m].includes(S) : !1}></bim-option>`)}
        </bim-dropdown>
        <bim-dropdown vertical label="Assignee" name="assignedTo">
          ${[...C].map(S => {
      const z = r != null && r.users ? r.users[S] : null,
        N = z ? z.name : S,
        V = z == null ? void 0 : z.picture;
      return f`<bim-option label=${N} value=${S} .img=${V} .checked=${d === S}></bim-option>`;
    })}
        </bim-dropdown>
      </div>
      <div style="display: flex; gap: 0.375rem">
        <bim-text-input vertical type="date" label="Due Date" name="dueDate" .value=${$}></bim-text-input> 
        <bim-dropdown vertical label="Stage" name="stage">
          ${[...E].map(S => f`<bim-option label=${S} .checked=${y === S}></bim-option>`)}
        </bim-dropdown>
      </div>
      <bim-text-input vertical label="Description" name="description" type="area" .value=${_ ?? null}></bim-text-input>
      <div style="justify-content: right; display: flex; gap: 0.375rem">
        <style>
          #${H} {
            background-color: transparent;
          }

          #${H}:hover {
            --bim-label--c: #FF5252;
          }

          #${Y}:hover {
            background-color: #329936;
          }
        </style>
        <bim-button id=${H} style="flex: 0" @click=${n} label="Cancel"></bim-button>
        <bim-button id=${Y} style="flex: 0" @click=${L} ${K(k)} label=${e ? "Update Topic" : "Add Topic"} icon=${e ? "tabler:refresh" : "mi:add"}></bim-button>
      </div>
    </div>
  `;
  },
  Kd = i => {
    const {
        components: t,
        dataStyles: e,
        onTopicEnter: o
      } = i,
      n = t.get(A.BCFTopics),
      s = i.topics ?? n.list.values();
    return f`
    <bim-table no-indentation ${K(a => {
      if (!a) return;
      const l = a;
      l.hiddenColumns.length === 0 && (l.hiddenColumns = ["Guid"]), l.columns = ["Title"], l.dataTransform = {
        Title: (c, h) => {
          const {
            Guid: u
          } = h;
          if (typeof u != "string") return c;
          const p = n.list.get(u);
          if (!p) return c;
          const d = `btn-${Q.newRandomId()}`;
          return f`
          <div style="display: flex; overflow: hidden;">
            <style>
              #${d} {
                background-color: transparent
              }

              #${d}:hover {
                --bim-label--c: var(--bim-ui_accent-base)
              }
            </style>
            <bim-button @click=${() => {
            o && o(p);
          }} id=${d} icon="iconamoon:enter-duotone"></bim-button>
            <bim-label>${c}</bim-label>
          </div>
        `;
        },
        Priority: c => {
          if (typeof c != "string") return c;
          const u = ((e == null ? void 0 : e.priorities) ?? tt.priorities)[c];
          return f`
          <bim-label
            .icon=${u == null ? void 0 : u.icon}
            style=${Bt({
            ...ee,
            ...(u == null ? void 0 : u.style)
          })}
          >${c}
          </bim-label>
        `;
        },
        Status: c => {
          if (typeof c != "string") return c;
          const u = ((e == null ? void 0 : e.statuses) ?? tt.statuses)[c];
          return f`
          <bim-label
            .icon=${u == null ? void 0 : u.icon}
            style=${Bt({
            ...ee,
            ...(u == null ? void 0 : u.style)
          })}
          >${c}
          </bim-label>
        `;
        },
        Type: c => {
          if (typeof c != "string") return c;
          const u = ((e == null ? void 0 : e.types) ?? tt.types)[c];
          return f`
          <bim-label
            .icon=${u == null ? void 0 : u.icon}
            style=${Bt({
            ...ee,
            ...(u == null ? void 0 : u.style)
          })}
          >${c}
          </bim-label>
        `;
        },
        Author: c => typeof c != "string" ? c : te(c, (e == null ? void 0 : e.users) ?? tt.users),
        Assignee: c => typeof c != "string" ? c : te(c, (e == null ? void 0 : e.users) ?? tt.users)
      }, l.data = [...s].map(c => {
        var h;
        return {
          data: {
            Guid: c.guid,
            Title: c.title,
            Status: c.status,
            Description: c.description ?? "",
            Author: c.creationAuthor,
            Assignee: c.assignedTo ?? "",
            Date: c.creationDate.toDateString(),
            DueDate: ((h = c.dueDate) == null ? void 0 : h.toDateString()) ?? "",
            Type: c.type,
            Priority: c.priority ?? ""
          }
        };
      });
    })}>
      <bim-label slot="missing-data" icon="ph:warning-fill" style="--bim-icon--c: gold;">There are no topics to display</bim-label>
    </bim-table>
  `;
  },
  Er = (i, t = !0) => {
    const e = U.create(Kd, i);
    if (t) {
      const {
          components: o,
          topics: n
        } = i,
        [, s] = e,
        r = o.get(A.BCFTopics);
      if (r.list.onItemUpdated.add(() => s()), r.list.onItemDeleted.add(() => s()), n) for (const a of n) a.relatedTopics.onItemAdded.add(() => s()), a.relatedTopics.onItemDeleted.add(() => s()), a.relatedTopics.onCleared.add(() => s());else r.list.onItemSet.add(() => s());
    }
    return e;
  },
  Zd = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    topicsList: Er
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  tu = i => {
    const {
        topic: t,
        styles: e,
        viewpoint: o
      } = i,
      n = {
        delete: !0,
        ...i.actions
      };
    return f`
    <bim-table no-indentation ${K(r => {
      if (!r) return;
      const a = r;
      a.headersHidden = !0, a.hiddenColumns = ["guid", "author"], a.dataTransform = {
        Comment: (c, h) => {
          const {
            guid: u
          } = h;
          if (typeof u != "string") return c;
          const p = t.comments.get(u);
          if (!p) return c;
          const d = () => {
              t.comments.delete(u);
            },
            m = `btn-${Q.newRandomId()}`;
          return f`
          <div style="display: flex; flex-direction: column; gap: 0.25rem; flex: 1">
            <div style="display: flex; justify-content: space-between;">
              <div style="display: flex; gap: 0.375rem;">
                ${te(p.author, e ?? tt.users)}
                <bim-label style="color: var(--bim-ui_bg-contrast-40)">@ ${p.date.toDateString()}</bim-label>
              </div>
              <div>
                <style>
                  #${m} {
                    background-color: transparent;
                    --bim-label--c: var(--bim-ui_bg-contrast-60)
                  }

                  #${m}:hover {
                    --bim-label--c: #FF5252;
                  }
                </style>
                ${n != null && n.delete ? f`<bim-button @click=${d} id=${m} icon="majesticons:delete-bin"></bim-button>` : null}
              </div>
            </div>
            <bim-label style="margin-left: 1.7rem; white-space: normal">${p.comment}</bim-label>
          </div>
        `;
        }
      };
      let l = t.comments.values();
      o && (l = [...t.comments.values()].filter(c => c.viewpoint === o)), a.data = [...l].map(c => ({
        data: {
          guid: c.guid,
          Comment: c.comment,
          author: (() => {
            const h = e;
            if (!h) return c.author;
            const u = h[c.author];
            return (u == null ? void 0 : u.name) ?? c.author;
          })()
        }
      }));
    })}>
      <bim-label slot="missing-data" icon="ph:warning-fill" style="--bim-icon--c: gold;">This topic has no comments</bim-label>
    </bim-table>
  `;
  },
  Sr = (i, t = !0) => {
    const e = U.create(tu, i);
    if (t) {
      const {
          topic: o
        } = i,
        [n, s] = e;
      o.comments.onItemSet.add(() => s()), o.comments.onItemUpdated.add(() => s()), o.comments.onItemDeleted.add(() => s()), o.comments.onCleared.add(() => s());
    }
    return e;
  },
  eu = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    topicComments: Sr
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  iu = i => {
    var l;
    const {
        components: t,
        topic: e
      } = i,
      o = {
        selectComponents: !0,
        colorizeComponent: !0,
        resetColors: !0,
        updateCamera: !0,
        delete: !0,
        unlink: !!e,
        ...i.actions
      },
      n = t.get(A.Viewpoints),
      s = ((l = i.topic) == null ? void 0 : l.viewpoints) ?? n.list.keys(),
      r = [];
    for (const c of s) {
      const h = n.list.get(c);
      h && r.push(h);
    }
    return f`
    <bim-table no-indentation ${K(c => {
      if (!c) return;
      const h = c;
      h.addEventListener("cellcreated", ({
        detail: u
      }) => {
        const {
          cell: p
        } = u;
        p.style.padding = "0.25rem";
      }), h.headersHidden = !0, h.hiddenColumns = ["Guid"], h.columns = ["Title", {
        name: "Actions",
        width: "auto"
      }], h.dataTransform = {
        Actions: (u, p) => {
          const {
            Guid: d
          } = p;
          if (!(d && typeof d == "string")) return d || "";
          const m = n.list.get(d);
          return m ? f`
          <bim-button icon="ph:eye-fill" @click=${() => m.go()}></bim-button>
          ${Object.values(o).includes(!0) ? f`
                <bim-button icon="prime:ellipsis-v">
                  <bim-context-menu>
                    ${o.selectComponents ? f`<bim-button label="Select Components" @click=${() => console.log(m.selection)}></bim-button> ` : null}
                    ${o.colorizeComponent ? f`<bim-button label="Colorize Components" @click=${() => m.applyColors()}></bim-button> ` : null}
                    ${o.resetColors ? f`<bim-button label="Reset Colors" @click=${() => m.resetColors()}></bim-button> ` : null}
                    ${o.updateCamera ? f`<bim-button label="Update Camera" @click=${() => m.updateCamera()}></bim-button> ` : null}
                    ${o.unlink ? f`<bim-button .disabled=${!e} label="Unlink" @click=${() => e == null ? void 0 : e.viewpoints.delete(m.guid)}></bim-button> ` : null}
                    ${o.delete ? f`<bim-button label="Delete" @click=${() => n.list.delete(m.guid)}></bim-button>` : null}
                  </bim-context-menu>
                </bim-button>
              ` : null}
        ` : d;
        }
      }, h.data = r.map((u, p) => ({
        data: {
          Guid: u.guid,
          Title: u.title ?? `Viewpoint ${i.topic ? p + 1 : ""}`,
          Actions: ""
        }
      }));
    })}>
      <bim-label slot="missing-data" icon="ph:warning-fill" style="--bim-icon--c: gold;">No viewpoints to show</bim-label>
    </bim-table>
  `;
  },
  kr = (i, t = !0) => {
    const e = U.create(iu, i),
      {
        components: o,
        topic: n
      } = i;
    if (t) {
      const [, s] = e,
        r = o.get(A.Viewpoints);
      r.list.onItemUpdated.add(() => s()), r.list.onItemDeleted.add(() => s()), r.list.onCleared.add(() => s()), n ? (n.viewpoints.onItemAdded.add(() => s()), n.viewpoints.onItemDeleted.add(() => s()), n.viewpoints.onCleared.add(() => s())) : r.list.onItemSet.add(() => s());
    }
    return e;
  },
  nu = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    viewpointsList: kr
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  bh = {
    ...qc,
    ...Jc,
    ...Zc,
    ...fd,
    ..._d,
    ...Id,
    ...Zd,
    ...eu,
    ...nu
  },
  ou = i => f`
    <bim-panel-section fixed label="New Topic" name="topic">
      ${Ar(i)}
    </bim-panel-section>
  `,
  su = i => U.create(ou, i),
  ru = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    topic: su
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  gh = {
    ...ru
  },
  au = (i, t) => {
    const {
        components: e,
        editing: o,
        topic: n,
        styles: s
      } = i,
      r = {
        update: !0,
        ...i.actions
      },
      a = (s == null ? void 0 : s.priorities) ?? tt.priorities,
      l = (s == null ? void 0 : s.statuses) ?? tt.statuses,
      c = (s == null ? void 0 : s.types) ?? tt.types;
    let h;
    n != null && n.priority && (h = a[n.priority]);
    let u;
    n != null && n.type && (u = c[n.type]);
    let p;
    n != null && n.type && (p = l[n.status]);
    let d, m;
    return o ? d = Ar({
      components: e,
      topic: n,
      styles: s,
      onSubmit: () => {
        t({
          editing: !1
        });
      },
      onCancel: () => {
        t({
          editing: !1
        });
      }
    }) : m = f`
      <div>
        <bim-label>Title</bim-label>
        <bim-label style="--bim-label--c: var(--bim-ui_bg-contrast-100)">${n.title}</bim-label>
      </div>

      ${n.description ? f`
            <div>
              <bim-label>Description</bim-label>
              <bim-label style="--bim-label--c: var(--bim-ui_bg-contrast-100); white-space: normal">${n.description}</bim-label>
            </div>
          ` : null}

      <div style="display: flex; gap: 0.375rem">
        <bim-label>Status</bim-label>
        <bim-label .icon=${p == null ? void 0 : p.icon} style=${Bt({
      ...ee,
      ...(p == null ? void 0 : p.style)
    })}
        >${n.status}
        </bim-label>
      </div>

      <div style="display: flex; gap: 0.375rem">
        <bim-label>Type</bim-label>
        <bim-label .icon=${u == null ? void 0 : u.icon} style=${Bt({
      ...ee,
      ...(u == null ? void 0 : u.style)
    })}
        >${n.type}
        </bim-label>
      </div>

      ${n.priority ? f`
            <div style="display: flex; gap: 0.375rem">
              <bim-label>Priority</bim-label>
              <bim-label .icon=${h == null ? void 0 : h.icon} style=${Bt({
      ...ee,
      ...(h == null ? void 0 : h.style)
    })}
              >${n.priority}
              </bim-label>
            </div>` : null}

      <div style="display: flex; gap: 0.375rem">
        <bim-label>Author</bim-label>
        ${te(n.creationAuthor, (s == null ? void 0 : s.users) ?? tt.users)}
      </div>

      ${n.assignedTo ? f`
          <div style="display: flex; gap: 0.375rem">
            <bim-label>Assignee</bim-label>
            ${te(n.assignedTo, (s == null ? void 0 : s.users) ?? tt.users)}
          </div>` : null}

      ${n.dueDate ? f`
          <div style="display: flex; gap: 0.375rem">
            <bim-label>Due Date</bim-label>
            <bim-label style="--bim-label--c: var(--bim-ui_bg-contrast-100)">${n.dueDate.toDateString()}</bim-label>
          </div>` : null}

      ${n.modifiedAuthor ? f`
          <div style="display: flex; gap: 0.375rem">
            <bim-label>Modified By</bim-label>
            ${te(n.modifiedAuthor, (s == null ? void 0 : s.users) ?? tt.users)}
          </div>` : null}

      ${n.modifiedDate ? f`
            <div style="display: flex; gap: 0.375rem">
              <bim-label>Modified Date</bim-label>
              <bim-label style="--bim-label--c: var(--bim-ui_bg-contrast-100)">${n.modifiedDate.toDateString()}</bim-label>
            </div>` : null}

      ${n.labels.size !== 0 ? f`
          <div style="display: flex; gap: 0.375rem">
            <bim-label>Labels</bim-label>
            <bim-label style="--bim-label--c: var(--bim-ui_bg-contrast-100)">${[...n.labels].join(", ")}</bim-label>
          </div>` : null}

      ${r.update ? f`
              <bim-button @click=${() => t({
      editing: !0
    })} label="Update Information" icon="tabler:refresh"></bim-button> 
            ` : null}
    `, f`
    <div style="display: flex; flex-direction: column; gap: 0.75rem;">
      ${o ? d : m}
    </div>
  `;
  },
  lu = i => U.create(au, i),
  cu = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    topicInformation: lu
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  du = (i, t) => {
    const {
        showInput: e,
        topic: o,
        styles: n
      } = i,
      s = {
        add: !0,
        delete: !0,
        ...i.actions
      },
      r = `input-${Q.newRandomId()}`,
      a = `btn-${Q.newRandomId()}`,
      l = `btn-${Q.newRandomId()}`,
      c = () => document.getElementById(a),
      h = () => document.getElementById(r),
      u = () => {
        const v = h();
        return v ? v.value.trim().length > 0 : !1;
      },
      p = () => {
        t({
          showInput: !0
        });
      },
      d = () => {
        const v = h(),
          C = u();
        v && C && (o.createComment(v.value), t({
          showInput: !1
        }));
      },
      m = () => {
        t({
          showInput: !1
        });
      },
      y = () => {
        const v = c();
        if (!v) return;
        if (!h()) {
          v.disabled = !0;
          return;
        }
        v.disabled = !u();
      },
      _ = f`
    ${s.add ? f`<bim-button @click=${p} label="Add Comment" icon="majesticons:comment-line"></bim-button>` : null}
  `,
      w = f`
    <bim-text-input id=${r} @input=${y} @keypress=${v => {
        v.code === "Enter" && v.ctrlKey && d();
      }} type="area"></bim-text-input>

    <div style="justify-content: right; display: flex; gap: 0.375rem">
      <style>
        #${a} {
          background-color: #329936;
        }  

        #${l} {
          background-color: transparent;
        }

        #${l}:hover {
          --bim-label--c: #FF5252;
        }
      </style>

      <bim-button style="flex: 0" id=${l} @click=${m} label="Cancel"></bim-button>
      <bim-button style="flex: 0" id=${a} @click=${d} label="Accept" icon="material-symbols:check" disabled></bim-button>
    </div>
  `,
      [b] = Sr({
        topic: o,
        actions: s,
        styles: n ?? tt.users
      });
    return f`
    <div style="display: flex; flex-direction: column; gap: 0.5rem">
      ${b}
      ${e ? w : _}
    </div>
  `;
  },
  uu = i => U.create(du, i),
  hu = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    topicComments: uu
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  pu = (i, t) => {
    const {
        components: e,
        topic: o,
        linking: n
      } = i,
      s = e.get(A.BCFTopics),
      r = {
        link: !0,
        ...i.actions
      },
      [a, l] = Er({
        components: e,
        topics: [...o.relatedTopics].map(p => s.list.get(p)).map(p => p)
      });
    a.headersHidden = !0, a.hiddenColumns = ["Guid", "Status",
    // "Title",
    "Description", "Author", "Assignee", "Date", "DueDate", "Type", "Priority"];
    const c = () => f`
      <bim-text-input placeholder="Search..." debounce="100" @input=${d => {
      const m = d.target;
      m instanceof ct && (a.queryString = m.value);
    }}></bim-text-input> 
    `;
    let h, u;
    if (n) {
      a.selectableRows = !0, l({
        topics: void 0
      });
      const p = a.data.filter($ => {
        const {
          Guid: w
        } = $.data;
        return typeof w != "string" ? !1 : o.relatedTopics.has(w);
      }).map($ => $.data);
      a.selection = new Set(p);
      const d = () => {
          const $ = [...a.selection].map(({
            Guid: w
          }) => typeof w != "string" ? null : s.list.has(w) ? w : null).map(w => w);
          o.relatedTopics.clear(), o.relatedTopics.add(...$), t({
            linking: !1
          });
        },
        m = () => {
          t({
            linking: !1
          });
        },
        y = `btn-${Q.newRandomId()}`,
        _ = `btn-${Q.newRandomId()}`;
      h = f`
      <div style="display: flex; gap: 0.25rem">
        <style>
          #${y}:hover {
            background-color: #329936;
          }  

          #${_} {
            background-color: transparent;
          }

          #${_}:hover {
            --bim-label--c: #FF5252;
          }
        </style>
        ${c()}
        <div style="display: flex; justify-content: right; gap: 0.25rem">
          <bim-button id=${_} @click=${m} style="flex: 0" label="Cancel" icon="material-symbols:close"></bim-button>
          <bim-button id=${y} @click=${d} style="flex: 0" label="Accept" icon="material-symbols:check"></bim-button>
        </div>
      </div> 
    `;
    } else {
      a.selectableRows = !1;
      const p = () => {
        t({
          linking: !0
        });
      };
      u = f`
      <div style="display: flex; justify-content: right; gap: 0.25rem">
        ${c()}
        ${r.link ? f`<bim-button style="flex: 0" @click=${p} icon="tabler:link"></bim-button>` : null}
      </div> 
    `;
    }
    return f`
    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
      ${u}
      ${h}
      ${a}
    </div> 
  `;
  },
  mu = i => U.create(pu, i),
  fu = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    topicRelations: mu
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  bu = (i, t) => {
    const {
        components: e,
        topic: o,
        world: n,
        linking: s
      } = i,
      r = {
        add: !0,
        link: !0,
        selectComponents: !0,
        colorizeComponent: !0,
        resetColors: !0,
        updateCamera: !0,
        delete: !0,
        unlink: !0,
        ...i.actions
      },
      a = e.get(A.Viewpoints),
      [l, c] = kr({
        components: e,
        topic: o,
        actions: r
      }),
      h = () => f`
      <bim-text-input placeholder="Search..." debounce="100" @input=${m => {
        const y = m.target;
        y instanceof ct && (l.queryString = y.value);
      }}></bim-text-input> 
    `;
    let u, p;
    if (s) {
      l.selectableRows = !0, c({
        topic: void 0,
        actions: {
          delete: !1,
          updateCamera: !1,
          colorizeComponent: !1,
          resetColors: !1
        }
      });
      const d = l.data.filter(w => {
        const {
          Guid: b
        } = w.data;
        return typeof b != "string" ? !1 : o.viewpoints.has(b);
      }).map(w => w.data);
      l.selection = new Set(d);
      const m = () => {
          const w = [...l.selection].map(({
            Guid: b
          }) => typeof b != "string" ? null : a.list.has(b) ? b : null).map(b => b);
          o.viewpoints.clear(), o.viewpoints.add(...w), t({
            linking: !1
          });
        },
        y = () => {
          t({
            linking: !1
          });
        },
        _ = `btn-${Q.newRandomId()}`,
        $ = `btn-${Q.newRandomId()}`;
      u = f`
      <div style="display: flex; gap: 0.25rem">
        <style>
          #${_}:hover {
            background-color: #329936;
          }  

          #${$} {
            background-color: transparent;
          }

          #${$}:hover {
            --bim-label--c: #FF5252;
          }
        </style>
        ${h()}
        <div style="display: flex; justify-content: right; gap: 0.25rem">
          <bim-button id=${$} @click=${y} style="flex: 0" label="Cancel" icon="material-symbols:close"></bim-button>
          <bim-button id=${_} @click=${m} style="flex: 0" label="Accept" icon="material-symbols:check"></bim-button>
        </div>
      </div> 
    `;
    } else {
      l.selectableRows = !1, c({
        topic: o,
        actions: r
      });
      const d = () => {
          if (!(o && n && r.add && !s)) return;
          const $ = a.create(n);
          o.viewpoints.add($.guid);
        },
        m = () => {
          t({
            linking: !0
          });
        },
        y = f`<bim-button style="flex: 0" @click=${d} .disabled=${!n} icon="mi:add"></bim-button>`,
        _ = f`<bim-button style="flex: 0" @click=${m} icon="tabler:link"></bim-button>`;
      p = f`
      <div style="display: flex; justify-content: right; gap: 0.25rem">
        ${h()}
        <div style="display: flex; justify-content: right; gap: 0.25rem">
          ${r.add ? y : null}
          ${r.link ? _ : null}
        </div>
      </div> 
    `;
    }
    return f`
    <div style="display: flex; flex-direction: column; gap: 0.5rem;">
      ${p}
      ${u}
      ${l}
    </div> 
  `;
  },
  gu = i => U.create(bu, i),
  yu = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    topicViewpoints: gu
  }, Symbol.toStringTag, {
    value: "Module"
  })),
  yh = {
    // ...materials,
    ...cu,
    ...hu,
    ...fu,
    ...yu
  };
/**
 * @license
 * Copyright 2019 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const ci = globalThis,
  Mn = ci.ShadowRoot && (ci.ShadyCSS === void 0 || ci.ShadyCSS.nativeShadow) && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype,
  Ln = Symbol(),
  Mo = /* @__PURE__ */new WeakMap();
let Tr = class {
  constructor(t, e, o) {
    if (this._$cssResult$ = !0, o !== Ln) throw Error("CSSResult is not constructable. Use `unsafeCSS` or `css` instead.");
    this.cssText = t, this.t = e;
  }
  get styleSheet() {
    let t = this.o;
    const e = this.t;
    if (Mn && t === void 0) {
      const o = e !== void 0 && e.length === 1;
      o && (t = Mo.get(e)), t === void 0 && ((this.o = t = new CSSStyleSheet()).replaceSync(this.cssText), o && Mo.set(e, t));
    }
    return t;
  }
  toString() {
    return this.cssText;
  }
};
const vu = i => new Tr(typeof i == "string" ? i : i + "", void 0, Ln),
  Dn = (i, ...t) => {
    const e = i.length === 1 ? i[0] : t.reduce((o, n, s) => o + (r => {
      if (r._$cssResult$ === !0) return r.cssText;
      if (typeof r == "number") return r;
      throw Error("Value passed to 'css' function must be a 'css' function result: " + r + ". Use 'unsafeCSS' to pass non-literal values, but take care to ensure page security.");
    })(n) + i[s + 1], i[0]);
    return new Tr(e, i, Ln);
  },
  _u = (i, t) => {
    if (Mn) i.adoptedStyleSheets = t.map(e => e instanceof CSSStyleSheet ? e : e.styleSheet);else for (const e of t) {
      const o = document.createElement("style"),
        n = ci.litNonce;
      n !== void 0 && o.setAttribute("nonce", n), o.textContent = e.cssText, i.appendChild(o);
    }
  },
  Lo = Mn ? i => i : i => i instanceof CSSStyleSheet ? (t => {
    let e = "";
    for (const o of t.cssRules) e += o.cssText;
    return vu(e);
  })(i) : i;
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const {
    is: wu,
    defineProperty: $u,
    getOwnPropertyDescriptor: xu,
    getOwnPropertyNames: Cu,
    getOwnPropertySymbols: Au,
    getPrototypeOf: Eu
  } = Object,
  At = globalThis,
  Do = At.trustedTypes,
  Su = Do ? Do.emptyScript : "",
  Qi = At.reactiveElementPolyfillSupport,
  Re = (i, t) => i,
  wi = {
    toAttribute(i, t) {
      switch (t) {
        case Boolean:
          i = i ? Su : null;
          break;
        case Object:
        case Array:
          i = i == null ? i : JSON.stringify(i);
      }
      return i;
    },
    fromAttribute(i, t) {
      let e = i;
      switch (t) {
        case Boolean:
          e = i !== null;
          break;
        case Number:
          e = i === null ? null : Number(i);
          break;
        case Object:
        case Array:
          try {
            e = JSON.parse(i);
          } catch {
            e = null;
          }
      }
      return e;
    }
  },
  jn = (i, t) => !wu(i, t),
  jo = {
    attribute: !0,
    type: String,
    converter: wi,
    reflect: !1,
    hasChanged: jn
  };
Symbol.metadata ?? (Symbol.metadata = Symbol("metadata")), At.litPropertyMetadata ?? (At.litPropertyMetadata = /* @__PURE__ */new WeakMap());
class Kt extends HTMLElement {
  static addInitializer(t) {
    this._$Ei(), (this.l ?? (this.l = [])).push(t);
  }
  static get observedAttributes() {
    return this.finalize(), this._$Eh && [...this._$Eh.keys()];
  }
  static createProperty(t, e = jo) {
    if (e.state && (e.attribute = !1), this._$Ei(), this.elementProperties.set(t, e), !e.noAccessor) {
      const o = Symbol(),
        n = this.getPropertyDescriptor(t, o, e);
      n !== void 0 && $u(this.prototype, t, n);
    }
  }
  static getPropertyDescriptor(t, e, o) {
    const {
      get: n,
      set: s
    } = xu(this.prototype, t) ?? {
      get() {
        return this[e];
      },
      set(r) {
        this[e] = r;
      }
    };
    return {
      get() {
        return n == null ? void 0 : n.call(this);
      },
      set(r) {
        const a = n == null ? void 0 : n.call(this);
        s.call(this, r), this.requestUpdate(t, a, o);
      },
      configurable: !0,
      enumerable: !0
    };
  }
  static getPropertyOptions(t) {
    return this.elementProperties.get(t) ?? jo;
  }
  static _$Ei() {
    if (this.hasOwnProperty(Re("elementProperties"))) return;
    const t = Eu(this);
    t.finalize(), t.l !== void 0 && (this.l = [...t.l]), this.elementProperties = new Map(t.elementProperties);
  }
  static finalize() {
    if (this.hasOwnProperty(Re("finalized"))) return;
    if (this.finalized = !0, this._$Ei(), this.hasOwnProperty(Re("properties"))) {
      const e = this.properties,
        o = [...Cu(e), ...Au(e)];
      for (const n of o) this.createProperty(n, e[n]);
    }
    const t = this[Symbol.metadata];
    if (t !== null) {
      const e = litPropertyMetadata.get(t);
      if (e !== void 0) for (const [o, n] of e) this.elementProperties.set(o, n);
    }
    this._$Eh = /* @__PURE__ */new Map();
    for (const [e, o] of this.elementProperties) {
      const n = this._$Eu(e, o);
      n !== void 0 && this._$Eh.set(n, e);
    }
    this.elementStyles = this.finalizeStyles(this.styles);
  }
  static finalizeStyles(t) {
    const e = [];
    if (Array.isArray(t)) {
      const o = new Set(t.flat(1 / 0).reverse());
      for (const n of o) e.unshift(Lo(n));
    } else t !== void 0 && e.push(Lo(t));
    return e;
  }
  static _$Eu(t, e) {
    const o = e.attribute;
    return o === !1 ? void 0 : typeof o == "string" ? o : typeof t == "string" ? t.toLowerCase() : void 0;
  }
  constructor() {
    super(), this._$Ep = void 0, this.isUpdatePending = !1, this.hasUpdated = !1, this._$Em = null, this._$Ev();
  }
  _$Ev() {
    var t;
    this._$ES = new Promise(e => this.enableUpdating = e), this._$AL = /* @__PURE__ */new Map(), this._$E_(), this.requestUpdate(), (t = this.constructor.l) == null || t.forEach(e => e(this));
  }
  addController(t) {
    var e;
    (this._$EO ?? (this._$EO = /* @__PURE__ */new Set())).add(t), this.renderRoot !== void 0 && this.isConnected && ((e = t.hostConnected) == null || e.call(t));
  }
  removeController(t) {
    var e;
    (e = this._$EO) == null || e.delete(t);
  }
  _$E_() {
    const t = /* @__PURE__ */new Map(),
      e = this.constructor.elementProperties;
    for (const o of e.keys()) this.hasOwnProperty(o) && (t.set(o, this[o]), delete this[o]);
    t.size > 0 && (this._$Ep = t);
  }
  createRenderRoot() {
    const t = this.shadowRoot ?? this.attachShadow(this.constructor.shadowRootOptions);
    return _u(t, this.constructor.elementStyles), t;
  }
  connectedCallback() {
    var t;
    this.renderRoot ?? (this.renderRoot = this.createRenderRoot()), this.enableUpdating(!0), (t = this._$EO) == null || t.forEach(e => {
      var o;
      return (o = e.hostConnected) == null ? void 0 : o.call(e);
    });
  }
  enableUpdating(t) {}
  disconnectedCallback() {
    var t;
    (t = this._$EO) == null || t.forEach(e => {
      var o;
      return (o = e.hostDisconnected) == null ? void 0 : o.call(e);
    });
  }
  attributeChangedCallback(t, e, o) {
    this._$AK(t, o);
  }
  _$EC(t, e) {
    var s;
    const o = this.constructor.elementProperties.get(t),
      n = this.constructor._$Eu(t, o);
    if (n !== void 0 && o.reflect === !0) {
      const r = (((s = o.converter) == null ? void 0 : s.toAttribute) !== void 0 ? o.converter : wi).toAttribute(e, o.type);
      this._$Em = t, r == null ? this.removeAttribute(n) : this.setAttribute(n, r), this._$Em = null;
    }
  }
  _$AK(t, e) {
    var s;
    const o = this.constructor,
      n = o._$Eh.get(t);
    if (n !== void 0 && this._$Em !== n) {
      const r = o.getPropertyOptions(n),
        a = typeof r.converter == "function" ? {
          fromAttribute: r.converter
        } : ((s = r.converter) == null ? void 0 : s.fromAttribute) !== void 0 ? r.converter : wi;
      this._$Em = n, this[n] = a.fromAttribute(e, r.type), this._$Em = null;
    }
  }
  requestUpdate(t, e, o) {
    if (t !== void 0) {
      if (o ?? (o = this.constructor.getPropertyOptions(t)), !(o.hasChanged ?? jn)(this[t], e)) return;
      this.P(t, e, o);
    }
    this.isUpdatePending === !1 && (this._$ES = this._$ET());
  }
  P(t, e, o) {
    this._$AL.has(t) || this._$AL.set(t, e), o.reflect === !0 && this._$Em !== t && (this._$Ej ?? (this._$Ej = /* @__PURE__ */new Set())).add(t);
  }
  async _$ET() {
    this.isUpdatePending = !0;
    try {
      await this._$ES;
    } catch (e) {
      Promise.reject(e);
    }
    const t = this.scheduleUpdate();
    return t != null && (await t), !this.isUpdatePending;
  }
  scheduleUpdate() {
    return this.performUpdate();
  }
  performUpdate() {
    var o;
    if (!this.isUpdatePending) return;
    if (!this.hasUpdated) {
      if (this.renderRoot ?? (this.renderRoot = this.createRenderRoot()), this._$Ep) {
        for (const [s, r] of this._$Ep) this[s] = r;
        this._$Ep = void 0;
      }
      const n = this.constructor.elementProperties;
      if (n.size > 0) for (const [s, r] of n) r.wrapped !== !0 || this._$AL.has(s) || this[s] === void 0 || this.P(s, this[s], r);
    }
    let t = !1;
    const e = this._$AL;
    try {
      t = this.shouldUpdate(e), t ? (this.willUpdate(e), (o = this._$EO) == null || o.forEach(n => {
        var s;
        return (s = n.hostUpdate) == null ? void 0 : s.call(n);
      }), this.update(e)) : this._$EU();
    } catch (n) {
      throw t = !1, this._$EU(), n;
    }
    t && this._$AE(e);
  }
  willUpdate(t) {}
  _$AE(t) {
    var e;
    (e = this._$EO) == null || e.forEach(o => {
      var n;
      return (n = o.hostUpdated) == null ? void 0 : n.call(o);
    }), this.hasUpdated || (this.hasUpdated = !0, this.firstUpdated(t)), this.updated(t);
  }
  _$EU() {
    this._$AL = /* @__PURE__ */new Map(), this.isUpdatePending = !1;
  }
  get updateComplete() {
    return this.getUpdateComplete();
  }
  getUpdateComplete() {
    return this._$ES;
  }
  shouldUpdate(t) {
    return !0;
  }
  update(t) {
    this._$Ej && (this._$Ej = this._$Ej.forEach(e => this._$EC(e, this[e]))), this._$EU();
  }
  updated(t) {}
  firstUpdated(t) {}
}
Kt.elementStyles = [], Kt.shadowRootOptions = {
  mode: "open"
}, Kt[Re("elementProperties")] = /* @__PURE__ */new Map(), Kt[Re("finalized")] = /* @__PURE__ */new Map(), Qi == null || Qi({
  ReactiveElement: Kt
}), (At.reactiveElementVersions ?? (At.reactiveElementVersions = [])).push("2.0.4");
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
let Ht = class extends Kt {
  constructor() {
    super(...arguments), this.renderOptions = {
      host: this
    }, this._$Do = void 0;
  }
  createRenderRoot() {
    var e;
    const t = super.createRenderRoot();
    return (e = this.renderOptions).renderBefore ?? (e.renderBefore = t.firstChild), t;
  }
  update(t) {
    const e = this.render();
    this.hasUpdated || (this.renderOptions.isConnected = this.isConnected), super.update(t), this._$Do = Fd(e, this.renderRoot, this.renderOptions);
  }
  connectedCallback() {
    var t;
    super.connectedCallback(), (t = this._$Do) == null || t.setConnected(!0);
  }
  disconnectedCallback() {
    var t;
    super.disconnectedCallback(), (t = this._$Do) == null || t.setConnected(!1);
  }
  render() {
    return de;
  }
};
var zo;
Ht._$litElement$ = !0, Ht.finalized = !0, (zo = globalThis.litElementHydrateSupport) == null || zo.call(globalThis, {
  LitElement: Ht
});
const Ki = globalThis.litElementPolyfillSupport;
Ki == null || Ki({
  LitElement: Ht
});
(globalThis.litElementVersions ?? (globalThis.litElementVersions = [])).push("4.1.1");
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const ku = {
    attribute: !0,
    type: String,
    converter: wi,
    reflect: !1,
    hasChanged: jn
  },
  Tu = (i = ku, t, e) => {
    const {
      kind: o,
      metadata: n
    } = e;
    let s = globalThis.litPropertyMetadata.get(n);
    if (s === void 0 && globalThis.litPropertyMetadata.set(n, s = /* @__PURE__ */new Map()), s.set(e.name, i), o === "accessor") {
      const {
        name: r
      } = e;
      return {
        set(a) {
          const l = t.get.call(this);
          t.set.call(this, a), this.requestUpdate(r, l, i);
        },
        init(a) {
          return a !== void 0 && this.P(r, void 0, i), a;
        }
      };
    }
    if (o === "setter") {
      const {
        name: r
      } = e;
      return function (a) {
        const l = this[r];
        t.call(this, a), this.requestUpdate(r, l, i);
      };
    }
    throw Error("Unsupported decorator location: " + o);
  };
function Z(i) {
  return (t, e) => typeof e == "object" ? Tu(i, t, e) : ((o, n, s) => {
    const r = n.hasOwnProperty(s);
    return n.constructor.createProperty(s, r ? {
      ...o,
      wrapped: !0
    } : o), r ? Object.getOwnPropertyDescriptor(n, s) : void 0;
  })(i, t, e);
}
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function Iu(i) {
  return Z({
    ...i,
    state: !0,
    attribute: !1
  });
}
class Ou extends Lr {
  constructor(t = document.createElement("div")) {
    super(), this.isCSS2DObject = !0, this.element = t, this.element.style.position = "absolute", this.element.style.userSelect = "none", this.element.setAttribute("draggable", !1), this.center = new Dr(0.5, 0.5), this.addEventListener("removed", function () {
      this.traverse(function (e) {
        e.element instanceof Element && e.element.parentNode !== null && e.element.parentNode.removeChild(e.element);
      });
    });
  }
  copy(t, e) {
    return super.copy(t, e), this.element = t.element.cloneNode(!0), this.center = t.center, this;
  }
}
new gn();
new Fo();
new Fo();
new gn();
new gn();
class Ru {
  constructor(t, e) {
    this._group = new R.Group(), this._frustum = new R.Frustum(), this._frustumMat = new R.Matrix4(), this._regenerateDelay = 200, this._regenerateCounter = 0, this.material = new R.LineBasicMaterial({
      color: "#2e3338"
    }), this.numbers = new R.Group(), this.maxRegenerateRetrys = 4, this.gridsFactor = 5, this._scaleX = 1, this._scaleY = 1, this._offsetX = 0, this._offsetY = 0, this._camera = t, this._container = e;
    const o = this.newGrid(-1),
      n = this.newGrid(-2);
    this.grids = {
      main: o,
      secondary: n
    }, this._group.add(n, o, this.numbers);
  }
  set scaleX(t) {
    this._scaleX = t, this.regenerate();
  }
  get scaleX() {
    return this._scaleX;
  }
  set scaleY(t) {
    this._scaleY = t, this.regenerate();
  }
  get scaleY() {
    return this._scaleY;
  }
  set offsetX(t) {
    this._offsetX = t, this.regenerate();
  }
  get offsetX() {
    return this._offsetX;
  }
  set offsetY(t) {
    this._offsetY = t, this.regenerate();
  }
  get offsetY() {
    return this._offsetY;
  }
  get() {
    return this._group;
  }
  dispose() {
    const {
      main: t,
      secondary: e
    } = this.grids;
    t.removeFromParent(), e.removeFromParent(), t.geometry.dispose(), t.material.dispose(), e.geometry.dispose(), e.material.dispose();
  }
  regenerate() {
    if (!this.isGridReady()) {
      if (this._regenerateCounter++, this._regenerateCounter > this.maxRegenerateRetrys) throw new Error("Grid could not be regenerated");
      setTimeout(() => this.regenerate, this._regenerateDelay);
      return;
    }
    this._regenerateCounter = 0, this._camera.updateMatrix(), this._camera.updateMatrixWorld();
    const e = this._frustumMat.multiplyMatrices(this._camera.projectionMatrix, this._camera.matrixWorldInverse);
    this._frustum.setFromProjectionMatrix(e);
    const {
        planes: o
      } = this._frustum,
      n = o[0].constant * -o[0].normal.x,
      s = o[1].constant * -o[1].normal.x,
      r = o[2].constant * -o[2].normal.y,
      a = o[3].constant * -o[3].normal.y,
      l = Math.abs(n - s),
      c = Math.abs(a - r),
      {
        clientWidth: h,
        clientHeight: u
      } = this._container,
      p = Math.max(h, u),
      m = Math.max(l, c) / p,
      y = Math.ceil(Math.log10(l / this.scaleX)),
      _ = Math.ceil(Math.log10(c / this.scaleY)),
      $ = 10 ** (y - 2) * this.scaleX,
      w = 10 ** (_ - 2) * this.scaleY,
      b = $ * this.gridsFactor,
      v = w * this.gridsFactor,
      C = Math.ceil(c / v),
      x = Math.ceil(l / b),
      E = Math.ceil(c / w),
      M = Math.ceil(l / $),
      L = $ * Math.ceil(s / $),
      k = w * Math.ceil(r / w),
      P = b * Math.ceil(s / b),
      Y = v * Math.ceil(r / v),
      H = [...this.numbers.children];
    for (const X of H) X.removeFromParent();
    this.numbers.children = [];
    const S = [],
      z = 9 * m,
      N = 1e4,
      V = P + this._offsetX,
      nt = Math.round(Math.abs(V / this.scaleX) * N) / N,
      pt = (x - 1) * b,
      Di = Math.round(Math.abs((V + pt) / this.scaleX) * N) / N,
      Ir = Math.max(nt, Di).toString().length * z;
    let ji = Math.ceil(Ir / b) * b;
    for (let X = 0; X < x; X++) {
      let W = P + X * b;
      S.push(W, a, 0, W, r, 0), W = Math.round(W * N) / N, ji = Math.round(ji * N) / N;
      const we = W % ji;
      if (!(b < 1 || v < 1) && Math.abs(we) > 0.01) continue;
      const Bi = this.newNumber((W + this._offsetX) / this.scaleX),
        Mr = 12 * m;
      Bi.position.set(W, r + Mr, 0);
    }
    for (let X = 0; X < C; X++) {
      const W = Y + X * v;
      S.push(s, W, 0, n, W, 0);
      const we = this.newNumber(W / this.scaleY);
      let Fi = 12;
      we.element.textContent && (Fi += 4 * we.element.textContent.length);
      const Bi = Fi * m;
      we.position.set(s + Bi, W, 0);
    }
    const zi = [];
    for (let X = 0; X < M; X++) {
      const W = L + X * $;
      zi.push(W, a, 0, W, r, 0);
    }
    for (let X = 0; X < E; X++) {
      const W = k + X * w;
      zi.push(s, W, 0, n, W, 0);
    }
    const Or = new R.BufferAttribute(new Float32Array(S), 3),
      Rr = new R.BufferAttribute(new Float32Array(zi), 3),
      {
        main: Pr,
        secondary: Nr
      } = this.grids;
    Pr.geometry.setAttribute("position", Or), Nr.geometry.setAttribute("position", Rr);
  }
  newNumber(t) {
    const e = document.createElement("bim-label");
    e.textContent = String(Math.round(t * 100) / 100);
    const o = new Ou(e);
    return this.numbers.add(o), o;
  }
  newGrid(t) {
    const e = new R.BufferGeometry(),
      o = new R.LineSegments(e, this.material);
    return o.frustumCulled = !1, o.renderOrder = t, o;
  }
  isGridReady() {
    const t = this._camera.projectionMatrix.elements;
    for (let e = 0; e < t.length; e++) {
      const o = t[e];
      if (Number.isNaN(o)) return !1;
    }
    return !0;
  }
}
var Pu = Object.defineProperty,
  Nu = Object.getOwnPropertyDescriptor,
  ii = (i, t, e, o) => {
    for (var n = Nu(t, e), s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && Pu(t, e, n), n;
  };
const zn = class zn extends Ht {
  constructor() {
    super(...arguments), this._grid = null, this._world = null, this.resize = () => {
      this._world && this._grid && this._grid.regenerate();
    };
  }
  set gridColor(t) {
    if (this._gridColor = t, !(t && this._grid)) return;
    const e = Number(t.replace("#", "0x"));
    Number.isNaN(e) || this._grid.material.color.setHex(e);
  }
  get gridColor() {
    return this._gridColor;
  }
  set gridScaleX(t) {
    this._gridScaleX = t, t && this._grid && (this._grid.scaleX = t);
  }
  get gridScaleX() {
    return this._gridScaleX;
  }
  set gridScaleY(t) {
    this._gridScaleY = t, t && this._grid && (this._grid.scaleY = t);
  }
  get gridScaleY() {
    return this._gridScaleY;
  }
  get gridOffsetX() {
    var t;
    return ((t = this._grid) == null ? void 0 : t.offsetX) || 0;
  }
  set gridOffsetX(t) {
    this._grid && (this._grid.offsetX = t);
  }
  get gridOffsetY() {
    var t;
    return ((t = this._grid) == null ? void 0 : t.offsetY) || 0;
  }
  set gridOffsetY(t) {
    this._grid && (this._grid.offsetY = t);
  }
  set components(t) {
    this.dispose();
    const o = t.get(A.Worlds).create();
    this._world = o, o.scene = new A.SimpleScene(t), o.scene.setup(), o.renderer = new j.RendererWith2D(t, this);
    const n = new A.OrthoPerspectiveCamera(t);
    o.camera = n;
    const s = new Ru(n.threeOrtho, this);
    this._grid = s, o.scene.three.add(s.get()), n.controls.addEventListener("update", () => s.regenerate()), setTimeout(async () => {
      o.camera.updateAspect(), n.set("Plan"), await n.controls.setLookAt(0, 0, 100, 0, 0, 0), await n.projection.set("Orthographic"), n.controls.dollySpeed = 3, n.controls.draggingSmoothTime = 0.085, n.controls.maxZoom = 1e3, n.controls.zoom(4);
    });
  }
  get world() {
    return this._world;
  }
  dispose() {
    var t;
    (t = this.world) == null || t.dispose(), this._world = null, this._grid = null;
  }
  connectedCallback() {
    super.connectedCallback(), new ResizeObserver(this.resize).observe(this);
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.dispose();
  }
  render() {
    return Nn`<slot></slot>`;
  }
};
zn.styles = Dn`
    :host {
      position: relative;
      display: flex;
      min-width: 0px;
      height: 100%;
      background-color: var(--bim-ui_bg-base);
    }
  `;
let Tt = zn;
ii([Z({
  type: String,
  attribute: "grid-color",
  reflect: !0
})], Tt.prototype, "gridColor");
ii([Z({
  type: Number,
  attribute: "grid-scale-x",
  reflect: !0
})], Tt.prototype, "gridScaleX");
ii([Z({
  type: Number,
  attribute: "grid-scale-y",
  reflect: !0
})], Tt.prototype, "gridScaleY");
ii([Z({
  type: Number,
  attribute: "grid-offset-x",
  reflect: !0
})], Tt.prototype, "gridOffsetX");
ii([Z({
  type: Number,
  attribute: "grid-offset-y",
  reflect: !0
})], Tt.prototype, "gridOffsetY");
var Mu = Object.defineProperty,
  Nt = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && Mu(t, e, n), n;
  };
const Fn = class Fn extends Ht {
  constructor() {
    super(...arguments), this._defaults = {
      size: 60
    }, this._cssMatrix3D = "", this._matrix = new R.Matrix4(), this._onRightClick = new Event("rightclick"), this._onLeftClick = new Event("leftclick"), this._onTopClick = new Event("topclick"), this._onBottomClick = new Event("bottomclick"), this._onFrontClick = new Event("frontclick"), this._onBackClick = new Event("backclick"), this._camera = null, this._epsilon = t => Math.abs(t) < 1e-10 ? 0 : t;
  }
  set camera(t) {
    this._camera = t, this.updateOrientation();
  }
  get camera() {
    return this._camera;
  }
  updateOrientation() {
    if (!this.camera) return;
    this._matrix.extractRotation(this.camera.matrixWorldInverse);
    const {
      elements: t
    } = this._matrix;
    this._cssMatrix3D = `matrix3d(
      ${this._epsilon(t[0])},
      ${this._epsilon(-t[1])},
      ${this._epsilon(t[2])},
      ${this._epsilon(t[3])},
      ${this._epsilon(t[4])},
      ${this._epsilon(-t[5])},
      ${this._epsilon(t[6])},
      ${this._epsilon(t[7])},
      ${this._epsilon(t[8])},
      ${this._epsilon(-t[9])},
      ${this._epsilon(t[10])},
      ${this._epsilon(t[11])},
      ${this._epsilon(t[12])},
      ${this._epsilon(-t[13])},
      ${this._epsilon(t[14])},
      ${this._epsilon(t[15])})
    `;
  }
  render() {
    const t = this.size ?? this._defaults.size;
    return Nn`
      <style>
        .face,
        .cube {
          width: ${t}px;
          height: ${t}px;
          transform: translateZ(-300px) ${this._cssMatrix3D};
        }

        .face-right {
          translate: ${t / 2}px 0 0;
        }

        .face-left {
          translate: ${-t / 2}px 0 0;
        }

        .face-top {
          translate: 0 ${t / 2}px 0;
        }

        .face-bottom {
          translate: 0 ${-t / 2}px 0;
        }

        .face-front {
          translate: 0 0 ${t / 2}px;
        }

        .face-back {
          translate: 0 0 ${-t / 2}px;
        }
      </style>
      <div class="parent">
        <div class="cube">
          <div
            class="face x-direction face-right"
            @click=${() => this.dispatchEvent(this._onRightClick)}
          >
            ${this.rightText}
          </div>
          <div
            class="face x-direction face-left"
            @click=${() => this.dispatchEvent(this._onLeftClick)}
          >
            ${this.leftText}
          </div>
          <div
            class="face y-direction face-top"
            @click=${() => this.dispatchEvent(this._onTopClick)}
          >
            ${this.topText}
          </div>
          <div
            class="face y-direction face-bottom"
            @click=${() => this.dispatchEvent(this._onBottomClick)}
          >
            ${this.bottomText}
          </div>
          <div
            class="face z-direction face-front"
            @click=${() => this.dispatchEvent(this._onFrontClick)}
          >
            ${this.frontText}
          </div>
          <div
            class="face z-direction face-back"
            @click=${() => this.dispatchEvent(this._onBackClick)}
          >
            ${this.backText}
          </div>
        </div>
      </div>
    `;
  }
};
Fn.styles = Dn`
    :host {
      position: absolute;
      z-index: 999;
      bottom: 1rem;
      right: 1rem;
    }

    .parent {
      perspective: 400px;
    }

    .cube {
      position: relative;
      transform-style: preserve-3d;
    }

    .face {
      position: absolute;
      display: flex;
      justify-content: center;
      user-select: none;
      align-items: center;
      cursor: pointer;
      text-align: center;
      transition: all 0.2s;
      color: var(--bim-view-cube--c, white);
      font-size: var(--bim-view-cube--fz, --bim-ui_size-2xl);
    }

    .x-direction {
      // background-color: var(--bim-view-cube_x--bgc, #c93830DD);
      background-color: var(--bim-view-cube_x--bgc, #01a6bcde);
    }

    .x-direction:hover {
      background-color: var(--bim-ui_accent-base, white);
    }

    .y-direction {
      // background-color: var(--bim-view-cube_y--bgc, #54ff19DD);
      background-color: var(--bim-view-cube_y--bgc, #8d0ec8de);
    }

    .y-direction:hover {
      background-color: var(--bim-ui_accent-base, white);
    }

    .z-direction {
      // background-color: var(--bim-view-cube_z--bgc, #3041c9DD);
      background-color: var(--bim-view-cube_z--bgc, #2718afde);
    }

    .z-direction:hover {
      background-color: var(--bim-ui_accent-base, white);
    }

    .face-front {
      transform: rotateX(180deg);
    }

    .face-back {
      transform: rotateZ(180deg);
    }

    .face-top {
      transform: rotateX(90deg);
    }

    .face-bottom {
      transform: rotateX(270deg);
    }

    .face-right {
      transform: rotateY(-270deg) rotateX(180deg);
    }

    .face-left {
      transform: rotateY(-90deg) rotateX(180deg);
    }
  `;
let dt = Fn;
Nt([Z({
  type: Number,
  reflect: !0
})], dt.prototype, "size");
Nt([Z({
  type: String,
  attribute: "right-text",
  reflect: !0
})], dt.prototype, "rightText");
Nt([Z({
  type: String,
  attribute: "left-text",
  reflect: !0
})], dt.prototype, "leftText");
Nt([Z({
  type: String,
  attribute: "top-text",
  reflect: !0
})], dt.prototype, "topText");
Nt([Z({
  type: String,
  attribute: "bottom-text",
  reflect: !0
})], dt.prototype, "bottomText");
Nt([Z({
  type: String,
  attribute: "front-text",
  reflect: !0
})], dt.prototype, "frontText");
Nt([Z({
  type: String,
  attribute: "back-text",
  reflect: !0
})], dt.prototype, "backText");
Nt([Iu()], dt.prototype, "_cssMatrix3D");
var Lu = Object.defineProperty,
  Du = (i, t, e, o) => {
    for (var n = void 0, s = i.length - 1, r; s >= 0; s--) (r = i[s]) && (n = r(t, e, n) || n);
    return n && Lu(t, e, n), n;
  };
const Bn = class Bn extends Ht {
  constructor() {
    super(...arguments), this.world = null, this._components = null, this._viewport = bn();
  }
  set components(t) {
    var e;
    if (this._components = t, this.components) {
      const o = this.components.get(A.Worlds);
      this.world = o.create(), this.world.name = this.name;
    } else (e = this.world) == null || e.dispose(), this.world = null;
  }
  get components() {
    return this._components;
  }
  connectedCallback() {
    super.connectedCallback(), this.world && (this.world.enabled = !0);
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.world && (this.world.enabled = !1);
  }
  dispose() {
    this.components = null, this.remove();
  }
  firstUpdated() {
    const {
      value: t
    } = this._viewport;
    if (!(this.components && t && this.world)) return;
    const e = new A.SimpleScene(this.components);
    this.world.scene = e, e.setup(), e.three.background = null;
    const o = new j.PostproductionRenderer(this.components, t);
    this.world.renderer = o;
    const {
        postproduction: n
      } = o,
      s = new A.OrthoPerspectiveCamera(this.components);
    this.world.camera = s;
    const r = this.components.get(A.Grids).create(this.world);
    r.material.uniforms.uColor.value = new R.Color(4342338), r.material.uniforms.uSize1.value = 2, r.material.uniforms.uSize2.value = 8, n.enabled = !0, n.customEffects.excludedMeshes.push(r.three), n.setPasses({
      custom: !0,
      ao: !0,
      gamma: !0
    }), n.customEffects.lineColor = 1513756;
  }
  onSlotChange() {
    const t = new Event("slotchange");
    this.dispatchEvent(t);
  }
  render() {
    return Nn` <bim-viewport ${Jd(this._viewport)}>
      <slot @slotchange=${this.onSlotChange}></slot>
    </bim-viewport>`;
  }
};
Bn.styles = Dn``;
let $i = Bn;
Du([Z({
  type: String,
  reflect: !0
})], $i.prototype, "name");
class wh {
  /**
   * Initializes the custom elements for the BIM application.
   *
   * @remarks
   * This method should be called once during the application's initialization.
   *
   */
  static init() {
    Q.defineCustomElement("bim-view-cube", dt), Q.defineCustomElement("bim-world-2d", Tt), Q.defineCustomElement("bim-world", $i);
  }
}
export { wh as Manager, dt as ViewCube, $i as World, Tt as World2D, hh as buttons, gh as forms, yh as sections, bh as tables };