import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, HostBinding, inject, Input} from '@angular/core';
import {TuiButton, TuiDataList, TuiDropdown, TuiHint, TuiIcon} from '@taiga-ui/core';
import {TuiAvatar, TuiLineClamp} from '@taiga-ui/kit';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  standalone: true,
  imports: [
    // Angular
    AsyncPipe,

    // Taiga
    TuiAvatar,
    TuiIcon,
    TuiButton,
    TuiDataList,
    TuiDropdown,
    TuiHint,
    TuiLineClamp,
  ],
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileCardComponent {
  private readonly oidcSecurityService = inject(OidcSecurityService);

  readonly user$ = this.oidcSecurityService.userData$;

  isExpanded = false;

  @HostBinding('class._expanded')
  @Input()
  set expanded(value: boolean) {
    this.isExpanded = value;
  }

  avatarUrl: string = null;

  logOut() {
    /** Можно не отписываться, потому что пользователя выкинет в ауф */
    this.oidcSecurityService.logoff().subscribe();
  }
}
