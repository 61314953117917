import {Pipe, PipeTransform} from '@angular/core';

import {LayoutSectionShareDto} from '../../dto/layout-section';
import {layoutSectionGetPluralApartName} from '../../scripts/layout-section';

@Pipe({
  standalone: true,
  name: 'layoutSectionApartPluralNamePipe',
  pure: true,
})
export class LayoutSectionApartPluralNamePipe implements PipeTransform {
  transform(share: LayoutSectionShareDto): string {
    return layoutSectionGetPluralApartName(share.roomCount, share.apartmentFormat);
  }
}
