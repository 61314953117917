import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  API_URL_PREFIX,
  HeatingSystem,
  HvacAirVentType,
  HvacBalancingValvePosition,
  HvacConfigDto,
  HvacFireResistanceLimit,
  HvacGetResourceParamsType,
  HvacInputUnit,
  HvacResourceType,
  HvacSmokeVentilationSharedAreaCalculationVariant,
} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HvacApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getResource(generationVariantId: string, dataType: HvacResourceType): Observable<Blob> {
    const params: HvacGetResourceParamsType = {
      dataType,
    };

    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Hvac/${generationVariantId}/resource`, {
      params: {
        ...params,
      },
      responseType: 'blob',
    });
  }

  getSettings(): Observable<HvacConfigDto> {
    const mockData = {
      basicSettings: {
        site: {
          winterMinimumTemperature: -30,
        },
      },
      heating: {
        basicHeatingSettings: {
          typicalStoreyRoomHeatLossConfig: {
            roomTemperatureConfig: {
              livingRoomTemperature: 20,
              kitchenTemperature: 19,
              waterClosetTemperature: 19,
              restRoomBathRoomTemperature: 24,
              hallWayHallDressingRoomAnyRoom: 19,
            },
            enclosingStructureOrientationCoefficients: {
              northNorthEastNorthWestEast: 0.1,
              southEastWest: 0.05,
              southWestSouth: 0,
            },
            roomsAirExchange: {
              livingRoomsWithApartmentAreaGreaterThan20SquareMetersPerPerson: 30,
              livingRoomsWithApartmentAreaLessThan20SquareMetersPerPerson: 3,
              anyRoomDressingRoom: 0.2,
              kitchen: 60,
              restRoomBathRoomTemperature: 50,
              laundryRoom: 25,
            },
            householdThermalOutput: {
              lessThan20SquareMetersCommonAreaPerPerson: 17,
              greaterOrEqualThan45SquareMetersCommonAreaPerPerson: 10,
            },
            apartmentFacadeWallHeatTransferCoefficient: 0.335,
            lluFacadeWallHeatTransferCoefficient: 0.335,
            windowHeatTransferCoefficient: 1.524,
            interApartmentDoorHeatTransferCoefficient: 1,
            interRoomDoorHeatTransferCoefficient: 1,
            innerWallHeatTransferCoefficient: 1,
            floorHeatTransferCoefficient: 1,
            ceilingHeatTransferCoefficient: 1,
            ceilingLLUHeatTransferCoefficient: 1,
            valveAirFlow: 30,
          },
        },
        apartmentHeating: {
          heatingSystemType: 'Collector' as HeatingSystem,
          collectorHeatingSystem: {
            panelHeatingRadiatorPlacement: {
              windowMaxSizePerOneRadiator: 2100,
              distanceFromFinishedFloorToHeatingDevice: 100,
              verticalDistanceFromHeatingDeviceToWindowOpening: 50,
              residentialHeatingDeviceMinWidthAsPercentageWidthStraightWindow: 75,
              commercialHeatingDeviceMinWidthAsPercentageWidthStraightWindow: 50,
              heatingDeviceMaxWidthCornerStainedGlassWindow: 400,
              heatingDeviceMaxWidthCornerRegularWindow: 200,
              heatingDeviceMaxWidth: 1600,
              heatingDeviceWidthAtLeastLessThanStraightWindowWidth: 400,
              heatingDeviceWidthAtLeastLessThanCornerStainedGlassWindowWidth: 200,
              verticalHeatingDeviceMaxHeight: 1200,
              verticalHeatingDevicePermissibleWidth: 300,
              thermalHeadLength: 100,
              thermalHeadDiameter: 59.5,
              distanceFromDoorOpeningToHeatingDevice: 100,
            },
            typicalStoreyHeatingCollectorPlacement: {
              inputUnitType: 'None' as HvacInputUnit,
              airVentType: 'MaevskiTap' as HvacAirVentType,
              balancingValvePosition: 'Standalone' as HvacBalancingValvePosition,
              heatingSupplyCollectorHeightCenterAboveFloor: 450,
              heatingProcessCollectorHeightCenterAboveFloor: 850,
              accessHatchHeightFromFinishedFloor: 125,
              beginningCollectorOffsetFromLeftExtremeEdgeToZone: 300,
              distanceBetweenCollectorOutputs: 100,
              outputStartOffset: 120,
            },
          },
        },
      },
      heatingTracing: {
        wetZoneOffset: 300,
        externalWallsRouteOffset: 250,
        distanceBetweenAxis: 100,
      },
      ventilation: {
        basicSettings: {
          ductInsets: {
            roundDuctInsetLength: 95,
            roundDuctInsetInsertionLength: 55,
            insetSizeAddWithWallValve: 20,
            rectangleInsetStandardLength: 100,
            rectangleInsetMinimalLength: 50,
          },
          ductInsulations: {
            verticalDuctApartmentVentilation: {
              thickness: 5,
              fireResistanceLimit: 'EI60' as HvacFireResistanceLimit,
            },
            horizontalDuctApartmentVentilationFromValveToWall: {
              thickness: 5,
              fireResistanceLimit: 'EI60' as HvacFireResistanceLimit,
            },
            verticalDuctInsideEngineeringZoneinSharedAreas: {
              thickness: 5,
              fireResistanceLimit: 'EI60' as HvacFireResistanceLimit,
            },
            smokeVentilationDuctsFromValveToFan: {
              thickness: 5,
              fireResistanceLimit: 'EI30' as HvacFireResistanceLimit,
            },
            fireSafeZoneDucts: {
              thickness: 5,
              fireResistanceLimit: 'EI60' as HvacFireResistanceLimit,
            },
          },
        },
        smokeVentilation: {
          smokeVentilationSharedAreas: {
            fireLoadMass: {
              oneRoomApartmentAndStudio: 27,
              twoRoomApartment: 30,
              threeRoomApartment: 40,
            },
            fireLoad: {
              roomFireLoadForApartmentLte100SquareMeters: 600,
              roomFireLoadForApartmentGt100SquareMeters: 500,
            },
            roomTemperature: 18,
            calculationVariant: 'V1' as HvacSmokeVentilationSharedAreaCalculationVariant,
            smokeExhaustAirDuctStandardSpeed: 20,
            compensationAirDuctStandardSpeed: 15,
            smokeExhaustValveStandardSpeed: 14,
            compensationValveStandardSpeed: 10,
            betweenSubZoneSideEdgeAndAirDuct: 100,
            betweenSubZoneSideEdgeAndSmokeExhaustAirDuctForActuator: 250,
            betweenSubZoneBackEdgeAndAirDuct: 100,
            betweenEngineeringOpeningEdgeAndAirDuct: 50,
            smokeExhaustLowering: 50,
            lowerCombustionHeat: 13.8,
          },
          smokeVentilationLLUConfig: {
            calculationVariant: 'V1' as HvacSmokeVentilationSharedAreaCalculationVariant,
          },
        },
      },
    };

    return of(mockData);
  }
}
