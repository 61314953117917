import {SubsystemCode} from '@shared/util';

export const ROUTES_SUBSYSTEM_MAP: {[routePath: string]: SubsystemCode} = {
  layout: 'Layout',
  structure: 'Structure',
  'structure-2': 'Structure2',
  hvac: 'HVAC',
  'hvac-2': 'HVAC2',
  plumbing: 'Plumbing',
  'plumbing-2': 'Plumbing2',
  electrical: 'Electrical',
  'electrical-2': 'Electrical2',
  spacePlanning: 'SpacePlanning',
  architecture: 'OMAR',
  'architecture-2': 'OMAR2',
  'architecture-3': 'OMAR3',
};
