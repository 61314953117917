import {OrganizationsApiService} from '@accounts-api/data-access';
import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {TuiAlertService} from '@taiga-ui/core';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {RemoveEntry, RemoveEntryFailure, RemoveEntrySuccess} from './actions';

export interface State {
  error: string | null;
  loading: boolean;
}

export const defaultState: State = {
  error: null,
  loading: false,
};

@Injectable()
@State<State>({
  name: 'organizationsListView',
  defaults: defaultState,
})
export class OrganizationsListViewState {
  private readonly organizationsApiService = inject(OrganizationsApiService);
  private readonly alerts = inject(TuiAlertService);

  @Selector()
  static loading(state: State): boolean {
    return state.loading;
  }

  @Selector()
  static error(state: State): string | null {
    return state.error;
  }

  @Action(RemoveEntry, {cancelUncompleted: true})
  removeEntry(ctx: StateContext<State>, payload: RemoveEntry) {
    return this.organizationsApiService.delete(payload.entry.id).pipe(
      switchMap(_apiResponse => ctx.dispatch(new RemoveEntrySuccess(payload.entry))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(
            new RemoveEntryFailure((error.error as any)?.title || 'Неизвестная ошибка', payload.entry),
          );
        }

        if (error instanceof Error) {
          return ctx.dispatch(new RemoveEntryFailure(error.message, payload.entry));
        }

        return EMPTY;
      }),
    );
  }

  @Action(RemoveEntrySuccess)
  removeEntrySuccess(ctx: StateContext<State>, payload: RemoveEntrySuccess) {
    return this.alerts.open(`Организация «${payload.entry.name}» успешно удалена`, {
      label: 'Выполнено',
      appearance: 'success',
    });
  }

  @Action(RemoveEntryFailure)
  removeEntryFailure(ctx: StateContext<State>, payload: RemoveEntryFailure) {
    return this.alerts.open(`Не удалось удалить организацию «${payload.entry.name}». Ошибка: ${payload.error}`, {
      label: 'Не выполнено',
      appearance: 'error',
    });
  }
}
