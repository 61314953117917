import 'iconify-icon';

import {css, html, LitElement} from 'lit';
import {property} from 'lit/decorators.js';

export type ToggleButtonCheckedEvent = CustomEvent<{value: boolean}>;
const toggleButtonChecked = (value: boolean): ToggleButtonCheckedEvent =>
  new CustomEvent('togglechecked', {
    detail: {value},
    bubbles: true,
    composed: true,
  });

export class ToggleButton extends LitElement {
  static override styles = css`
    .real-button {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      appearance: none;
      padding: 0;
      border: 0;
      background-color: #f6f6f6;
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;
      flex-shrink: 0;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: visible;
      vertical-align: middle;
      max-inline-size: 100%;
      gap: 0.25rem;
      block-size: 2.75rem;
      border-radius: 0.25rem;
      padding: 0.825rem;
      user-select: none;
      color: #333333;
      font: inherit;
      font-weight: 700;
      cursor: pointer;
    }

    :host(:not([checked]):hover) .real-button,
    :host(:not([checked]):active) .real-button,
    :host(:not([checked])[active]) .real-button {
      background-color: #ededed;
    }

    :host([checked]) .real-button,
    :host([checked]) .real-button,
    :host([active][checked]) .real-button {
      background-color: #2a61ed;
      color: #fff;
    }

    :host([disabled]) .real-button {
      background-color: gray;
      cursor: default;
    }

    :host(:not([tooltip-visible])) .tooltip {
      display: none;
    }

    iconify-icon {
      height: 1.25rem;
      width: 1.25rem;
      color: inherit;
      transition: color 0.15s;
    }
  `;

  /**
   * The label to be displayed on the button.
   * @type {Boolean}
   * @default false
   * @example <ntc-ifc-toggle-button label="Click me"></ntc-ifc-toggle-button>
   */
  @property({type: Boolean, reflect: true, attribute: 'checked'})
  checked = false;

  /**
   * The label to be displayed on the button.
   * @type {string}
   * @default undefined
   * @example <ntc-ifc-toggle-button label="Click me"></ntc-ifc-toggle-button>
   */
  @property({type: String, reflect: true})
  label?: string;

  /**
   * A boolean attribute which, if present, indicates that the button is disabled.
   * @default false
   * @example <ntc-ifc-toggle-button label="Click me" disabled></ntc-ifc-toggle-button>
   */
  @property({type: Boolean, reflect: true, attribute: 'disabled'})
  disabled = false;

  /**
   * The icon to be displayed on the button.
   * @type {string}
   * @default undefined
   * @example <ntc-ifc-toggle-button icon="my-icon"></ntc-ifc-toggle-button>
   */
  @property({type: String, reflect: true})
  icon?: string;

  @property({type: String, attribute: 'tooltip-title', reflect: true})
  tooltipText?: string;

  @property({type: Boolean, attribute: 'tooltip-visible', reflect: true})
  tooltipVisible = false;

  protected override render() {
    return html`
      <button
        class="real-button"
        @click=${this.onClick}
        @mouseenter=${this.onMouseEnter}
        @mouseleave=${this.onMouseLeave}
      >
        ${this.label || this.icon
          ? html`
              <iconify-icon .icon=${this.icon} height="none"></iconify-icon>
            `
          : null}
        <div
          style="position: absolute;
        width: fit-content;
        top: -40px;
        background: #333;
        padding: 5px 5px;
        border-radius: 4px;
        color: #fff;"
          class="tooltip"
        >
          <p style="text-wrap: nowrap; margin: 0;">
            <strong>${this.tooltipText}</strong>
          </p>
        </div>
      </button>
    `;
  }

  private onClick(event: MouseEvent): void {
    event.stopPropagation();

    this.checked = !this.checked;
    this.requestUpdate();

    this.dispatchEvent(toggleButtonChecked(this.checked));
    super.click();
  }

  private onMouseEnter() {
    this.tooltipVisible = true;
  }

  private onMouseLeave() {
    this.tooltipVisible = false;
  }
}
