<div class="overflow">
  <div class="header">
    <a class="logo-link" [routerLink]="['/']">
      <app-logo class="logo" />
    </a>
  </div>
  <div class="content">
    <tui-scrollbar class="content__scrollbar">
      <app-nav class="nav scrollable" [expanded]="hovered" />
    </tui-scrollbar>
  </div>
  <div class="footer">
    <organizations-card [expanded]="hovered" />
    <profile-card [expanded]="hovered" />
  </div>
</div>
