@if (user$ | async; as user) {
  <div class="user">
    <tui-avatar [size]="'m'" [src]="avatarUrl || '@tui.user'" [round]="true"></tui-avatar>

    @if (isExpanded) {
      <div class="user__name user__name--long">
        <tui-line-clamp [content]="tooltip" [lineHeight]="24" [linesLimit]="1"></tui-line-clamp>
      </div>
    }
    <ng-template #tooltip>
      <div>
        {{ user.userData?.name || user.userData?.email }}
      </div>
    </ng-template>
  </div>
}

@if (isExpanded) {
  <div tuiDropdownOpen [tuiDropdownOffset]="-10" [tuiDropdown]="profileActionsTemplate">
    <button tuiButton type="button" appearance="icon" size="xs" iconStart="@tui.ellipsis-vertical"></button>
  </div>
}

<ng-template #profileActionsTemplate>
  <tui-data-list role="menu">
    <button type="button" tuiOption role="menuitemradio" appearance="flat" size="s" [disabled]="true">
      Открыть профиль
    </button>
    <button type="button" tuiOption role="menuitemradio" appearance="flat" size="s" (click)="logOut()">
      Выйти из системы
    </button>
  </tui-data-list>
</ng-template>
