export enum ArtefactsTypeEnum {
  AIIFEA = 'AIIFEA',
  AIIFEM = 'AIIFEM',
  AIIOAM = 'AIIOAM',
  Contract = 'Contract',
  Dxf = 'Dxf',
  FeaResult = 'FeaResult',
  FeaTask = 'FeaTask',
  Ifc = 'Ifc',
  Preview = 'Preview',
  Report = 'Report',
}
