<div class="links-group">
  <!-- TODO: Удалить проверку, когда у всех пользователей будет активная организация -->
  @if (activeOrganization$ | async) {
    <app-nav-menu
      [icon]="'@tui.box'"
      [expanded]="expanded"
      [activeLinks]="usersActive.isActive || rolesActive.isActive"
    >
      <ng-container menu-button>Организации</ng-container>
      <ng-container menu-items>
        <a
          #usersActive="routerLinkActive"
          type="button"
          routerLinkActive="active"
          class="link nav-menu-link"
          [routerLink]="['', 'users']"
        >
          Пользователи
        </a>
        <a
          #rolesActive="routerLinkActive"
          type="button"
          routerLinkActive="active"
          class="link nav-menu-link"
          [routerLink]="['', 'roles']"
        >
          Роли
        </a>
      </ng-container>
    </app-nav-menu>
  }
  <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'portfolio']">
    <tui-icon icon="@tui.briefcase" class="link__icon"></tui-icon>
    <span class="link__text">Проекты</span>
  </a>
  <a routerLinkActive="active" class="links-group__entry link" [routerLink]="['', 'references']">
    <tui-icon icon="@tui.book" class="link__icon"></tui-icon>
    <span class="link__text">Справочники</span>
  </a>
</div>
<div class="links-group links-group--bring-to-bot">
  <a
    href="https://forms.yandex.ru/cloud/65b1fedef47e73517583ffbb/"
    rel="noreferrer"
    target="_blank"
    class="links-group__entry link"
  >
    <tui-icon icon="@tui.life-buoy" class="link__icon"></tui-icon>
    <span class="link__text">Написать в поддержку</span>
  </a>
  <a href="https://docs.platforma.build/" rel="noreferrer" target="_blank" class="links-group__entry link">
    <tui-icon icon="@tui.book-open" class="link__icon"></tui-icon>
    <span class="link__text">Справка</span>
  </a>
</div>
