import {OrganizationsApiService} from '@accounts-api/data-access';
import {OrganizationDto} from '@accounts-api/util';
import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {CreateSuccess, UpdateSuccess} from '@organizations/data-access/entry-form';
import {RemoveEntrySuccess} from '@organizations/data-access/list-view';
import {TuiAlertService} from '@taiga-ui/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {
  GetActiveOrganization,
  GetActiveOrganizationFailure,
  GetActiveOrganizationSuccess,
  GetData,
  GetDataFailure,
  GetDataSuccess,
  InitView,
  SetActiveOrganization,
  SetActiveOrganizationFailure,
  SetActiveOrganizationSuccess,
} from './actions';

export interface State {
  data: readonly OrganizationDto[] | null;
  activeOrganization: OrganizationDto | null;
  loading: boolean;
  loadingOrganization: boolean;
}

export const defaultState: State = {
  data: null,
  activeOrganization: null,
  loading: false,
  loadingOrganization: false,
};

@Injectable()
@State<State>({
  name: 'organizationsCard',
  defaults: defaultState,
})
export class OrganizationsCardState {
  private readonly organizationsApiService = inject(OrganizationsApiService);
  private readonly alerts = inject(TuiAlertService);
  private readonly oidcSecurityService = inject(OidcSecurityService);

  @Selector()
  static data(state: State): readonly OrganizationDto[] | null {
    return state.data;
  }

  @Selector()
  static activeOrganization(state: State): OrganizationDto | null {
    return state.activeOrganization;
  }

  @Selector()
  static loading(state: State): boolean {
    return state.loading;
  }

  @Selector()
  static loadingOrganization(state: State): boolean {
    return state.loadingOrganization;
  }

  @Action(InitView)
  initView(ctx: StateContext<State>) {
    const state = ctx.getState();

    if (state.data === null && state.loading !== true) {
      return ctx.dispatch([new GetActiveOrganization(), new GetData()]);
    }

    return EMPTY;
  }

  @Action(GetData, {cancelUncompleted: true})
  getData(ctx: StateContext<State>) {
    ctx.patchState({loadingOrganization: true});

    return this.organizationsApiService.getAll().pipe(
      switchMap(apiResponse => ctx.dispatch(new GetDataSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new GetDataFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new GetDataFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(GetDataSuccess)
  getDataSuccess(ctx: StateContext<State>, payload: GetDataSuccess) {
    ctx.patchState({
      data: payload.apiResponse,
      loadingOrganization: false,
    });
  }

  @Action(GetDataFailure)
  getDataFailure(ctx: StateContext<State>, payload: GetDataFailure) {
    ctx.patchState({
      loadingOrganization: false,
    });

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      appearance: 'error',
    });
  }

  @Action(GetActiveOrganization, {cancelUncompleted: true})
  getActiveOrganization(ctx: StateContext<State>) {
    ctx.patchState({loading: true});

    return this.organizationsApiService.getActiveOrganization().pipe(
      switchMap(apiResponse => ctx.dispatch(new GetActiveOrganizationSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new GetActiveOrganizationFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new GetActiveOrganizationFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(GetActiveOrganizationSuccess)
  getActiveOrganizationSuccess(ctx: StateContext<State>, payload: GetActiveOrganizationSuccess) {
    ctx.patchState({
      activeOrganization: payload.apiResponse,
      loading: false,
    });
  }

  @Action(GetActiveOrganizationFailure)
  getActiveOrganizationFailure(ctx: StateContext<State>, payload: GetActiveOrganizationFailure) {
    ctx.patchState({
      loading: false,
    });

    return this.alerts.open(payload.error, {
      label: 'Внимание',
      appearance: 'warning',
    });
  }

  @Action(SetActiveOrganization, {cancelUncompleted: true})
  setActiveOrganization(ctx: StateContext<State>, payload: SetActiveOrganization) {
    ctx.patchState({loading: true});

    return this.organizationsApiService.setActiveOrganization(payload.organizationId).pipe(
      switchMap(() => ctx.dispatch(new SetActiveOrganizationSuccess())),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new SetActiveOrganizationFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new SetActiveOrganizationFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(SetActiveOrganizationSuccess)
  setActiveOrganizationSuccess(ctx: StateContext<State>) {
    ctx.patchState({
      loading: false,
    });

    window.location.href = '/';
  }

  @Action(SetActiveOrganizationFailure)
  setActiveOrganizationFailure(ctx: StateContext<State>, payload: SetActiveOrganizationFailure) {
    ctx.patchState({
      loading: false,
    });

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      appearance: 'error',
    });
  }

  /**
   * Создание организации на странице организации
   */
  @Action([CreateSuccess])
  formApiActionSuccess(ctx: StateContext<State>) {
    /**
     * Любые изменения в организациях влияют на авторизационный токен
     * и требуют рефреша существующего
     */
    return this.oidcSecurityService.forceRefreshSession().pipe(switchMap(_payload => ctx.dispatch(new GetData())));
  }

  /**
   * Обновление организации на странице организации
   */
  @Action([UpdateSuccess])
  onGroupEntryUpdate(ctx: StateContext<State>, payload: UpdateSuccess) {
    const state = ctx.getState();

    ctx.patchState({
      data: state.data.map(group => (group.id === payload.apiResponse.id ? payload.apiResponse : group)),
    });

    /**
     * Любые изменения в организациях влияют на авторизационный токен
     * и требуют рефреша существующего
     */
    return this.oidcSecurityService.forceRefreshSession();
  }

  /**
   * Удаление организации на странице организации
   */
  @Action([RemoveEntrySuccess])
  onGroupEntryRemove(ctx: StateContext<State>, payload: RemoveEntrySuccess) {
    const state = ctx.getState();

    ctx.patchState({
      data: state.data.filter(group => group.id !== payload.entry.id),
    });

    /**
     * Любые изменения в организациях влияют на авторизационный токен
     * и требуют рефреша существующего
     */
    return this.oidcSecurityService.forceRefreshSession();
  }
}
