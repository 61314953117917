import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  API_URL_PREFIX,
  GroupedTopologiesDto,
  SectionDto,
  TopologyDto,
  TopologyVariantsDto,
} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StoreyLayoutsApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getGeneratedTopologies(generationTaskId: string): Observable<TopologyDto[]> {
    return this.http.get<TopologyDto[]>(
      `${this.gatewayUrl}/${this.apiPrefix}/StoreyLayouts/${generationTaskId}/topologies`,
    );
  }

  getBestVariants(generationTaskId: string): Observable<TopologyVariantsDto> {
    return this.http.get<TopologyVariantsDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/StoreyLayouts/${generationTaskId}/best-variants`,
    );
  }

  getGroupedVariants(topologyId: string) {
    return this.http.get<GroupedTopologiesDto>(
      `${this.gatewayUrl}/${this.apiPrefix}/StoreyLayouts/grouped-variants/${topologyId}`,
    );
  }

  topologyImageUrl(topologyId: string): string {
    return `${this.gatewayUrl}/${this.apiPrefix}/StoreyLayouts/topologies/${topologyId}/image`;
  }

  variantImageUrl(variantId: string): string {
    return `${this.gatewayUrl}/${this.apiPrefix}/StoreyLayouts/variants/${variantId}/image`;
  }

  getVariantsUrl() {
    return `${this.gatewayUrl}/${this.apiPrefix}/StoreyLayouts/variants`;
  }

  getSection(objectModelId: string): Observable<SectionDto> {
    return this.http.get<SectionDto>(`${this.gatewayUrl}/${this.apiPrefix}/StoreyLayouts/sections/${objectModelId}`);
  }
}
