import {AsyncPipe, NgTemplateOutlet} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from '@angular/router';
import {SubsystemBarService} from '@common/components/subsystem-bar/services';
import {ROUTES, SUBSYSTEM_ROUTE} from '@common/consts';
import {GenerationTaskApiService} from '@generation-api-v2/data-access';
import {
  GenerationTaskSubsystemName,
  GenerationTaskSubsystemType,
  GenerationTaskWithSourceVariantDto,
} from '@generation-api-v2/util';
import {RouterState} from '@ngxs/router-plugin';
import {Store} from '@ngxs/store';
import {ProjectBreadcrumbsComponent} from '@project/feature/breadcrumbs';
import {SubsystemShellState} from '@subsystem/data-access';
import {TuiLet} from '@taiga-ui/cdk';
import {TuiIcon, TuiScrollbar} from '@taiga-ui/core';
import {ExtendedRouterState} from '@util/custom-router-serializer';
import {BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, of, switchMap, tap} from 'rxjs';

import {initialData} from '../../consts';
import {SubsystemBreadcrumbsLinkComponent} from '../subsystem-breadcrumbs-link';
import {SubsystemBreadcrumbsLink} from '../subsystem-breadcrumbs-link/subsystem-breadcrumbs-link';

@Component({
  standalone: true,
  imports: [
    // Angular
    AsyncPipe,
    NgTemplateOutlet,
    RouterLink,

    // Taiga
    TuiScrollbar,
    TuiIcon,
    TuiLet,

    // Custom
    ProjectBreadcrumbsComponent,
    SubsystemBreadcrumbsLinkComponent,
  ],
  selector: 'subsystem-breadcrumbs',
  templateUrl: './subsystem-breadcrumbs.component.html',
  styleUrl: './subsystem-breadcrumbs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubsystemBreadcrumbsComponent {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly generationTaskApi = inject(GenerationTaskApiService);
  private readonly subsystemBreadcrumbsService = inject(SubsystemBarService);

  private readonly subsystemType: GenerationTaskSubsystemType = (
    this.route.snapshot.firstChild?.firstChild?.data ?? this.route.snapshot.data
  )?.subsystemName as GenerationTaskSubsystemType;

  private pageIsVariants: boolean;
  private pageIsVariant: boolean;
  private pageIsSectionVariant: boolean;
  private pageIsTask: boolean;
  private pageIsViewer: boolean;
  private viewerVariantId: string;

  readonly breadcrumbsData$ = new BehaviorSubject<SubsystemBreadcrumbsLink[]>([]);
  readonly backLink$ = new BehaviorSubject<string[]>(null);
  readonly mapLinkData$ = new BehaviorSubject<{link: string[]; params: {}} | null>(null);

  readonly rightTemplate$ = this.subsystemBreadcrumbsService.rightTemplate$;

  readonly subsystemShellRoot$ = this.store.select(SubsystemShellState.rootRoute);
  readonly generationTaskId$ = this.store
    .select(RouterState.state<ExtendedRouterState>)
    .pipe(map(routerState => (routerState.pathParams?.generationTaskId as string) || null));

  title: string;
  parentsData: GenerationTaskWithSourceVariantDto[];

  constructor() {
    this.generationTaskId$
      .pipe(
        /**
         * До дестроя компонента роут успевает измениться и срабатывает ложный запрос.
         * Убираю его нулевым дебоунсом
         */
        debounceTime(0),
        distinctUntilChanged(),
        switchMap(() => this.getParents()),
        takeUntilDestroyed(),
      )
      .subscribe();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.checkBackLink());
  }

  trackBySubsystemName(_index: number, link: SubsystemBreadcrumbsLink): string {
    return link.subsystem;
  }

  // eslint-disable-next-line max-statements
  checkBackLink() {
    /** Текущий роут */
    const currentRoute = this.router.url;
    const routeParts = currentRoute.split(/[/?&=]/);
    const pathParams = this.store.selectSnapshot(RouterState.state<ExtendedRouterState>).pathParams;
    const taskNumber = this.parentsData?.reverse().find(entry => entry.subsystem === this.subsystemType)?.number;

    /** ID текущего задания */
    const generationTaskId = pathParams?.generationTaskId;

    /** ID текущего варианта */
    const variantId: string = pathParams?.variantId;

    /** ID текущего варианта вьювера */
    this.viewerVariantId = routeParts[routeParts.findIndex(f => f === 'variantId') + 1];

    /** Страница является таблицей заданий */
    const pageIsTaskList = !generationTaskId;

    /** Страница является конфигуратором задания */
    this.pageIsTask = routeParts.includes('task');

    /** Страница является списком вариантов */
    this.pageIsVariants = routeParts.includes('variants') && !variantId;

    /** Страница является вариантом */
    this.pageIsVariant = routeParts.includes('variants') && !!variantId;

    /** Страница является вариантом секции */
    this.pageIsSectionVariant = routeParts.includes('section-variant');

    this.pageIsViewer =
      routeParts.includes('page-ifc-viewer') ||
      routeParts.includes('structure-viewer') ||
      routeParts.includes('diagnostic-structure-viewer');

    this.checkMapLink(generationTaskId, variantId);

    if (pageIsTaskList) {
      /** Переход к проекту */
      this.backLink$.next(['.']);

      return;
    }

    if (this.pageIsSectionVariant) {
      /** Переход к списку вариантов секции */
      this.backLink$.next([SUBSYSTEM_ROUTE[this.subsystemType], generationTaskId, 'variants', variantId]);

      return;
    }

    if (this.pageIsTask || this.pageIsVariants) {
      this.title = `Задание  ${GenerationTaskSubsystemName[this.subsystemType]} ${taskNumber || ''}`;
      /** Переход к списку заданий */
      this.backLink$.next([SUBSYSTEM_ROUTE[this.subsystemType]]);

      return;
    }

    if (
      (this.pageIsVariant && this.subsystemType === 'Layout') ||
      (this.pageIsVariant && this.subsystemType === 'LayoutSections') ||
      (this.pageIsVariant && this.subsystemType === 'Structure')
    ) {
      this.title = `Вариант ${GenerationTaskSubsystemName[this.subsystemType]}`;

      /** Переход к списку вариантов */
      this.backLink$.next([SUBSYSTEM_ROUTE[this.subsystemType], generationTaskId, 'variants']);
    } else {
      /** Переход к списку заданий */
      this.backLink$.next([SUBSYSTEM_ROUTE[this.subsystemType]]);
    }
  }

  private getParents() {
    this.checkBackLink();
    const routerState = this.store.selectSnapshot(RouterState.state<ExtendedRouterState>);

    if (routerState.pathParams.generationTaskId) {
      return this.generationTaskApi.getParents(routerState.pathParams.generationTaskId).pipe(
        tap(data => {
          this.parentsData = data.slice();
          this.buildBreadcrumbs(this.parentsData);

          if (this.pageIsTask || this.pageIsVariants) {
            const variantNumber = data
              .slice()
              .reverse()
              .find(entry => entry.subsystem === this.subsystemType)?.number;

            this.title = `Задание ${GenerationTaskSubsystemName[this.subsystemType]} ${variantNumber}`;
          }

          if (this.pageIsViewer) {
            const projectId = data
              .slice()
              .reverse()
              .find(entry => entry.subsystem === this.subsystemType)?.projectId;

            this.title = `Вариант ${GenerationTaskSubsystemName[this.subsystemType]} / IFC Модель`;
            this.backLink$.next([
              '/portfolio',
              // TODO: в ответе метода getParents нет информации о портфеле
              'unknown',
              projectId,
              SUBSYSTEM_ROUTE[this.subsystemType],
              routerState.pathParams.generationTaskId,
              'variants',
              this.viewerVariantId,
            ]);

            this.mapLinkData$.next({
              link: [
                '/portfolio',
                // TODO: в ответе метода getParents нет информации о портфеле
                'unknown',
                projectId,
                'map',
              ],
              params: {generationTaskId: routerState.pathParams.generationTaskId, variantId: this.viewerVariantId},
            });
          }
        }),
      );
    }

    this.breadcrumbsData$.next([]);
    this.title = null;

    return of(null);
  }

  private buildBreadcrumbs(data: GenerationTaskWithSourceVariantDto[]) {
    const breadcrumbsData: SubsystemBreadcrumbsLink[] = [...initialData];

    data.forEach(generationTask => {
      const breadcrumbsIndex = breadcrumbsData.findIndex(f => {
        if (generationTask.subsystem === 'LayoutSections') {
          return f.subsystem === 'Layout';
        }

        return f.subsystem === generationTask.subsystem;
      });

      breadcrumbsData[breadcrumbsIndex] = {
        subsystem: generationTask.subsystem,
        generationTaskId: generationTask.id,
        projectId: generationTask.projectId,
        active: generationTask.subsystem === this.subsystemType,
        variantId: data.find(s => generationTask.id === s?.sourceVariant?.generationTaskId)?.sourceVariant?.id ?? null,
      };
    });

    this.breadcrumbsData$.next(breadcrumbsData);
  }

  private checkMapLink(generationTaskId: string | null, variantId: string | null) {
    const queryParams: {[k: string]: string} = {};

    if (generationTaskId) {
      queryParams.generationTaskId = generationTaskId;
    }

    if (variantId) {
      queryParams.variantId = variantId;
    }

    this.mapLinkData$.next({link: [ROUTES.map], params: queryParams});
  }
}
