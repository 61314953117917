/**
 * Тип секции
 *
 * @alias 'Platforma.enum.ApartmentFormat'
 */
export enum SectionType {
  Tower = 'Tower',
  Row = 'Row',
  Corner = 'Corner',
}
