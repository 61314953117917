<div
  class="menu"
  [ngClass]="{
    _expanded: isExpanded,
  }"
>
  <button type="button" class="menu-button" [ngClass]="{active: isActiveRoutes}" (click)="toggleDropdown()">
    <tui-icon class="menu-button__icon" [icon]="icon"></tui-icon>
    <ng-content select="[menu-button]"></ng-content>
    <tui-icon
      icon="@tui.chevron-down"
      class="menu-button__icon menu-button__icon--chevron"
      [ngClass]="{open: dropdownOpen && this.isExpanded}"
    ></tui-icon>
  </button>
  <div #dropdown class="dropdown" [ngClass]="{open: dropdownOpen && this.isExpanded}">
    <ng-content select="[menu-items]"></ng-content>
  </div>
</div>
