import {ApartmentFormatEnum} from '../../enums/layout-section';

export function layoutSectionGetApartName(roomCount: number, format: ApartmentFormatEnum): string {
  const formatPlural: {
    [key in ApartmentFormatEnum]: string;
  } = {
    Euro: 'Евро',
    Classic: '',
    Studio: 'студия',
  };

  return `${roomCount}-комнатная ${formatPlural[format]}`.trim();
}
