import {NgClass, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TuiIcon} from '@taiga-ui/core';

@Component({
  standalone: true,
  imports: [NgIf, RouterModule, TuiIcon, NgClass],
  selector: 'app-nav-menu',
  templateUrl: './app-nav-menu.component.html',
  styleUrls: ['./app-nav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavMenuComponent {
  @ViewChild('dropdown', {static: false})
  menuItems!: ElementRef;

  @Input()
  icon: string;

  @Input()
  set expanded(value: boolean) {
    this.isExpanded = value;

    this.dropdownOpen = value && this.isActiveRoutes;
    this.updateDropdownHeight();
  }

  @Input()
  set activeLinks(value: boolean) {
    this.isActiveRoutes = value;
  }

  isExpanded = false;
  isActiveRoutes = false;
  dropdownOpen = false;

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
    this.updateDropdownHeight();
  }

  private updateDropdownHeight(): void {
    if (!this.menuItems) {
      return;
    }

    const menuElement = this.menuItems.nativeElement;

    if (this.dropdownOpen) {
      const scrollHeight = menuElement.scrollHeight;

      menuElement.style.maxHeight = `${scrollHeight}px`;
    } else {
      menuElement.style.maxHeight = '0';
    }
  }
}
