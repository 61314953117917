import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NgModule} from '@angular/core';
import {TuiLoader} from '@taiga-ui/core';

import {IfcViewerComponent} from './ifc-viewer.component';

@NgModule({
  declarations: [IfcViewerComponent],
  exports: [IfcViewerComponent],
  imports: [CommonModule, NgOptimizedImage, TuiLoader],
})
export class IfcViewerModule {}
