import {OrganizationDto} from '@accounts-api/util';
import {EntryFormValue} from '@organizations/util';

const STATE_NAME = '[Organizations Entry Form]';

export class Reset {
  static readonly type = `${STATE_NAME} Clear`;
}

export class Create {
  static readonly type = `${STATE_NAME} Create`;
  constructor(public formValue: EntryFormValue) {}
}

export class CreateSuccess {
  static readonly type = `${STATE_NAME} Create Success`;
  constructor(public apiResponse: string) {}
}

export class CreateFailure {
  static readonly type = `${STATE_NAME} Create Failure`;
  constructor(public error: string) {}
}

export class Update {
  static readonly type = `${STATE_NAME} Update`;
  constructor(
    public groupId: string,
    public formValue: EntryFormValue,
  ) {}
}

export class UpdateSuccess {
  static readonly type = `${STATE_NAME} Update Success`;
  constructor(public apiResponse: OrganizationDto) {}
}

export class UpdateFailure {
  static readonly type = `${STATE_NAME} Update Failure`;
  constructor(public error: string) {}
}

export class UploadImage {
  static readonly type = `${STATE_NAME} Upload Image`;
  constructor(public file: File) {}
}

export class UploadImageSuccess {
  static readonly type = `${STATE_NAME} Upload Image Success`;
  constructor(
    public apiResponse: string,
    public file: File,
  ) {}
}

export class UploadImageFailure {
  static readonly type = `${STATE_NAME} Upload Image Failure`;
  constructor(
    public error: string,
    public file: File,
  ) {}
}
