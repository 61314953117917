import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  Output,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {BehaviorSubject, distinctUntilChanged, filter, tap} from 'rxjs';

import {IFCViewer} from './util';

@Component({
  selector: 'ntc-ifc-viewer',
  templateUrl: './ifc-viewer.component.html',
  styleUrls: ['./ifc-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IfcViewerComponent implements AfterViewInit {
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly zone = inject(NgZone);

  private readonly ifcBlob$ = new BehaviorSubject<Blob | null>(null);

  @Input()
  set ifc(file: Blob | null) {
    this.ifcBlob$.next(file);
  }

  @Output()
  closeIfc = new EventEmitter();

  viewer: IFCViewer | null = null;

  ngAfterViewInit() {
    this.ifcBlob$
      .pipe(
        filter(file => file !== null),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(file => {
        this.zone.runOutsideAngular(() => {
          const hostElement = this.elementRef.nativeElement.querySelector<HTMLElement>('#ifc-wrapper');
          const canvasElement = this.elementRef.nativeElement.querySelector<HTMLCanvasElement>('#ifc-viewer');

          this.viewer = new IFCViewer(hostElement, canvasElement, async () => {
            this.viewer.disposeModel();
            await this.viewer.load(file);
          });

          this.viewerClosingSubscription();
        });
      });
  }

  ngOnDestroy() {
    this.viewer?.disposeAll();
  }

  private viewerClosingSubscription(): void {
    this.viewer.closeIfc$
      .pipe(
        distinctUntilChanged(),
        tap(close => {
          if (close) {
            this.closeIfc.emit();
            this.viewer.closeIfc$.next(false);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
